import React from 'react'
import {
  Grid,
  Header,
  Button,
  Form,
  Icon,
  Divider,
  Message
} from 'semantic-ui-react'

import axios from 'axios'

import { validate } from 'chinese-idcard-checker'

import { withRouter } from 'react-router-dom'
import { countrySelect } from './CountrySelect'

class IdentityDetail extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      log: '',
      token: '',
      identifier: '',
      userInfo: [],
      userID: '',
      merchantInfo: {},

      firstName: '',
      lastName: '',
      idNum: '',
      country: '',
      verifyShow: false,
      idVerify: '',
      saved: false,
      doneShow: false,

      loading: true,
      somethingWrong: false,
      saveLoading: false
    }
    this.getUserInfo = this.getUserInfo.bind(this)

    this.onFirstName = this.onFirstName.bind(this)
    this.onLastName = this.onLastName.bind(this)
    this.onCountry = this.onCountry.bind(this)
    this.onIdNum = this.onIdNum.bind(this)
    this.onVerifyShow = this.onVerifyShow.bind(this)
    this.onVerify = this.onVerify.bind(this)

    this.handleBackClick = this.handleBackClick.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.hanldeDoneClick = this.hanldeDoneClick.bind(this)
    this.handleBackToStart = this.handleBackToStart.bind(this)
  }

  componentDidMount () {
    const self = this
    const log = localStorage.getItem('login')
    self.setState({ log: log })
    if (log === 'true') {
      self.getUserInfo()
    }
    window.onload = event => {
      self.setState({
        loading: false
      })
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 700)
  }

  getUserInfo () {
    const self = this
    const identifier = localStorage.getItem('identifier')
    const token = localStorage.getItem('token')

    self.setState({
      identifier: identifier,
      token: token
    })

    axios('https://sheyou-backend.herokuapp.com/users/me', {
      method: 'get',
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(function (res) {
        console.log(res)
        const userInfo = [res.data]
        if (userInfo === null) {
          self.setState({
            userInfo: [],
            userID: userInfo[0].id
          })
        } else if (userInfo.length > 0) {
          self.setState({
            userInfo: userInfo,
            userID: userInfo[0].id
          })
        }

        const merchantInfo = userInfo[0].merchantInfo
        console.log(merchantInfo)
        if (merchantInfo !== null) {
          self.setState({ merchantInfo: merchantInfo })
        }

        if (
          merchantInfo.address === undefined ||
          merchantInfo.creditcard === undefined ||
          merchantInfo.mobilephone === undefined
        ) {
          self.setState({ goToStart: true })
        } else {
          self.setState({ goToStart: false })
        }

        if (
          merchantInfo.identity !== null &&
          merchantInfo.identity !== undefined
        ) {
          self.setState({
            firstName: merchantInfo.identity.firstname,
            lastName: merchantInfo.identity.lastname,
            country: merchantInfo.identity.country,
            idNum: merchantInfo.identity.idnum,

            verifyShow: true,
            idVerify: true,
            saved: true,
            doneShow: true
          })
        }

        console.log(userInfo)
      })

      .catch(function (err) {
        self.props.history.push('/loginfirst')
      })
  }

  onFirstName (event) {
    this.setState({
      firstName: event.target.value,
      saved: false
    })
    this.onVerifyShow()
    this.onDoneShow()
  }

  onLastName (event) {
    this.setState({
      lastName: event.target.value,
      saved: false
    })
    this.onVerifyShow()
    this.onDoneShow()
  }

  onCountry (event, data) {
    this.setState({
      country: data.value,
      saved: false
    })
    this.onVerifyShow()
    this.onDoneShow()
  }

  onIdNum (event) {
    this.setState({
      idNum: event.target.value,
      saved: false
    })
    if (this.state.merchantInfo.identity !== undefined) {
      if (this.state.merchantInfo.identity.idnum === event.target.value) {
        this.setState({
          idVerify: true
        })
      } else {
        this.setState({
          idVerify: ''
        })
      }
    }
    this.onVerifyShow()
    this.onDoneShow()
  }

  onVerifyShow () {
    const firstName = this.state.firstName
    const lastName = this.state.lastName
    const idNum = this.state.idNum
    if (firstName !== '' && lastName !== '' && idNum !== '') {
      this.setState({
        verifyShow: true
      })
    } else {
      this.setState({
        verifyShow: false
      })
    }
  }

  onVerify () {
    const idNum = this.state.idNum
    if (validate(idNum) === true) {
      this.setState({
        idVerify: true,
        doneShow: true
      })
    } else {
      this.setState({
        idVerify: false
      })
    }
    console.log(this.state.idVerify)
  }

  onDoneShow () {
    const firstName = this.state.firstName
    const lastName = this.state.lastName
    const idNum = this.state.idNum
    const idVerify = this.state.idVerify
    // console.log(firstName, lastName, idNum, idVerify)
    if (
      firstName !== '' &&
      lastName !== '' &&
      idNum !== '' &&
      idVerify === true
    ) {
      this.setState({
        doneShow: true
      })
    } else {
      this.setState({
        doneShow: false
      })
    }
  }

  handleSaveClick () {
    const self = this
    const s = self.state
    const userID = this.state.userID
    const token = localStorage.getItem('token')
    if (s.idVerify === true) {
      const idInfo = {
        identity: {
          firstname: s.firstName,
          lastname: s.lastName,
          country: s.country,
          idnum: s.idNum
        }
      }
      
      self.setState({saveLoading: true})

      const merchantInfo = Object.assign(s.merchantInfo, idInfo)
      console.log(merchantInfo)

      axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
        method: 'put',
        data: { merchantInfo },
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
        .then(function (response) {
          console.log(response)
          self.setState({
            saved: true,
            somethingWrong: false,
            saveLoading: false
          })
          // console.log(s.merchantInfo)
        })

        .catch(function (err) {
          self.setState({
            somethingWrong: 'something is wrong' + err,
            saveLoading: false
          })
        })
    } else {
      self.setState({
        somethingWrong:
          'You have not verified your identity number, please verify before you save the information.'
      })
    }
  }

  handleBackClick () {
    const self = this
    const link = '/merchantVerify/phone'
    window.location.assign('https://sheyoufashion.com/#' + link)
    self.props.history.push(link)
    window.location.reload(true)
  }

  hanldeDoneClick () {
    const self = this
    const s = self.state
    const identity = s.merchantInfo.identity
    const userID = s.userID
    const link = '/merchantpage/product'
    const token=localStorage.getItem('token')
    const merchantVerified = {
      merchantVerified: true
    }

    const merchantInfo = Object.assign(s.merchantInfo, merchantVerified)
    console.log(merchantInfo)

    if (identity !== undefined) {
      if (s.idVerify === true && identity.idnum === s.idNum) {
        if (
          identity.country === s.country &&
          identity.firstname === s.firstName &&
          identity.lastname === s.lastName
        ) {
          self.setState({ saved: true })
        }
      }
    }

    if (s.saved === true && s.idVerify === true) {
      axios({
        method: 'put',
        url: 'https://sheyou-backend.herokuapp.com/users/' + userID,
        data: {
          merchantInfo: merchantInfo
        },
        headers: {
            Authorization: 'Bearer ' + token
          }

      })
        .then(function (response) {
          console.log(response)
          window.location.assign('https://sheyoufashion.com/#' + link)
          self.props.history.push(link)
          window.location.reload(true)
        })
        .catch(function (err) {
          self.setState({
            somethingWrong: 'something is wrong' + err
          })
        })
    } else if (s.saved !== true) {
      self.setState({
        somethingWrong:
          'You have not saved your information, please click the save button before you proceed.'
      })
    } else if (s.idVerify !== true) {
      self.setState({
        somethingWrong:
          'You have not verified your id number, please verify it before you proceed.'
      })
    }
  }

  handleBackToStart () {
    const self = this
    const link = '/merchantVerify/address'
    window.location.assign('https://sheyoufashion.com/#' + link)
    self.props.history.push(link)
    window.location.reload(true)
  }

  render () {
    const self = this
    const s = self.state
    const idVerify = s.idVerify
    const countries = countrySelect()

    return (
      <>
        {this.state.loading ? (
          <Grid>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>

            <Grid.Row colums={2}>
              <Grid.Column width={7}></Grid.Column>
              <Grid.Column width={6}>
                <Header size='huge'>
                  <Icon loading name='asterisk' size='massive' color='yellow' />
                  Loading...
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
          </Grid>
        ) : (
          <>
            {(Object.keys(s.merchantInfo).length === 0 || s.goToStart === true) && (
              <Grid centered>
                <Grid.Row>
                  <Message negative>
                    <Message.Header>
                      Required Merchant Verification Information Empty
                    </Message.Header>
                    <p>
                      Please go back and fill in your merchant address, or
                      verify your credit card, or verify your phone number
                      first.
                    </p>
                  </Message>
                </Grid.Row>

                <Grid.Row columns={1}>
                  <Grid.Column>
                    <Button
                      onClick={this.handleBackToStart}
                      color='orange'
                      fluid
                    >
                      BACK TO START
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )}

            {Object.keys(s.merchantInfo).length !== 0 && s.goToStart === false && (
              <Grid>
                <Grid.Row columns={1}>
                  <Grid.Column width={16}>
                    <Header>Verify Your Identity Detail</Header>
                    <Divider />
                    <Form style={{ position: 'relative', left: -70 }}>
                      <Form.Group>
                        <Form.Field style={{ marginLeft: 285, marginRight: 0 }}>
                          <Form.Input
                            required
                            label='First Name'
                            placeholder='First Name'
                            style={{ width: 300 }}
                            value={this.state.firstName}
                            onChange={this.onFirstName}
                          />
                        </Form.Field>
                        <Form.Field style={{ marginLeft: 110, marginRight: 0 }}>
                          <Form.Input
                            required
                            label='Last Name'
                            placeholder='Last Name'
                            style={{ width: 300 }}
                            value={this.state.lastName}
                            onChange={this.onLastName}
                          />
                        </Form.Field>
                      </Form.Group>
                      <Form.Group>
                        <Form.Field style={{ marginLeft: 285, marginRight: 0 }}>
                          <Form.Select
                            required
                            options={countries}
                            label='Country of Citizenship'
                            placeholder='Select'
                            style={{ width: 300 }}
                            search
                            searchInput={{}}
                            onChange={this.onCountry}
                            value={s.country}
                          />
                        </Form.Field>
                        <Form.Field style={{ marginLeft: 110, marginRight: 0 }}>
                          <Form.Input
                            required
                            label='ID Number'
                            placeholder='Enter ID Number'
                            style={{ width: 300 }}
                            value={this.state.idNum}
                            onChange={this.onIdNum}
                          />
                        </Form.Field>
                      </Form.Group>
                    </Form>
                    <Grid.Row>
                      {idVerify === true && (
                        <Message success>
                          <Message.Header>Verify Successful</Message.Header>
                          <p>Your ID number has been successfully verified.</p>
                        </Message>
                      )}
                      {idVerify === false && (
                        <Message negative>
                          <Message.Header>Verify Failure</Message.Header>
                          <p>Your ID number is invalid, please check again.</p>
                        </Message>
                      )}
                    </Grid.Row>
                    <br />
                    <Button onClick={this.onVerify} disabled={!s.verifyShow}>
                      Verify
                    </Button>

                    {s.somethingWrong !== false && (
                      <Grid.Row>
                        <br />
                        <Message warning>
                          <Message.Header>Somthing Went Wrong</Message.Header>
                          <p>{this.state.somethingWrong}</p>
                        </Message>
                      </Grid.Row>
                    )}
                    {(s.saved===true && s.saveLoading===false)&& (
                      <Grid.Row>
                          <br/>
                        <Message positive>
                          <Message.Header>Saved</Message.Header>
                          <p>
                            You have saved your Identity Detail, please confirm
                            and then click NEXT
                          </p>
                        </Message>
                      </Grid.Row>
                    )}
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row columns={3}>
                  <Grid.Column>
                    <Button onClick={this.handleBackClick} fluid color='orange'>
                      Back
                    </Button>
                  </Grid.Column>
                  <Grid.Column>
                    <Button loading={this.state.saveLoading} onClick={this.handleSaveClick} fluid>
                      SAVE
                    </Button>
                  </Grid.Column>
                  <Grid.Column>
                    <Button
                      onClick={this.hanldeDoneClick}
                      fluid
                      color='orange'
                      disabled={!s.doneShow}
                    >
                      Done
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )}
          </>
        )}
      </>
    )
  }
}

export default withRouter(IdentityDetail)
