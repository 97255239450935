import React from 'react'
import PastPastOrders from './PastPastOrders'

class PastPastOrderList extends React.Component {



    render() {
        const p = this.props
        const orders = p.orders
        const isMerchantOrder=p.isMerchantOrder
        const userId=p.userId
        const activeItem=p.activeItem
        const mobile=p.mobile

        return (orders.map(function(it) {
            if(it.canceled!==true){
                if(it.orders.data.rentOrBuy==="rent"&&it.returned===true)
                return <PastPastOrders returnInfo={it.orders.returnInfo} mobile={mobile} userId={userId} data={it} activeItem={activeItem} orderid={it.id} delivered={it.delivered} isMerchantOrder={isMerchantOrder}/>
                if(it.orders.data.rentOrBuy==="buy"&&it.delivered===true)
                return <PastPastOrders returnInfo={it.orders.returnInfo} mobile={mobile} userId={userId} data={it} activeItem={activeItem} orderid={it.id} delivered={it.delivered} isMerchantOrder={isMerchantOrder}/>
                
            }
            
        }))
        
    }
}


PastPastOrderList.defaultProps = {
    orders: []
}


export default PastPastOrderList