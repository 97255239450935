import React from 'react'
import PastOrder from './PastOrder'

class PastOrders extends React.Component {



    render() {
        const p = this.props
        const orders = p.orders
        const activeItem = p.activeItem
        const isMerchantOrder=p.isMerchantOrder
        const userId=p.userId
        const mobile=p.mobile
        console.log(p)


        return (orders.map(function (it) {
            console.log(it)
            if (it.canceled !== true) {
                return <PastOrder mobile={mobile} data={it} orderid={it.id} activeItem={activeItem}
                isMerchantOrder={isMerchantOrder} userId={userId}
                delivered={it.delivered} returnInfo={it.orders.returnInfo}/>

            }
        }))



    }
}


PastOrders.defaultProps = {
    orders: []
}


export default PastOrders