import React from 'react'
import {
  Menu,
  Button,
  Icon,
  Dropdown,
  Confirm,
  Header,
  Search,
  Label
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import axios from 'axios'

class Nav extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      activeItem: '',
      login: 'false',
      open: false,
      identifier: '',
      cart: [],
      bag: [],
      accessoryCart: [],
      accessoryBag: [],
      isAMember: false,
      windowWidth: 0
      // show:false
    }
    this.onHandleSignOut = this.onHandleSignOut.bind(this)
    this.handleItemClick = this.handleItemClick.bind(this)
    this.show = this.show.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleItemClickMember = this.handleItemClickMember.bind(this)
  }

  componentDidMount () {
    // const self=this

    this.getUserInfo()
    // const cart=this.state.cart
    // const bag=this.state.bag
  }

  handleItemClickMember () {
    const isAMember = this.state.isAMember
    if (isAMember === true) {
      this.props.history.push('/mypage/membership')
    } else {
      this.props.history.push('/plans')
    }
  }

  getUserInfo () {
    const self = this
    const log = localStorage.getItem('login')
    const identifier = localStorage.getItem('identifier')

    const token = localStorage.getItem('token')

    // const now = timestamp.utc('YYYY-MM-DD')
    // console.log(now)

    // const activeItem=localStorage.getItem('menu')
    // if(activeItem!==undefined||activeItem!==null)
    // {self.setState({activeItem:activeItem})}

    self.setState({
      login: log,
      identifier: identifier
    })

    if (log === 'true') {
      axios('https://sheyou-backend.herokuapp.com/users/me', {
        method: 'get',
        headers: {
          Authorization: 'Bearer ' + token
        }
      }).then(function (res) {
        if (res.status === 200) {
          var userInfo = [res.data]

          if (userInfo[0].cart === null) {
            userInfo[0].cart = []
          }
          if (userInfo[0].accessoryCart === null) {
            userInfo[0].accessoryCart = []
          }
          if (userInfo[0].accessoryBag === null) {
            userInfo[0].accessoryBag = []
          }
          if (userInfo[0].bag === null) {
            userInfo[0].bag = []
          }
          self.setState({
            userInfo: userInfo,
            userID: userInfo[0].id,
            cart: userInfo[0].cart,
            bag: userInfo[0].bag,
            accessoryCart: userInfo[0].accessoryCart,
            accessoryBag: userInfo[0].accessoryBag,
            membership: userInfo[0].membership
          })

          if (userInfo[0].membership !== null) {
            self.setState({
              isAMember: userInfo[0].membership.isAMember
            })
          }

          userInfo = JSON.stringify(userInfo)
          localStorage.setItem('userInfo', userInfo)
        } else {
          alert('Something went wrong, maybe log in again :(')
          localStorage.setItem('login', 'false')
        }
      })
    }
  }

  handleItemClick (target, data) {
    // const name=data.name
    const link = data.linkto
    console.log(target)
    this.setState({
      activeItem: data.name
    })
    if (link === '/clothing') {
      localStorage.setItem('filter', 'All')
    }
    if (link === '/accessory') {
      localStorage.setItem('accessoryFilter', 'All Accessories')
    }
    if (link === '/sale') {
      localStorage.setItem('filter', 'All')
    }
    if (link === '/heart') {
      localStorage.setItem('filter', 'All')
    }

    const self = this
    if (link === '/merchants') {
      const merchantLogin = localStorage.getItem('merchantLogin')
      if (merchantLogin !== 'true') {
        self.props.history.push('/merchantsLog')
      } else {
        self.props.history.push('/merchantPage')
      }
    } else {
      self.props.history.push(link)
    }
    // if (name===undefined||name===null)
    // {
    //   localStorage.setItem('menu',"Home")
    //   self.setState({activeItem:name})
    // }
    // else
    // {
    //   localStorage.setItem('menu',name)
    //   self.setState({activeItem:name})
    // }
    self.props.history.push(link)
    window.setTimeout(() => {
      if (self.state.loading === true) {
        window.location.reload(true)
      }
    }, 200)
  }

  onHandleSignOut (event) {
    event.preventDefault()
    const self = this
    self.setState({ login: false, open: false })
    const rememberMe = localStorage.getItem('rememberMe')
    if (rememberMe === 'true') {
      localStorage.setItem('login', 'false')
      localStorage.setItem('merchantLogin','false')
    } else {
      localStorage.setItem('login', 'false')
    localStorage.setItem('merchantLogin','false')
    localStorage.setItem('identifier','')
    localStorage.setItem('password','')
    localStorage.removeItem('userInfo')
    localStorage.removeItem('cartItem')
    localStorage.removeItem('cartProducts')
    localStorage.removeItem('token')
    localStorage.removeItem('log')
    }

    // alert('you have been signed out')
    self.props.history.push('/')
  }

  show () {
    const self = this
    self.setState({ open: true })
  }

  handleCancel () {
    const self = this
    self.setState({ open: false })
  }

  // handleItemClick = (e, { name }) => this.setState({ activeItem: name })

  render () {
    const activeItem = this.state.activeItem
    const log = this.state.login
    const p = this.props
    const windowWidth = p.windWidth

    return (
      <>
        <Menu
          borderless
          color='teal'
          size='huge'
          inverted
          style={{ marginTop: 0, marginBottom: 0 }}
        >
          <Menu.Item>
            <Link to='/'>
              <Header
                as='h1'
                inverted
                linkto='/'
                name='Home'
                active={activeItem === 'Home'}
              >
                SHEYOU
                <Label style={{ backgroundColor: '#F5F5DD' }}>BETA</Label>
              </Header>
            </Link>
          </Menu.Item>

          <Dropdown item icon='bars' style={{ width: 200 }}>
            <Dropdown.Menu>
              <Menu.Item
                linkto='/clothing'
                name='Browse'
                active={activeItem === 'Browse'}
                onClick={this.handleItemClick}
                className='bottomDivider'
              >
                Browse
              </Menu.Item>
              <Menu.Item
                name='About'
                linkto='/about'
                active={activeItem === 'About'}
                onClick={this.handleItemClick}
                className='bottomDivider'
              >
                About
              </Menu.Item>

              <Menu.Item
                linkto='/merchants'
                name='Merchants'
                active={activeItem === 'Merchants'}
                onClick={this.handleItemClick}
                className='bottomDivider'
                
              >
                Merchants
              </Menu.Item>

              <Menu.Item
                name='Contact'
                linkto='/contactUs'
                active={activeItem === 'Contact'}
                onClick={this.handleItemClick}
                className='bottomDivider'
              >
                Contact
              </Menu.Item>

              <Menu.Item>
                <Menu.Item>
                  <Search placeholder='Search...' />
                </Menu.Item>
              </Menu.Item>

              {log === 'true' && (
                <>
                  <Dropdown.Divider />
                  <Menu.Item
                    name='MyPage'
                    linkto='/mypage'
                    active={activeItem === 'MyPage'}
                    onClick={this.handleItemClick}
                  >
                    My Page
                  </Menu.Item>
                  <Menu.Item
                    name='Cart'
                    linkto='/cart'
                    active={activeItem === 'Cart'}
                    onClick={this.handleItemClick}
                  >
                    <Icon name='shopping cart' style={{ paddingRight: 50 }} />
                    Cart
                  </Menu.Item>
                  <Menu.Item
                    name='Heart'
                    linkto='/heart'
                    active={activeItem === 'Heart'}
                    onClick={this.handleItemClick}
                  >
                    <Icon name='heart' style={{ paddingRight: 50 }} />
                    Heart
                  </Menu.Item>

                  <Menu.Item
                    name='Message'
                    linkto='/message'
                    active={activeItem === 'Message'}
                    onClick={this.handleItemClick}
                  >
                    <Icon name='chat' style={{ paddingRight: 50 }} />
                    Message
                  </Menu.Item>
                </>
              )}

              {windowWidth < 655 && (
                <>
                  <Dropdown.Divider />
                  {log === 'true' ? (
                    <Menu.Item onClick={this.show} style={{marginBottom:8}}>
                      <Icon name='sign-out' style={{ paddingRight: 50 }} />
                      Sign out
                    </Menu.Item>
                  ) : (
                    <Menu.Item as={Link} to='/signin' style={{marginBottom:8}}>
                      <Icon name='sign-in' style={{ paddingRight: 50 }} /> Sign
                      In / Sign Up
                    </Menu.Item>
                  )}
                </>
              )}
            </Dropdown.Menu>
          </Dropdown>

          {windowWidth >= 655 && (
            <Menu.Menu position='right'>
              {log === 'true' ? (
                <Menu.Item>
                  <Button size='huge' inverted onClick={this.show}>
                    Sign out
                  </Button>
                </Menu.Item>
              ) : (
                <Menu.Item>
                  <Link to='/signin'>
                    <Button size='huge' inverted>
                      Sign In / Sign Up
                    </Button>
                  </Link>
                </Menu.Item>
              )}
            </Menu.Menu>
          )}
        </Menu>

        <Confirm
          open={this.state.open}
          cancelButton='Never mind'
          confirmButton='Yes'
          content='Are you sure you want to log out?'
          onCancel={this.handleCancel}
          onConfirm={this.onHandleSignOut}
        />
      </>
    )
  }
}

export default withRouter(Nav)
