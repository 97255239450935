import React from 'react'
import {
  Grid,
  Header,
  Segment,
  Icon,
  Container,
  Button,
  Divider
} from 'semantic-ui-react'
import 'react-chat-elements/dist/main.css'
import { Input, MessageList, ChatItem } from 'react-chat-elements'
import { orderBy } from 'natural-orderby'
import '../../App.css'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import NavMobilePhone from '../nav/NavMobilePhone'
import ToPrevious from '../RedirectPages/ToPrevious'
import LoadingPhone from '../RedirectPages/LoadingPhone'
class MessagePhone extends React.Component {
  constructor (props) {
    super(props)
    this.inputText = React.createRef()
    this.state = {
      loading: true,
      sender: '',
      senderName: '',
      receiver: '',
      messages: false,
      receiverInfo: '',
      chatList: [],
      receiverName: '',
      chatUsers: [],
      lastMessages: false,
      messageLoaded: false,
      buttonLoading: false,
      scrollTo: 'bottom',
      scrollLoading: false,
      noMoreChat: 'none',
      chatNum: 6,
      messageLength: 'none'
    }
    this.handleInput = this.handleInput.bind(this)
    this.getMessage = this.getMessage.bind(this)
    this.sendMessage = this.sendMessage.bind(this)
    this.getChatList = this.getChatList.bind(this)
    this.onScrollToTop = this.onScrollToTop.bind(this)
    this.getNewInfo = this.getNewInfo.bind(this)
  }

  getNewInfo () {
    var messageInfo = localStorage.getItem('messageInfo')
    messageInfo = JSON.parse(messageInfo)
    var receiver = messageInfo.id
    var receiverName = messageInfo.name
    var receiverInfo = messageInfo.info

    const self = this

    receiverInfo.updated_at = new Date()
    self.setState({
      receiver: receiver,
      receiverName: receiverName,
      receiverInfo: receiverInfo
    })
    receiver = JSON.stringify(receiver)
    receiverName = JSON.stringify(receiverName)
    receiverInfo.updated_at = new Date()
    receiverInfo = JSON.stringify(receiverInfo)
    localStorage.setItem('receiver', receiver)
    localStorage.setItem('receiverName', receiverName)
    localStorage.setItem('receiverInfo', receiverInfo)

    self.getChatList()
    self.getMessage()
  }

  componentDidMount () {
    window.scrollTo(0, 0)

    const self = this

    console.log(self, document, window)

    var receiver = localStorage.getItem('receiver')
    const p = this.props
    var list = p.list
    self.setState({
      list: list
    })

    window.onload = event => {
      self.setState({
        loading: false
      })
    }

    window.setTimeout(() => {
      const element = document.getElementById('aUniqueScrollBox')
      if (element === null) {
        window.location.reload(1)
      }
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 1000)
    const login = localStorage.getItem('login')
    if (login === 'true') {
      self.getChatList()

      if (
        receiver !== null &&
        receiver !== undefined &&
        receiver !== '' &&
        receiver.length!== 0
      ) {
        self.getMessage()
        self.getNewInfo()
      }
    }
    
  }

  getChatList () {
    const self = this
    const token = localStorage.getItem('token')
    var sender = localStorage.getItem('sender')
    if (sender === undefined || sender === null || sender === '') {
      sender = localStorage.getItem('userInfo')
      sender = JSON.parse(sender)
      sender = sender.id
    }
    var senderName = localStorage.getItem('senderName')
    var receiver = localStorage.getItem('receiver')
    var receiverInfo = localStorage.getItem('receiverInfo')
    var receiverName = receiverInfo.username
    if (
      receiverInfo !== '' &&
      receiverInfo !== undefined &&
      receiverInfo !== null
    ) {
      receiverInfo = JSON.parse(receiverInfo)
      self.setState({
        receiver: receiver,
        receiverInfo: receiverInfo,
        receiverName: receiverName
      })
      
    }

    self.setState({
      sender: sender,
      senderName: senderName
    })
    var chatUsers = []

    axios('https://sheyou-backend.herokuapp.com/users/' + sender, {
      method: 'get',
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(function (res) {
        if (res.status === 200) {
          if (res.date.length!== 0) {
            chatUsers = res.data.chatUsers
            // console.log(chatUsers)
            chatUsers.forEach(function (e) {
              // console.log(e, receiver)
              const id = JSON.stringify(e.id)
              if (id === receiver) {
                e.updated_at = new Date()
                self.setState({
                  receiverName: e.username,
                  receiver: e.id,
                  receiverInfo: e
                })
                localStorage.setItem('receiverName', e.username)
              }
            })

            chatUsers = orderBy(chatUsers, [v => v.updated_at], ['desc'])

            const chatListLength = chatUsers.length
            var lastMessages = []
            var i = 0

            chatUsers.forEach(function (e) {
              axios(
                'https://sheyou-backend.herokuapp.com/messages?messageSender=' +
                  sender +
                  '&messageSender=' +
                  e.id +
                  '&messageReceiver=' +
                  sender +
                  '&messageReceiver=' +
                  e.id +
                  '&_sort=created_at:DESC',
                {
                  method: 'get',
                  headers: {
                    Authorization: 'Bearer ' + token
                  }
                }
              )
                .then(function (res) {
                  if (res.status === 200) {
                    // console.log(res)
                    const id = JSON.stringify(e.id)
                    if (id === receiver) {
                      const messageLength = res.data.length
                      // console.log(res, messageLength)
                      self.setState({
                        messageLength: messageLength
                      })
                    }
                    lastMessages.push(res.data[0])
                    self.setState({ lastMessages: lastMessages })
                  }
                  i++
                  if (i === chatListLength) {
                    self.setState({
                      messageLoaded: true
                    })
                  }
                })
                .catch(function (err) {
                  i++
                  self.props.history.push('/loginfirst')
                })
            })
            self.setState({
              chatUsers: chatUsers
            })
          } else {
            if (
              receiver !== null &&
              receiver !== undefined &&
              receiver !== '' &&
              receiver.length!== 0
            ) {
              axios('https://sheyou-backend.herokuapp.com/users/' + sender, {
                method: 'put',
                headers: {
                  Authorization: 'Bearer ' + token
                }
              })
                .then(function (e) {
                  self.setState({
                    receiverName: e.data.username,
                    receiver: e.data.id,
                    receiverInfo: e.data
                  })
                  axios(
                    'https://sheyou-backend.herokuapp.com/messages?messageSender=' +
                      sender +
                      '&messageSender=' +
                      e.data.id +
                      '&messageReceiver=' +
                      sender +
                      '&messageReceiver=' +
                      e.data.id,
                    {
                      method: 'get',
                      headers: {
                        Authorization: 'Bearer ' + token
                      }
                    }
                  )
                    .then(function (res) {
                      var lastMessages = []
                      lastMessages.push(res.data[0])
                      const id = JSON.stringify(e.data.id)
                      if (id === receiver) {
                        const messageLength = res.data.length
                        console.log(res, messageLength)
                        self.setState({
                          messageLength: messageLength
                        })
                      }
                      self.setState({
                        lastMessages: lastMessages,
                        messageLoaded: true
                      })
                    })
                    .catch(function (err) {
                      self.props.history.push('/loginfirst')
                    })
                })
                .catch(function (err) {
                  self.props.history.push('/loginfirst')
                })
            }
          }
        }
      })
      .catch(function (err) {
        self.props.history.push('/loginfirst')
      })
  }

  getMessage (add) {
    const self = this
    const s = this.state
    var messageInfo = localStorage.getItem('messageInfo')
    messageInfo = JSON.parse(messageInfo)
    // console.log(messageInfo, messageLength)

    var sender = localStorage.getItem('sender')
    var receiver = localStorage.getItem('receiver')
    const token = localStorage.getItem('token')

    const newId = messageInfo.id
    const adddd = messageInfo.add
    if (add === undefined) {
      if (adddd !== undefined) {
        add = adddd
      }
    }
    // console.log(add, newId)
    var num = s.chatNum
    if (add === 'newMessage' && newId !== undefined) {
      messageInfo.add = undefined
      messageInfo = JSON.stringify(messageInfo)
      localStorage.setItem('messageInfo', messageInfo)
      const id = newId
      num = 6
      var chatUsers = s.chatUsers
      chatUsers.forEach(function (e) {
        if (e.id === id) {
          e.updated_at = new Date()
        }
      })

      chatUsers = orderBy(chatUsers, [v => v.updated_at], ['desc'])
      self.setState({
        chatUsers: chatUsers
      })
    }
    if (add === 10) {
      num = num + add
      self.setState({
        scrollLoading: true
      })
    } else if (add === 'new message') {
      num = num + 1
      self.setState({
        scrollTo: 'bottom',
        buttonLoading: true
      })
    }

    axios(
      'https://sheyou-backend.herokuapp.com/messages?_sort=created_at:DESC' +
        '&messageSender=' +
        sender +
        '&messageReceiver=' +
        sender +
        '&messageSender=' +
        receiver +
        '&messageReceiver=' +
        receiver +
        '&_limit=' +
        num,
      {
        method: 'get',
        headers: {
          Authorization: 'Bearer ' + token
        }
      }
    )
      .then(function (res) {
        if (res.status === 200) {
          const messageLength = self.state.messageLength
          // console.log(messageLength, s.chatNum)
          if (
            messageLength !== 'none' &&
            (messageLength < s.chatNum + 1 || messageLength === s.chatNum + 1)
          ) {
            self.setState({
              noMoreChat: true
            })
          } else if (
            messageLength === 'none' ||
            messageLength > s.chatNum + 1
          ) {
            self.setState({
              noMoreChat: false
            })
          }
          self.setState({
            scrollLoading: false,
            chatNum: num
          })
          var messages = res.data
          messages = orderBy(messages, [v => v.created_at], ['asc'])

          if (res.date.length!== 0) {
            self.setState({
              messages: messages,
              buttonLoading: false
            })
            if (add === 'new message') {
              self.setState({
                scrollTo: 'bottom'
              })
            } else if (add === 10) {
              self.setState({
                scrollTo: 'original'
              })
            }
          }
        }
      })
      .catch(function (err) {
        self.props.history.push('/loginfirst')
      })
  }

  sendMessage (event, data) {
    var sender = localStorage.getItem('sender')
    var receiver = localStorage.getItem('receiver')
    const token = localStorage.getItem('token')
    const self = this
    var text = this.state.inputText
    // console.log(event, data)
    self.inputText.current.value = ''
    axios('https://sheyou-backend.herokuapp.com/messages', {
      method: 'post',
      data: {
        messageReceiver: receiver,
        messageSender: sender,
        messageText: text
      },
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(function (res) {
        if (res.status === 200) {
          self.getMessage('new message')
        }
      })
      .catch(function (err) {
        self.props.history.push('/loginfirst')
      })
  }

  handleInput (event, data) {
    event.preventDefault()
    const self = this
    self.setState({
      inputText: self.inputText.current.value
    })
  }

  onScrollToTop (event, data) {
    event.preventDefault()

    const scrolltop = event.target.scrollTop

    if (scrolltop === 0) {
      this.getMessage(10)
    }
  }

  render () {
    const loading = this.state.loading

    const s = this.state
    const list = s.list
    const self = this
    // console.log(s, self.scrollBar)
    var element = document.getElementById('aUniqueScrollBox')
    console.log(element)
    if (element !== null) {
      if (s.scrollTo === 'bottom') {
        element.scrollTop = element.scrollHeight
      } else if (s.scrollTo === 'top') {
        
          element.scrollTop = 0
        
      }
    }

    
    const windowHeight = window.innerHeight
    const windowWidth = window.windowWidth

    return (
      <div>
        {/* <Nav /> */}

        {loading ? (
          <LoadingPhone />
        ) : (
          <>
           
              <>
                {s.chatUsers.length === 0 && (
                  <Grid>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>

                    <Grid.Row colums={2}>
                      <Grid.Column width={7}></Grid.Column>
                      <Grid.Column width={6}>
                        <Header size='huge'>No Chats Available</Header>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                  </Grid>
                )}

                {s.chatUsers.length!== 0 && (
                  <Container
                    style={{ width: windowWidth, height: windowHeight }}
                  >
                    <Grid>
                      <>
                        <Grid.Row>
                          <Grid.Column width={2}>
                            <ToPrevious color='grey' />
                          </Grid.Column>
                          <Grid.Column width={14}>
                            {s.messages ? (
                              <ChatItem
                                avatar={
                                  'https://ui-avatars.com/api/?name=' +
                                  s.receiverName +
                                  '&background=random'
                                }
                                alt={s.receiverName}
                                title={s.receiverName}
                                date={false}
                              />
                            ) : (
                              <></>
                            )}
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Column width={16}>
                          <Container
                            id='aUniqueScrollBox'
                            onScroll={self.onScrollToTop}
                            style={{
                              height: windowHeight - 220,
                              marginTop: 20,
                              overflowY: 'scroll'
                            }}
                          >
                            {s.noMoreChat !== true && s.scrollLoading && (
                              <Grid>
                                <Grid.Row></Grid.Row>

                                <Grid.Row centered>
                                  <Header>
                                    <Icon
                                      loading
                                      name='asterisk'
                                      color='yellow'
                                    />
                                    Loading chat history
                                  </Header>
                                </Grid.Row>
                                <Grid.Row></Grid.Row>
                              </Grid>
                            )}
                            {s.noMoreChat &&
                              s.scrollLoading !== true &&
                              s.messages.length !== 0 && (
                                <Grid>
                                  <Grid.Row centered>
                                    <p>No More Chat History</p>
                                  </Grid.Row>
                                  <Divider />
                                  <Grid.Row></Grid.Row>
                                </Grid>
                              )}
                            {!s.noMoreChat &&
                              s.scrollLoading !== true &&
                              s.messages.length !== 0 && (
                                <Grid>
                                  <Grid.Row></Grid.Row>
                                  <Grid.Row centered>
                                    <Icon
                                      name='chevron up'
                                      size='big'
                                      color='grey'
                                      onClick={self.onScrollToTop}
                                    />
                                  </Grid.Row>
                                  <Grid.Row></Grid.Row>
                                </Grid>
                              )}

                            {s.messages.length > 0 ? (
                              <>
                                <MessageList
                                  toBottomHeight='300'
                                  className='message-list'
                                  dataSource={s.messages.map(function (m) {
                                    const id = JSON.stringify(
                                      m.messageSender[0].id
                                    )
                                    return {
                                      position:
                                        id === s.sender ? 'right' : 'left',
                                      type: 'text',
                                      title: m.messageSender[0].username,
                                      text: m.messageText,
                                      date: m.created_at
                                    }
                                  })}
                                />
                              </>
                            ) : (
                              <>
                                <Grid>
                                  <Grid.Row centered>
                                    <Header color='grey'>
                                      No Chat History
                                    </Header>
                                  </Grid.Row>
                                </Grid>
                              </>
                            )}
                          </Container>

                          <Segment>
                            <Input
                              placeholder='Type here...'
                              multiline={true}
                              type='string'
                              referance={self.inputText}
                              defaultValue={s.inputText}
                              onChange={this.handleInput}
                              rightButtons={
                                <Button
                                  color='white'
                                  backgroundColor='black'
                                  text='Send'
                                  content='Send'
                                  loading={s.buttonLoading}
                                  onClick={this.sendMessage}
                                />
                              }
                            />
                          </Segment>
                        </Grid.Column>
                      </>
                    </Grid>
                  </Container>
                )}
              </>
           
          </>
        )}
        {list && <NavMobilePhone />}
      </div>
    )
  }
}

export default withRouter(MessagePhone)
