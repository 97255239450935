import React from 'react'
import axios from 'axios'
import { Grid, Header, Icon, Dropdown, Pagination, Segment} from 'semantic-ui-react'
import MainFilter from '../clothingPage/MainFilter'
// import SizeFilter from './SaleSizeFilter'
import { withRouter } from 'react-router-dom'
import SaleCardTemplete from './SaleCardTemplete'
import Foot from '../foot/Foot'
import { Parallax } from 'react-parallax';
import round from '@stdlib/math-base-special-round'
const userLang = navigator.language || navigator.userLanguage



class SalePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      productLoading: false,
      // productShow: [],

      catOpt: [],
      catLoading: false,
      sizeOpt: [],
      sizeLoading: false,
      genderOpt: [],
      genderLoading: false,

      filterCat: 2,
      filterSize: 0,
      filterGender: 0,

      activePage: 1,
      pageNum: 0
      // productId
    }
    // this.onHandleClick = this.onHandleClick.bind(this)
    this.handlePaginationChange=this.handlePaginationChange.bind(this)
    this.getFilterProduct = this.getFilterProduct.bind(this)

  }
  componentDidMount() {

    window.scrollTo(0, 0)

    const self = this
    const p = this.props
    const search = p.location.search 
    var filterCat = {}
    var filterSize = {}
    var filterGender = {}

    if (search === null || search === undefined || search === '') {
       filterCat = this.state.filterCat
       filterSize = this.state.filterSize
       filterGender = this.state.filterGender
    }else{
      const params = new URLSearchParams(search);
      var page = params.get('p')
       filterCat = parseInt(params.get('cat'))
       filterSize = parseInt(params.get('size'))
       filterGender = parseInt(params.get('gender'))
    }
    console.log(filterGender)

    if(page===null||page===undefined||page===''){
      page=1
    }

    if (page !== undefined && page !== null) {
      self.setState({
        activePage: page,
        filterCat: filterCat,
        filterSize: filterSize,
        filterGender: filterGender
      })
      // self.getProducts()
    }

    
    self.setState({loading: true})
    
    self.getFilterInfo()

    console.log(filterSize)

    if(filterSize === 0){
      if(filterGender === 0){
        axios({
            url: 'https://sheyou-backend.herokuapp.com/second-hand-products?categories.id='+filterCat,
            method: 'get',
            // headers: {
            //     'Authorization': 'Bearer ' + token
            // }
        }).then(function(res){
          var data = res.data
          var dataShow = []
          const itemNum = data.length
          var activePage = self.state.activePage
          activePage = parseInt(activePage)
          for (var i = activePage * 9 - 9; i < activePage * 9; i++) {
            if (i < itemNum) {
              dataShow.push(data[i])
            }
          }

          var roundPageNum = 0
          var pageNum = 0
          roundPageNum = round(itemNum / 9)
          pageNum = itemNum / 9.0

          if(pageNum - roundPageNum  > 0){
            pageNum = roundPageNum + 1
          }else{
            pageNum = roundPageNum
          }

          self.setState({
            productShow: dataShow,
            pageNum: pageNum,
            loading: false,
            itemNum: itemNum
          })
        }).catch(function(err){
            console.log(err)
        })
      }else{
        axios({
          url: 'https://sheyou-backend.herokuapp.com/second-hand-products?genders.id='+filterGender+'&categories.id='+filterCat,
          method: 'get',
          // headers: {
          //     'Authorization': 'Bearer ' + token
          // }
      }).then(function(res){
          const data = res.data
          var dataShow = []
          const itemNum = data.length
          var activePage = self.state.activePage
          activePage = parseInt(activePage)
          for (var i = activePage * 9 - 9; i < activePage * 9; i++) {
            if (i < itemNum) {
              dataShow.push(data[i])
            }
          }

          var roundPageNum = 0
          var pageNum = 0
          roundPageNum = round(itemNum / 9)
          pageNum = itemNum / 9.0

          if(pageNum - roundPageNum  > 0){
            pageNum = roundPageNum + 1
          }else{
            pageNum = roundPageNum
          }
          self.setState({
            productShow: dataShow,
            pageNum: pageNum,
            loading: false,
            itemNum: itemNum
          })
          console.log(pageNum)
          
      }).catch(function(err){
          console.log(err)
      })
      }
    }else{
      if(filterGender === 'All'){
        //filter only category
        axios({
          url: 'https://sheyou-backend.herokuapp.com/second-hand-products?sizes.id='+filterSize+'&categories.id='+filterCat,
          method: 'get',
          // headers: {
          //     'Authorization': 'Bearer ' + token
          // }
        }).then(function(res){
          // console.log(res.data)
            var data = res.data
            var dataShow = []
            const itemNum = data.length
            var activePage = self.state.activePage
            activePage = parseInt(activePage)
            for (var i = activePage * 9 - 9; i < activePage * 9; i++) {
              if (i < itemNum) {
                dataShow.push(data[i])
              }
            }

            var roundPageNum = 0
            var pageNum = 0
            roundPageNum = round(itemNum / 9)
            pageNum = itemNum / 9.0

            if(pageNum - roundPageNum  > 0){
              pageNum = roundPageNum + 1
            }else{
              pageNum = roundPageNum
            }

            self.setState({
              productShow: dataShow,
              pageNum: pageNum,
              loading: false,
              itemNum: itemNum
            })
        }).catch(function(err){
            console.log(err)
        })
      }else{
        axios({
            url: 'https://sheyou-backend.herokuapp.com/second-hand-products?sizes.id='+filterSize + '&genders.id=' + filterGender + '&categories.id='+filterCat,
            method: 'get',
            // headers: {
            //     'Authorization': 'Bearer ' + token
            // }
        }).then(function(res){
            // console.log(res)
            const data = res.data
            var dataShow = []
            const itemNum = data.length
            var activePage = self.state.activePage
            activePage = parseInt(activePage)
            for (var i = activePage * 9 - 9; i < activePage * 9; i++) {
              if (i < itemNum) {
                dataShow.push(data[i])
              }
            }

            var roundPageNum = 0
            var pageNum = 0
            roundPageNum = round(itemNum / 9)
            pageNum = itemNum / 9.0

            if(pageNum - roundPageNum  > 0){
              pageNum = roundPageNum + 1
            }else{
              pageNum = roundPageNum
            }
            self.setState({
              productShow: dataShow,
              pageNum: pageNum,
              loading: false,
              itemNum: itemNum
            })
            console.log(pageNum)
            
        }).catch(function(err){
            console.log(err)
        })
      }
    }


    
    // window.onload = (event) => {
    //   self.setState({
    //     loading: false
    //   })
    // };



    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }

    }, 700);
  }

  // handlePaginationChange(e, {activePage}){
  //   this.setState({activePage})
  //   const thisPath = this.props.history.location.pathname
  //   this.props.history.push(thisPath+'?p='+activePage)
  //   window.location.reload(true)
  // }

  handlePaginationChange (e, { activePage }) {
    console.log(this.state.totalPages)
    this.setState({ activePage })
    const thisPath = this.props.history.location.pathname
    const filterCat = this.state.filterCat
    const filterSize = this.state.filterSize
    const filterGender = this.state.filterGender
    this.props.history.push(thisPath + '?cat=' + filterCat + '&gender=' + filterGender +'&size=' + filterSize + '&p=' + activePage)
    window.location.reload(true)
  }

  

  getFilterProduct(event, data){

    console.log(11111)
    const self = this
    self.setState({
      productLoading: true,
      activePage: 1
    })
    const value = parseInt(data.value.slice(-1))
    const filterType = data.value.slice(0,-1)

    if(filterType === 'size'){
      this.setState({
          filterSize: value
      }, ()=>{
          const filterCat = this.state.filterCat
          const filterSize = this.state.filterSize
          const filterGender = this.state.filterGender

          console.log(filterCat, filterSize, filterGender)

          const thisPath = this.props.history.location.pathname
          
          this.props.history.push(thisPath + '?cat=' + filterCat + '&gender=' + filterGender +'&size=' + filterSize + '&p=1')

          window.location.reload(true)
      })
        
    }else if(filterType === 'category'){
        this.setState({
            filterCat: value
        }, () => {
          const filterCat = this.state.filterCat
          const filterSize = this.state.filterSize
          const filterGender = this.state.filterGender

          console.log(filterCat, filterSize, filterGender)

          const thisPath = this.props.history.location.pathname
          this.props.history.push(thisPath + '?cat=' + filterCat + '&gender=' + filterGender +'&size=' + filterSize + '&p=1')

          window.location.reload(true)
          
        })
    }else if(filterType === 'gender'){
      console.log('filtered3')
      this.setState({
          filterGender: value
      }, () => {
          const filterCat = this.state.filterCat
          const filterSize = this.state.filterSize
          const filterGender = this.state.filterGender

          console.log(filterCat, filterSize, filterGender)

          const thisPath = this.props.history.location.pathname
          this.props.history.push(thisPath + '?cat=' + filterCat + '&gender=' + filterGender +'&size=' + filterSize + '&p=1')

          window.location.reload(true)
      })
    }
  }

  getFilterInfo(){
      const self = this
      var categories = []
      var sizes = [{
          text: 'All Size',
          value: 'size0',
        key: 'size0',
        filter: 'size'
      }]

      var genders = [{
        text: 'All',
        value: 'gender0',
        key: 'gender0',
        className: 'gender'
      }]
  

      self.setState({
          catLoading: true,
          sizeLoading: true
      })

      axios({
          url: 'https://sheyou-backend.herokuapp.com/categories',
          method: 'get'
        }).then(function (res) {
          const data = res.data
          data.forEach(function (option) {
            const category = {
              text: option.name,
              value: 'category' + option.id,
              key: 'category' + option.id,
              filter: 'category'
            }
            categories.push(category)
          })
    
          self.setState({
            catOpt: categories,
            catLoading: false
          })
        }).catch(function(err){
          console.log(err)
        })

        axios('https://sheyou-backend.herokuapp.com/sizes', {
        method: 'get'
        }).then(function (res) {
        const data = res.data
        data.forEach(function (option) {
            const size = {
              text: option.name,
              value: 'size' + option.id,
              key: 'size' + option.id,
              filter: 'size'
            }
            sizes.push(size)
        })

        self.setState({
            sizeOpt: sizes,
            sizeLoading: false
        })
        }).catch(function(err){
            console.log(err)
        })

        axios
        .get('https://sheyou-backend.herokuapp.com/genders')
        .then(function(res){
          const data = res.data
          data.forEach(function (option) {
              const gender = {
              text: option.name,
              value: 'gender' + option.id,
              key: 'gender' + option.id,
              className: 'gender'
              }
              genders.push(gender)
          })

          self.setState({
              genderOpt: genders,
              genderLoading: false
          })
        }).catch(function(err){
          console.log(err)
      })
      
  }




  render() {
    const s = this.state
    console.log(s)
    const loading = s.loading
    // const n={position:'absolute',top:1000}
    const self=this

    const filterSize = 'size'+s.filterSize
    const filterCat = 'category'+s.filterCat
    const filterGender = 'gender'+s.filterGender

    return <div>
      {/* <Nav /> */}


      {loading ? (
        <Grid >
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>


          <Grid.Row colums={2}>
            <Grid.Column width={7}></Grid.Column>
            <Grid.Column width={6}>
              <Header size='huge'>
                <Icon loading name='asterisk' size='massive' color='yellow' />
                Loading...
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
        </Grid>
      ) : (
        <Grid stackable style={{marginTop:10}}>
          <Grid.Column floated='left' width={4} style={{ paddingLeft: 80 }} >
              <Grid.Row>
                <MainFilter activeItem='Sale' />
              </Grid.Row>

              <Grid.Row></Grid.Row>
              <br />
              <br />
              <br />



              <Grid.Row>
                <Header as='h5'>
                    <Icon name='filter' />
                    <Header.Content>
                      {userLang !== 'zh-CN' && 'Filter by category: '}
                      {userLang === 'zh-CN' && '筛选类别'}
                    <Dropdown
                        inline
                        options={s.catOpt}
                        loading={s.catLoading}
                        defaultValue={filterCat}
                        onChange={this.getFilterProduct}
                        upward={false}
                    />
                    </Header.Content>
                </Header>
              </Grid.Row>

              <br />
              <br />
              <Grid.Row>
                  <Header as='h5'>
                      <Icon name='filter'/>
                      <Header.Content>
                        {userLang !== 'zh-CN' && 'Filter by gender: '}
                        {userLang === 'zh-CN' && '根据性别筛选'}
                      <Dropdown
                          inline
                          options={s.genderOpt}
                          loading={s.genderLoading}
                          defaultValue={filterGender}
                          onChange={this.getFilterProduct}
                      />
                      </Header.Content>
                  </Header>
              </Grid.Row>

              <br />
              <br />
              <Grid.Row>
                  <Header as='h5'>
                      <Icon name='filter'/>
                      <Header.Content>
                        {userLang !== 'zh-CN' && 'Filter by sizes: '}
                        {userLang === 'zh-CN' && '筛选尺码'}
                      <Dropdown
                          inline
                          options={s.sizeOpt}
                          loading={s.sizeLoading}
                          defaultValue={filterSize}
                          onChange={this.getFilterProduct}
                      />
                      </Header.Content>
                  </Header>
              </Grid.Row>
              
          </Grid.Column>

          {s.productShow !== undefined &&
            <>
              {s.productShow.length === 0 && (
                <Grid.Column width={12} style={{ paddingRight: 50 }}>
                  <Parallax
                    bgImage='https://i.loli.net/2020/11/25/ZHjYm1wL9runqOz.png'
                    bgImageAlt=''
                    strength={200}
                  >
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />

                    <div>
                      <Segment>
                        <Grid>
                          <Grid.Row centered>
                            <Header>
                              <Icon loading name='search' size='massive' color='yellow' />
                              No Product Found
                            </Header>
                          </Grid.Row>

                          {/* <Grid.Row centered>
                        <Button size='huge' color='orange' onClick={this.handleItemClick}> Browse All</Button>
                      </Grid.Row> */}
                        </Grid>
                      </Segment>
                    </div>

                    <div style={{ height: '270px' }} />
                  </Parallax>
                </Grid.Column>
              )}

              {s.productShow.length >= 1 && (
                <Grid.Column width={12}>
                  <SaleCardTemplete products={s.productShow} />
                  <Grid>
                    <Grid.Row centered>
                      <Pagination
                        onPageChange={this.handlePaginationChange}
                        activePage={self.state.activePage}
                        pointing
                        secondary
                        ellipsisItem={false}
                        totalPages={this.state.pageNum}
                      />
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              )}
            </>
          }

          {s.productShow === undefined &&
            <>
              <Grid.Column width={12} style={{ paddingRight: 50 }}>
                <Parallax
                  bgImage='https://i.loli.net/2020/11/25/ZHjYm1wL9runqOz.png'
                  bgImageAlt=''
                  strength={200}
                >
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />

                  <div>
                    <Segment>
                      <Grid>
                        <Grid.Row centered>
                          <Header>
                              <Icon loading name='asterisk' size='massive' color='yellow' />
                              Loading...
                          </Header>
                        </Grid.Row>

                        {/* <Grid.Row centered>
                      <Button size='huge' color='orange' onClick={this.handleItemClick}> Browse All</Button>
                    </Grid.Row> */}
                      </Grid>
                    </Segment>
                  </div>

                  <div style={{ height: '270px' }} />
                </Parallax>
              </Grid.Column>
            </>
          }
        </Grid>
      )}
      <Foot />

    </div>


  }

}

export default withRouter(SalePage)

