import React from 'react'
import { Button, } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
const userLang = navigator.language || navigator.userLanguage

const SustainButton = () => (
   
  <div>
    <Link to='/about'>
      <Button style={{backgroundColor:'#e4bf8f', color:'#493d2b'}} circular size='huge'> {userLang === 'zh-CN'
        ? '更多'
        : 'Learn More'}</Button>
    
    </Link>
  </div>
  
)

export default SustainButton