import React from 'react'
import axios from 'axios'

import {
  Grid,
  Header,
  Icon,
  Segment,
  Button,
  Form,
  Divider,
  Label,
  Dropdown,
  Message
} from 'semantic-ui-react'

import { withRouter } from 'react-router-dom'
// import UploadImages from './UploadProductPhoto'
// import reactImageSize from 'react-image-size'
import UploadProductPhoto from './UploadProductPhoto'

class ToSaleProduct extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      token: '',
      log: '',

      name: '',
      price: '',
      newPrice:'',
      stock: '',
      descript: '',
      productPhoto: '',
      descriptPhoto: '',
      newProductPhoto: [],
      newDescriptPhoto: [],

      accessoryCatOpt: [],
      clothingCatOpt: [],
      genderOpt: [],
      sizeOpt: [],
      occasionOpt: [],
      colorOpt: [],
      styleOpt: [],

      accessoryCat: '',
      clothingCat: '',
      gender: '',
      sizes: [],
      occasions: [],
      colors: [],
      styles: [],

      saveLoading: false,
      back: false,
      loading: true,
      saved: false,
      somethingWrong: false,
      productinfo: '',
    }
    // this.getTagInfo = this.getTagInfo.bind(this)

    this.onName = this.onName.bind(this)
    this.onPrice = this.onPrice.bind(this)
    this.onNewPrice=this.onNewPrice.bind(this)
    this.onStock = this.onStock.bind(this)
    this.onDescript = this.onDescript.bind(this)

    this.handleProductPhoto = this.handleProductPhoto.bind(this)
    this.handleDescriptPhoto = this.handleDescriptPhoto.bind(this)

    this.handleSingleSelect = this.handleSingleSelect.bind(this)
    this.handleChooseTag = this.handleChooseTag.bind(this)
    this.handleDeleteTag = this.handleDeleteTag.bind(this)

    this.handleBackClick = this.handleBackClick.bind(this)
    this.handleYesBack = this.handleYesBack.bind(this)
    this.handleNoBack = this.handleNoBack.bind(this)

    this.removeItem = this.removeItem.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handlePreview = this.handlePreview.bind(this)
    this.uploadPhotoToBackend = this.uploadPhotoToBackend.bind(this)
    this.scrollToTop = this.scrollToTop.bind(this)
    this.getProductInfo = this.getProductInfo.bind(this)
  }

  componentDidMount () {
    const self = this


    const log = localStorage.getItem('login')
    const activeItem = this.props.activeItem
    self.setState({ activeItem: activeItem, log: log }, ()=>{
      if (log === 'true') {
        self.getTagInfo()
        self.getProductInfo()
      }
    })
    

  }
  
  getProductInfo () {
    const self = this
    var activeItem = self.state.activeItem
    if(activeItem === 'SaleClothing'){
      activeItem = 'products'
    }else if(activeItem === 'SaleAccessory'){
      activeItem = 'accessaries'
    }
    console.log(self.props)

    const productId = this.props.history.location.search.substring(4)

    axios
      .get('https://sheyou-backend.herokuapp.com/'+activeItem+'?id=' + productId)
      .then(function (res) {
        const data = res.data[0]
        console.log(data)

        const name = data.name
        const descript = data.description
        const price = data.price
        const stock = data.stock
        const newDescriptPhoto = data.detail.map(function (each) {
          return { id: each.id, url: each.url }
        })

        var sizes = []
        var clothingCat = []
        var accessoryCat = []
        if(activeItem !== 'accessaries'){
         sizes = data.sizes.map(function (each) {
            return {
              id: each.id,
              text: each.name,
              value: each.name,
              key: each.name
            }
          })
          clothingCat = data.categories.map(function (each) {
          return {
            id: each.id,
            text: each.name,
            value: each.name,
            key: each.name
          }
        })
        }else{
          accessoryCat = data.accessory_categories.map(function (each) {
            return {
              id: each.id,
              text: each.name,
              value: each.name,
              key: each.name
            }
          })
        }

        const newProductPhoto = data.picture.map(function (each) {
          return {
            id: each.id,
            url: each.url,
            thumbnail: each.formats.thumbnail.url
          }
        })

        const gender = data.genders.map(function (each) {
          return {
            id: each.id,
            text: each.name,
            value: each.name,
            key: each.name
          }
        })

        const colors = data.colors.map(function (each) {
          return {
            id: each.id,
            text: each.name,
            value: each.name,
            key: each.name
          }
        })
        const ocassions = data.ocassions.map(function (each) {
          return {
            id: each.id,
            text: each.name,
            value: each.name,
            key: each.name
          }
        })
        const styles = data.styles.map(function (each) {
          return {
            id: each.id,
            text: each.name,
            value: each.name,
            key: each.name
          }
        })

        self.setState({
          name: name,
          descript: descript,
          price: price,
          stock: stock,
          forSell:data.forEach,
          forRent:false,
          newDescriptPhoto: newDescriptPhoto,
          newProductPhoto: newProductPhoto,
          clothingCat: clothingCat,
          accessoryCat: accessoryCat,
          gender: gender,
          sizes: sizes,
          colors: colors,
          occasions: ocassions,
          styles: styles,
          loading:false
        })
      })
      .catch(function (err) {
        console.log(err)
      })
  }

  getTagInfo () {
    const self = this
    const token = localStorage.getItem('token')
    self.setState({ token: token })
    var clothingCats = []
    var accessoryCats = []
    var genders = []
    var colors = []
    var sizes = []
    var occasions = []
    var styles = []

    axios('https://sheyou-backend.herokuapp.com/genders', {
      method: 'get'
    }).then(function (res) {
      const data = res.data
      data.forEach(function (option) {
        const value = option.name.toLowerCase()
        const gender = {
          id: option.id,
          text: option.name,
          value: value,
          key: value
        }
        genders.push(gender)
      })

      self.setState({
        genderOpt: genders
      })
    })

    axios('https://sheyou-backend.herokuapp.com/categories', {
      method: 'get'
    }).then(function (res) {
      const data = res.data
      data.forEach(function (option) {
        const value = option.name.toLowerCase()
        const clothingCat = {
          id: option.id,
          text: option.name,
          value: value,
          key: value
        }
        clothingCats.push(clothingCat)
      })

      self.setState({
        clothingCatOpt: clothingCats
      })
    })

    axios('https://sheyou-backend.herokuapp.com/accessory-categories', {
      method: 'get'
    }).then(function (res) {
      const data = res.data
      data.forEach(function (option) {
        const value = option.name.toLowerCase()
        const accessoryCat = {
          id: option.id,
          text: option.name,
          value: value,
          key: value
        }
        accessoryCats.push(accessoryCat)
      })

      self.setState({
        accessoryCatOpt: accessoryCats
      })
    })

    axios('https://sheyou-backend.herokuapp.com/colors', {
      method: 'get'
    }).then(function (res) {
      const data = res.data
      data.forEach(function (option) {
        const value = option.name.toLowerCase()
        const style = {
          id: option.id,
          text: option.name,
          value: value,
          key: value
        }
        colors.push(style)
      })

      self.setState({
        colorOpt: colors
      })
    })

    axios('https://sheyou-backend.herokuapp.com/sizes', {
      method: 'get'
    }).then(function (res) {
      const data = res.data
      data.forEach(function (option) {
        const value = option.name.toLowerCase()
        const size = {
          id: option.id,
          text: option.name,
          value: value,
          key: value
        }
        sizes.push(size)
      })

      self.setState({
        sizeOpt: sizes
      })
    })

    axios('https://sheyou-backend.herokuapp.com/ocassions', {
      method: 'get'
    }).then(function (res) {
      const data = res.data
      data.forEach(function (option) {
        const value = option.name.toLowerCase()
        const occasion = {
          id: option.id,
          text: option.name,
          value: value,
          key: value
        }
        occasions.push(occasion)
      })

      self.setState({
        occasionOpt: occasions
      })
    })

    axios('https://sheyou-backend.herokuapp.com/styles', {
      method: 'get'
    }).then(function (res) {
      const data = res.data
      data.forEach(function (option) {
        const value = option.name.toLowerCase()
        const style = {
          id: option.id,
          text: option.name,
          value: value,
          key: value
        }
        styles.push(style)
      })

      self.setState({
        styleOpt: styles
      })
    })
  }

  onName (event) {
    this.setState({
      name: event.target.value
    })
  }

  onPrice (event) {
    this.setState({
      price: event.target.value
    })
  }
  
  onNewPrice (event) {
    this.setState({
      newPrice: event.target.value
    })
  }

  onStock (event) {
    this.setState({
      stock: event.target.value
    })
  }

  onDescript (event) {
    this.setState({
      descript: event.target.value
    })
  }

  handleProductPhoto (event, formData, value) {
    console.log(event)
    const productPhoto = event
    this.setState({
      productPhoto: productPhoto
    })
  }

  handleDescriptPhoto (event, formData, value) {
    console.log(event)
    const descriptPhoto = event
    this.setState({
      descriptPhoto: descriptPhoto
    })
  }

  handleSingleSelect (event, data) {
    const value = data.value
    const placeholder = data.placeholder
    const option = data.options.filter(function (option) {
      return option.value === value
    })

    if (placeholder === 'Clothing category') {
      this.setState({ clothingCat: option[0] })
    } else if (placeholder === 'Accessory category') {
      this.setState({ accessoryCat: option[0] })
    } else if (placeholder === 'Select gender') {
      this.setState({ gender: option[0] })
    }
  }

  handleChooseTag (event, data) {
    const self = this
    const tagtype = data.tagtype
    var add = true

    if (tagtype === 'size') {
      var tags = this.state.sizes
    } else if (tagtype === 'color') {
      tags = this.state.colors
    } else if (tagtype === 'occasion') {
      tags = this.state.occasions
    } else if (tagtype === 'style') {
      tags = this.state.styles
    }

    const tag = {
      text: data.text,
      value: data.value,
      id: data.id
    }

    for (let i = 0; i < tags.length; i++) {
      if (tag.id === tags[i].id) {
        add = false
      }
    }

    if (add === true) {
      var tagsNew = tags.concat(tag)
      if (tagtype === 'size') {
        self.setState({ sizes: tagsNew })
      } else if (tagtype === 'color') {
        self.setState({ colors: tagsNew })
      } else if (tagtype === 'occasion') {
        self.setState({ occasions: tagsNew })
      } else if (tagtype === 'style') {
        self.setState({ styles: tagsNew })
      }
    }
  }

  handleDeleteTag (event, data) {
    const tagDel = data.id
    const self = this
    const tagtype = data.tagtype

    if (tagtype === 'size') {
      var tagsNew = this.state.sizes.filter(function (size) {
        return size.id !== tagDel
      })
      self.setState({ sizes: tagsNew })
    } else if (tagtype === 'color') {
      tagsNew = this.state.colors.filter(function (color) {
        return color.id !== tagDel
      })
      self.setState({ colors: tagsNew })
    } else if (tagtype === 'occasion') {
      tagsNew = this.state.occasions.filter(function (occasion) {
        return occasion.id !== tagDel
      })
      self.setState({ occasions: tagsNew })
    } else if (tagtype === 'style') {
      tagsNew = this.state.styles.filter(function (style) {
        return style.id !== tagDel
      })
      self.setState({ styles: tagsNew })
    }
  }

  handleBackClick () {
    const self = this
    self.setState({
      back: true
    })
  }

  handleYesBack () {
    const self = this
    const link = '/merchantpage/products'
    localStorage.removeItem('newProduct')
    window.location.assign('https://sheyoufashion.com/#' + link)
    self.props.history.push(link)
    window.location.reload(true)
  }

  handleNoBack () {
    this.setState({
      back: false
    })
  }

  handlePreview () {
    const self = this
    const activeItem = self.state.activeItem
    const productId = this.props.history.location.search.substring(4)

    if (self.state.saved === true) {
      self.props.history.push(
        '/merchantpage/product/toSale'+activeItem+'/preview?id=' + productId
      )
    } else self.setState({ somethingWrong: 'You have not saved the form.' })
  }

  handleSaveClick () {
    const self = this
    const s = self.state
    const activeItem = s.activeItem
    console.log(s)
    self.setState({
      somethingWrong: false,
      saveLoading: true
    })

    if (
      s.name === '' ||
      s.price === '' ||
      s.newPrice===''||
      s.stock === '' ||
      s.descript === ''
    ) {
      self.setState({
        somethingWrong: 'You have not finished the form.',
        previewLoading: false
      })
    } else {
      if (activeItem === 'SaleAccessory') {
        if (
          Object.keys(s.accessoryCat).length !== 0 &&
          Object.keys(s.gender).length !== 0 &&
          s.colors[0] !== undefined &&
          s.occasions[0] !== undefined &&
          s.styles[0] !== undefined
        ) {
          const newProduct = {
            name: s.name,
            description: s.descript,
            price: s.price,
            stock: s.stock,
            productPhoto: s.productPhoto.file,
            descriptPhoto: s.descriptPhoto.file,

            accessoryCat: s.accessoryCat,
            gender: s.gender,
            colors: s.colors,
            occasions: s.occasions,
            styles: s.styles
          }
          localStorage.setItem('newSaleProduct', JSON.stringify(newProduct))
          self.setState({ saved: true, saveLoading: false })
          // console.log(newProduct)
        } else {
          self.setState({
            somethingWrong:
              'You have not finished selecting all the tags to your new product.'
          })
          console.log('2')
        }
      } else if (activeItem === 'SaleClothing') {
        if (
          s.clothingCat !== '' &&
          s.gender !== '' &&
          s.sizes[0] !== undefined &&
          s.colors[0] !== undefined &&
          s.occasions[0] !== undefined &&
          s.styles[0] !== undefined
        ) {
          if (
            s.newDescriptPhoto !== undefined &&
            s.newDescriptPhoto.length > 0 &&
            s.descriptPhoto === '' &&
            s.newProductPhoto !== undefined &&
            s.newProductPhoto.length > 0 &&
            s.productPhoto === ''
          ) {
            const newProduct = {
              name: s.name,
              description: s.descript,
              price: s.price,
              newPrice:s.newPrice,
              stock: s.stock,
              newProductPhoto: s.newProductPhoto,
              newDescriptPhoto: s.newDescriptPhoto,

              clothingCat: s.clothingCat,
              gender: s.gender,
              sizes: s.sizes,
              colors: s.colors,
              occasions: s.occasions,
              styles: s.styles
            }
            localStorage.setItem('newSaleProduct', JSON.stringify(newProduct))
            self.scrollToTop()
            self.setState({ saved: true, saveLoading: false })
          } else {
            this.uploadPhotoToBackend()
          }
        } else {
          self.setState({
            somethingWrong:
              'You have not finished selecting all the tags to your new product.',
            saveLoading: false
          })
        }
      }
    }
  }

  scrollToTop () {
    window.scrollTo(0, 0)
  }

  uploadPhotoToBackend () {
    const self = this
    const s = self.state
    const activeItem = s.activeItem
    const token = s.token
    var fileP=null
    if (s.productPhoto !== '' && s.descriptPhoto !== '') {
       fileP = new FormData()
      s.productPhoto.forEach(img => {
        fileP.append('files', img)
      })
      axios
        .post('https://sheyou-backend.herokuapp.com/upload', fileP, {
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(function (response) {
          // console.log(response)
          const data = response.data
          var newProductPhoto = data.map(image => ({
            id: image.id,
            url: image.url,
            thumbnail: image.formats.thumbnail.url
          }))
          if (
            s.newProductPhoto !== undefined &&
            s.newProductPhoto.length!== 0 &&
            s.newProductPhoto !== null
          ) {
            s.newProductPhoto.forEach(function (each) {
              newProductPhoto.push(each)
            })
          }

          var fileD = new FormData()
          s.descriptPhoto.forEach(img => {
            fileD.append('files', img)
          })
          axios
            .post('https://sheyou-backend.herokuapp.com/upload', fileD, {
              headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'multipart/form-data'
              }
            })
            .then(function (response) {
              // console.log(response)
              const data = response.data
              console.log(data)
              var newDescriptPhoto = data.map(image => ({
                id: image.id,
                url: image.url
              }))

              if (
                s.newDescriptPhoto !== undefined &&
                s.newDescriptPhoto.length!== 0 &&
                s.newDescriptPhoto !== null
              ) {
                s.newDescriptPhoto.forEach(function (each) {
                  newDescriptPhoto.push(each)
                })
              }

              console.log(s)

              if(activeItem === 'SaleClothing'){
                const newProduct = {
                  name: s.name,
                  description: s.descript,
                  price: s.price,
                  newPrice:s.newPrice,
                  stock: s.stock,
                  newDescriptPhoto: newDescriptPhoto,
                  newProductPhoto: newProductPhoto,
                  productPhoto: s.productPhoto,
                  descriptPhoto: s.descriptPhoto,

                  clothingCat: s.clothingCat,
                  gender: s.gender,
                  sizes: s.sizes,
                  colors: s.colors,
                  occasions: s.occasions,
                  styles: s.styles
                }
                localStorage.setItem('newSaleProduct', JSON.stringify(newProduct))
                self.setState({ saved: true, saveLoading: false })
              }else if(activeItem === 'SaleAccessory'){
                const newProduct = {
                  name: s.name,
                  description: s.descript,
                  price: s.price,
                  newPrice:s.newPrice,
                  stock: s.stock,
                  newDescriptPhoto: newDescriptPhoto,
                  newProductPhoto: newProductPhoto,
                  productPhoto: s.productPhoto,
                  descriptPhoto: s.descriptPhoto,

                  accessoryCat: s.accessoryCat,
                  gender: s.gender,
                  colors: s.colors,
                  occasions: s.occasions,
                  styles: s.styles
                }
                localStorage.setItem('newSaleProduct', JSON.stringify(newProduct))
                self.setState({ saved: true, saveLoading: false })
              }
            })
            .catch(function (err) {
              console.log(err)
            })
        })
        .catch(function (err) {
          console.log(err)
        })
    }

    if (s.productPhoto === '' && s.descriptPhoto !== '') {
       var fileD = new FormData()
      s.descriptPhoto.forEach(img => {
        fileD.append('files', img)
      })
      axios
        .post('https://sheyou-backend.herokuapp.com/upload', fileD, {
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(function (response) {
          console.log(response)
          const data = response.data
          console.log(data)
          var newDescriptPhoto = data.map(image => ({
            id: image.id,
            url: image.url
          }))

          if (
            s.newDescriptPhoto !== undefined &&
            s.newDescriptPhoto.length!== 0 &&
            s.newDescriptPhoto !== null
          ) {
            s.newDescriptPhoto.forEach(function (each) {
              newDescriptPhoto.push(each)
            })
          }

          console.log(s)

          if(activeItem === 'SaleClothing'){
            const newProduct = {
              name: s.name,
              description: s.descript,
              price: s.price,
              newPrice:s.newPrice,
              stock: s.stock,
              newDescriptPhoto: newDescriptPhoto,
              newProductPhoto: s.newProductPhoto,
              productPhoto: s.productPhoto,
              descriptPhoto: s.descriptPhoto,

              clothingCat: s.clothingCat,
              gender: s.gender,
              sizes: s.sizes,
              colors: s.colors,
              occasions: s.occasions,
              styles: s.styles
            }
            localStorage.setItem('newSaleProduct', JSON.stringify(newProduct))
            self.setState({ saved: true, saveLoading: false })
          }else if(activeItem === 'SaleAccessory'){
            const newProduct = {
              name: s.name,
              description: s.descript,
              price: s.price,
              newPrice:s.newPrice,
              stock: s.stock,
              newDescriptPhoto: newDescriptPhoto,
              newProductPhoto: s.newProductPhoto,
              productPhoto: s.productPhoto,
              descriptPhoto: s.descriptPhoto,


              accessoryCat: s.accessoryCat,
              gender: s.gender,
              colors: s.colors,
              occasions: s.occasions,
              styles: s.styles
            }
            localStorage.setItem('newSaleProduct', JSON.stringify(newProduct))
            self.setState({ saved: true, saveLoading: false })
          }
        })
        .catch(function (err) {
          console.log(err)
        })
    }

    if (s.productPhoto !== '' && s.descriptPhoto === '') {
       fileP = new FormData()
      s.productPhoto.forEach(img => {
        fileP.append('files', img)
      })
      axios
        .post('https://sheyou-backend.herokuapp.com/upload', fileP, {
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(function (response) {
          console.log(response)
          const data = response.data
          var newProductPhoto = data.map(function (image) {
            return {
              id: image.id,
              url: image.url,
              thumbnail: image.formats.thumbnail.url
            }
          })
          if (
            s.newProductPhoto !== undefined &&
            s.newProductPhoto.length!== 0 &&
            s.newProductPhoto !== null
          ) {
            s.newProductPhoto.forEach(function (each) {
              newProductPhoto.push(each)
            })
          }

          console.log(s)

          if(activeItem === 'SaleClothing'){
            const newProduct = {
              name: s.name,
              description: s.descript,
              price: s.price,
              newPrice:s.newPrice,
              stock: s.stock,
              newDescriptPhoto: s.newDescriptPhoto,
              newProductPhoto: newProductPhoto,
              productPhoto: s.productPhoto,
              descriptPhoto: s.descriptPhoto,
              forSell: s.forSell,
              forRent: s.forRent,

              clothingCat: s.clothingCat,
              gender: s.gender,
              sizes: s.sizes,
              colors: s.colors,
              occasions: s.occasions,
              styles: s.styles
            }
            localStorage.setItem('newSaleProduct', JSON.stringify(newProduct))
            self.setState({ saved: true, saveLoading: false })
          }else{
            const newProduct = {
              name: s.name,
              description: s.descript,
              price: s.price,
              newPrice:s.newPrice,
              stock: s.stock,
              newDescriptPhoto: s.newDescriptPhoto,
              newProductPhoto: newProductPhoto,
              productPhoto: s.productPhoto,
              descriptPhoto: s.descriptPhoto,

              accessoryCat: s.accessoryCat,
              gender: s.gender,
              colors: s.colors,
              occasions: s.occasions,
              styles: s.styles
            }
            localStorage.setItem('newSaleProduct', JSON.stringify(newProduct))
            self.setState({ saved: true, saveLoading: false })
          }
        })
        .catch(function (err) {
          console.log(err)
        })
    }
    if (s.productPhoto === '' && s.descriptPhoto === '') {
      self.scrollToTop()
      self.setState({
        somethingWrong: 'You have not uploaded product / description photos.',
        saveLoading: false
      })
    }
  }

  removeItem (id) {
    const self = this
    const s = self.state
    const activeItem = s.activeItem
    var newDescriptPhoto = s.newDescriptPhoto.filter(function (each) {
      return each.id !== id
    })

    var newProductPhoto = s.newProductPhoto.filter(function (each) {
      return each.id !== id
    })

    self.setState({
      newDescriptPhoto: newDescriptPhoto,
      newProductPhoto: newProductPhoto
    })

    console.log(s)

    if(activeItem === 'SaleClothing'){
      const newProduct = {
        name: s.name,
        description: s.descript,
        price: s.price,
        newPrice:s.newPrice,
        stock: s.stock,
        newDescriptPhoto: newDescriptPhoto,
        newProductPhoto: newProductPhoto,
        productPhoto: s.productPhoto,
        descriptPhoto: s.descriptPhoto,

        clothingCat: s.clothingCat,
        gender: s.gender,
        sizes: s.sizes,
        colors: s.colors,
        occasions: s.occasions,
        styles: s.styles
      }
      localStorage.setItem('newSaleProduct', JSON.stringify(newProduct))
    }else{
      const newProduct = {
        name: s.name,
        description: s.descript,
        price: s.price,
        newPrice:s.newPrice,
        stock: s.stock,
        newDescriptPhoto: newDescriptPhoto,
        newProductPhoto: newProductPhoto,
        productPhoto: s.productPhoto,
        descriptPhoto: s.descriptPhoto,

        accessoryCat: s.accessoryCat,
        gender: s.gender,
        colors: s.colors,
        occasions: s.occasions,
        styles: s.styles
      }
      localStorage.setItem('newSaleProduct', JSON.stringify(newProduct))
    }
  }

  render () {
    const self = this

    var product = localStorage.getItem('newSaleProduct')
    if (product !== undefined && product !== null) {
      product = JSON.parse(product)
    }

    const s = self.state
    console.log(s)
    const activeItem = s.activeItem

    return (
      <>
        {this.state.loading ? (
          <Grid>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>

            <Grid.Row colums={2}>
              <Grid.Column width={7}></Grid.Column>
              <Grid.Column width={6}>
                <Header size='huge'>
                  <Icon loading name='asterisk' size='massive' color='yellow' />
                  Loading...
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
          </Grid>
        ) : (
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column width={11}>
                <Segment style={{ paddingLeft: 20, paddingRight: 20 }}>
                  <Header as='h1'>
                   Sale
                    <Header.Subheader >
                      Sell your product with a discount
                    </Header.Subheader>
                  </Header>
                  <Divider />
                  <Form>
                    <Form.Group widths='equal'>
                      <Form.Input
                        fluid
                        label='Name'
                        placeholder='Name'
                        value={this.state.name}
                        onChange={this.onName}
                      />
                      <Form.Input
                        fluid
                        label='Stock'
                        placeholder='Stock'
                        value={this.state.stock}
                        onChange={this.onStock}
                      />
                      
                      </Form.Group>
                      <Form.Group widths='equal'>
                        <Form.Input
                          fluid
                          label='Price'
                          placeholder='Original Price'
                          value={this.state.price}
                          onChange={this.onPrice}
                        />
                        <Form.Input
                          fluid
                          label='New Price'
                          placeholder='New Price'
                          value={this.state.newPrice}
                          onChange={this.onNewPrice}
                        />
                        
                      </Form.Group>

                    <Form.TextArea
                      label='Description'
                      placeholder='Please describe your product'
                      value={this.state.descript}
                      onChange={this.onDescript}
                    />
                  </Form>

                  <br />
                  <Divider />
                  <br />

                  <Grid>
                    <Grid.Row columns={2}>
                      <Grid.Column>
                        <Header color='orange' textAlign='center' as='h3'>
                          Upload Product Photo
                          <Header.Subheader>
                            Maximum of 6 pictures
                          </Header.Subheader>
                          <br />
                          {s.productinfo !== null && (
                            <UploadProductPhoto
                              onUploadPic={this.handleProductPhoto}
                              imageP={s.newProductPhoto}
                              removePic={this.removeItem}
                            />
                          )}
                          {s.productinfo === null && (
                            <UploadProductPhoto
                              onUploadPic={this.handleProductPhoto}
                            />
                          )}
                        </Header>
                      </Grid.Column>
                      <Grid.Column>
                        <Header color='orange' textAlign='center' as='h3'>
                          Upload Description Photo
                          <Header.Subheader>
                            Maximum of 6 pictures
                          </Header.Subheader>
                          <br />
                          {s.productinfo !== null && (
                            <UploadProductPhoto
                              onUploadPic={this.handleDescriptPhoto}
                              imageP={s.newDescriptPhoto}
                              removePic={this.removeItem}
                            />
                          )}
                          {s.productinfo === null && (
                            <UploadProductPhoto
                              onUploadPic={this.handleDescriptPhoto}
                            />
                          )}
                        </Header>
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row></Grid.Row>

                  </Grid>
                </Segment>
              </Grid.Column>
              <Grid.Column width={4}>
                <Grid>
                  <Grid.Row>
                    <Button
                      size='large'
                      icon
                      labelPosition='left'
                      color='orange'
                      onClick={this.handlePreview}
                    >
                      <Icon name='chevron right' />
                      Preview and Upload
                    </Button>
                  </Grid.Row>
                </Grid>
                {s.somethingWrong !== false && (
                  <Message warning>
                    <Message.Header>Form Incomplete</Message.Header>
                    {s.somethingWrong}
                  </Message>
                )}

                <Segment>
                  <Grid centered style={{ paddingTop: 25, paddingBottom: 25 }}>
                    {activeItem === 'SaleAccessory' && (
                      <Grid.Row className='accessoryCat'>
                        <Dropdown
                          button
                          floating
                          selection
                          placeholder='Accessory category'
                          options={s.accessoryCatOpt}
                          onChange={this.handleSingleSelect}
                        />
                      </Grid.Row>
                    )}

                    {activeItem !== 'SaleAccessory' && s.clothingCat === '' && (
                      <Grid.Row className='clothingCat'>
                        <Dropdown
                          button
                          floating
                          selection
                          placeholder='Clothing category'
                          options={s.clothingCatOpt}
                          onChange={this.handleSingleSelect}
                        />
                      </Grid.Row>
                    )}
                    {activeItem !== 'SaleAccessory' && s.clothingCat !== '' && (
                      <Grid.Row className='clothingCat'>
                        <Dropdown
                          button
                          floating
                          selection
                          placeholder={s.clothingCat[0].value}
                          options={s.clothingCatOpt}
                          onChange={this.handleSingleSelect}
                        />
                      </Grid.Row>
                    )}

                    {s.gender !== '' && (
                      <Grid.Row className='gender'>
                        <Dropdown
                          button
                          floating
                          selection
                          placeholder={s.gender[0].value}
                          options={s.genderOpt}
                          onChange={this.handleSingleSelect}
                        />
                      </Grid.Row>
                    )}
                    {s.gender === '' && (
                      <Grid.Row className='gender'>
                        <Dropdown
                          button
                          floating
                          selection
                          placeholder='Select gender'
                          options={s.genderOpt}
                          onChange={this.handleSingleSelect}
                        />
                      </Grid.Row>
                    )}

                    {activeItem !== 'SaleAccessory' && (
                      <>
                        <Grid.Row className='size'>
                          <Dropdown
                            button
                            floating
                            selection
                            text='Select sizes'
                          >
                            <Dropdown.Menu>
                              {s.sizeOpt.map(size => (
                                <Dropdown.Item
                                  key={size.value}
                                  id={size.id}
                                  text={size.text}
                                  value={size.value}
                                  onClick={this.handleChooseTag}
                                  tagtype='size'
                                />
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </Grid.Row>
                        {s.sizes.length > 0 && (
                          <Grid.Row
                            style={{ paddingTop: 10, paddingBottom: 10 }}
                          >
                            {s.sizes.map(size => (
                              <Label key={size}>
                                {size.text}
                                <Icon
                                  name='delete'
                                  id={size.id}
                                  onClick={this.handleDeleteTag}
                                  tagtype='size'
                                />
                              </Label>
                            ))}
                          </Grid.Row>
                        )}
                      </>
                    )}

                    <Grid.Row className='color'>
                      <Dropdown button floating selection text='Select Colors'>
                        <Dropdown.Menu>
                          {s.colorOpt.map(color => (
                            <Dropdown.Item
                              key={color.value}
                              id={color.id}
                              text={color.text}
                              value={color.value}
                              onClick={this.handleChooseTag}
                              tagtype='color'
                            />
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Grid.Row>
                    {s.colors.length > 0 && (
                      <Grid.Row style={{ paddingTop: 10, paddingBottom: 10 }}>
                        {s.colors.map(color => (
                          <Label key={color}>
                            {color.text}
                            <Icon
                              name='delete'
                              id={color.id}
                              onClick={this.handleDeleteTag}
                              tagtype='color'
                            />
                          </Label>
                        ))}
                      </Grid.Row>
                    )}

                    <Grid.Row className='occasion'>
                      <Dropdown
                        button
                        floating
                        selection
                        text='Select Occasions'
                      >
                        <Dropdown.Menu>
                          <Dropdown.Menu scrolling>
                            {s.occasionOpt.map(occasion => (
                              <Dropdown.Item
                                key={occasion.value}
                                id={occasion.id}
                                text={occasion.text}
                                value={occasion.value}
                                onClick={this.handleChooseTag}
                                tagtype='occasion'
                              />
                            ))}
                          </Dropdown.Menu>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Grid.Row>
                    {s.occasions.length > 0 && (
                      <Grid.Row style={{ paddingTop: 10, paddingBottom: 10 }}>
                        {s.occasions.map(occasion => (
                          <Label key={occasion.value}>
                            {occasion.text}
                            <Icon
                              name='delete'
                              id={occasion.id}
                              onClick={this.handleDeleteTag}
                              tagtype='occasion'
                            />
                          </Label>
                        ))}
                      </Grid.Row>
                    )}

                    <Grid.Row className='style'>
                      <Dropdown button floating selection text='Select Styles'>
                        <Dropdown.Menu>
                          <Dropdown.Menu scrolling>
                            {s.styleOpt.map(style => (
                              <Dropdown.Item
                                key={style.value}
                                id={style.id}
                                text={style.text}
                                value={style.value}
                                onClick={this.handleChooseTag}
                                tagtype='style'
                              />
                            ))}
                          </Dropdown.Menu>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Grid.Row>
                    {s.styles.length > 0 && (
                      <Grid.Row style={{ paddingTop: 10, paddingBottom: 10 }}>
                        {s.styles.map(style => (
                          <Label key={style.value}>
                            {style.text}
                            <Icon
                              name='delete'
                              id={style.id}
                              onClick={this.handleDeleteTag}
                              tagtype='style'
                            />
                          </Label>
                        ))}
                      </Grid.Row>
                    )}
                  </Grid>
                </Segment>
                {this.state.saved === true && (
                  <Message success>
                    <Message.Header>Success!</Message.Header>
                    Product Saved. Please Preview and Upload
                  </Message>
                )}

                <Button onClick={this.handleBackClick} size='large'>
                  BACK
                </Button>

                <Button
                  onClick={this.handleSaveClick}
                  loading={this.state.saveLoading}
                  color='orange'
                  size='large'
                >
                  SAVE
                </Button>
                {this.state.back === true && (
                  <div style={{ paddingTop: 10 }}>
                    <Message warning>
                      Are you sure you want to go back without adding this
                      product? The information you filled in will not be saved.
                    </Message>
                    <Button onClick={this.handleYesBack}>Yes</Button>
                    <Button onClick={this.handleNoBack}>No</Button>
                  </div>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </>
    )
  }
}

export default withRouter(ToSaleProduct)
