import React from 'react'
import { Grid } from 'semantic-ui-react'
import { Parallax } from 'react-parallax'

class SusParall extends React.Component {
  render () {
    const p = this.props
    const image = p.image
    const name = p.name
    const content1 = p.content1
    const strength = p.strength

    return (
      <div
        style={{
          backgroundImage: `url(${image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <div className='intro-section'>
          <div className='full-height-section' style={{ padding: 50 }}>
            {content1}
          </div>
        </div>
      </div>
    )
  }
}

export default SusParall
