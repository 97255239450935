import React from 'react'
// import { Parallax, } from 'react-parallax';
import { withRouter } from 'react-router-dom'
import EnterpriseShopPop from './EnterpriseShopPop'

class OpenMerchantShop extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      shopName: '',
      email: '',
      emailVerification: '',
      phoneNumber: '',
      phoneVerification: ''
    }
 }

  componentDidMount () {
    window.scrollTo(0, 0)
    const self = this
    window.onload = event => {
      self.setState({
        loading: false
      })
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 700)
  }


  render () {
    console.log(this)
    const self = this
    return (
      <EnterpriseShopPop/>
    )
  }
}

export default withRouter(OpenMerchantShop)
