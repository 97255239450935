import React from 'react'
import { Menu } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
const userLang = navigator.language || navigator.userLanguage



class MainFilter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeItem: 'Clothing',
    }
    this.handleItemClick = this.handleItemClick.bind(this)
  }


  componentDidMount() {
    const self = this
    const activeItem = this.props.activeItem
    if (activeItem !== undefined || activeItem !== null) { self.setState({ activeItem: activeItem }) }
  }

  handleItemClick(event, data) {
    var name = data.name
    const link = data.link
    const self = this
    window.location.assign('https://www.sheyoufashion.com/#'+link)
    if (name === undefined || name === null) {
      self.props.history.push('/clothing')
      window.location.reload(true)
      
    }
    else {
      self.props.history.push(link)
      window.location.reload(true)
    }
  }

  render() {
    const activeItem = this.state.activeItem

    var clothing = 'Clothing'
    var accessory = 'Accessory'
    var sale = 'Sale'
    if(userLang === 'zh-CN'){
      clothing = '服装'
      accessory = '配饰'
      sale = '出售'
    }

    return (
      <>
        <Menu fluid vertical  >
          <Menu.Item header>
            {userLang === 'zh-CN' && '购买'}
            {userLang !== 'zh-CN' && 'Shop For'}
          </Menu.Item>

          <Menu.Item
            name={clothing}
            link='/clothing'
            active={activeItem === 'Clothing'}
            onClick={this.handleItemClick}
          />

          <Menu.Item
            name={accessory}
            link='/accessory'
            active={activeItem === 'Accessory'}
            onClick={this.handleItemClick}
          />

          <Menu.Item
            name={sale}
            link='/sale'
            active={activeItem === 'Sale'}
            onClick={this.handleItemClick}
          />

        </Menu>
      </>

    )
  }
}

export default withRouter(MainFilter)