import React from 'react'
import OngoingPastOrder from './OngoingPastOrder'

class OngoingPastOrderList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    
   
    }

    hasNotReturned(value){
        const p = this.props
        const activeItem=p.activeItem
        const it=value
        const isMerchantOrder=p.isMerchantOrder
        const userId=p.userId
        const mobile=p.mobile
        if(it.canceled!==true){
        if(it.delivered===null||(it.orders.data.rentOrBuy==="rent"&&it.returned===null))
        return <OngoingPastOrder mobile={mobile} userId={userId} isMerchantOrder={isMerchantOrder} data={it} activeItem={activeItem} orderId={it.id} returnInfo={it.orders.returnInfo}/>}
    }

    render() {
        const p = this.props
        const orders = p.orders
        
        console.log(orders)

        return (orders.map(it => this.hasNotReturned(it)))
        
    }
}


OngoingPastOrderList.defaultProps = {
    orders: []
}


export default OngoingPastOrderList