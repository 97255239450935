import React from 'react'
import { Button } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'

class IntroPageButton extends React.Component {
  handleClick = () => {
    this.props.history.push('/merchantsLog')
  }

  render() {
    const userLang = navigator.language || navigator.userLanguage
    return (
      <div>
        <Button
          color='orange'
          circular
          size='big'
          style={{
            paddingTop: 20,
            paddingBottom: 20,
            paddingLeft: 50,
            paddingRight: 50,
            backgroundColor: '#e4bf8f',
            color: '#493d2b'
          }}
          onClick={this.handleClick}
        >
          {userLang === 'zh-CN' ? '开始上架' : 'Begin Listing'}
        </Button>
      </div>
    )
  }
}

export default withRouter(IntroPageButton)
