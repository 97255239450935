import React from 'react'
// import { Parallax, } from 'react-parallax';
import { Segment, Button, Container, Form, Grid, Header } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'

import IntlTelInput from 'react-intl-tel-input-ueat'
import 'react-intl-tel-input-ueat/dist/main.css'


class EnterpriseShopPop extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      shopName: '',
      email: '',
      emailVerification: '',
      phoneNumber: '',
      phoneVerification: ''
    }
    this.onHandleShopName = this.onHandleShopName.bind(this)
    this.onHandleEmail = this.onHandleEmail.bind(this)
    this.onHandleEmailVerification = this.onHandleEmailVerification.bind(this)
    this.onHandlePhoneNumber = this.onHandlePhoneNumber.bind(this)
    this.onHandlePhoneVerification = this.onHandlePhoneVerification.bind(this)
  }

  componentDidMount () {
    window.scrollTo(0, 0)
    const self = this
    window.onload = event => {
      self.setState({
        loading: false
      })
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 700)
  }

  onHandleShopName (event) {
    this.setState({
      shopName: event.target.value
    })
  }
  onHandleEmail (event) {
    this.setState({
      email: event.target.value
    })
  }
  onHandleEmailVerification (event) {
    this.setState({
      emailVerification: event.target.value
    })
  }
  onHandlePhoneNumber (event) {
    this.setState({
      phoneNumber: event.target.value
    })
  }
  onHandlePhoneVerification (event) {
    this.setState({
      phoneVerification: event.target.value
    })
  }

  onOpenShop () {}

  render () {
    console.log(this)
    // const self = this
    return (
      <div className='bg_color_image_orange_green' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
        <Segment placeholder style={{ width: '80%', maxWidth: '600px' }}>
          <Container>
            <Grid>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Header as='h2' color='teal' textAlign='center'>
                    Open Enterprise Shop
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Form>
                    <Form.Input
                      onChange={this.onHandleShopName}
                      label='Shop Name'
                      placeholder='Shop Name'
                    />
                    <Form.Input
                      onChange={this.onHandleEmail}
                      label='Email'
                      placeholder='Email'
                    />
                    <Form.Input
                      onChange={this.onHandleEmailVerification}
                      label='Email Verification'
                      placeholder='Email Verification'
                    />
                    <Form.Field>
                      <label>Phone Number</label>
                      <IntlTelInput
                        preferredCountries={['cn']}
                        onPhoneNumberChange={this.onHandlePhoneNumber}
                      />
                    </Form.Field>
                    <Form.Input
                      onChange={this.onHandlePhoneVerification}
                      label='Phone Verification'
                      placeholder='Phone Verification'
                    />
                  </Form>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column textAlign='center'>
                  <Button
                    onClick={this.onOpenShop}
                    content='Open Shop'
                    color='orange'
                    size='large'
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Segment>
      </div>
    )
  }
}

export default withRouter(EnterpriseShopPop)
