import React from 'react'
// import { Parallax, } from 'react-parallax';
import {
  Segment,
  Button,
  Container,
  Form,
  Grid,
  // Icon,
  Header
} from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
// import PhoneVerification from '../utils/PhoneVerification'

class PersonalShopPop extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      shopName: '',
      email: '',
      phoneNumber: '',
      phoneVerification: ''
    }
    this.onHandleShopName = this.onHandleShopName.bind(this)
    this.onHandleShopName = this.onHandleShopName.bind(this)
    this.handlePhoneChange = this.handlePhoneChange.bind(this)
    this.handlePhoneVerification = this.handlePhoneVerification.bind(this)
  }

  handlePhoneChange (phoneNumber) {
    this.setState({ phoneNumber })
  }

  handlePhoneVerification (event) {
    this.setState({ 
      phoneVerification: event.target.value 
    })
  }

  componentDidMount () {
    window.scrollTo(0, 0)
    const self = this
    window.onload = event => {
      self.setState({
        loading: false
      })
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 700)
  }

  onHandleShopName (event) {
    this.setState({
      shopName: event.target.value
    })
  }

  onOpenShop () {}

  render () {
    const self = this
    console.log(self.state)
    return (
      <div
        className='bg_color_image_green_orange'
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh'
        }}
      >
        <Segment placeholder style={{ width: '80%', maxWidth: '600px' }}>
          <Container>
            <Grid>
              <Grid.Row>
                {/* <Grid.Column width={1}>
                  <Icon
                    name='arrow left'
                    size='large'
                    onClick={() => this.props.history.goBack()}
                    style={{ cursor: 'pointer' }}
                  />
                </Grid.Column> */}
                <Grid.Column width={16}>
                  <Header as='h2' color='teal' textAlign='center'>
                    Open Personal Shop
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Form>
                    <Form.Input
                      onChange={self.onHandleShopName}
                      label='Shop Name'
                      placeholder='Shop Name'
                    />
                    
                    <Form.Input
                      onChange={self.onHandleShopName}
                      label='Shop description'
                      placeholder="What's the product aboout"
                    />

                    {/* <PhoneVerification
                      self={self}
                      onPhoneChange={self.handlePhoneChange}
                    />

                    <Form.Input
                      onChange={self.handlePhoneVerification}
                      label='Verification Code'
                      placeholder='Verification Code'
                    /> */}
                  </Form>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column textAlign='center'>
                  <Button
                    onClick={this.onOpenShop}
                    content='Open Shop'
                    color='orange'
                    size='large'
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Segment>
      </div>
    )
  }
}

export default withRouter(PersonalShopPop)
