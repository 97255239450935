import React from 'react'
import axios from 'axios'
import { Grid, Header, Icon, Segment, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { Parallax } from 'react-parallax'
import { withRouter } from 'react-router-dom'

import PastOrders from '../../pastOrders/PastOrdersList'
import PastPastOrders from '../../pastOrders/PastPastOrderList'
import OngoingPastOrders from '../../pastOrders/OngoingtPastOrderList'

class MerchantOrder extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      orders: [],
      loading: true,
      login: false,
      userInfo: null,
      userId: '',
      ongoingOrder: '',
      pastOrder: ''
    }
    this.getMerchantInfo = this.getMerchantInfo.bind(this)
    this.sortOrders = this.sortOrders.bind(this)
  }

  componentDidMount () {
    const self = this

    var userInfo = localStorage.getItem('userInfo')
    userInfo = JSON.parse(userInfo)
    var userId = userInfo[0].id
    // console.log(userId, userInfo)
    self.setState({
      userInfo: userInfo,
      userId: userId
    })

    const merchantLogin = localStorage.getItem('merchantLogin')
    const login=localStorage.getItem('login')
    if (merchantLogin === 'true'&&login === 'true') {
      self.setState({
        login: true
      })
      self.getMerchantInfo(userId)
    }else{
      // self.props.history.push('/merchantsLog')
      console.log(merchantLogin)
    }

   
  }

  getMerchantInfo (userId) {
    const self = this
    // const identifier = localStorage.getItem('identifier')
    const token = localStorage.getItem('token')
    console.log(userId)

    axios('https://sheyou-backend.herokuapp.com/orders?merchants=' + userId, {
      method: 'get',
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).then(function (res) {
      if (res.status === 200) {
        var d = res.data
        console.log(d)
        self.setState({ orders: d, userId: userId, loading: false })
      } else {
        alert('something went wrong :(')
        // localStorage.setItem('login', 'false')
      }
    })
  }

  sortOrders () {
    const self = this
    const s = self.state
    const userId = self.state.userId
    const d = s.orders
    // console.log('originl date', d)
    d.forEach(function (i) {
      const orderDataA = []
      const orderDataC = []
      i.orders.data.accessory.forEach(function (a) {
        // console.log(a)
        if (a.merchant === userId) {
          orderDataA.push(a)
        }
      })
      i.orders.data.accessory = orderDataA
      i.orders.data.clothing.forEach(function (c) {
        // console.log(c)

        if (c.merchant === userId) {
          orderDataC.push(c)
        }
      })
      i.orders.data.clothing = orderDataC
    })
    // console.log('new data', d)
  }

  

  render () {
    const self = this
    const s = self.state
    const p = this.props
    var activeItem = p.activeItem
    const login = s.login

    const orders = s.orders
    console.log(login, orders, activeItem)
    return (<>
        {this.state.loading ? (
          <Grid>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>

            <Grid.Row colums={2}>
              <Grid.Column width={7}></Grid.Column>
              <Grid.Column width={6}>
                <Header size='huge'>
                  <Icon loading name='asterisk' size='massive' color='yellow' />
                  Loading...
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
          </Grid>
        ) : (
          <>
            {login !== true ? (
              <Parallax
                bgImage='https://i.loli.net/2020/11/25/ZHjYm1wL9runqOz.png'
                bgImageAlt=''
                strength={200}
              >
                <div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <Segment>
                    <Grid>
                      <Grid.Row centered>
                        <Header> You Should login first</Header>
                      </Grid.Row>

                      <Grid.Row centered>
                        <Link to='/signin'>
                          <Button size='huge' color='orange'>
                            Login
                          </Button>
                        </Link>
                      </Grid.Row>
                    </Grid>
                  </Segment>
                </div>

                <div style={{ height: '200px' }} />
              </Parallax>
            ) : (
              <div>
                {activeItem === 'All' && (
                  <>
                    {orders.length === 0 && (
                      <Grid>
                        <Grid.Row centered>
                          <Header centered color='orange' as='h1'>
                          
                            You haven no order yet
                          </Header>
                          <Header centered color='orange' as='h1'>
                          
                            Check out the clothing
                          </Header>
                        </Grid.Row>
                        <Grid.Row centered>
                          <Link to='/clothing'>
                            <Button color='teal'>Let's go!</Button>
                          </Link>
                        </Grid.Row>
                      </Grid>
                    )}
                    {orders.length !== 0 && (
                      <>
                         
                        <Grid divided='vertically'>
                          <PastOrders
                            orders={orders}
                            userId={s.userId}
                            activeItem={activeItem}
                            isMerchantOrder={true}
                          />
                        </Grid>
                      </>
                    )}
                  </>
                )}
                {activeItem === 'Ongoing' && (
                  <>
                    {orders.length === 0 && (
                      <Grid>
                        <Grid.Row centered>
                          <Header centered color='orange' as='h1'>
                          
                            You haven no order yet
                          </Header>
                          <Header centered color='orange' as='h1'>
                          
                            Check out the clothing
                          </Header>
                        </Grid.Row>
                        <Grid.Row centered>
                          <Link to='/clothing'>
                            <Button color='teal'>Let's go!</Button>
                          </Link>
                        </Grid.Row>
                      </Grid>
                    )}
                    {orders.length !== 0 && (
                      <>
                        <Grid divided='vertically'>
                          <OngoingPastOrders
                            orders={s.orders}
                            userId={s.userId}
                            activeItem={activeItem}
                            isMerchantOrder={true}
                          />
                           
                        </Grid>
                      </>
                    )}
                  </>
                )}
                {activeItem === 'Past' && (
                  <>
                    {orders.length === 0 && (
                      <Grid>
                        <Grid.Row centered>
                          <Header centered color='orange' as='h1'>
                          
                            You haven no order yet
                          </Header>
                          <Header centered color='orange' as='h1'>
                          
                            Check out the clothing
                          </Header>
                        </Grid.Row>
                        <Grid.Row centered>
                          <Link to='/clothing'>
                            <Button color='teal'>Let's go!</Button>
                          </Link>
                        </Grid.Row>
                      </Grid>
                    )}
                    {orders.length !== 0 && (
                      <>
                        <Grid divided='vertically'>
                          <PastPastOrders
                            orders={s.orders}
                            userId={s.userId}
                            activeItem={activeItem}
                            isMerchantOrder={true}
                          />
                        </Grid>
                      </>
                    )}
                  </>
                )}
              </div>
            )}
          </>
        )}
      </>
    )
  }
}
export default withRouter(MerchantOrder)
