import React from 'react'
import {
  Grid,
  Segment,
  Button,
  Container,
  Label,
  Header,
  Icon,
  Divider,
  Rating,
  Form,
  TextArea,
} from 'semantic-ui-react'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import TheProductInfoToComment from './TheProductInfoToComment'
import UploadImages from '../merchants/merchantPage/UploadProductPhoto'

class Comment extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      comment: '',
      products: '',
      commentData: '',
      productInfoComment: '',
      productToCommentId: '',
      rating: '',
      commentInformation: '',
      pictures: '',
      loading: true,
      is: ''
    }
    this.onComment = this.onComment.bind(this)
    this.getProducts = this.getProducts.bind(this)
    this.getOrder = this.getOrder.bind(this)
    this.onHandleSubmit = this.onHandleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.getAccessory = this.getAccessory.bind(this)
    this.getSaleProducts = this.getSaleProducts.bind(this)
  }

  componentDidMount () {
    window.scrollTo(0, 0)
    const self = this

    const is = localStorage.getItem('is')
    if (is === 'clothing') {
      this.getProducts()
    } else if (is === 'saleClothing') {
      this.getSaleProducts()
    } else {
      this.getAccessory()
    }
    this.getOrder()

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 700)

    var commentInformation = localStorage.getItem('commentInformation')
    commentInformation = JSON.parse(commentInformation)

    this.setState({
      commentInformation: commentInformation,
      is: is
    })
  }

  handleRate = (e, { rating, maxRating }) =>
    this.setState({ rating, maxRating })

  getProducts () {
    const self = this
    const productId = localStorage.getItem('productToCommentId')

    axios
      .get('https://sheyou-backend.herokuapp.com/products?id=' + productId)
      .then(function (res) {
        var data = res.data[0]
        // data=JSON.stringify(data)
        // localStorage.setItem("productInfoComment",data)
        self.setState({
          productInfoComment: data,
          productToCommentId: productId
        })
      })
  }
  getSaleProducts () {
    const self = this
    const productId = localStorage.getItem('productToCommentId')

    axios
      .get(
        'https://sheyou-backend.herokuapp.com/second-hand-products?id=' +
          productId
      )
      .then(function (res) {
        var data = res.data[0]
        // data=JSON.stringify(data)
        // localStorage.setItem("productInfoComment",data)
        self.setState({
          productInfoComment: data,
          productToCommentId: productId
        })
      })
  }

  getAccessory () {
    const self = this
    const productId = localStorage.getItem('productToCommentId')

    axios
      .get('https://sheyou-backend.herokuapp.com/accessaries?id=' + productId)
      .then(function (res) {
        var data = res.data[0]
        // data=JSON.stringify(data)
        // localStorage.setItem("productInfoComment",data)
        self.setState({
          productInfoComment: data,
          productToCommentId: productId
        })
      })
  }

  getOrder () {
    const orderId = localStorage.getItem('orderToCommentId')
    const self = this

    axios
      .get('https://sheyou-backend.herokuapp.com/orders/' + orderId)
      .then(function (res) {
        console.log(res)
        var data = res.data.orders
        // data=JSON.stringify(data)
        // localStorage.setItem('commentData',data)
        self.setState({ commentData: data, loading: false })
      })
  }

  onComment (event) {
    this.setState({
      comment: event.target.value
    })
  }

  ratingStars () {
    this.setState({
      ratingStars: 'zero stars'
    })
  }

  handleChange (event, formData, value) {
    console.log(event)
    const pic = event

    this.setState({
      pictures: pic
    })
  }

  onHandleSubmit (event) {
    const self = this
    const orderId = localStorage.getItem('orderToCommentId')
    console.log(orderId)
    const productId = localStorage.getItem('productToCommentId')
    const is = self.state.is

    if (self.state.rating === '') {
      self.setState({ rating: false })
    }
    if (self.state.comment === '') {
      self.setState({ comment: false })
    }

    var commentInformation = localStorage.getItem('commentInformation')
    commentInformation = JSON.parse(commentInformation)

    var userInfo = localStorage.getItem('userInfo')
    userInfo = JSON.parse(userInfo)
    console.log(userInfo)
    const userinfo = {
      height: userInfo[0].height,
      clothingsize: userInfo[0].clothingsize,
      bustsize: userInfo[0].bustsize,
      username: userInfo[0].username,
      weight: userInfo[0].weight,
      id: userInfo[0].id,
      bodytype: userInfo[0].bodytype
    }

    const comment = {
      comment: self.state.comment,
      productId: self.state.productToCommentId,
      is: is,
      rating: self.state.rating,
      commentInformation: commentInformation,
      userInfo: userinfo,
      orderId: orderId
    }
    const token = localStorage.getItem('token')
    var pictures = self.state.pictures

    if (pictures !== '') {
      var file = new FormData()
      pictures.forEach(img=>{
        file.append('files', img)
      })
      
      const formData = new FormData()
      formData.append('ref', 'comments')
      formData.append('field', 'pictures')
      formData.append('data', comment)
      formData.append('files', pictures)
    }
    console.log(file.values)
    if (
      comment.rating !== undefined &&
      comment.comment !== undefined &&
      comment.rating !== '' &&
      comment.comment !== ''
    ) {
      if (pictures !== '') {
        axios
          .post('https://sheyou-backend.herokuapp.com/upload', file, {
            headers: {
              Authorization: 'Bearer ' + token,
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(res => {
            console.log(res)
            const fileId = res.data.map(function(i){
              return i.id
            })

            axios('https://sheyou-backend.herokuapp.com/comments', {
              method: 'post',
              data: {
                pictures: fileId,
                comment: { comment }
              },
              headers: {
                Authorization: 'Bearer ' + token
              }
            })
              .catch(error => console.log(error))
              .then(function (res) {
                console.log(res)
                console.log(orderId)
                axios
                  .get('https://sheyou-backend.herokuapp.com/orders/' + orderId)
                  .then(function (res) {
                    var orderInfo = res.data.orders.data

                    if (is === 'clothing') {
                      orderInfo.clothing.forEach(function (each) {
                        const idd = JSON.stringify(each.id)
                        if (each.type !== 'sale' && idd === productId) {
                          // const id = each.id
                          each = Object.assign(each, { commented: true })
                          return each
                        }
                      })
                    } else if (is === 'saleClothing') {
                      orderInfo.clothing.forEach(function (each) {
                        const idd = JSON.stringify(each.id)
                        if (each.type === 'sale' && idd === productId) {
                          // const id = each.id
                          each = Object.assign(each, { commented: true })
                          return each
                        }
                      })
                    } else {
                      orderInfo.accessory.forEach(function (each) {
                        const idd = JSON.stringify(each.id)
                        if (idd === productId) {
                          // const id = each.id
                          each = Object.assign(each, { commented: true })
                          return each
                        }
                      })
                    }

                    console.log(orderInfo, res.data)

                    axios(
                      'https://sheyou-backend.herokuapp.com/orders/' + orderId,
                      {
                        method: 'put',
                        data: res.data,
                        headers: {
                          Authorization: 'Bearer ' + token
                        }
                      }
                    )
                      .then(function (res) {
                        console.log(res)
                        alert('comment successfully :)')
                        self.props.history.push('/mypage')
                      })
                      .catch(function (err) {
                        console.log(err)
                      })
                  })
                  .catch(function (err) {
                    console.log(err)
                  })
              })
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        axios('https://sheyou-backend.herokuapp.com/comments', {
          method: 'post',
          data: {
            comment: { comment }
          },
          headers: {
            Authorization: 'Bearer ' + token
          }
        })
          .catch(error => console.log(error))
          .then(function (res) {
            console.log(res)
            console.log(orderId)
            axios
              .get('https://sheyou-backend.herokuapp.com/orders/' + orderId)
              .then(function (res) {
                var orderInfo = res.data.orders.data

                if (is === 'clothing') {
                  orderInfo.clothing.forEach(function (each) {
                    const idd = JSON.stringify(each.id)
                    if (each.type !== 'sale' && idd === productId) {
                      // const id = each.id
                      each = Object.assign(each, { commented: true })
                      return each
                    }
                  })
                } else if (is === 'saleClothing') {
                  orderInfo.clothing.forEach(function (each) {
                    const idd = JSON.stringify(each.id)
                    if (each.type === 'sale' && idd === productId) {
                      // const id = each.id
                      each = Object.assign(each, { commented: true })
                      return each
                    }
                  })
                } else {
                  orderInfo.accessory.forEach(function (each) {
                    const idd = JSON.stringify(each.id)
                    if (idd === productId) {
                      // const id = each.id
                      each = Object.assign(each, { commented: true })
                      return each
                    }
                  })
                }

                axios(
                  'https://sheyou-backend.herokuapp.com/orders/' + orderId,
                  {
                    method: 'put',
                    data: res.data,
                    headers: {
                      Authorization: 'Bearer ' + token
                    }
                  }
                )
                  .then(function (res) {
                    console.log(res)
                    alert('comment successfully :)')
                    self.props.history.push('/mypage')
                  })
                  .catch(function (err) {
                    console.log(err)
                  })
              })
              .catch(function (err) {
                console.log(err)
              })
          })
      }
    }

    // alert("You have submited your comment successfully")
    // self.props.history.push("/mypage")
  }

  render () {
    const s = this.state
    const self = this
    console.log(s)

    return (
      <div>
        {/* <Nav /> */}
        {s.loading ? (
          <Grid>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>

            <Grid.Row colums={2}>
              <Grid.Column width={7}></Grid.Column>
              <Grid.Column width={6}>
                <Header size='huge'>
                  <Icon loading name='asterisk' size='massive' color='yellow' />
                  Loading...
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
          </Grid>
        ) : (
          <>
            <div className='bg_image'>
              <br />
              <br />
              <Container>
                <Segment>
                  <Grid container centered>
                    {s.productInfoComment !== '' && s.commentData && (
                      <TheProductInfoToComment
                        productInfoComment={s.productInfoComment}
                        commentData={s.commentData}
                      />
                    )}

                    <Grid.Row centered>
                      <Grid.Column>
                        <Divider />
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row centered columns={4}>
                      <Grid.Column>
                        <Header textAlign='center' color='orange' as='h2'>
                          Give Your Rating
                        </Header>
                      </Grid.Column>

                      <Grid.Column>
                        <Rating
                          onRate={this.handleRate}
                          maxRating={5}
                          icon='star'
                          size='massive'
                        />
                      </Grid.Column>
                      {s.rating === false && (
                        <Label basic color='red' pointing='left'>
                          Please give a rating before submiting
                        </Label>
                      )}
                    </Grid.Row>

                    <Grid.Row centered>
                      <Grid.Column>
                        <Divider />
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row centered>
                      <Header color='orange' textAlign='center' as='h2'>
                        Upload Pictures
                        <Header.Subheader>
                          Maximum of 6 pictures
                        </Header.Subheader>
                        <br />
                        <UploadImages onUploadPic={this.handleChange} />
                        {/* <Icon.Group size='huge' style={{ margin: 12 }}>
                          <Icon
                            bordered
                            color='teal'
                            name='add'
                            style={{ width: 200 }}
                          />
                          <Icon corner name='add' size='huge' color='grey' />
                          <Form>
                            <Input
                              onChange={this.handleChange}
                              ref='file'
                              type='file'
                              multiple='true'
                              accept='image'
                            />
                          </Form>
                        </Icon.Group> */}
                      </Header>
                    </Grid.Row>

                    <Grid.Row centered>
                      <Grid.Column>
                        <Divider />
                        <Header textAlign='center' color='orange' as='h2'>
                          {' '}
                          Leave Your Comment
                        </Header>
                      </Grid.Column>
                    </Grid.Row>

                    {/* <FileUpload/> */}

                    <Grid.Row centered>
                      <Grid.Column width={8}>
                        <Form>
                          <TextArea
                            onChange={this.onComment}
                            placeholder='How do you feel about the product?'
                          />
                          {s.comment === false && (
                            <Label basic color='red' pointing>
                              Please leave a comment before submiting
                            </Label>
                          )}
                        </Form>
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row></Grid.Row>

                    <Grid.Row></Grid.Row>
                    <Grid.Row centered>
                      <Button
                        color='teal'
                        style={{ paddingLeft: 40, paddingRight: 40 }}
                        onClick={self.onHandleSubmit}
                      >
                        Submit
                      </Button>
                    </Grid.Row>
                  </Grid>
                </Segment>
              </Container>
              <br />
              <br />
            </div>
          </>
        )}
      </div>
    )
  }
}

export default withRouter(Comment)
