import React from 'react'
import axios from 'axios'
import { withRouter } from 'react-router-dom'

import { Grid, Menu, Header, Icon } from 'semantic-ui-react'

import LoadingPhone from '../RedirectPages/LoadingPhone'
import NavMobilePhone from '../nav/NavMobilePhone'
import LogInFirstPhone from '../RedirectPages/LogInFirstPhone'

class MyPagePhone extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      products: [],
      order: [],
      someData: [],
      activeItem: 'All',
      clickValue: '',
      login: false,
      buyOrRent: '',
      justSomeProductInfo: [],
      orders: [],
      loading: true,
      signinVisible: false,
      itemsVisible: false
    }
    this.getProducts = this.getProducts.bind(this)
    this.signinVisible = this.signinVisible.bind(this)
    this.itemsVisible = this.itemsVisible.bind(this)
    this.toOrders = this.toOrders.bind(this)
    this.handleItemClick = this.handleItemClick.bind(this)
    this.onHandleSignOut = this.onHandleSignOut.bind(this)
    this.handleItemClickCart = this.handleItemClickCart.bind(this)
  }

  signinVisible () {
    const signinVisible = this.state.signinVisible
    console.log(signinVisible)

    if (signinVisible !== true) {
      this.setState({
        signinVisible: true
      })
    } else if (signinVisible === true) {
      this.setState({
        signinVisible: false
      })
    }
  }

  handleItemClickCart (event, data) {
    const self = this
    const log = localStorage.getItem('login')
    if (log !== 'true') {
      self.signinVisible()
    } else {
      self.props.history.push('/cart')
    }
  }

  toOrders () {
    const self = this

    const login = this.state.login
    if (login === false) {
      self.signinVisible()
    } else {
      self.props.history.push('/myOrders')
    }
  }

  itemsVisible () {
    const itemsVisible = this.state.itemsVisible
    console.log(itemsVisible)

    if (itemsVisible !== true) {
      this.setState({
        itemsVisible: true
      })
    } else if (itemsVisible === true) {
      this.setState({
        itemsVisible: false
      })
    }
  }

  componentDidMount () {
    window.scrollTo(0, 0)
    const self = this
    this.getProducts()
    this.ifLog()

    console.log(this.props)
    if (this.props.activeItem === 'Membership') {
      self.setState({
        activeItem: 'Membership'
      })
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        self.setState({
          loading: false
        })
      }
    }, 1000)
  }

  getProducts () {
    const self = this
    // const token=this.state.token
    const identifier = localStorage.getItem('identifier')
    const token = localStorage.getItem('token')

    axios(
      'https://sheyou-backend.herokuapp.com/orders?users_permissions_user.username=' +
        identifier,
      {
        method: 'get',
        headers: {
          Authorization: 'Bearer ' + token
        }
      }
    ).then(function (res) {
      if (res.status === 200) {
        const d = res.data
        const len = d.length
        const orders = []
        for (var i = len - 1; i >= 0; i--) {
          orders.push(d[i])
        }
        self.setState({
          orders: orders
        })

        window.setTimeout(() => {
          self.setState({
            loading: false
          })
        }, 1000)
      } else {
        alert('something went wrong :(')
        localStorage.setItem('login', 'false')
      }
    })
  }

  ifLog () {
    const log = localStorage.getItem('login')
    const self = this
    if (log === 'false') {
      self.setState({ login: false })
    } else {
      self.setState({ login: true })
    }
  }

  onHandleSignOut (event) {
    event.preventDefault()
    const self = this
    self.setState({ login: false })
    const rememberMe = localStorage.getItem('rememberMe')
    if (rememberMe === 'true') {
      localStorage.setItem('login', 'false')
    } else {
      localStorage.setItem('login', 'false')
      localStorage.setItem('identifier', '')
      localStorage.setItem('password', '')
    }

    // alert('you have been signed out')
    self.props.history.push('/loggingOut')
  }

  show () {
    const self = this
    self.setState({ open: true })
  }

  handleItemClick (event, data) {
    console.log(event, data)
    const name = data.name

    this.setState({ activeItem: name })
    if (name === 'Profile Detail') {
      this.props.history.push('/profileDetail')
    } else if (name === 'Fit Preference') {
      this.props.history.push('/fitPreference')
    } else if (name === 'Address') {
      this.props.history.push('/myAddress')
    } else if (name === 'About') {
      this.props.history.push('/about')
    } else if (name === 'Contact Us') {
      this.props.history.push('/contactUs')
    } else if (name === 'Merchant') {
      this.props.history.push('/merchantsLog')
    } else if (name === 'Change Password') {
      this.props.history.push('/changePassword')
    }
  }

  render () {
    const s = this.state
    const self = this
    const loading = s.loading
    const activeItem = this.state.activeItem
    const login = localStorage.getItem('login')
    const identifier = localStorage.getItem('identifier')

    return (
      <div>
        {/* <Nav /> */}

        {loading === true && <LoadingPhone />}

        {loading === false && (
          <>
            {login !== 'true' && (
              <LogInFirstPhone signinVisible={this.signinVisible} />
            )}

            {login === 'true' && (
              <>
                <Menu icon borderless widths={3}>
                  <Menu.Item>
                    <Header
                      as='h1'
                      color='teal'
                      // style={{ position: 'relative', top: -9 }}
                    >
                      SHEYOU
                    </Header>
                  </Menu.Item>
                </Menu>

                <div className='bg_color_image_green_orange'>
                  <Grid
                    columns='equal'
                    divided
                    style={{ paddingTop: 50, paddingBottom: 50 }}
                  >
                    <Grid.Column width={2}></Grid.Column>
                    <Grid.Column width={7}>
                      <Grid.Row
                        style={{
                          paddingBottom: 0,
                          marginBottom: 0,
                          marginTop: 60
                        }}
                      >
                        <Header as='h2' style={{ color: 'rgba(59,83,98)' }}>
                          Welcom,
                          <br />
                          {identifier}
                        </Header>
                      </Grid.Row>
                      {/* <Grid.Row style={{ paddingTop: 0, marginTop: 0 }}>
                    <Header
                      as='h5'
                      name='Profile Detail'
                      onClick={self.handleItemClick}
                      style={{
                        marginTop: 5,
                        paddingLeft: 10,
                        color: 'rgba(59,83,98)'
                      }}
                    >
                      <Icon.Group size='small'>
                        <Icon name='write' />
                      </Icon.Group>
                      Change
                    </Header>
                  </Grid.Row> */}
                      <Grid.Row></Grid.Row>
                    </Grid.Column>

                    <Grid.Column width={4}>
                      <Grid.Row centered>
                        <Icon.Group onClick={self.toOrders}>
                          <Icon size='huge' name='circle' inverted />
                          <Icon
                            style={{
                              paddingLeft: 0,
                              marginLeft: 0,
                              paddingRight: 3,
                              opacity: '0.9'
                            }}
                            color='orange'
                            size='big'
                            name='shopping bag'
                          />
                        </Icon.Group>
                        <Header
                          as='h5'
                          style={{
                            paddingTop: 0,
                            marginTop: 5,
                            marginBottom: 10,
                            paddingLeft: 10,
                            color: 'rgba(59,83,98)'
                          }}
                        >
                          Orders
                        </Header>
                      </Grid.Row>

                      <Grid.Row centered>
                        <Icon.Group onClick={self.handleItemClickCart}>
                          <Icon size='huge' name='circle' inverted />
                          <Icon
                            style={{
                              paddingLeft: 0,
                              marginLeft: 0,
                              paddingRight: 3,
                              opacity: '0.9'
                            }}
                            color='orange'
                            size='big'
                            name='cart'
                          />
                        </Icon.Group>
                        <Header
                          onClick={self.handleItemClickCart}
                          as='h5'
                          style={{
                            paddingTop: 0,
                            color: 'rgba(59,83,98)',
                            marginTop: 5,
                            paddingLeft: 15
                          }}
                        >
                          Cart
                        </Header>
                      </Grid.Row>

                      <Grid.Row></Grid.Row>
                    </Grid.Column>
                  </Grid>
                </div>

                <div style={{ backgroundColor: '#E6E9EA', paddingTop: 10 }}>
                  <Menu
                    vertical
                    borderless
                    style={{ width: '100%', paddingTop: 5, paddingBottom: 5 }}
                  >
                    <Menu.Item
                      name='Merchant'
                      active={activeItem === 'Merchant'}
                      onClick={this.handleItemClick}
                    >
                      <Grid columns='equal '>
                        <Grid.Column width={1}></Grid.Column>
                        <Grid.Column width={2}>
                          <Icon name='flag' color='teal' circular />
                        </Grid.Column>
                        <Grid.Column
                          style={{ position: 'relative', top: 6 }}
                          width={10}
                        >
                          <Header style={{ color: 'rgba(59,83,98)' }} as='h5'>
                            Merchant Account
                          </Header>
                        </Grid.Column>
                        <Grid.Column style={{ position: 'relative', top: 6 }}>
                          <Icon name='angle right' />
                        </Grid.Column>
                      </Grid>
                    </Menu.Item>
                  </Menu>
                </div>

                <div style={{ backgroundColor: '#E6E9EA', paddingTop: 10 }}>
                  <Menu
                    vertical
                    borderless
                    style={{ width: '100%', paddingTop: 5, paddingBottom: 5 }}
                  >
                    <Menu.Item
                      name='Profile Detail'
                      active={activeItem === 'Profile Detail'}
                      onClick={this.handleItemClick}
                      // style={{ paddingBottom: 0 }}
                    >
                      <Grid columns='equal '>
                        <Grid.Column width={1}></Grid.Column>
                        <Grid.Column width={2}>
                          <Icon color='teal' circular name='write' />
                        </Grid.Column>
                        <Grid.Column
                          style={{ position: 'relative', top: 6 }}
                          width={10}
                        >
                          <Header style={{ color: 'rgba(59,83,98)' }} as='h5'>
                            Profile Detail
                          </Header>
                        </Grid.Column>
                        <Grid.Column style={{ position: 'relative', top: 6 }}>
                          <Icon name='angle right' />
                        </Grid.Column>
                      </Grid>
                    </Menu.Item>

                    <Menu.Item
                      name='Fit Preference'
                      active={activeItem === 'Fit Preference'}
                      onClick={this.handleItemClick}
                    >
                      <Grid columns='equal '>
                        <Grid.Column width={1}></Grid.Column>
                        <Grid.Column width={2}>
                          <Icon
                            color='teal'
                            circular
                            name='sliders horizontal'
                          />
                        </Grid.Column>
                        <Grid.Column
                          style={{ position: 'relative', top: 6 }}
                          width={10}
                        >
                          <Header style={{ color: 'rgba(59,83,98)' }} as='h5'>
                            Fit Preference
                          </Header>
                        </Grid.Column>
                        <Grid.Column style={{ position: 'relative', top: 6 }}>
                          <Icon name='angle right' />
                        </Grid.Column>
                      </Grid>
                    </Menu.Item>

                    <Menu.Item
                      name='Address'
                      active={activeItem === 'Address'}
                      onClick={this.handleItemClick}
                      // style={{ paddingBottom: 0 }}
                    >
                      <Grid columns='equal '>
                        <Grid.Column width={1}></Grid.Column>
                        <Grid.Column width={2}>
                          <Icon color='teal' circular name='address book' />
                        </Grid.Column>
                        <Grid.Column
                          style={{ position: 'relative', top: 6 }}
                          width={10}
                        >
                          <Header style={{ color: 'rgba(59,83,98)' }} as='h5'>
                            My Address
                          </Header>
                        </Grid.Column>
                        <Grid.Column style={{ position: 'relative', top: 6 }}>
                          <Icon name='angle right' />
                        </Grid.Column>
                      </Grid>
                    </Menu.Item>
                  </Menu>
                </div>

                <div style={{ backgroundColor: '#E6E9EA', paddingTop: 10 }}>
                  <Menu
                    vertical
                    style={{
                      width: '100%'
                    }}
                  >
                    <Menu.Item
                      name='Change Password'
                      active={activeItem === 'Change Password'}
                      onClick={this.handleItemClick}
                    >
                      <Grid columns='equal '>
                        <Grid.Column width={1}></Grid.Column>
                        <Grid.Column width={12}>
                          <Header style={{ color: 'rgba(59,83,98)' }} as='h5'>
                            Change Password
                          </Header>
                        </Grid.Column>
                        <Grid.Column>
                          <Icon
                            name='edit'
                            // style={{ position: 'relative', top: 3 }}
                          />
                        </Grid.Column>
                      </Grid>
                    </Menu.Item>

                    <Menu.Item
                      name='Log Out'
                      active={activeItem === 'Log Out'}
                      onClick={this.onHandleSignOut}
                    >
                      <Grid columns='equal '>
                        <Grid.Column width={1}></Grid.Column>
                        <Grid.Column width={12}>
                          <Header style={{ color: 'rgba(59,83,98)' }} as='h5'>
                            Log Out
                          </Header>
                        </Grid.Column>
                        <Grid.Column>
                          <Icon
                            name='sign-out'
                            // style={{ position: 'relative', top: 3 }}
                          />
                        </Grid.Column>
                      </Grid>
                    </Menu.Item>
                  </Menu>
                </div>

                <div style={{ backgroundColor: '#E6E9EA', paddingTop: 10 }}>
                  <Menu vertical style={{ width: '100%' }}>
                    <Menu.Item
                      name='About'
                      active={activeItem === 'About'}
                      onClick={this.handleItemClick}
                    >
                      <Grid columns='equal '>
                        <Grid.Column width={1}></Grid.Column>
                        <Grid.Column width={12}>
                          <Header style={{ color: 'rgba(59,83,98)' }} as='h5'>
                            How This Works
                          </Header>
                        </Grid.Column>
                        <Grid.Column>
                          <Icon name='angle right' />
                        </Grid.Column>
                      </Grid>
                    </Menu.Item>
                    <Menu.Item
                      name='Contact Us'
                      active={activeItem === 'Contact Us'}
                      onClick={this.handleItemClick}
                    >
                      <Grid columns='equal '>
                        <Grid.Column width={1}></Grid.Column>
                        <Grid.Column width={12}>
                          <Header style={{ color: 'rgba(59,83,98)' }} as='h5'>
                            Contact Us
                          </Header>
                        </Grid.Column>
                        <Grid.Column>
                          <Icon name='angle right' />
                        </Grid.Column>
                      </Grid>
                    </Menu.Item>
                  </Menu>

                  <Grid style={{ paddingBottom: 55 }}>
                    <Grid.Row centered>
                      <Header as='h6' style={{ color: '#384D5A' }}>
                        @Sichuan Sheyu Renyu Trading Co. LTD
                      </Header>
                    </Grid.Row>
                  </Grid>
                </div>
              </>
            )}
          </>
        )}

        <NavMobilePhone />
      </div>
    )
  }
}

export default withRouter(MyPagePhone)
