import React from 'react'
import { Grid, Header, Image } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'



class TheProductInfoToComment extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            products: '',
            identifier: '',
            orderData: '',
            colors: '',
            sizes: '',
            rentOrBuy: "",



        }
        this.getProducts = this.getProducts.bind(this)
        this.getOrder = this.getOrder.bind(this)
    }

    componentDidMount() {
        this.getProducts()
        this.getOrder()
    }


    getProducts() {
        const self = this
        // var data=localStorage.getItem("productInfoComment")
        // data=JSON.parse(data)
        const p = this.props
        const data = p.productInfoComment
        self.setState({
            products: data,
        })
    }

    getOrder() {
        const self = this
        // var data=localStorage.getItem('commentDate')
        // data=JSON.parse(data)
        const p = this.props
        const data = p.commentData
        console.log(data)
        var productId = localStorage.getItem("productToCommentId")
        productId = JSON.parse(productId)
        var is = localStorage.getItem("is")

        if (is === 'clothing') {
            data.data.clothing.forEach(function (it) {
                if (it.type !== 'sale' && it.id === productId) {
                    self.setState({
                        colors: it.colors,
                        sizes: it.sizes
                    })
                }
            })
        } else if (is === 'saleClothing') {
            data.data.clothing.forEach(function (it) {
                if (it.type === 'sale' && it.id === productId) {
                    self.setState({
                        colors: it.colors,
                        sizes: it.sizes
                    })
                }
            })
        } else {
            data.data.accessory.forEach(function (it) {
                if (it.id === productId) {
                    self.setState({
                        colors: it.colors,
                        sizes: it.sizes
                    })
                }
            })
        }


        self.setState({
            identifier: data.info.identifier,
            orderData: data.data,
            rentOrBuy: data.info.rentOrBuy

        })



    }


    render() {
        const self = this.state
        console.log(self)
        var commentInformation = {
            colors: self.colors,
            identifier: self.identifier,
            rentOrBuy: self.rentOrBuy,
            sizes: self.sizes,

        }
        commentInformation = JSON.stringify(commentInformation)
        localStorage.setItem("commentInformation", commentInformation)
        const products = this.state.products

        return <Grid.Row  >
            <Grid stackable columns={4}>
                

                <Grid.Column width={3}></Grid.Column>

                <Grid.Column width={6} >
                    {products !== '' && <Image size="medium" src={products.picture[0].url} />}

                </Grid.Column>


                <Grid.Column>

                    <Grid divided='vertically' >


                        <Grid.Row >
                            <Grid.Column width={16}>
                                <Header  > {products !== '' && products.name}</Header>
                            </Grid.Column>

                        </Grid.Row>

                        <Grid.Row>


                            <Grid.Column width={8}>
                                <Header as='h6'>Descriptions: </Header>
                            </Grid.Column>
                            <Grid.Column width={13}>
                                <Header as='h6'>{products !== '' && products.description}</Header>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Header as='h6'>Color Worn: {self.colors}</Header>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <Header as='h6'>Size Worn: {self.sizes}</Header>
                            </Grid.Column>
                        </Grid.Row>


                    </Grid>

                </Grid.Column>

            </Grid>
        </Grid.Row>


    }

}

export default withRouter(TheProductInfoToComment)

