import React from 'react'
import {
  Segment,
  Button,
  Header,
  Form,
  Grid,
  Message,
  Confirm
} from 'semantic-ui-react'
import { Link, withRouter } from 'react-router-dom'
import axios from 'axios'
import Foot from '../foot/Foot'
import timestamp from 'time-stamp'
import newOTP from 'otp-generators'
import emailjs from 'emailjs-com'

import MerchantSec from '../homePage/merchantSec/MerchantSec'
const userLang = navigator.language || navigator.userLanguage
var jwt = require('jsonwebtoken')


class MerchantLog extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      username: '',
      password: '',
      products: [],
      token: '',
      rememberMe: false,
      loading: true,
      open: false,
      somethingWrong: false,
      wrongOpen: false
    }
    this.onHandleUsername = this.onHandleUsername.bind(this)
    this.onHandlePassword = this.onHandlePassword.bind(this)
    this.onHandleSubmit = this.onHandleSubmit.bind(this)
    this.onHandleRememberMe = this.onHandleRememberMe.bind(this)
    this.toOpenShop = this.toOpenShop.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.onRigisterMerchant = this.onRigisterMerchant.bind(this)
  }
  handleCancel () {
    const self = this
    self.setState({ open: false })
  }

  onRigisterMerchant () {
    const self = this
    self.props.history.push('/merchantOpenShop')
  }
  toOpenShop () {
    this.props.history.push('/merchantOpenShop')
  }

  componentWillMount () {
    window.scrollTo(0, 0)
  }
  componentDidMount () {
    window.scrollTo(0, 0)
    const self = this
    window.onload = event => {
      self.setState({
        loading: false
      })
    }

    const merchantLogin = localStorage.getItem('merchantLogin')
    const login = localStorage.getItem('login')
    if (merchantLogin === 'true' && login === 'true') {
      self.props.history.push('/merchantPage')
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 700)
    const remember = localStorage.getItem('rememberMe')
    var identifier = localStorage.getItem('identifier')
    var password = localStorage.getItem('password')
    if (remember === 'true')
      this.setState({
        rememberMe: remember,
        username: identifier,
        password: password
      })
  }

  onHandleUsername (event) {
    this.setState({
      username: event.target.value
    })
  }

  onHandlePassword (event) {
    this.setState({
      password: event.target.value
    })
  }

  onHandleSubmit (event) {
    event.preventDefault()
    const remember = this.state.rememberMe
    const self = this
    var identifier = this.state.username
    var password = this.state.password
    var rememberMe = this.state.rememberMe

    if (remember === 'true') {
      identifier = localStorage.getItem('identifier')
      password = localStorage.getItem('password')
      const identifierRetype = this.state.username
      const passwordRetype = this.state.password
      if (identifierRetype !== '' && passwordRetype !== '') {
        identifier = identifierRetype
        password = passwordRetype
      }
    } else {
      identifier = localStorage.getItem('identifier')
      password = localStorage.getItem('password')
      const rememberMe = localStorage.getItem('rememberMe')

      console.log(identifier, password)
      if (rememberMe !== null) {
        localStorage.removeItem('rememberMe')
      }

      if (
        (identifier === null && password === null) ||
        (identifier === '' && password === '')
      ) {
        identifier = this.state.username
        password = this.state.password
      }
    }

    axios
      .post('https://sheyou-backend.herokuapp.com/auth/local', {
        identifier: identifier,
        password: password
      })
      .then(function (res) {
        console.log('1', res)

        if (res.status === 200) {
          const isMerchant = res.data.user.merchantInfo.isMerchant
          const username = res.data.user.username
          const email = res.data.user.email
          const merchantConfirmed = res.data.user.merchantConfirmed
          if (isMerchant && merchantConfirmed) {
            localStorage.removeItem('toLog')
            localStorage.setItem('login', 'true')
            localStorage.setItem('merchantLogin', 'true')
            localStorage.setItem('log', 'true')
            const time = timestamp.utc('YYYY-MM-DD-HH')
            localStorage.setItem('loginTime', time)
            const token = res.data.jwt
            localStorage.setItem('token', token)
            localStorage.setItem('identifier', identifier)
            localStorage.setItem('password', password)
            localStorage.setItem('rememberMe', rememberMe)

            self.props.history.push('/loggingMerchant')
          } 
          else if (isMerchant && !merchantConfirmed) {

            const verificationCode = newOTP.generate(6, {
              alphabets: false,
              upperCase: false,
              specialChar: false
            })

            const token = jwt.sign(
              {
                data: verificationCode
              },
              'sheyoufashion',
              { expiresIn: '5min' }
            )

            emailjs
              .send(
                'service_orld5qb',
                'template_c1h9qsr',
                {
                  email: email,
                  reply_to: 'susanna.xinshu@gmail.com',
                  name: username,
                  verificationCode: verificationCode
                },
                'user_4rMSnDYBigUutDlR5RSib'
              )
              .then(
                function (response) {
                  console.log('SUCCESS!', response.status, response.text)
                  self.setState({
                    loading: false,
                    sentEmail: true
                  })

                  localStorage.setItem('identifier', identifier)
                  localStorage.setItem('verificationCode', token)
                  self.props.history.push('/emailConfirmationMerchant')
                },
                function (error) {
                  self.setState({
                    loading: false,
                    sentEmail: false,
                    somethingWrong:
                      'Email confirmation FAILED, please contact us...' +
                      error.text
                  })
                }
              )
          
          }
          else if(!isMerchant){
            const username = res.data.user.username

            self.setState({
              open: true,
              username: username
            })
          }
        } else {
          localStorage.removeItem('toLog')
          self.setState({
            rememberMe: false,
            username: '',
            password: '',
            somethingWrong: 'Something went wrong'
          })
        }
      })
      .catch(error => {
        localStorage.removeItem('toLog')
        self.setState({
          rememberMe: false,
          username: '',
          password: ''
        })
        if (error.response.status === 400) {
          self.setState({
            somethingWrong: 'Wrong identifier & password combination'
          })
          localStorage.removeItem('identifier')
          localStorage.removeItem('password')
          localStorage.removeItem('rememberMe')
        } else {
          self.setState({
            somethingWrong: 'An error occurred:' + error.response.statusText
          })
        }
      })
  }

  onHandleResetPassword (event) {
    this.props.history.push('/forgetpassword')
  }

  onHandleRememberMe (event) {
    const self = this
    const remember = self.state.rememberMe
    if (remember !== 'true') {
      self.setState({ rememberMe: true })
    } else {
      self.setState({ rememberMe: false })
    }
  }

  render () {
    console.log(this.state)
    const self = this
    const remember = this.state.rememberMe
    const identifier = this.state.username
    const password = this.state.password
    const toLog = localStorage.getItem('toLog')

    return (
      <div>
        {/* <Nav /> */}

        <Grid>
          <Grid.Row
            style={{
              paddingBottom: 0,
              marginTop: 0,
              marginBottom: 0
            }}
          >
            {window.innerWidth < 766 ? null : (
              <Grid.Column width={6} className='afrogirl'></Grid.Column>
            )}
            <Grid.Column
              width={window.innerWidth < 766 ? 16 : 10}
              style={{ padding: 50 }}
              centered
              className='bg_color_image'
            >
              <Grid centered>
                <Grid.Column width={16} textAlign='center'>
                  <Header style={{ fontSize: 50, color: '#FFFFFF' }} inverted>
                    Welcome, Merchants!
                  </Header>
                </Grid.Column>
              </Grid>

              <Grid cenetred>
                <Grid.Column width={16}>
                  <Segment
                    style={{
                      paddingTop: 50,
                      paddingBottom: 50,
                      borderRadius: 10
                    }}
                  >
                    {toLog === 'true' && (
                      <Message info>
                        <Message.Header>Success</Message.Header>
                        <p>Now You Can Log In</p>
                      </Message>
                    )}
                    <Grid>
                      <Grid.Row centered>
                        <Header style={{ fontSize: 30 }}>Log In</Header>
                      </Grid.Row>
                    </Grid>

                    <br />
                    <br />
                    <Grid centered>
                      <Form style={{ paddingLeft: 20, paddingRight: 20 }}>
                        {remember !== 'true' && (
                          <Form.Input
                            onChange={this.onHandleUsername}
                            icon='user'
                            iconPosition='left'
                            label='Username / Email'
                            placeholder='Usernam / Email'
                            style={{ width: 300 }}
                          />
                        )}

                        {remember === 'true' && (
                          <Form.Input
                            onChange={this.onHandleUsername}
                            icon='user'
                            iconPosition='left'
                            label='Usernam / Email'
                            placeholder='Usernam / Email'
                            value={identifier}
                            style={{ width: 300 }}
                          />
                        )}

                        {remember !== 'true' && (
                          <Form.Input
                            onChange={this.onHandlePassword}
                            icon='lock'
                            iconPosition='left'
                            label='Password'
                            type='password'
                            style={{ width: 300 }}
                          />
                        )}

                        {remember === 'true' && (
                          <Form.Input
                            onChange={this.onHandlePassword}
                            icon='lock'
                            iconPosition='left'
                            label='Password'
                            type='password'
                            value={password}
                            style={{ width: 300 }}
                          />
                        )}
                        <Link to='/forgetpassword'>
                          <Form.Field>
                            <h style={{ color: '#384D5A' }}>Forget Password</h>
                          </Form.Field>
                        </Link>
                      </Form>
                    </Grid>

                    <Grid centered>
                      <Grid.Row>
                        {this.state.somethingWrong !== false && (
                          <Message negative>
                            <Message.Header>Login Failed</Message.Header>
                            <p>{this.state.somethingWrong}</p>
                          </Message>
                        )}
                      </Grid.Row>
                      <Grid.Row>
                        <Grid stackable centered>
                          <Grid.Column width={8} textAlign='center'>
                            <Button
                              onClick={this.onHandleSubmit}
                              content='Log In'
                              color='orange'
                              style={{ paddingLeft: 50, paddingRight: 50 }}
                            />
                          </Grid.Column>

                          <Grid.Column width={8} textAlign='center'>
                            <Link to='/merchantOpenShop'>
                              <Button
                                basic
                                content='Create Account'
                                color='orange'
                              />
                            </Link>
                          </Grid.Column>
                        </Grid>
                      </Grid.Row>
                    </Grid>
                  </Segment>
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row
            style={{
              marginBottom: 0,
              paddingTop: 0,
              paddingBottom: 0,
              backgroundImage:
                'url(https://res.cloudinary.com/sheyou/image/upload/v1654170472/beautiful_women_posing_in_underwear_2022_02_08_02_06_16_utc_d9a0755449.jpg)',
              backgroundSize: 'cover'
            }}
          >
            <Segment style={{ backgroundColor: 'rgba(0,0,0,0.3)' }}>
              <Grid
                style={{
                  paddingTop: 80,
                  paddingBottom: 200,
                  marginTop: 0,
                  marginBottom: 0
                }}
              >
                <Grid.Row columns={3}>
                  <Grid.Column width={1}></Grid.Column>
                  <Grid.Column width={14}>
                    <Header
                      style={{
                        color: 'white',
                        fontSize: 100
                      }}
                    >
                      "
                    </Header>
                    <Header
                      style={{
                        position: 'relative',
                        color: 'white',
                        fontSize: 50
                      }}
                    >
                      {userLang === 'zh-CN' && '没有衣服穿'}
                      {userLang !== 'zh-CN' && 'I Have Nothing To Wear'}
                    </Header>
                  </Grid.Column>
                  <Grid.Column width={1}></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={1}></Grid.Column>
                  <Grid.Column width={8}>
                    <Header as='h3' style={{ color: 'white' }}>
                      {userLang === 'zh-CN' &&
                        '研究表明，80%的女性平均仅将衣柜里的衣服穿五次'}
                      {userLang !== 'zh-CN' &&
                        'Research shows 80% of women wear the clothing in their closets an average of 5 times.'}
                    </Header>{' '}
                  </Grid.Column>
                  <Grid.Column width={2}></Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={1}></Grid.Column>
                  <Grid.Column width={8}>
                    <Header as='h3' style={{ color: 'white' }}>
                      {userLang === 'zh-CN' &&
                        '租���可以减少购买服装的高额支出。既能满足想穿新衣服的愿望，又能减轻整理衣橱和洗衣服造成的负担。'}
                      {userLang !== 'zh-CN' &&
                        'Renting can reduce high expenses on garments. Satisfy both the want to wear something new and release the stress of closet organizing and laundry.'}
                    </Header>
                  </Grid.Column>
                  <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Grid.Row>
          <Grid.Row
            style={{
              paddingTop: 0,
              paddingBottom: 0,
              marginTop: 0,
              backgroundImage:
                'url(https://res.cloudinary.com/sheyou/image/upload/v1654417910/three_warehouse_workers_doing_garment_stock_take_i_2022_03_07_23_58_38_utc_a576439648.jpg)',
              backgroundSize: 'cover'
            }}
          >
            <Segment style={{ backgroundColor: 'rgba(0,0,0,0.3)' }}>
              <Grid centered style={{ paddingTop: 100, paddingBottom: 100 }}>
                <Header
                  style={{
                    color: 'white',
                    fontSize: 50,
                    paddingBottom: 100
                  }}
                >
                  {userLang === 'zh-CN' && '商户怎样使用平台'}
                      {userLang !== 'zh-CN' && 'How it Works For Merchants'}
                    </Header>
                <Grid.Column width={8}>
                  <div>
                      <>
                           <Header
                            as='h3'
                            style={{
                              color: 'white'
                            }}
                          >
                            {userLang === 'zh-CN' 
                              ? '我们为个人、品牌、设计师和商家提供一个平台，用于出租、销售或交换他们的产品。'
                              : 'We provide individuals, brands, designers and merchants with a platform to rent out, sell, or trade their products.'}
                          </Header>

                          <Header
                            as='h3'
                            style={{
                              color: 'white'
                            }}
                          >
                            {userLang === 'zh-CN'
                              ? '一切都是高度可定制的。您可以选择您的设计价格以及想要出租的时长。'
                              : 'Everything is highly customizable. You can choose how much your design is and how long you want to rent it out.'}
                          </Header>
                          <Header 
                            as='h3'
                            style={{
                              color: 'white'
                            }}
                          >
                            {userLang === 'zh-CN'
                              ? '您可以对客户进行评分，并决定他们是否需要支付押金。'
                              : 'You can rate your customers and decide if they need to pay deposit.'}
                          </Header>
                      </>
                    

                  
                  </div>
                </Grid.Column>
              </Grid>
            </Segment>
          </Grid.Row>

          <MerchantSec
            self={self}
            pic='https://res.cloudinary.com/sheyou/image/upload/v1654418469/snapshot_of_colorful_summer_stylish_outfits_and_pa_2021_09_02_23_57_46_utc_fa385d6696.jpg'
          />
        </Grid>

        <Confirm
          open={this.state.open}
          cancelButton='No'
          confirmButton='Yes'
          header='You do not have a merchant account yet'
          content='Do you want to open one?'
          size='small'
          onCancel={this.handleCancel}
          onConfirm={this.onRigisterMerchant}
        />

        <Foot />
      </div>
    )
  }
}

export default withRouter(MerchantLog)
