import React from 'react'
import axios from 'axios'
import {
  Grid,
  Menu,
  Header,
  Button,
  Segment,
  Icon,
  Sidebar
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import LoadingPhone from '../RedirectPages/LoadingPhone'
import PastOrders from '../pastOrders/PastOrdersListPhone'
import PastPastOrders from '../pastOrders/PastPastOrderListPhone'
import OngoingPastOrders from '../pastOrders/OngoingtPastOrderListPhone'
import LogInPagePhonePop from './LogInPagePhonePop'
// import ToPrevious from '../RedirectPages/ToPrevious'
// import NavMobilePhone from '../nav/NavMobilePhone'
const userLang = navigator.language || navigator.userLanguage

class MyOrdersPhone extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      products: [],
      order: [],
      someData: [],
      activeItem: 'All',
      activeItemZh: '全部',
      clickValue: '',
      login: false,
      buyOrRent: '',
      justSomeProductInfo: [],
      orders: [],
      loading: true,
      signinVisible: false,
      itemsVisible: false
    }
    this.getProducts = this.getProducts.bind(this)
    this.signinVisible = this.signinVisible.bind(this)
  }

  signinVisible () {
    const signinVisible = this.state.signinVisible
    console.log(signinVisible)

    if (signinVisible !== true) {
      this.setState({
        signinVisible: true
      })
    } else if (signinVisible === true) {
      this.setState({
        signinVisible: false
      })
    }
  }

  componentDidMount () {
    window.scrollTo(0, 0)
    const self = this

    this.getProducts()
    this.ifLog()

    console.log(this.props)
    if (this.props.activeItem === 'Membership') {
      self.setState({
        activeItem: 'Membership'
      })
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        self.setState({
          loading: false
        })
      }
    }, 1000)
  }

  getProducts () {
    const self = this
    // const token=this.state.token
    const identifier = localStorage.getItem('identifier')
    const token = localStorage.getItem('token')

    axios('https://sheyou-backend.herokuapp.com/orders?users_permissions_user.username='+identifier, {
      method: 'get',
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).then(function (res) {
      if (res.status === 200) {
        const d = res.data
        const len = d.length
        const orders = []
        for (var i = len - 1; i >= 0; i--) {
          orders.push(d[i])
        }
        self.setState({
          orders: orders
        })

        window.setTimeout(() => {
          self.setState({
            loading: false
          })
        }, 1000)
      } else {
        alert('something went wrong :(')
        localStorage.setItem('login', 'false')
      }
    })
  }

  ifLog () {
    const log = localStorage.getItem('login')
    const self = this
    if (log === 'false') {
      self.setState({ login: false })
    } else {
      self.setState({ login: true })
    }
  }
  toPrevios(){
    window.history.go(-1)
  }

  handleItemClick = (e, { name }) => this.setState({ activeItem: name })
  handleItemClickZh (e, { name }) {
    console.log(name)
    this.setState({ activeItemZh: name })
  }
  render () {
    const s = this.state
    const self = this
    console.log(s)
    const orders = s.orders
    const loading = s.loading
    const activeItem = this.state.activeItem
    const login = s.login
    const activeItemZh = s.activeItemZh

    // var ordersWithRealProductInfo = localStorage.getItem('ordersWithRealProductInfo')
    // ordersWithRealProductInfo = JSON.parse(ordersWithRealProductInfo)

    return (
      <div>

                   
        {loading === true && <LoadingPhone />}

        {loading === false && login === true && (
          <Grid columns={2} style={{ marginBottom: 100,paddingTop:20 }}>
            {userLang === 'zh-CN' && (
              <>
                <Grid.Row
                  centered
                  style={{ borderBottom: '1.5px solid rgba(0,0,0,0.1)' }}
                >
                  <Menu secondary>
                    
                    <Menu.Item header color='teal'>
                      我的订单
                    </Menu.Item>
                    <Menu.Item
                      name='全部'
                      active={activeItemZh === '全部'}
                      onClick={this.handleItemClickZh}
                    />
                    <Menu.Item
                      name='进行中'
                      active={activeItemZh === '进行中'}
                      onClick={this.handleItemClickZh}
                    />
                    <Menu.Item
                      name='已完成'
                      active={activeItemZh === '已完成'}
                      onClick={this.handleItemClickZh}
                    />
                  </Menu>
                </Grid.Row>

                {orders.length === 0 &&
                  activeItemZh !== '简介' &&
                  activeItemZh !== '折扣' &&
                  activeItemZh !== '设置' &&
                  activeItemZh !== '会员计划' && (
                    <>
                      <Grid.Row centered>
                        <Header centered color='orange' as='h1'>
                          {' '}
                          您没有任何订单
                        </Header>
                        <Header centered color='orange' as='h1'>
                          {' '}
                          可以先浏览我们的衣橱
                        </Header>
                      </Grid.Row>
                      <Grid.Row centered>
                        <Link to='/clothing'>
                          <Button color='teal'>前往</Button>
                        </Link>
                      </Grid.Row>
                    </>
                  )}

                {orders.length !== 0 && activeItemZh === '全部' && (
                  <Grid.Row>
                    <Grid divided='vertically'>
                      <PastOrders orders={orders} activeItem={'All'} />
                    </Grid>
                  </Grid.Row>
                )}
                {orders.length !== 0 && activeItemZh === '已完成' && (
                  <Grid.Row>
                    <Grid divided='vertically'>
                      <PastPastOrders orders={orders} activeItem={'Past'} />
                    </Grid>
                  </Grid.Row>
                )}
                {orders.length !== 0 && activeItemZh === '进行中' && (
                  <Grid.Row>
                    <Grid divided='vertically'>
                      <OngoingPastOrders
                        orders={orders}
                        activeItem={'Ongoing'}
                      />
                    </Grid>
                  </Grid.Row>
                )}
              </>
            )}

            {userLang !== 'zh-CN' && (
              <>
                <Grid.Row
                  centered
                  style={{ borderBottom: '1.5px solid rgba(0,0,0,0.1)' }}
                >
               
                <Grid.Column width={1}>
                <Icon name='chevron left'  onClick={self.toPrevios} style={{paddingTop:11}}/>
                </Grid.Column>
                <Grid.Column width={14}>
                <Menu secondary>
                    <Menu.Item header color='teal'>
                      My orders
                    </Menu.Item>
                    <Menu.Item
                      name='All'
                      active={activeItem === 'All'}
                      onClick={this.handleItemClick}
                    />
                    <Menu.Item
                      name='Ongoing'
                      active={activeItem === 'Ongoing'}
                      onClick={this.handleItemClick}
                    />
                    <Menu.Item
                      name='Past'
                      active={activeItem === 'Past'}
                      onClick={this.handleItemClick}
                    />
                  </Menu>
                </Grid.Column>
            
                
                </Grid.Row>

                {orders.length === 0 &&
                  activeItem !== 'Profile' &&
                  activeItem !== 'Get Discount' &&
                  activeItem !== 'Setting' &&
                  activeItem !== 'Membership' && (
                    <>
                      <Grid.Row centered>
                        <Header centered color='orange' as='h1'>
                          {' '}
                          You haven no order yet
                        </Header>
                        <Header centered color='orange' as='h1'>
                          {' '}
                          Check out the clothing
                        </Header>
                      </Grid.Row>
                      <Grid.Row centered>
                        <Link to='/clothing'>
                          <Button color='teal'>Let's go!</Button>
                        </Link>
                      </Grid.Row>
                    </>
                  )}

                {orders.length !== 0 && activeItem === 'All' && (
                  <Grid.Row>
                    <br />
                    <Grid divided='vertically'>
                      <PastOrders orders={orders} activeItem={activeItem} />
                    </Grid>
                  </Grid.Row>
                )}
                {orders.length !== 0 && activeItem === 'Past' && (
                  <Grid.Row>
                    <br />
                    <Grid divided='vertically'>
                      <PastPastOrders orders={orders} activeItem={activeItem} />
                    </Grid>
                  </Grid.Row>
                )}
                {orders.length !== 0 && activeItem === 'Ongoing' && (
                  <Grid.Row>
                    <br />
                    <Grid divided='vertically'>
                      <OngoingPastOrders
                        orders={orders}
                        activeItem={activeItem}
                      />
                    </Grid>
                  </Grid.Row>
                )}
              </>
            )}
          </Grid>
        )}

        <Sidebar
          as={Menu}
          animation='overlay'
          // onHide={this.signinVisible}
          vertical
          visible={this.state.signinVisible === true}
          direction='bottom'
        >
          <Segment style={{ width: '100%', paddingBottom: 7, paddingTop: 7 }}>
            <Grid>
              <Grid.Row columns={4}>
                <Grid.Column width={1}>
                  <Icon
                    name='close'
                    size='small'
                    onClick={self.signinVisible}
                  />
                </Grid.Column>
                <Grid.Column width={5}></Grid.Column>
                <Grid.Column width={4}>
                  <Header
                    as='h4'
                    color='teal'
                    textAlign='centered'
                    style={{
                      paddingBottom: 0,
                      marginBottom: 0,
                      fontWeight: 500
                    }}
                  >
                    Sign In
                  </Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>

            {/* <Header as='h6'  textAlign='centered' style={{paddingTop:0,marginTop:0,color:'#384D5A'}}>
                             Next Generation Fashion Platform 
                         </Header> */}
          </Segment>
          <LogInPagePhonePop />
        </Sidebar>

      </div>
    )
  }
}

export default MyOrdersPhone
