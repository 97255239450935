import React from 'react'
import axios from 'axios'
import {  Grid, Icon, Divider, Image,
    Segment, Container, Button,Header } from 'semantic-ui-react'

import { Parallax,} from 'react-parallax';
import {withRouter} from 'react-router-dom'
import {
  Link
} from "react-router-dom"; 
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "grey" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "grey" }}
      onClick={onClick}
    />
  );
}



class Payment extends React.Component{
    constructor(props){
        super(props)
        this.state={
           cartItem:[],
           Item:'',
           products:[],
           rentProducts:'',
           rentCartInfo:'',
           identifier:'',
            password:'',
            rentEmpty:true,
            
            
        }
        
        this.onHandleSubmit=this.onHandleSubmit.bind(this)
        this.getProductInBuyCart=this.getProductInRentCart.bind(this)
    }
      
      componentDidMount(){
          const identifier=localStorage.getItem('identifier')
          const password=localStorage.getItem('password')
          const self=this
          self.setState({
              identifier:identifier,
              password:password
          })
          
          this.getProductInRentCart()

      }



    getProductInRentCart(){
      const self =this
      const cartIt=localStorage.getItem('rentItem')

      const parsedCart = JSON.parse(cartIt)

      if(cartIt===''||cartIt===null){
          self.setState({rentEmpty:true})
      }else{self.setState({rentEmpty:false})

        parsedCart.forEach( function(cart){axios.get('https://sheyou-backend.herokuapp.com/products?id='+cart.id)
          .then(function(res){

              const productSelected=res.data[0]
              
              if(self.state.rentProducts===''){
                  
                      var product=productSelected
                      
                      // parsedCart.filter(function(cartP){
                          // if(cartP.id===cart.id){
                              self.setState({rentCartInfo:cart})
                      // }})
                      

                      
                          product.stock=self.state.rentCartInfo.number
                          product.colors[0]=self.state.rentCartInfo.colors
                          product.sizes[0]=self.state.rentCartInfo.sizes
                          
                      
                      const stringProduct=JSON.stringify(product)
                      self.setState({rentProducts:stringProduct})
              }
              else if(self.state.rentProducts!=='')
              {
                  product=productSelected
                      
                      // parsedCart.filter(function(cartP){
                          // if(cartP.id===cart.id){
                              self.setState({rentCartInfo:cart})
                      // }})
                      product.stock=self.state.rentCartInfo.number
                      product.colors[0]=self.state.rentCartInfo.colors
                      product.sizes[0]=self.state.rentCartInfo.sizes
                      
                      const stringProduct=JSON.stringify(product)
                      product=self.state.rentProducts

                      product=product+','+stringProduct
                      self.setState({rentProducts:product})
              }
          })
      })

} 
  }

      onHandleSubmit(event){
        const self = this

          
        var order=localStorage.getItem('order')
        order=JSON.parse(order)
        console.log(order)

        const token=localStorage.getItem('token')
        
        axios('https://sheyou-backend.herokuapp.com/orders', {
            method: 'post',
            data:{
                orders:order
            },
            headers: {
                'Authorization': 'Bearer '+token,
                }
        })
        // .catch(error => console.log(6637353,error))
        // .then(data => console.log(6637443353,data))
        alert("You have submited your order successfully, Now you can check your order in your page")
        self.props.history.push("/mypage")
      }

    render(){
      const self=this
      var order=localStorage.getItem('order')
      order=JSON.parse(order)
      const address=order.info.detailAddress
      const area=order.info.city+' ,'+order.info.country

      const storeProducts=self.state.rentProducts
      localStorage.setItem('rentProducts',storeProducts)
      var products=localStorage.getItem('rentProducts')
 
      products=JSON.parse('['+products+']')



        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />

           
          };

      return<div> 
          {/* <Nav/> */}

          <Parallax
            bgImage='https://i.loli.net/2020/11/25/ZHjYm1wL9runqOz.png'
            bgImageAlt=''
            strength={120}>

                <br/>
                <br/>
                <br/>
                <br/>
                

                <Container>
                  <Segment >
                      <Grid container>
                      <Grid.Row columns={5} style={{marginTop:20}}>
                    <Grid.Column width={4}> 
                    </Grid.Column>
                    <Grid.Column width={2}>
                        <Icon size='big'name='dot circle outline' color='grey'/>
                        <Header as='h6' textAlign='left' style={{padding:0,marginTop:7}}>Adress</Header>
                    </Grid.Column>

                    <Grid.Column width={4}> 
                    <Divider />
                    </Grid.Column>

                    <Grid.Column width={2}>
                    <Icon size='big'name='dot circle' color='orange'/>
                        <Header as='h6' textAlign='left' style={{padding:0,marginTop:7}}>Confirm</Header>
                    </Grid.Column>
                </Grid.Row>

                        <Grid.Row centered>
                        <Grid.Column width={6}>
                        <Slider {...settings}>
                        
                          
                        
                          { products.length!==0&&products.map(function(product){
                            return<div>
                                    <Grid columns={1}>
                                      <Grid.Row>
                                        <Grid.Column>
                                              <Image src={'https://sheyou-backend.herokuapp.com/'+product.picture[0].formats.small.url}/>  
                                                <Header textAlign='center' as="h5" color='grey'>{product.name}</Header>  
                                                <Header textAlign='center' as="h5" color='orange'>{product.price}</Header> 
                                        </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                    </div>
                                })
                            }   
                        </Slider>

                        </Grid.Column>
                        </Grid.Row>
                        
                        <Grid.Row>
                        </Grid.Row>
                        <Grid.Row>
                        </Grid.Row>
                            

                            
                        <Grid.Row columns={3} style={{marginBottom:0,marginTop:2,paddingBottom:0}}>
                            <Grid.Column width={2}>
                            </Grid.Column>

                            <Grid.Column width={11}>
                              <Header  as='h5' textAlign='left' style={{marginTop:1}}>Address</Header>
                            </Grid.Column>

                            <Grid.Column width={2}>
                              <Icon name='check circle' size='large' color='orange'/>
                            </Grid.Column>
                        </Grid.Row>
                            
                        <Grid.Row columns={3}>
                            <Grid.Column width={2}>
                            </Grid.Column>

                            <Grid.Column width={10}>
                              <Header as='h5' textAlign='left' color='grey'>{address}</Header>
                            </Grid.Column>

                            <Grid.Column width={2}>
                              <Header as='h5' textAlign='right' color='grey'> {area}</Header>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column width={2}>
                            </Grid.Column>

                            <Grid.Column width={11}>
                              <Link to="/checkout">
                                <Header  as='h5' textAlign='left' color='orange' >Change</Header>
                              </Link>
                            </Grid.Column>

                        </Grid.Row>

                        <Grid.Row columns={3} style={{marginBottom:0,marginTop:2,paddingBottom:0}}>
                            <Grid.Column width={2}>
                            </Grid.Column>

                            <Grid.Column width={11}>
                              <Header  as='h5' textAlign='left' style={{marginTop:1}}>Time of Returning</Header>
                            </Grid.Column>

                            <Grid.Column width={2}>
                              <Icon name='check circle' size='large' color='orange'/>
                            </Grid.Column>
                        </Grid.Row>
                            
                        <Grid.Row columns={3}>
                            <Grid.Column width={2}>
                            </Grid.Column>

                            <Grid.Column width={10}>
                                30 days
                            </Grid.Column>

                            <Grid.Column width={2}>
                              <Header as='h5' textAlign='right' color='grey'> After receiving</Header>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column width={2}>
                            </Grid.Column>

                            <Grid.Column width={11}>
                              <Link to="/payment">
                                <Header  as='h5' textAlign='left' color='orange' >Change</Header>
                              </Link>
                            </Grid.Column>

                        </Grid.Row>

                        <Grid.Row centered>
                          
                        <Button color='orange' style={{paddingLeft:40,paddingRight:40}} onClick={this.onHandleSubmit}>Submit</Button>
                        
                        </Grid.Row>
                    </Grid>
                  </Segment>
                 </Container>

            <div style={{ height:"200px"} }/>
            </Parallax>
        
        </div>
       }

} 

export default withRouter(Payment)