import React, { useEffect, useRef, useState } from 'react'
import './IntroSecVertical.css'
import { Grid, Header } from 'semantic-ui-react'
import IntroPageButton from './IntroSecButton'

import { Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
const userLang = navigator.language || navigator.userLanguage

const SharingMap = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <div>
      <div className='half-height-section' style={{ backgroundColor: '#F5F5DD', padding: 30 }}>
        <Grid centered>
          <Grid.Row>
            <Grid.Column width={12}>
              <Grid centered>
                <Grid.Row>
                  <Grid.Column>
                    <Header
                      
                      style={{
                        fontSize: 40,
                        textAlign: 'center',
                        textShadow: '2px 2px #e4bf8f'
                      }}
                    >
                      {userLang === 'zh-CN'
                        ? '跳过二手店'
                        : 'Skip Thrift Stores'}
                    </Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Header
                      as='h2'
                      
                      style={{
                        textAlign: 'center',
                        textShadow: '2px 2px #e4bf8f'
                      }}
                    >
                      {userLang === 'zh-CN'
                        ? '直接从时尚爱好者本地提货或送到你家'
                        : 'Pickup clothes in person, or get goods shipped to you'}
                    </Header>
                  </Grid.Column>
                </Grid.Row>
                {/* <Grid.Row>
                  <Link to='/clothing'>
                    <Button
                      color='orange'
                      circular
                      size='big'
                      style={{
                        paddingTop: 20,
                        paddingBottom: 20,
                        paddingLeft: 50,
                        paddingRight: 50,
                        backgroundColor: '#e4bf8f',
                        color: '#493d2b'
                      }}
                    >
                      {userLang === 'zh-CN' ? '如何运作' : 'How it Works'}
                    </Button>
                    </Link> 
                </Grid.Row>*/}
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      <div
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/sheyou/image/upload/v1727638024/Locall_fa347948aa.svg')",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }}
      >
          <div className='half-height-section'></div>
      </div>
    </div>
  )
}

export default SharingMap
