import React from 'react'
import axios from 'axios'
import {
  Grid,
  Image,
  Header,
  Popup,
  Button,
  Segment,
  Icon,
  Placeholder
} from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'

class PastOrderItem extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      productInfo: {},
      loading: true,
      token: '',
      isCommented: '',
      delivered: false,
      open: false,
      keep: false
    }
    this.onHandleClick = this.onHandleClick.bind(this)
    // this.onHandleClickDelivery = this.onHandleClickDelivery.bind(this)
    this.keep = this.keep.bind(this)
    // this.onHandleDelivered = this.onHandleDelivered.bind(this)
    this.getSaleProductData = this.getSaleProductData.bind(this)
    this.getAccessoryData = this.getAccessoryData.bind(this)
  }

  open = () => this.setState({ open: true })
  close = () => this.setState({ open: false })

  componentDidMount () {
    const self = this
    const token = localStorage.getItem('token')
    const p = this.props
    const delivered = p.delivered
    const is = p.is

    if (is === 'clothing') {
      this.getProductData()
    } else if (is === 'saleClothing') {
      this.getSaleProductData()
    } else {
      this.getAccessoryData()
    }
    self.setState({
      token: token,
      delivered: delivered
    })
    window.setTimeout(() => {
      if (self.state.loading === true) {
        self.setState({
          loading: false
        })
      }
    }, 500)
  }

  getProductData () {
    const self = this
    const p = this.props
    const productId = p.id
    const orderid = p.orderid

    axios('https://sheyou-backend.herokuapp.com/products?id=' + productId, {
      method: 'get'
    })
      .then(function (res) {
        const theData = res.data[0]
        self.setState({ productInfo: theData })
      })
      .catch(function (err) {
        console.log(err)
      })
    axios('https://sheyou-backend.herokuapp.com/orders/' + orderid, {
      method: 'get'
    })
      .then(function (res) {
        res.data.orders.data.clothing.forEach(function (each) {
          if (each.id === productId) {
            if (each.keep === true) {
              self.setState({
                keep: true
              })
            }
            if (each.commented === true) {
              self.setState({
                isCommented: true
              })
            }
          }
        })
      })
      .catch(function (err) {
        console.log(err)
      })
  }
  getSaleProductData () {
    const self = this
    const p = this.props
    const productId = p.id
    const orderid = p.orderid

    axios(
      'https://sheyou-backend.herokuapp.com/second-hand-products?id=' +
        productId,
      {
        method: 'get'
      }
    )
      .then(function (res) {
        const theData = res.data[0]
        self.setState({ productInfo: theData })
      })
      .catch(function (err) {
        console.log(err)
      })
    axios('https://sheyou-backend.herokuapp.com/orders/' + orderid, {
      method: 'get'
    })
      .then(function (res) {
        res.data.orders.data.clothing.forEach(function (each) {
          if (
            each.type === 'sale' &&
            each.id === productId &&
            each.commented === true
          ) {
            self.setState({
              isCommented: true
            })
          }
          if (
            each.type === 'sale' &&
            each.id === productId &&
            each.keep === true
          ) {
            self.setState({
              keep: true
            })
          }
        })
      })
      .catch(function (err) {
        console.log(err)
      })
  }
  getAccessoryData () {
    const self = this
    const p = this.props
    const productId = p.id
    const orderid = p.orderid

    axios('https://sheyou-backend.herokuapp.com/accessaries?id=' + productId, {
      method: 'get'
    })
      .then(function (res) {
        const theData = res.data[0]
        self.setState({ productInfo: theData })
      })
      .catch(function (err) {
        console.log(err)
      })
    axios('https://sheyou-backend.herokuapp.com/orders/' + orderid, {
      method: 'get'
    })
      .then(function (res) {
        res.data.orders.data.accessory.forEach(function (each) {
          if (each.id === productId && each.commented === true) {
            self.setState({
              isCommented: true
            })
          }
          if (each.id === productId && each.keep === true) {
            self.setState({
              keep: true
            })
          }
        })
      })
      .catch(function (err) {
        console.log(err)
      })
  }

  onHandleClick (event, data) {
    const self = this
    const productId = data.id
    const orderid = data.orderid
    const is = this.props.is
    console.log(data)
    event.preventDefault()
    localStorage.setItem('productToCommentId', productId)
    localStorage.setItem('orderToCommentId', orderid)
    localStorage.setItem('is', is)
    self.props.history.push('/comment')
  }

  // onHandleClickDelivery(event, data) {
  //     const self = this
  //     const productId = data.id
  //     event.preventDefault()
  //     const orderid = data.orderid
  //     localStorage.setItem('productToDeliveryId', productId)
  //     localStorage.setItem('orderToDeliveryId', orderid)
  //     self.props.history.push('/delivery')
  // }

  keep (event, data) {
    const self = this
    const productId = data.id
    const orderid = data.orderid
    const is = this.props.is
    console.log(data)
    event.preventDefault()
    localStorage.setItem('productToKeepId', productId)
    localStorage.setItem('orderToKeepId', orderid)
    localStorage.setItem('is', is)
    self.props.history.push('/keeppurchase')
  }

  // onHandleDelivered(data, event) {
  //     const self = this
  //     const orderid = event.orderid
  //     const token = this.state.token
  //     this.setState({
  //         loading: true
  //     })
  //     console.log(data, event)
  //     axios('https://sheyou-backend.herokuapp.com/orders/' + orderid, {
  //         method: 'put',
  //         data: {
  //             delivered: true
  //         },
  //         headers: {
  //             'Authorization': 'Bearer ' + token,
  //         }
  //     }).catch(error => console.log(error))
  //         .then(function (res) {
  //             console.log(res)
  //             if (res.status !== 200) {
  //                 alert('something went wrong')
  //             } else {
  //                 self.setState({
  //                     loading: false,
  //                     delivered: true,
  //                     open:false
  //                 })
  //             }
  //         })
  // }

  render () {
    const self = this
    const productInfo = this.state.productInfo
    const pics = productInfo.picture
    const p = this.props
    const orderid = p.orderid
    const returned = p.returned
    const isMerchantOrder = p.isMerchantOrder
    const loading = self.state.loading

    return (
      <>
        {loading ? (
          <Segment>
            <Grid>
              <Grid.Row columns={3}>
                <Grid.Column width={1}></Grid.Column>
                <Grid.Column width={8}>
                  <Placeholder style={{ height: 100, width: 100 }}>
                    <Placeholder.Image square />
                  </Placeholder>
                </Grid.Column>
                <Grid.Column width={6}>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column>
                        <Placeholder>
                          <Placeholder.Header>
                            <Placeholder.Line length='medium' />
                          </Placeholder.Header>
                        </Placeholder>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={8}>
                        <Placeholder>
                          <Placeholder.Paragraph>
                            <Placeholder.Line length='short' />
                          </Placeholder.Paragraph>
                        </Placeholder>
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Column width={8}>
                      <Button disabled={loading}>Button</Button>
                    </Grid.Column>
                    <Grid.Row></Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        ) : (
          <>
            <Segment>
              <Grid>
                <Grid.Row columns={3}>
                  <Grid.Column width={6}>
                    {pics !== undefined && (
                      <Image
                        size='large'
                        src={productInfo.picture[0].formats.small.url}
                      />
                    )}
                  </Grid.Column>
                  <Grid.Column width={9}>
                    <Grid>
                      <Grid.Row style={{ paddingBottom: 0, marginBottom: 0 }}>
                        <p> {productInfo.name}</p>
                      </Grid.Row>
                      <Grid.Row>
                        {/* Date: */}
                        <Grid.Column width={8}>
                          {this.state.keep !== true && (
                            <>
                              {'Keep It For : '}

                              <strike> {productInfo.price}</strike>
                              {' ' +
                                Math.round(
                                  (productInfo.price * 0.9 * 100) / 100
                                ) +
                                ' RMB'}
                            </>
                          )}
                          {this.state.keep === true && (
                            <>
                              <Header as='h5' color='orange'>
                                {' '}
                                successfully kept this product for
                                {' ' +
                                  Math.round(
                                    (productInfo.price * 0.9 * 100) / 100
                                  ) +
                                  ' RMB'}
                              </Header>
                            </>
                          )}
                        </Grid.Column>
                      </Grid.Row>

                      {!isMerchantOrder && (
                        <Grid.Row>
                          
                          {self.state.delivered === true &&
                            returned !== true &&
                            self.state.keep !== true && (
                              <>
                                <Grid.Column width={8}>
                                  <Button
                                    basic
                                    color='teal'
                                    id={productInfo.id}
                                    orderid={orderid}
                                    onClick={this.keep}
                                  >
                                    Keep It
                                  </Button>
                                </Grid.Column>
                              </>
                            )}
                          {self.state.delivered === true && (
                            <Grid.Column width={8}>
                              {self.state.isCommented !== true &&
                                self.state.keep === true && (
                                  <Button
                                    color='teal'
                                    id={productInfo.id}
                                    orderid={orderid}
                                    onClick={self.onHandleClick}
                                  >
                                    Comment
                                  </Button>
                                )}

                              {self.state.isCommented === true && (
                                <Popup
                                  pinned
                                  on='click'
                                  content='Thanks for the feedback'
                                  trigger={
                                    <Button icon labelPosition='left'>
                                      <Icon name='check' />
                                      Commented
                                    </Button>
                                  }
                                />
                              )}
                            </Grid.Column>
                          )}
                        </Grid.Row>
                      )}
                    </Grid>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </>
        )}
      </>
    )
  }
}

export default withRouter(PastOrderItem)
