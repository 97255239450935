import React from 'react'
import {
  Form,
  Grid,
  Header,
  Segment,
  Icon,
  Container,
  Message,
  Divider
} from 'semantic-ui-react'

import '../../App.css'
import emailjs from 'emailjs-com'

import { withRouter } from 'react-router-dom'
import LoadingPhone from '../RedirectPages/LoadingPhone'
import ToPrevious from '../RedirectPages/ToPrevious'
// const userLang = navigator.language || navigator.userLanguage

class ContactPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      firstName: '',
      email: '',
      message: '',
      lastName: '',
      phonenumber: '',
      somethingWrong: false,
      sentEmail: false
    }
    this.onFirstName = this.onFirstName.bind(this)
    this.onLastName = this.onLastName.bind(this)
    this.onPhonenumber = this.onPhonenumber.bind(this)
    this.onHandleEmail = this.onHandleEmail.bind(this)
    this.onHandleMessage = this.onHandleMessage.bind(this)
    this.onHandleSubmit = this.onHandleSubmit.bind(this)
  }

  componentDidMount () {
    window.scrollTo(0, 0)

    const self = this

    window.onload = event => {
      self.setState({
        loading: false
      })
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 500)
  }

  onHandleSubmit () {
    const self = this
    const firstName = this.state.firstName
    const email = this.state.email
    const message = this.state.message
    const lastName = this.state.lastName
    const phonenumber = this.state.phonenumber
    const verificationCode =
      message + ', with phone number: ' + phonenumber + ', and email: ' + email

    const name = firstName + ', ' + lastName

    emailjs
      .send(
        'service_orld5qb',
        'template_l5xa2ig',
        {
          email: 'susanna.xinshu@gmail.com',
          name: name,
          verificationCode: verificationCode,
          reply_to: 'susanna.xinshu@gmail.com'
        },
        'user_4rMSnDYBigUutDlR5RSib'
      )
      .then(
        function (response) {
          console.log('SUCCESS!', response.status, response.text)
          self.setState({
            loading: false,
            sentEmail: true
          })
        },
        function (error) {
          self.setState({
            loading: false,
            sentEmail: false
          })
          self.setState({ somethingWrong: 'FAILED...' + error.text })
        }
      )
  }

  onFirstName (event) {
    this.setState({
      firstName: event.target.value
    })
  }
  onHandleEmail (event) {
    this.setState({
      email: event.target.value
    })
  }
  onHandleMessage (event) {
    this.setState({
      message: event.target.value
    })
  }
  onLastName (event) {
    this.setState({
      lastName: event.target.value
    })
  }
  onPhonenumber (event) {
    this.setState({
      phonenumber: event.target.value
    })
  }

  render () {
    const loading = this.state.loading
    const self = this
    console.log(self.state)

    return (
      <div>
        {/* <Nav /> */}

        {loading ? (
          <LoadingPhone />
        ) : (
          <>
            <div className='bg_color_image'>
              <br />

              <Grid>

                <ToPrevious color='' />
                <Grid.Row centered style={{ fontSize: 20 }}>
                  <Header inverted>Contact Us </Header>
                </Grid.Row>
              </Grid>

              <Segment raised>
                <Grid centered>
                  <Grid.Row></Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={4}>
                      <Icon
                        name='phone'
                        color='teal'
                        size='big'
                        // style={{ position: 'relative', top: 20 }}
                      >
                        <Header
                          as='h4'
                          // style={{ position: 'relative', left: -6, top: 5 }}
                          color='teal'
                        >
                          PHONE
                        </Header>
                      </Icon>
                    </Grid.Column>
                    <Grid.Column width={6}>
                      <Grid>
                        <Grid.Row>
                          <Header as='h5' color='grey'>
                            {' (360)202-2983'}
                          </Header>
                        </Grid.Row>
                        <Grid.Row>
                          <Header as='h5' color='grey'>
                          {' +86 17308079166'}
                          </Header>
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>
                  </Grid.Row>
                  <Divider />
                  <Grid.Row>
                    <Grid.Column width={4}>
                      <Icon
                        name='mail'
                        color='teal'
                        size='big'
                        // style={{ position: 'relative', top: 20 }}
                      >
                        <Header
                          as='h4'
                          // style={{ position: 'relative', left: -3, top: 5 }}
                          color='teal'
                        >
                          EMAIL
                        </Header>
                      </Icon>
                    </Grid.Column>
                    <Grid.Column width={6}>
                      <Grid>
                        <Grid.Row>
                          <Header as='h5' color='grey'>
                            susanna.xinshu@gmail.com
                          </Header>
                        </Grid.Row>
                        <Grid.Row>
                          <Header as='h5' color='grey'>
                          1181456762@qq.com
                          </Header>
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>

                <br />
                <Divider />
                <br />

                <Container>
                  <Header textAlign='center' as='h3' color='grey'>
                    Send Message Now
                  </Header>
                  <Form>
                    <Form.Group widths='equal'>
                      <Form.Input
                        fluid
                        label='First name'
                        placeholder='First name'
                        onChange={self.onFirstName}
                      />
                      <Form.Input
                        fluid
                        label='Last name'
                        placeholder='Last name'
                        onChange={self.onLastName}
                      />
                    </Form.Group>
                    <Form.Group widths='equal'>
                      <Form.Input
                        fluid
                        label='Phone Number'
                        placeholder='Phone Number'
                        onChange={self.onPhonenumber}
                      />
                      <Form.Input
                        fluid
                        label='Email'
                        placeholder='Email'
                        onChange={self.onHandleEmail}
                      />
                    </Form.Group>
                    <Form.TextArea
                      onChange={self.onHandleMessage}
                      label='Write Your Message'
                      placeholder='Write Your Message'
                    />

                    <br />
                    <Grid>
                      <Grid.Row centered>
                        <Form.Button
                          color='orange'
                          centered
                          style={{ paddingLeft: 30, paddingRight: 30 }}
                          onClick={self.onHandleSubmit}
                        >
                          Send Your Message
                        </Form.Button>
                      </Grid.Row>
                    </Grid>
                  </Form>
                </Container>

                <br />
                {self.state.somethingWrong !== false && (
                  <Message>
                    <Message.Header>Soemthing Went Wrong</Message.Header>
                    <p>{this.state.somethingWrong}</p>
                  </Message>
                )}

                {self.state.sentEmail === true && (
                  <Grid.Row>
                    <Message
                      success
                      header='Message Sent'
                      content='Message Received. We Will Reply As Soon As Possible'
                    />
                  </Grid.Row>
                )}
                <br />
                <br />
                <br />
              </Segment>

              <br />
              <br />
              <br />
              <br />
            </div>
          </>
        )}
      </div>
    )
  }
}

export default withRouter(ContactPage)
