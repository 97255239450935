import React from 'react'
import ImageUploading from 'react-images-uploading'
import {
  Segment,
  Icon,
  Button,
  Header,
  Image,
  Message
} from 'semantic-ui-react'

// import reactImageSize from 'react-image-size';

export default function UploadProductPhoto (self) {
  const [images, setImages] = React.useState([])
  const maxNumber = 6

  
  const imageP=self.imageP

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex, self)
    setImages(imageList)

    const imageSet = imageList.map(function (i) {
      return i.file
    })

    self.onUploadPic(imageSet)
  }

  const removeNewImage=(id)=>{
    self.removePic(id)
  }

  return (
    <div className='App'>
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey='data_url'
      >
        {({
          imageList,
          onImageUpload,
          errors,
          onImageRemove,
          isDragging,
          dragProps
        }) => (
          // write your building UI
          <div className='upload__image-wrapper'>
            <Segment
              placeholder
              tertiary={isDragging ? true : false}
              {...dragProps}
              // style={{ width: 300 }}
            >
              {images.length === 0 && (
                <Header icon style={{ paddingLeft: 40, paddingRight: 40 }}>
                  <Icon name='picture outline' />
                  Click button or Drag here
                </Header>
              )}

              <Button
                color='teal'
                onClick={onImageUpload}
                style={{ marginBottom: 20 }}
              >
                Add Images
              </Button>
            
                {images.length > 0 && (
                  <Image.Group size='small'>
                    {imageList.map((image, index) => (
                      <Image
                        src={image['data_url']}
                        label={{
                          corner: 'right',
                          icon: 'delete',
                          size:'small',
                          onClick: () => onImageRemove(index)
                        }}
                        alt='product photo'
                      />
                    ))}
                  </Image.Group>
                )}

                {imageP!==undefined&&imageP!==null&&imageP.length>0 &&
                (
                  <Image.Group size='small'>
                    {imageP.map((image, index) => (
                      <Image
                        src={image.url}
                        id={image.id}
                        label={{
                          corner: 'right',
                          icon: 'delete',
                          size:'small',
                          onClick: () => removeNewImage(image.id)
                        }}
                        alt='product photo'
                      />
                    ))}
                  </Image.Group>
                )
                }
       
            </Segment>
            {errors && (
              <div>
                {errors.maxNumber && (
                  <Message negative size='large'>
                    <Message.Header>Error</Message.Header>
                    <p>Number of selected images exceed maxNumber</p>
                  </Message>
                )}
                {errors.acceptType && (
                  <Message negative size='large'>
                    <Message.Header>Error</Message.Header>
                    <p>Your selected file type is not allowed</p>
                  </Message>
                )}
                {errors.maxFileSize && (
                  <Message negative size='large'>
                    <Message.Header>Error</Message.Header>
                    <p>Selected file size exceed maxFileSize</p>
                  </Message>
                )}
                {errors.resolution && (
                  <Message negative size='large'>
                    <Message.Header>Error</Message.Header>
                    <p> Selected file is not match your desired resolution</p>
                  </Message>
                )}
              </div>
            )}
          </div>
        )}
      </ImageUploading>
    </div>
  )
}
