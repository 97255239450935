import React from 'react'
import { Parallax,} from 'react-parallax';
import { Container, Grid, Header, Menu,
  Segment,Label, Embed, Accordion, Icon,Image, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

class AboutPage extends React.Component{
  state = {
    activeItem: 'What is Clothes Shaing',
    activeIndex: 0
  }


  componentDidMount() {
    window.scrollTo(0,0)
    // console.log(window)
  }
  
  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  handleItemClick = (e, { name }) => this.setState({ activeItem: name })

    render(){
      const { activeItem } = this.state
      const { activeIndex } = this.state

      return<div> 
         {/* <Nav/> */}

          <Parallax
            bgImage='https://i.loli.net/2020/11/25/ZHjYm1wL9runqOz.png'
            bgImageAlt=''
            strength={150}>
                
            <div>
                <br/>
                <br/>

                <Container  fluid>
                    
                        <Grid columns={2}>
                            <Grid.Column width={3}>
                                <Menu pointing vertical>
                                    <Menu.Item header color='teal'>About Us</Menu.Item>
                                    
                                    <Menu.Item
                                    name='Sustainability'
                                    active={activeItem === 'Sustainability'}
                                    onClick={this.handleItemClick}
                                    />
                                    <Menu.Item
                                    name='Egalitarianism'
                                    active={activeItem === 'Egalitarianism'}
                                    onClick={this.handleItemClick}
                                    />
                                    <Menu.Item
                                    name='What is Clothes Shaing'
                                    active={activeItem === 'What is Clothes Shaing'}
                                    onClick={this.handleItemClick}
                                    />

                                </Menu>
                            </Grid.Column>

                            



                  {activeItem==='What is Clothes Shaing'&&
                 <Grid.Column width={13}>
                  <Segment raised>
                     <Grid columns={3} >
                      <Grid.Column  width={2}></Grid.Column>
                      <Grid.Column width={12}>
                       <Segment placeholder>
                        <Grid centered columns={2} stackable>
                        <Grid.Row centered columns={4}>
                          <Grid.Column>

                             <Header textAlign='center' as='h2'>
                             <Image size='tiny' centered src="https://i.loli.net/2021/01/05/yZSo3ntjO7xJVQM.png"/>
                             <br/>

                                Browse
                                <Header.Subheader>
                                  Try different styles in SHEYOU clothes sharing
                                </Header.Subheader>
                              </Header>                         
                            
                           </Grid.Column>
                          <Grid.Column>

                           <Header textAlign='center' as='h2'>
                           <Image size='tiny' centered src="https://i.loli.net/2021/01/05/skL6OlSpwftRnXy.png"/>
                           <br/>
                              Rent
                              <Header.Subheader>
                                Wear all the clothes witout worrying about cleaning and delivery
                              </Header.Subheader>
                            </Header>                       
                          </Grid.Column>

                          <Grid.Column>

                            <Header textAlign='center' as='h2'>
                            <Image size='tiny' centered src="https://i.loli.net/2021/01/05/tLQOMzDcmXU42yq.png"/>
                            <br/>
                                Return
                                <Header.Subheader>
                                  Pick a time and place for delivery man to come after wearing for a month
                                </Header.Subheader>
                              </Header>   
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row centered columns={4}>
                          <Grid.Column>

                           <Header textAlign='center' as='h2'>
                           <Image size='tiny' centered src="https://i.loli.net/2021/01/05/17wEozsJK45cpf3.png"/>
                           <br/>
                                Repeat
                                <Header.Subheader>
                                  Enjoying Repeating this process
                                </Header.Subheader>
                              </Header>   
                          </Grid.Column>
                          <Grid.Column>

                          <Image size='tiny' src=""/>
                          <Header textAlign='center' as='h2'>
                              OR
                          </Header>   
                          </Grid.Column>
                          <Grid.Column>

                            <Header textAlign='center' as='h2'>
                            <Image size='tiny' centered src="https://i.loli.net/2021/01/05/NRaH2S1ujFQk4np.png"/>
                            <br/>
                                Buy with a discount
                                <Header.Subheader>
                                  You can choose to keep the clothing
                                </Header.Subheader>
                              </Header>   
                          </Grid.Column>
                      
                        
                        </Grid.Row>
                      </Grid>
                       </Segment>
                       </Grid.Column>
                       <Grid.Column width={2}></Grid.Column>
                       </Grid>
                  </Segment>
                  </Grid.Column>
                  }

                            
                  {activeItem==='Sustainability'&&         
                   <Grid.Column width={13} >
                            
                            
                                <Segment raised>

                                  
                                    <Grid >
                                      
                                      <Grid.Row></Grid.Row>
                                      <Grid.Row centered style={{fontSize:30}} >
                                        <Header  >Why We Support   </Header>
                                      {/* </Grid.Row>

                                      <Grid.Row centered style={{fontSize:30}}> */}
                                      <Header color='orange' > Sustainable Fashion  </Header>
                                       </Grid.Row>
                                      
                                      <Grid.Row centered style={{fontSize:30}}> 
                                        <Header  >instead of Fast Fashion </Header>
                                      </Grid.Row>
                                      <Label as='a' color='orange' ribbon size='massive'> Let's watch a 3-minute-video first</Label>
                                      <Grid.Row>
                                      </Grid.Row>


                                    </Grid>
                                    <Container>
                                      <Embed
                                          icon='play circle '
                                          placeholder='https://i.loli.net/2020/12/15/DchTU5FgR9zpAeu.jpg'
                                          url='http://v.qq.com/iframe/player.html?vid=m0980830f7h'
                                        />
                                  </Container>
                                  <Grid>
                                  <Grid.Row></Grid.Row>
                                    <Grid.Row>
                                      <Label as='a' color='orange' ribbon size='massive'> Learn More</Label>
                                      </Grid.Row>
                                  </Grid>
                                  <Accordion fluid styled>
                                        <Accordion.Title
                                          active={activeIndex === 0}
                                          index={0}
                                          onClick={this.handleClick}
                                        >
                                          <Icon name='dropdown' />
                                          Water Contamination
                                        </Accordion.Title>
                                        <Accordion.Content active={activeIndex === 0}>
                                          
                                          <Grid stackable>
                                            <Grid.Row columns={4} > 
                                            <Grid.Column width={2}></Grid.Column>
                                              <Grid.Column width={7}>
                                                  <Image src='http://23927233.s21i.faiusr.com/4/ABUIABAEGAAgtPiL9wUojKmOHjCIBTiIBQ.png' size='medium'/>
                                              </Grid.Column>
                                              <Grid.Column width={1}></Grid.Column>
                                              <Grid.Column width={4}>

                                                <Grid>
                                                <Grid.Row></Grid.Row>
                                                  <Grid.Row></Grid.Row>
                                                  <Grid.Row>
                                                  <Header>
                                                      Cotton is the most common natural fiber used to


                                                      make clothing, accounting for about 33 percent of

                                                      all fibers found in textiles.
                                                  </Header>
                                                  </Grid.Row>
                                                
                                                  </Grid>
                                              </Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row columns={3} > 
                                            <Grid.Column width={2}></Grid.Column>
                                              
                                              
                                              <Grid.Column width={5}>

                                                <Grid>
                                                <Grid.Row></Grid.Row>
                                                  <Grid.Row>
                                                  <Header>
                                                  In Central Asia, for instance, the Aral Sea has

                                                      nearly disappeared because cotton farmers draw

                                                      excessively from the Amu Darya and Syr Darya rivers.
                                                  </Header>
                                                  </Grid.Row>
                                                  <Grid.Row></Grid.Row>

                                                
                                                  </Grid>
                                                  
                                              </Grid.Column>
                                              <Grid.Column width={2}></Grid.Column>
                                              <Grid.Column width={6}>
                                                  <Image src='http://23927233.s21i.faiusr.com/4/ABUIABAEGAAgtPiL9wUogKTuvAYwwAI4kAI.png' size='medium'/>
                                              </Grid.Column>
                                            </Grid.Row>

                                            <Grid>
                                              <Grid.Row columns={3}>
                                              <Grid.Column width={2}></Grid.Column>
                                              <Grid.Column width={12}>
                                                <Grid>
                                                  <Grid.Row>
                                                  <Grid.Row></Grid.Row>
                                                      <Header>
                                                      Water use and pollution also take place during clothing production. About 20 percent of industrial water pollution is due to garment manufacturing.
                                                      </Header>
                                                      </Grid.Row>
                                                      <Grid.Row></Grid.Row>

                                                      <Grid.Row>
                                                      <Header>
                                                      In China, the factory of the world, it is estimated that 70 percent of the rivers and lakes are contaminated by the 2.5 billion gallons of wastewater produced by the textile industry.
                                                      </Header>
                                                      </Grid.Row>

                                                      <Grid.Row></Grid.Row>

                                                      <Grid.Row>
                                                      <Header>
                                                            
                                                      </Header>
                                                      </Grid.Row>
                                                      </Grid>
                                                  </Grid.Column>
                                                  <Grid.Column width={2}></Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                          </Grid>
                                        </Accordion.Content>

                                        <Accordion.Title
                                          active={activeIndex === 1 ? true : false}
                                          index={1}
                                          onClick={this.handleClick}
                                        >
                                          <Icon name='dropdown' />
                                          Other Pollution
                                        </Accordion.Title>
                                        <Accordion.Content active={activeIndex === 1 ? true : false}>
                                        <Grid>
                                              <Grid.Row columns={3}>
                                              <Grid.Column width={2}></Grid.Column>
                                              <Grid.Column width={12}>
                                                <Grid>
                                                      <Grid.Row>
                                                      <Grid.Row></Grid.Row>
                                                      <Header>
                                                      The platation of cotton uses many harmful pesticides and chemical fertilizers, which 
                                                      eventually results in soil. In addition, the textile process puts toxins into nearby lands.

                                                      </Header>
                                                      </Grid.Row>
                                                      <Grid.Row></Grid.Row>

                                                      <Grid.Row>
                                                      <Header>
                                                      17% to 20% of industrial wastes come from textile dyeing. Every year the industry has 20 tons of toxic
                                                      water, which contains nitrate, copper, arsenic, lead, cadmium, mercury, nickel...

                                                      </Header>
                                                      </Grid.Row>

                                                      <Grid.Row></Grid.Row>              
                                                      <Grid.Row>
                                                      <Header>

                                                      Production of nylon produces nitrous oxide which is a greenhouse gas 300 times more potent than carbon dioxide. Both polyester and nylon also break down in washing machines leading to the build up of microplastics in our water systems.                      </Header>
                                                      </Grid.Row>

                                                      <Grid.Row></Grid.Row>

                                                      <Grid.Row>
                                                      <Header>
                                                      Electricity is needed to run machinery such as sewing machines and air pumps in textile factories. Huge amounts of heat is needed for washing, drying and dying the cloth.
                                
                                                      </Header>
                                                      </Grid.Row>

                                                      <Grid.Row></Grid.Row>
                                                      <Grid.Row>
                                                        <Header>
                                                          The further costs of transporting the garments produces additional CO2 as the vast majority of garments have travelled by ship. Ships burn bunker fuel which contains 1800 times more sulfur than US domestic vehicle fuel, making shipping a significant polluting sector.
                                                        </Header>
                                                      </Grid.Row>
                                                      </Grid>
                                                  </Grid.Column>
                                                  <Grid.Column width={2}></Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Accordion.Content>

                                        <Accordion.Title
                                          active={activeIndex === 2}
                                          index={2}
                                          onClick={this.handleClick}
                                        >
                                          <Icon name='dropdown' />
                                          Let's make a difference!
                                        </Accordion.Title>
                                        <Accordion.Content active={activeIndex === 2}>
                                        <Grid>
                                              <Grid.Row columns={3}>
                                              <Grid.Column width={2}></Grid.Column>
                                              <Grid.Column width={12}>
                                                <Grid>
                                                      <Grid.Row>
                                                      <Grid.Row></Grid.Row>
                                                      <Header>
                                                      1. Consume less new, conventional fashion. 
                                                      </Header>
                                                      </Grid.Row>
                                                      <Grid.Row> 
                                                        <Link to='/clothing'>
                                                        <Button color='teal'>
                                                        Try Our Clothes Sharing!
                                                        </Button>
                                                      </Link></Grid.Row>
                                                      
                                                      <Grid.Row></Grid.Row>

                                                      <Grid.Row>
                                                      <Header>
                                                      2. Try to buy second hand if you can.
                                                      </Header>
                                                      </Grid.Row>

                                                      <Grid.Row></Grid.Row>              
                                                      <Grid.Row>
                                                      <Header>
                                                      3. When you buy new clothes , buy it from a more sustainable label that uses organic, natural, or recycled fibers and manufactures.
                                                      </Header>
                                                      </Grid.Row>

                                                      <Grid.Row></Grid.Row>

                                                      
                                                      </Grid>
                                                  </Grid.Column>
                                                  <Grid.Column width={2}></Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Accordion.Content>
                                      </Accordion>

                                  </Segment>
                            </Grid.Column>
                            }

                    {activeItem==='Egalitarianism'&&
                     <Grid.Column width={13} >
                     <Segment raised>
                     <Grid >
                                      
                                      <Grid.Row></Grid.Row>
                                      <Grid.Row centered style={{fontSize:30}} >
                                        <Header  >A Fashion Show About   </Header>
                                     
                                      <Header color='orange' > Feminism </Header>
                                       </Grid.Row>
                                      
                                      <Grid.Row centered style={{fontSize:30}}> 
                                        <Header as='h4'>This First part of the video is from Weibo 
                                          “be a lady they said” </Header>
                                          <Header as='h4'>
                                          The second part is the recording of SHEYOU Fashion Show in July, 6, 2020
                                          </Header>
                                      </Grid.Row>
                                      <Label as='a' color='orange' ribbon size='massive'> SHEYOU Feminism Fahsion Show</Label>
                                      <Grid.Row>
                                      </Grid.Row>


                                    </Grid>

                                      <Embed
                                          icon='play circle '
                                          placeholder='https://i.loli.net/2020/11/12/1S6ZMwxBirshLjq.jpg'
                                          url='http://23927233.s21v.faiusr.com/58/ABUIABA6GAAg1NC0_AUolayKzwE.mp4'
                                        />
                                        

                     </Segment>
                     </Grid.Column>
                       
                    }      
                        </Grid>
                    
                </Container>
            </div>

            <div style={{ height:"200px"} }/>
            </Parallax>
         
       
        </div>
       }

} 

export default AboutPage