import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import './IntroSecVertical.css'
import { Grid, Header, Segment } from 'semantic-ui-react'

const userLang = navigator.language || navigator.userLanguage

const SharingExchange = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <div>
      <div
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/sheyou/image/upload/v1727638013/exchange_8ed19f8200.svg')",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <div className='intro-section'>
          <div className='full-height-section' style={{ padding: 50 }}>
            <Grid centered>
              <Grid.Row>
                <Grid.Column>
                  <Segment
                    circular
                    style={{
                      backgroundColor: 'rgba(245, 245, 221,0.4)',
                      border: 'none',
                      width: '400px',
                      height: '400px'
                    }}
                  >
                    <Grid centered>
                      <Grid.Row>
                        <Grid.Column>
                          <Header
                            style={{
                              fontSize: 40,
                              color: 'black',
                              textAlign: 'center',
                              textShadow: '2px 2px #e4bf8f'
                            }}
                          >
                            {userLang === 'zh-CN'
                              ? '欢迎来到SHEYOU'
                              : 'Welcome to SHEYOU'}
                          </Header>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Header
                            as='h2'
                            style={{
                              textAlign: 'center',
                              textShadow: '2px 2px #e4bf8f'
                            }}
                          >
                            {userLang === 'zh-CN'
                              ? '新时代时尚租赁租赁平台'
                              : 'Next Generation Fashion Renting Platform'}
                          </Header>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Link to='/signin'>
                          <Button
                            color='orange'
                            circular
                            size='big'
                            style={{
                              paddingTop: 20,
                              paddingBottom: 20,
                              paddingLeft: 50,
                              paddingRight: 50,
                              backgroundColor: '#e4bf8f',
                              color: '#493d2b'
                            }}
                          >
                            {userLang === 'zh-CN' ? '如何运作' : 'Get Started'}
                          </Button>
                        </Link>
                      </Grid.Row>
                    </Grid>
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SharingExchange
