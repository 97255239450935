import React from 'react'
import { Icon, Header } from 'semantic-ui-react';




 
class ToPrevious extends React.Component{
    constructor (props) {
        super(props)
        this.state = {
       color:'',
        }
    }
    
      componentDidMount () {
       const color=this.props.color
       const text= this.props.text
       this.setState({color:color,text:text})
      }

      toPrevios(){
        window.history.go(-1)
      }
    
    render(){
        const self=this
        const color=self.state.color
        const text=self.state.text
        var ifInvert=false
        if(color===''){
            ifInvert=true
        }
        return      <>
        {text!==null&&text!==undefined&&
        <Header as='h3' style={{marginTop:20,marginLeft:20}}  inverted={ifInvert} color={color} onClick={self.toPrevios}>
                <Icon name='chevron left' />
                <Header.Content>
                Back
                </Header.Content>
                
        </Header>
        }
        {(text===null||text===undefined)&&
                <Icon name='chevron left' style={{marginTop:20,marginLeft:20}} size="large" inverted={ifInvert} color={color} onClick={self.toPrevios}/>
        }
        </>    

}
    
} 

export default ToPrevious