import React, { useEffect, useRef } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import './occasionAnimated.css'
import { Grid, Header, Segment } from 'semantic-ui-react'
import { Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

const userLang = navigator.language || navigator.userLanguage

gsap.registerPlugin(ScrollTrigger)

// const getComplementaryColor = (rgb) => {
//   const [r, g, b] = rgb.match(/\d+/g).map(Number);
//   return `rgb(${255 - r}, ${255 - g}, ${255 - b})`;
// };

const IntroSecAnimated = () => {
  const sectionsRef = useRef([])
  const sectionsRef1 = useRef([])
  useEffect(() => {
    const sections = sectionsRef.current;
    const sections1 = sectionsRef1.current;

    // Add console logs to debug
    // console.log('Sections:', sections);
    // console.log('Sections1:', sections1);

    if (sections.length > 0 && sections1.length > 0) {
      // Delay the animation to ensure DOM is fully loaded
      setTimeout(() => {
        gsap.to(sections, {
          xPercent: -200 * (sections.length - 1),
          ease: 'none',
          scrollTrigger: {
            trigger: sections[0],
            pin: true,
            scrub: 1,
            snap: 1 / (sections.length - 1),
            end: () => `+=${sections[0]?.offsetWidth * (sections.length - 1)}`
          }
        });

        gsap.to(sections1, {
          xPercent: -200 * (sections1.length - 1),
          ease: 'none',
          scrollTrigger: {
            trigger: sections1[0],
            pin: true,
            scrub: 1,
            snap: 1 / (sections1.length - 1),
            end: () => `+=${sections1[0]?.offsetWidth * (sections1.length - 1)}`
          }
        });
      }, 100); // Adjust the delay as needed
    }
  }, [sectionsRef.current, sectionsRef1.current]);

  return (
    <div>
      <div className='occasion-container'>
        <div
          className='occasion-section'
          ref={el => (sectionsRef.current[0] = el)}
          style={{
            backgroundImage:
              "url('https://res.cloudinary.com/sheyou/image/upload/v1727638068/tennis_5d41863bed.svg')"
          }}
        >
          <div className='full-height-section' style={{ padding: 30 }}></div>
        </div>
        <div
          className='occasion-section'
          ref={el => (sectionsRef.current[1] = el)}
          style={{
            backgroundImage:
              "url('https://res.cloudinary.com/sheyou/image/upload/v1727638068/couture_f2bfe95973.svg')"
          }}
        >
          <div className='full-height-section' style={{ padding: 30 }}></div>
        </div>
      </div>

      <div className='static-container'>
        <div
          className='half-height-section'
          style={{ backgroundColor: '#00BAB0', zIndex: 1000 }}
        >
          <Grid centered>
            <Grid.Row>
              <Header
                style={{
                  fontSize: 40,
                  color: 'black',
                  textAlign: 'center',
                  textShadow: '2px 2px #e4bf8f'
                }}
              >
                {userLang === 'zh-CN'
                  ? '让时尚循环起来'
                  : 'Keep fashion circular with styles'}
              </Header>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                  <Header
                    as='h2'
                    style={{ textShadow: '2px 2px #e4bf8f' }}
                    textAlign='center'
                  >
                    {userLang === 'zh-CN'
                      ? '和设计师和时尚爱好者们一起庆祝个性风格'
                      : 'Celebrate individuality with fashion lovers and designers'}
                  </Header>
                </Grid.Column>
              </Grid.Row>
            <Grid.Row>
              <Link to='/clothing'>
                <Button
                  color='orange'
                  circular
                  size='big'
                  style={{
                    paddingTop: 20,
                    paddingBottom: 20,
                    paddingLeft: 50,
                    paddingRight: 50,
                    backgroundColor: '#e4bf8f',
                    color: '#493d2b'
                  }}
                >
                  {userLang === 'zh-CN' ? '浏览' : 'Browse'}
                </Button>
              </Link>
            </Grid.Row>
          </Grid>
        </div>
      </div>

      <div className='occasion-container'>
        <div
          className='occasion-section'
          ref={el => (sectionsRef1.current[0] = el)}
          style={{
            backgroundImage:
              "url('https://res.cloudinary.com/sheyou/image/upload/v1727638068/metalic_541fb1a11a.svg')"
          }}
        >
          <div className='full-height-section' style={{ padding: 30 }}></div>
        </div>
        <div
          className='occasion-section'
          ref={el => (sectionsRef1.current[1] = el)}
          style={{
            backgroundImage:
              "url('https://res.cloudinary.com/sheyou/image/upload/v1727638234/suit_ef6ed1de24.svg')"
          }}
        >
          <div className='full-height-section' style={{ padding: 30 }}></div>
        </div>
      </div>
    </div>
  )
}

export default IntroSecAnimated
