import React from 'react'
import {
  Grid,
  Header,
  Segment,
  Icon,
  Container,
  Button,
  Placeholder,
  Divider
} from 'semantic-ui-react'
import 'react-chat-elements/dist/main.css'
import { Input, MessageList, ChatItem } from 'react-chat-elements'
import { orderBy } from 'natural-orderby'
import Foot from '../foot/Foot'
import '../../App.css'
import { withRouter } from 'react-router-dom'
import axios from 'axios'

class ContactPage extends React.Component {
  constructor (props) {
    super(props)
    this.inputText = React.createRef()
    this.state = {
      loading: true,
      sender: '',
      senderName: '',
      receiver: '',
      messages: false,
      receiverInfo: '',
      chatList: [],
      receiverName: '',
      chatUsers: [],
      lastMessages: false,
      messageLoaded: false,
      buttonLoading: false,
      scrollTo: 'bottom',
      scrollLoading: false,
      noMoreChat: false,
      chatNum: 5
    }
    this.handleInput = this.handleInput.bind(this)
    this.getMessage = this.getMessage.bind(this)
    this.sendMessage = this.sendMessage.bind(this)
    this.getChatList = this.getChatList.bind(this)
    this.onScrollToTop = this.onScrollToTop.bind(this)
    this.getNewInfo = this.getNewInfo.bind(this)
  }

  getNewInfo (receiver, receiverName, receiverInfo) {
    const self = this

    receiverInfo.updated_at = new Date()
    self.setState({
      receiver: receiver,
      receiverName: receiverName,
      receiverInfo: receiverInfo
    })
    receiver = JSON.stringify(receiver)
    receiverName = JSON.stringify(receiverName)
    receiverInfo.updated_at = new Date()
    receiverInfo = JSON.stringify(receiverInfo)
    localStorage.setItem('receiver', receiver)
    localStorage.setItem('receiverName', receiverName)
    localStorage.setItem('receiverInfo', receiverInfo)

    self.getChatList()
    self.getMessage()
  }

  componentDidMount () {
    window.scrollTo(0, 0)

    const self = this
    var receiver = localStorage.getItem('receiver')

    self.getChatList()

    if (
      receiver !== null &&
      receiver !== undefined &&
      receiver !== '' &&
      receiver.length !== 0
    ) {
      self.getMessage()
    }

    // axios(
    //   'https://sheyou-backend.herokuapp.com/messages?messengers='+sender+'&_sort=created_at:DESC',
    //   {
    //     method: 'get',
    //     headers: {
    //       Authorization: 'Bearer ' + token
    //     }
    //   }
    // )
    //   .then(function (res) {
    //     if (res.status === 200) {
    //       console.log(res)
    //       if (res.date .length!== 0) {
    //         self.setState({
    //           messages: res.data
    //         })
    //       }
    //     }
    //   })
    //   .catch(function (err) {
    //     self.props.history.push('/loginfirst')
    //   })
  }

  getChatList () {
    const self = this
    const token = localStorage.getItem('token')
    var sender = localStorage.getItem('sender')
    if (sender === undefined || sender === null || sender === '') {
      sender = localStorage.getItem('userInfo')
      sender = JSON.parse(sender)
      sender = sender.id
    }
    var senderName = localStorage.getItem('senderName')
    var receiver = localStorage.getItem('receiver')
    var receiverInfo = localStorage.getItem('receiverInfo')
    var receiverName = receiverInfo.username
    if (
      receiverInfo !== '' &&
      receiverInfo !== undefined &&
      receiverInfo !== null
    ) {
      receiverInfo = JSON.parse(receiverInfo)
      self.setState({
        receiver: receiver,
        receiverInfo: receiverInfo,
        receiverName: receiverName
      })
    }

    self.setState({
      sender: sender,
      senderName: senderName
    })
    var chatUsers = []

    axios('https://sheyou-backend.herokuapp.com/users/' + sender, {
      method: 'get',
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(function (res) {
        self.setState({
          loading: false
        })
        console.log(res)
        if (res.status === 200) {
          if (res.date.length !== 0) {
            chatUsers = res.data.chatUsers
            chatUsers.forEach(function (e) {
              // console.log(e, receiver)
              const id = JSON.stringify(e.id)
              if (id === receiver) {
                e.updated_at = new Date()
                self.setState({
                  receiverName: e.username,
                  receiver: e.id,
                  receiverInfo: e
                })
                localStorage.setItem('receiverName', e.username)
              }
            })

            chatUsers = orderBy(chatUsers, [v => v.updated_at], ['desc'])

            const chatListLength = chatUsers.length
            var lastMessages = []
            var i = 0

            chatUsers.forEach(function (e) {
              axios(
                'https://sheyou-backend.herokuapp.com/messages?messageSender=' +
                  sender +
                  '&messageSender=' +
                  e.id +
                  '&messageReceiver=' +
                  sender +
                  '&messageReceiver=' +
                  e.id +
                  '&_sort=created_at:DESC',
                {
                  method: 'get',
                  headers: {
                    Authorization: 'Bearer ' + token
                  }
                }
              )
                .then(function (res) {
                  if (res.status === 200) {
                    // console.log(res)
                    lastMessages.push(res.data[0])
                    self.setState({ lastMessages: lastMessages })
                  }
                  i++
                  if (i === chatListLength) {
                    self.setState({
                      messageLoaded: true
                    })
                  }
                })
                .catch(function (err) {
                  i++
                  // self.props.history.push('/loginfirst')
                  console.log('err', err)
                })
            })
            self.setState({
              chatUsers: chatUsers
            })
          } else {
            if (
              receiver !== null &&
              receiver !== undefined &&
              receiver !== '' &&
              receiver.length !== 0
            ) {
              axios('https://sheyou-backend.herokuapp.com/users/' + sender, {
                method: 'put',
                headers: {
                  Authorization: 'Bearer ' + token
                }
              })
                .then(function (e) {
                  self.setState({
                    receiverName: e.data.username,
                    receiver: e.data.id,
                    receiverInfo: e.data
                  })
                  axios(
                    'https://sheyou-backend.herokuapp.com/messages?messageSender=' +
                      sender +
                      '&messageSender=' +
                      e.data.id +
                      '&messageReceiver=' +
                      sender +
                      '&messageReceiver=' +
                      e.data.id,
                    {
                      method: 'get',
                      headers: {
                        Authorization: 'Bearer ' + token
                      }
                    }
                  )
                    .then(function (res) {
                      var lastMessages = []
                      lastMessages.push(res.data[0])
                      self.setState({
                        lastMessages: lastMessages,
                        messageLoaded: true
                      })
                    })
                    .catch(function (err) {
                      console.log('err', err)
                    })
                })
                .catch(function (err) {
                  console.log('err', err)
                })
            }
          }
        }
      })
      .catch(function (err) {
        
        console.log('err', err)
      })
  }

  getMessage (add, newId) {
    const self = this
    const s = this.state
    var sender = localStorage.getItem('sender')
    var receiver = localStorage.getItem('receiver')
    const token = localStorage.getItem('token')
    self.setState({
      noMoreChat: false
    })

    var num = s.chatNum
    if (add !== undefined && newId !== undefined) {
      const id = newId
      num = 5
      var chatUsers = s.chatUsers
      chatUsers.forEach(function (e) {
        if (e.id === id) {
          e.updated_at = new Date()
        }
      })

      chatUsers = orderBy(chatUsers, [v => v.updated_at], ['desc'])
      self.setState({
        chatUsers: chatUsers,
        scrollTo: 'bottom'
      })
    }
    if (add === 10) {
      num = num + add
      self.setState({
        scrollLoading: true
      })
    } else if (add === 'new message') {
      num = num + 1
      self.setState({
        scrollTo: 'bottom',
        buttonLoading: true
      })
    }

    axios(
      'https://sheyou-backend.herokuapp.com/messages?_sort=created_at:DESC' +
        '&messageSender=' +
        sender +
        '&messageReceiver=' +
        sender +
        '&messageSender=' +
        receiver +
        '&messageReceiver=' +
        receiver +
        '&_limit=' +
        num,
      {
        method: 'get',
        headers: {
          Authorization: 'Bearer ' + token
        }
      }
    )
      .then(function (res) {
        self.setState({
          loading: false
        })
        if (res.status === 200) {
          if (res.data.length < s.chatNum) {
            self.setState({
              noMoreChat: true
            })
          }
          self.setState({
            scrollLoading: false,
            chatNum: num
          })
          var messages = res.data
          messages = orderBy(messages, [v => v.created_at], ['asc'])

          if (res.date.length !== 0) {
            self.setState({
              messages: messages,
              buttonLoading: false
            })
            if (add === 'new message') {
              self.setState({
                scrollTo: 'bottom'
              })
            } else if (add === 10) {
              self.setState({
                scrollTo: 'original'
              })
            } else if (newId !== undefined) {
              self.setState({
                scrollTo: 'bottom'
              })
            }
          }
        }
      })
      .catch(function (err) {
        self.setState({
          loading: false
        })
        console.log('err', err)
      })

    // axios(
    //   'https://sheyou-backend.herokuapp.com/messages?_where[0][messageSender]=' +
    //     sender +
    //     '&_where[1][messageReceiver]=' +
    //     receiver,
    //   // +'&_sort=created_at:AESC',
    //   {
    //     method: 'get',
    //     headers: {
    //       Authorization: 'Bearer ' + token
    //     }
    //   }
    // )
    //   .then(function (res) {
    //     if (res.status === 200) {
    //       console.log(res)
    //       if (res.date .length!== 0) {
    //         self.setState({
    //           messages: res.data
    //         })
    //       }
    //     }
    //   })
    //   .catch(function (err) {
    //     self.props.history.push('/loginfirst')
    //   })
  }

  sendMessage (event, data) {
    var sender = localStorage.getItem('sender')
    var receiver = localStorage.getItem('receiver')
    const token = localStorage.getItem('token')
    const self = this
    var text = this.state.inputText
    console.log(event, data)
    self.inputText.current.value = ''
    axios('https://sheyou-backend.herokuapp.com/messages', {
      method: 'post',
      data: {
        messageReceiver: receiver,
        messageSender: sender,
        messageText: text
      },
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(function (res) {
        self.setState({
          loading: false
        })
        if (res.status === 200) {
          self.getMessage('new message')
        }
      })
      .catch(function (err) {
        self.setState({
          loading: false
        })
        console.log('err', err)
      })
  }
  handleInput (event, data) {
    event.preventDefault()
    const self = this
    self.setState({
      inputText: self.inputText.current.value
    })
  }

  onScrollToTop (event, data) {
    event.preventDefault()

    const scrolltop = event.target.scrollTop

    if (scrolltop === 0) {
      this.getMessage(10)
    }
  }

  render () {
    const loading = this.state.loading
    const s = this.state
    const self = this
    var element = document.getElementById('box')

    if (element !== null && s.scrollTo === 'bottom') {
      element.scrollTop = element.scrollHeight
    } else if (element !== null && s.scrollTo === 'top') {
      element.scrollTop = 0
    }

    return (
      <div>
        {/* <Nav /> */}

        {loading ? (
          <Grid>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>

            <Grid.Row colums={2}>
              <Grid.Column width={7}></Grid.Column>
              <Grid.Column width={6}>
                <Header size='huge'>
                  <Icon loading name='asterisk' size='massive' color='yellow' />
                  Loading...
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
          </Grid>
        ) : (
          <>
            {/* {s.chatUsers.length === 0 && (
              <Grid>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>

                <Grid.Row colums={2}>
                  <Grid.Column width={7}></Grid.Column>
                  <Grid.Column width={6}>
                    <Header size='huge'>No Chats Available</Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
              </Grid>
            )}

            {s.chatUsers.length !== 0 && ( */}
              <div className='bg_color_image'>
                <br />
                <br />

                <Grid>
                  <Grid.Row></Grid.Row>
                  <Grid.Row centered style={{ fontSize: 30 }}>
                    <Header inverted>Messaging </Header>
                  </Grid.Row>
                </Grid>
                <br />
                <br />
                <Container>
                  <Grid>
                    <Grid.Column width={6}>
                      <Segment style={{ height: 545 }}>
                        {s.messageLoaded ? (
                          <>
                            {s.chatUsers.map(function (each) {
                              var lastMessage = false
                              var lastTime = false

                              s.lastMessages.forEach(function (e) {
                                if (
                                  e !== undefined &&
                                  (e.messageSender[0].username ===
                                    each.username ||
                                    e.messageReceiver[0].username ===
                                      each.username)
                                ) {
                                  lastMessage = e.messageText

                                  lastTime = new Date(e.created_at)
                                }
                              })
                              return (
                                <ChatItem
                                  alt=''
                                  avatar={
                                    'https://ui-avatars.com/api/?name=' +
                                    each.username +
                                    '&background=random'
                                  }
                                  title={each.username}
                                  subtitle={lastMessage}
                                  date={lastTime}
                                  unread={0}
                                  id={each.id}
                                  onClick={() =>
                                    self.getMessage('newMessage', each.id) +
                                    self.getNewInfo(each.id, each.name, each)
                                  }
                                />
                              )
                            })}
                          </>
                        ) : (
                          <>
                            {s.chatUsers.map(function (each) {
                              return (
                                <Placeholder>
                                  <Placeholder.Header image>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                  </Placeholder.Header>
                                </Placeholder>
                              )
                            })}
                          </>
                        )}
                      </Segment>
                    </Grid.Column>
                    <Grid.Column width={10}>
                      <Segment style={{ height: 545 }}>
                        {s.messages ? (
                          <ChatItem
                            avatar={
                              'https://ui-avatars.com/api/?name=' +
                              s.receiverName +
                              '&background=random'
                            }
                            alt={s.receiverName}
                            title={s.receiverName}
                            date={false}
                          />
                        ) : (
                          <></>
                        )}
                        <Container
                          id='box'
                          onScroll={self.onScrollToTop}
                          style={{
                            height: 330,
                            marginTop: 20,
                            overflowY: 'scroll'
                          }}
                        >
                          {s.noMoreChat !== true && s.scrollLoading && (
                            <Grid>
                              <Grid.Row></Grid.Row>

                              <Grid.Row centered>
                                <Header>
                                  <Icon
                                    loading
                                    name='asterisk'
                                    color='yellow'
                                  />
                                  Loading chat history
                                </Header>
                              </Grid.Row>
                              <Grid.Row></Grid.Row>
                            </Grid>
                          )}
                          {s.noMoreChat && (
                            <Grid>
                              <Grid.Row centered>
                                <p>No More Chat History</p>
                              </Grid.Row>
                              <Divider />
                              <Grid.Row></Grid.Row>
                            </Grid>
                          )}

                          {s.messages ? (
                            <>
                              {s.messages.length !== 0 ? (
                                <>
                                  <MessageList
                                    toBottomHeight='300'
                                    className='message-list'
                                    dataSource={s.messages.map(function (m) {
                                      const id = JSON.stringify(
                                        m.messageSender[0].id
                                      )
                                      return {
                                        position:
                                          id === s.sender ? 'right' : 'left',
                                        type: 'text',
                                        title: m.messageSender[0].username,
                                        text: m.messageText,
                                        date: m.created_at
                                      }
                                    })}
                                  />
                                </>
                              ) : (
                                <>
                                  <Grid>
                                    <Grid.Row centered>
                                      <Header color='grey'>
                                        No Chat History
                                      </Header>
                                    </Grid.Row>
                                  </Grid>
                                </>
                              )}
                            </>
                          ) : (
                            <Grid>
                              <Grid.Row></Grid.Row>
                              <Grid.Row></Grid.Row>

                              <Grid.Row centered>
                                <Header size='big'>
                                  Send A Message
                                  {/* <Icon
                                    loading
                                    name='asterisk'
                                    size='huge'
                                    color='yellow'
                                  />
                                  Loading... */}
                                </Header>
                              </Grid.Row>
                              <Grid.Row></Grid.Row>
                              <Grid.Row></Grid.Row>
                            </Grid>
                          )}
                        </Container>

                        <Segment>
                          <Input
                            placeholder='Type here...'
                            multiline={true}
                            type='string'
                            referance={self.inputText}
                            defaultValue={s.inputText}
                            onChange={this.handleInput}
                            rightButtons={
                              <Button
                                color='white'
                                backgroundColor='black'
                                text='Send'
                                content='Send'
                                loading={s.buttonLoading}
                                onClick={this.sendMessage}
                              />
                            }
                          />
                        </Segment>
                      </Segment>
                    </Grid.Column>
                  </Grid>
                </Container>

                <br />
                <br />
                <br />
                <br />
              </div>
            {/* )} */}
          </>
        )}
        <Foot />
      </div>
    )
  }
}

export default withRouter(ContactPage)
