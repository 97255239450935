import React from 'react'
import { Grid, Button, Header, Segment } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

const CenteredBackground = ({ 
  backgroundImg='https://res.cloudinary.com/sheyou/image/upload/v1641196507/IMG_0879_072c82fdcf.png ', 
  headerText, 
  buttonText, 
  buttonLink, 
  buttonColor = 'orange',
  buttonSize = 'huge',
  segmentWidth = 500,
  height = '100vh'
}) => {
  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: height,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'
      }}
    >
      <Segment style={{ maxWidth: segmentWidth, padding: '50px' }}>
        <Grid>
          <Grid.Row centered>
            <Header>{headerText}</Header>
          </Grid.Row>

          {buttonText  && (
            <Grid.Row centered>
              <Link to={buttonLink}>
                <Button size={buttonSize} color={buttonColor}>
                  {buttonText}
                </Button>
              </Link>
            </Grid.Row>
          )}
        </Grid>
      </Segment>
    </div>
  )
}

CenteredBackground.propTypes = {
  backgroundImg: PropTypes.string.isRequired,
  headerText: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  buttonColor: PropTypes.string,
  buttonSize: PropTypes.string,
  segmentWidth: PropTypes.number,
  minHeight: PropTypes.string
}

export default CenteredBackground 