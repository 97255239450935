import React from 'react'
import {
  Grid,
  Header,
  Segment,
  Icon,
  Menu,
  Sidebar,
} from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'

class SigninPopPhoe extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
        signinVisible:false
    }
    this.signinVisible = this.signinVisible.bind(this)
  }

  signinVisible () {
    const signinVisible = this.props.signinVisible
    console.log(signinVisible)

    if (signinVisible !== true) {
      this.setState({
        signinVisible: true
      })
    } else if (signinVisible === true) {
      this.setState({
        signinVisible: false
      })
    }
  }

  componentDidMount () {
    window.scrollTo(0, 0)
    const self = this

    
    const login = localStorage.getItem('login')
    self.setState({
      login: login
    })
   

    window.onload = event => {
      self.setState({
        loading: false
      })
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 2000)
    const identifier = localStorage.getItem('identifier')
    const token = localStorage.getItem('token')

    self.setState({
      identifier: identifier,
      token: token
    })

  }

  render () {
    const self = this
  

    return (
      <>
       
            <Sidebar
              as={Menu}
              animation='overlay'
              // onHide={this.signinVisible}
              vertical
              visible={self.state.signinVisible === true}
              direction='bottom'
            >
              <Segment
                style={{ width: '100%', paddingBottom: 7, paddingTop: 7 }}
              >
                <Grid>
                  <Grid.Row></Grid.Row>
                  <Grid.Row columns={4}>
                    <Grid.Column width={1}>
                      <Icon
                        name='close'
                        size='small'
                        onClick={self.signinVisible}
                      />
                    </Grid.Column>
                    <Grid.Column width={5}></Grid.Column>
                    <Grid.Column width={4}>
                      <Header
                        as='h4'
                        color='teal'
                        textAlign='centered'
                        style={{
                          paddingBottom: 0,
                          marginBottom: 0,
                          fontWeight: 500
                        }}
                      >
                        Sign In
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>

                {/* <Header as='h6'  textAlign='centered' style={{paddingTop:0,marginTop:0,color:'#384D5A'}}>
                             Next Generation Fashion Platform 
                         </Header> */}
              </Segment>
            </Sidebar>

      </>
    )
  }
}

export default withRouter(SigninPopPhoe)
