import React from 'react'
import { Grid,Form, Button, Container,Segment, Message } from 'semantic-ui-react'

import { Parallax,} from 'react-parallax';
import axios from 'axios'
import { withRouter } from 'react-router-dom'
// import Nav from '../nav/Nav'

const API_URL = process.env.NODE_ENV === 'production' 
  ? process.env.REACT_APP_backend_baseUrl_live 
  : process.env.REACT_APP_backend_baseUrl_localTest;

class ReserPasswordPage extends React.Component {
  constructor(props){
    super(props)
    this.state={
      newpassword:'',
      verifypassword:'',
      resetlink:'',
      code:'',
      verify:true,
      somethingWrong: false
  }
  this.onHandleNewPassword=this.onHandleNewPassword.bind(this)
  this.onHandleVerifyPassword=this.onHandleVerifyPassword.bind(this)
  this.onHandleSubmit=this.onHandleSubmit.bind(this)

}

componentDidMount(){
  window.scrollTo(0,0)
  this.setState({
    code:this.props.location.search.substring(6)
  })
}


onHandleNewPassword(event){
  
  this.setState({
    newpassword:event.target.value
  })

  console.log(this.state)
}

onHandleVerifyPassword(event){
  
  this.setState({
    verifypassword:event.target.value
  })

  console.log(this.state)
}

onHandleSubmit(event){
  event.preventDefault()
  const self=this
  const  newpassword=self.state.newpassword
  const verifypassword=self.state.verifypassword
  
  const code=self.state.code
  
  console.log(code,newpassword===verifypassword)
  if(newpassword===verifypassword){
    axios.post(`${API_URL}/auth/reset-password`, {
      code: code, // code contained in the reset link of step 3.
      password: newpassword,
      passwordConfirmation: verifypassword,
    })
    .then(response => {
      // alert("Your user's password has been reset successfully");
      self.setState({
        somethingWrong: "Your user's password has been reset successfully"
      })
      this.props.history.push("/signin")
    })
    .catch(error => {
      // alert('An error occurred:', error.response);
      self.setState({
        somethingWrong: 'An error occurred: ' + error.response
      })
    });
  }
  if(newpassword!==verifypassword){self.setState({
    verify:false
  })}
  


}



// onHandleDropdown(event,data){
//   this.setState({
//     value:data.value
//   })
// }
  
  render() {
    const self=this
    console.log(self.state);
    
    return<div>
       {/* <Nav /> */}

    
    <Parallax
    bgImage='https://i.loli.net/2020/11/25/ZHjYm1wL9runqOz.png'
    bgImageAlt=''
    strength={400}>
        
    <div>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <Container>
      <Segment>

        {this.state.somethingWrong !== false && (
          <Message negative>
            <Message.Header>Password Reset Failed</Message.Header>
            <p>{this.state.somethingWrong}</p>
          </Message>
        )}

        {/* {verify===false&&
        <Form stackable onSubmit={this.onHandleSubmit}>
        <Form.Input onChange={this.onHandleResetlink}
          label='Resetlink'
          placeholder='Resetlink'
        />
        <Form.Input onChange={this.onHandleNewPassword}
          label='New Password'
          placeholder='New Password'
          type='password'
          error={{
            content: 'the new password and the verify password must be the same',
            pointing: 'below',
          }}
        />
        <Form.Input onChange={this.onHandleVerifyPassword}
          label='Verify Password'
          placeholder='Verify Password'
          type='password'
          error={{
            content: 'the new password and the verify password must be the same',
            pointing: 'below',
          }}
        />
        </Form>
        
        } */}

          <Form stackable onSubmit={this.onHandleSubmit}>
       
          <Form.Input onChange={this.onHandleNewPassword}
            label='New Password'
            placeholder='New Password'
            type='password'
          />
          <Form.Input onChange={this.onHandleVerifyPassword}
            label='Verify Password'
            placeholder='Verify Password'
            type='password'
          />
          </Form>
          

          <br/>
          <br/>
          

          <Grid  columns={4} centered>
            
              <Grid.Column width={4}>
              <Button onClick={this.onHandleSubmit} type='submit' fluid size='large' floated='right' content='submit' color='orange' />
              </Grid.Column>

          </Grid>
    </Segment>
    </Container>
    </div>

    <div style={{ height:"300px"} }/>
</Parallax>
    

</div>
  }

}

export default withRouter(ReserPasswordPage)









