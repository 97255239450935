import React from 'react'
import { Grid, Header, Icon } from 'semantic-ui-react'

import { withRouter } from 'react-router-dom'
const userLang = navigator.language || navigator.userLanguage

class LoginFirst extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }
  componentDidMount () {
    const self = this
    window.setTimeout(() => {
      self.props.history.push('/signin')
    }, 1000)
  }

  render () {
    const p = this.props
    const mobile = p.mobile
    return (
      <div>
        {!mobile && (
          <Grid>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>

            <Grid.Row colums={2}>
              <Grid.Column width={7}></Grid.Column>
              <Grid.Column width={6}>
                <Header size='huge'>
                  <Icon
                    name='warning sign'
                    size='massive'
                    color='yellow'
                  />
                  {userLang !== 'zh-CN' &&
                    'Something went wrong, please login again'}
                  {userLang === 'zh-CN' && '出错了，请重新登录'}
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
          </Grid>
        )}

        {mobile && (
          <Grid
            centered
            columns={5}
            style={{ position: 'absolute', top: '40%' }}
          >
            <Grid.Column>
              <Icon name='warning sign' size='huge' color='yellow' />
            </Grid.Column>

            <Grid.Row centered>
              <Header size='huge'>
                {userLang !== 'zh-CN' && 'Something went wrong'}
                <br />
                {userLang !== 'zh-CN' && 'please login again'}
                {userLang === 'zh-CN' && '出错了，请重新登录'}
              </Header>
            </Grid.Row>
          </Grid>
        )}
      </div>
    )
  }
}

export default withRouter(LoginFirst)
