import React from 'react'
import { Parallax } from 'react-parallax'
import {
  Container,
  Grid,
  Header,
  Segment,
  Label,
  Embed,
  Accordion,
  Icon,
  Image,
  Button
} from 'semantic-ui-react'
import MerchantSec from '../homePage/merchantSec/MerchantSec'
// import { Link } from 'react-router-dom';
// import ReactPlayer from 'react-player'
import Foot from '../foot/Foot'
import '../../App.css'
import { withRouter } from 'react-router-dom'
const userLang = navigator.language || navigator.userLanguage

class AboutPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      activeItem: 'What is Clothes Shaing',
      activeIndex: -1,
      loading: true
    }
    this.toPlans = this.toPlans.bind(this)
    this.toShop = this.toShop.bind(this)
  }

  toPlans () {
    this.props.history.push('/plans')
  }

  toShop () {
    this.props.history.push('/clothing')
  }

  componentDidMount () {
    window.scrollTo(0, 0)

    const self = this

    window.onload = event => {
      self.setState({
        loading: false
      })
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 500)
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  handleItemClick = (e, { name }) => this.setState({ activeItem: name })

  render () {
    const { activeIndex } = this.state
    const loading = this.state.loading
    const self = this

    return (
      <div>
        {/* <Nav /> */}

        {loading ? (
          <Grid>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>

            <Grid.Row colums={2}>
              <Grid.Column width={7}></Grid.Column>
              <Grid.Column width={6}>
                <Header size='huge'>
                  <Icon loading name='asterisk' size='massive' color='yellow' />
                  Loading...
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
          </Grid>
        ) : (
          <>
            <Parallax
              bgImage={
                'https://res.cloudinary.com/sheyou/image/upload/v1654171401/cheerful_multiethnic_women_sitting_on_pier_2022_05_31_06_57_17_utc_2ec9b28908.jpg'
              }
              bgImageAlt='image'
              strength={-200}
            >
              <Grid style={{ paddingTop: 150, paddingBottom: 100 }}>
                <Grid.Row centered style={{ fontSize: 30 }}>
                  <Header inverted>
                    {userLang === 'zh-CN' && '共享衣橱是怎么运作的'}
                    {userLang !== 'zh-CN' && 'How it Works for Users'}
                  </Header>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={2}></Grid.Column>
                  <Grid.Column width={12}>
                    <Segment>
                      <Grid centered columns={2} stackable>
                        <Grid.Row centered columns={4}>
                          <Grid.Column>
                            <Header textAlign='center' as='h2'>
                              <Image
                                size='tiny'
                                centered
                                src={
                                  'https://res.cloudinary.com/sheyou/image/upload/v1641199099/1_7334db6741.png?5303827.600000024'
                                }
                              />
                              <br />
                              {userLang !== 'zh-CN' && 'Browse'}
                              {userLang === 'zh-CN' && '浏览'}
                              <Header.Subheader>
                                {userLang !== 'zh-CN' &&
                                  'Choose clothing to buy, rent, or trade'}
                                {userLang === 'zh-CN' &&
                                  '选择购买租借或者交换喜欢的衣物'}
                              </Header.Subheader>
                            </Header>
                          </Grid.Column>
                          <Grid.Column>
                            <Header textAlign='center' as='h2'>
                              <Image
                                size='tiny'
                                centered
                                src={
                                  'https://res.cloudinary.com/sheyou/image/upload/v1641199099/2_fd29edf670.png?5301763.600000024'
                                }
                              />
                              <br />

                              {userLang !== 'zh-CN' && 'Rent'}
                              {userLang === 'zh-CN' && '租借'}
                              <Header.Subheader>
                                {userLang !== 'zh-CN' &&
                                  'Pick up items in person or get delivered from owners/designers'}
                                {userLang === 'zh-CN' &&
                                  '从附近取衣或者送货上门'}
                              </Header.Subheader>
                            </Header>
                          </Grid.Column>

                          <Grid.Column>
                            <Header textAlign='center' as='h2'>
                              <Image
                                size='tiny'
                                centered
                                src={
                                  'https://res.cloudinary.com/sheyou/image/upload/v1641199100/infinite_c73324f586.png?5305877.400000036'
                                }
                              />
                              <br />
                              {userLang === 'zh-CN' && '重复'}
                              {userLang !== 'zh-CN' && 'Repeat'}
                              <Header.Subheader>
                                {userLang !== 'zh-CN' &&
                                  'Enjoying Repeating this process'}
                                {userLang === 'zh-CN' &&
                                  '循环这一过程，祝您愉快！'}
                              </Header.Subheader>
                            </Header>
                          </Grid.Column>
                          {/* <Grid.Column>
                            <Header textAlign='center' as='h2'>
                              <Image
                                size='tiny'
                                centered
                                src={
                                  'https://res.cloudinary.com/sheyou/image/upload/v1641199099/3_2b4f73bf75.png?5295625.199999988'
                                }
                              />
                              <br />
                              {userLang === 'zh-CN' && '归还'}
                              {userLang !== 'zh-CN' && 'Return'}
                              <Header.Subheader>
                                {userLang === 'zh-CN' &&
                                  '选择一个您想要的时间，我们会上门取件'}
                                {userLang !== 'zh-CN' &&
                                  'Pick a time and place for delivery man to come whenever you want'}
                              </Header.Subheader>
                            </Header>
                          </Grid.Column> */}
                        </Grid.Row>

                        {/* <Grid.Row centered columns={4}>
                          <Grid.Column>
                            <Header textAlign='center' as='h2'>
                              <Image
                                size='tiny'
                                centered
                                src={
                                  'https://res.cloudinary.com/sheyou/image/upload/v1641199100/infinite_c73324f586.png?5305877.400000036'
                                }
                              />
                              <br />
                              {userLang === 'zh-CN' && '重复'}
                              {userLang !== 'zh-CN' && 'Repeat'}
                              <Header.Subheader>
                                {userLang !== 'zh-CN' &&
                                  'Enjoying Repeating this process'}
                                {userLang === 'zh-CN' &&
                                  '循环这一过程，祝您愉快！'}
                              </Header.Subheader>
                            </Header>
                          </Grid.Column>
                          <Grid.Column>
                            <Image size='tiny' src='' />
                            <Header textAlign='center' as='h2'>
                              {userLang !== 'zh-CN' && 'OR'}
                              {userLang === 'zh-CN' && '或者'}
                            </Header>
                          </Grid.Column>
                          <Grid.Column>
                            <Header textAlign='center' as='h2'>
                              <Image
                                size='tiny'
                                centered
                                src={
                                  'https://res.cloudinary.com/sheyou/image/upload/v1641199099/heart_2_a220ae99d6.png?5299756.199999988'
                                }
                              />
                              <br />
                              {userLang === 'zh-CN' && '以折扣价购买'}
                              {userLang !== 'zh-CN' && 'Buy with a discount'}
                              <Header.Subheader>
                                {userLang === 'zh-CN' &&
                                  '您可以选择留下心仪的商品'}
                                {userLang !== 'zh-CN' &&
                                  'You can choose to keep the clothing'}
                              </Header.Subheader>
                            </Header>
                          </Grid.Column>
                        </Grid.Row> */}
                      </Grid>
                    </Segment>
                  </Grid.Column>
                  <Grid.Column width={2}></Grid.Column>
                </Grid.Row>

                <Grid.Row centered>
                  <Button
                    onClick={this.toShop}
                    // inverted
                    size='huge'
                    style={{
                      paddingLeft: 50,
                      paddingRight: 50,
                      paddingTop: 10,
                      paddingBottom: 10
                    }}
                  >
                    {userLang === 'zh-CN' && '寻找新风格'}
                    {userLang !== 'zh-CN' && 'Look for new style!'}
                  </Button>
                  {/* <Button
                      onClick={this.toPlans}
                      // inverted
                      size='huge'
                      style={{
                        paddingLeft: 50,
                        paddingRight: 50,
                        paddingTop: 10,
                        paddingBottom: 10
                      }}
                    >
                      
                      {userLang === 'zh-CN' &&
                      '成为会员'}
                      {userLang !== 'zh-CN' &&
                        'Become Member'
                      }
                    </Button> */}
                </Grid.Row>
                <Grid.Row></Grid.Row>
              </Grid>
            </Parallax>

            <div className='bg_image'>
              <br />
              <br />
              <Container style={{ marginTop: 10 }}>
                <Grid>
                  <Grid.Column>
                    <Segment raised>
                      <Grid>
                        <Grid.Row></Grid.Row>
                        <Grid.Row centered style={{ fontSize: 30 }}>
                          <Header>Why We Support </Header>
                          <Header color='orange'> Sustainable Fashion </Header>
                        </Grid.Row>

                        <Grid.Row centered style={{ fontSize: 30 }}>
                          <Header>instead of Fast Fashion </Header>
                        </Grid.Row>
                        <Label as='a' color='orange' ribbon size='massive'>
                          {' '}
                          Let's watch a 3-minute-video first
                        </Label>
                        <Grid.Row></Grid.Row>
                      </Grid>
                      <Container>
                        <Embed
                          icon='play circle'
                          placeholder='https://i.loli.net/2020/12/15/DchTU5FgR9zpAeu.jpg'
                          url='http://v.qq.com/iframe/player.html?vid=m0980830f7h'
                        />
                      </Container>
                      <Grid>
                        <Grid.Row></Grid.Row>
                        <Grid.Row>
                          <Label as='a' color='orange' ribbon size='massive'>
                            {' '}
                            Learn More
                          </Label>
                        </Grid.Row>
                      </Grid>
                      <Accordion fluid styled>
                        <Accordion.Title
                          active={activeIndex === 0}
                          index={0}
                          onClick={this.handleClick}
                        >
                          <Icon name='dropdown' />
                          Water Contamination
                        </Accordion.Title>
                        <Accordion.Content active={activeIndex === 0}>
                          <Grid stackable>
                            <Grid.Row columns={4}>
                              <Grid.Column width={2}></Grid.Column>
                              <Grid.Column width={7}>
                                <Image
                                  src='http://23927233.s21i.faiusr.com/4/ABUIABAEGAAgtPiL9wUojKmOHjCIBTiIBQ.png'
                                  size='medium'
                                />
                              </Grid.Column>
                              <Grid.Column width={1}></Grid.Column>
                              <Grid.Column width={4}>
                                <Grid>
                                  <Grid.Row></Grid.Row>
                                  <Grid.Row></Grid.Row>
                                  <Grid.Row>
                                    <Header>
                                      Cotton is the most common natural fiber
                                      used to make clothing, accounting for
                                      about 33 percent of all fibers found in
                                      textiles.
                                    </Header>
                                  </Grid.Row>
                                </Grid>
                              </Grid.Column>
                            </Grid.Row>

                            <Grid.Row columns={3}>
                              <Grid.Column width={2}></Grid.Column>

                              <Grid.Column width={5}>
                                <Grid>
                                  <Grid.Row></Grid.Row>
                                  <Grid.Row>
                                    <Header>
                                      In Central Asia, for instance, the Aral
                                      Sea has nearly disappeared because cotton
                                      farmers draw excessively from the Amu
                                      Darya and Syr Darya rivers.
                                    </Header>
                                  </Grid.Row>
                                  <Grid.Row></Grid.Row>
                                </Grid>
                              </Grid.Column>
                              <Grid.Column width={2}></Grid.Column>
                              <Grid.Column width={6}>
                                <Image
                                  src='http://23927233.s21i.faiusr.com/4/ABUIABAEGAAgtPiL9wUogKTuvAYwwAI4kAI.png'
                                  size='medium'
                                />
                              </Grid.Column>
                            </Grid.Row>

                            <Grid>
                              <Grid.Row columns={3}>
                                <Grid.Column width={2}></Grid.Column>
                                <Grid.Column width={12}>
                                  <Grid>
                                    <Grid.Row>
                                      <Grid.Row></Grid.Row>
                                      <Header>
                                        Water use and pollution also take place
                                        during clothing production. About 20
                                        percent of industrial water pollution is
                                        due to garment manufacturing.
                                      </Header>
                                    </Grid.Row>
                                    <Grid.Row></Grid.Row>

                                    <Grid.Row>
                                      <Header>
                                        In China, the factory of the world, it
                                        is estimated that 70 percent of the
                                        rivers and lakes are contaminated by the
                                        2.5 billion gallons of wastewater
                                        produced by the textile industry.
                                      </Header>
                                    </Grid.Row>

                                    <Grid.Row></Grid.Row>

                                    <Grid.Row>
                                      <Header></Header>
                                    </Grid.Row>
                                  </Grid>
                                </Grid.Column>
                                <Grid.Column width={2}></Grid.Column>
                              </Grid.Row>
                            </Grid>
                          </Grid>
                        </Accordion.Content>

                        <Accordion.Title
                          active={activeIndex === 1 ? true : false}
                          index={1}
                          onClick={this.handleClick}
                        >
                          <Icon name='dropdown' />
                          Other Pollution
                        </Accordion.Title>

                        <Accordion.Content
                          active={activeIndex === 1 ? true : false}
                        >
                          <Grid>
                            <Grid.Row columns={3}>
                              <Grid.Column width={2}></Grid.Column>
                              <Grid.Column width={12}>
                                <Grid>
                                  <Grid.Row>
                                    <Grid.Row></Grid.Row>
                                    <Header>
                                      The platation of cotton uses many harmful
                                      pesticides and chemical fertilizers, which
                                      eventually results in soil. In addition,
                                      the textile process puts toxins into
                                      nearby lands.
                                    </Header>
                                  </Grid.Row>
                                  <Grid.Row></Grid.Row>

                                  <Grid.Row>
                                    <Header>
                                      17% to 20% of industrial wastes come from
                                      textile dyeing. Every year the industry
                                      has 20 tons of toxic water, which contains
                                      nitrate, copper, arsenic, lead, cadmium,
                                      mercury, nickel...
                                    </Header>
                                  </Grid.Row>

                                  <Grid.Row></Grid.Row>
                                  <Grid.Row>
                                    <Header>
                                      Production of nylon produces nitrous oxide
                                      which is a greenhouse gas 300 times more
                                      potent than carbon dioxide. Both polyester
                                      and nylon also break down in washing
                                      machines leading to the build up of
                                      microplastics in our water systems.{' '}
                                    </Header>
                                  </Grid.Row>

                                  <Grid.Row></Grid.Row>

                                  <Grid.Row>
                                    <Header>
                                      Electricity is needed to run machinery
                                      such as sewing machines and air pumps in
                                      textile factories. Huge amounts of heat is
                                      needed for washing, drying and dying the
                                      cloth.
                                    </Header>
                                  </Grid.Row>

                                  <Grid.Row></Grid.Row>
                                  <Grid.Row>
                                    <Header>
                                      The further costs of transporting the
                                      garments produces additional CO2 as the
                                      vast majority of garments have travelled
                                      by ship. Ships burn bunker fuel which
                                      contains 1800 times more sulfur than US
                                      domestic vehicle fuel, making shipping a
                                      significant polluting sector.
                                    </Header>
                                  </Grid.Row>
                                </Grid>
                              </Grid.Column>
                              <Grid.Column width={2}></Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </Accordion.Content>

                        <Accordion.Title
                          active={activeIndex === 2}
                          index={2}
                          onClick={this.handleClick}
                        >
                          <Icon name='dropdown' />
                          Let's make a difference!
                        </Accordion.Title>
                        <Accordion.Content active={activeIndex === 2}>
                          <Grid>
                            <Grid.Row columns={3}>
                              <Grid.Column width={2}></Grid.Column>
                              <Grid.Column width={12}>
                                <Grid>
                                  <Grid.Row>
                                    <Grid.Row></Grid.Row>
                                    <Header>
                                      1. Consume less new, conventional fashion.
                                    </Header>
                                  </Grid.Row>
                                  <Grid.Row></Grid.Row>

                                  <Grid.Row>
                                    <Header>
                                      2. Try to buy second hand if you can.
                                    </Header>
                                  </Grid.Row>

                                  <Grid.Row></Grid.Row>
                                  <Grid.Row>
                                    <Header>
                                      3. When you buy new clothes , buy it from
                                      a more sustainable label that uses
                                      organic, natural, or recycled fibers and
                                      manufactures.
                                    </Header>
                                  </Grid.Row>

                                  <Grid.Row></Grid.Row>
                                </Grid>
                              </Grid.Column>
                              <Grid.Column width={2}></Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </Accordion.Content>
                      </Accordion>
                    </Segment>
                  </Grid.Column>
                </Grid>
              </Container>
              <br />
              <br />
            </div>

            <Parallax
              bgImage={
                'https://res.cloudinary.com/sheyou/image/upload/v1654170478/body_positive_three_attractive_multicultural_wome_2021_08_29_04_54_43_utc_31e9d52795.jpg'
              }
              bgImageAlt='image'
              strength={-200}
            >
              <Grid
                style={{
                  paddingTop: 80,
                  paddingBottom: 250,
                  backgroundColor: 'rgba(0,0,0,0.3)'
                }}
              >
                <Grid.Row
                  columns={3}
                  style={{
                    paddingBottom: 100
                  }}
                >
                  <Grid.Column width={1}></Grid.Column>
                  <Grid.Column width={14}>
                    <Header
                      style={{
                        color: 'white',
                        fontSize: 100
                      }}
                    >
                      "
                    </Header>
                    <Header
                      style={{
                        position: 'relative',
                        color: 'white',
                        fontSize: 50
                      }}
                    >
                      {userLang === 'zh-CN' && '没有衣服穿'}
                      {userLang !== 'zh-CN' && 'I Have Nothing To Wear'}
                    </Header>
                  </Grid.Column>
                  <Grid.Column width={1}></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={1}></Grid.Column>
                  <Grid.Column width={8}>
                    <Header as='h3' style={{ color: 'white' }}>
                      {userLang === 'zh-CN' &&
                        '研究表明，80%的女性平均仅将衣柜里的衣服穿五次'}
                      {userLang !== 'zh-CN' &&
                        'Research shows 80% of women wear the clothing in their closets an average of 5 times.'}
                    </Header>{' '}
                  </Grid.Column>
                  <Grid.Column width={2}></Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={1}></Grid.Column>
                  <Grid.Column width={8}>
                    <Header as='h3' style={{ color: 'white' }}>
                      {userLang === 'zh-CN' &&
                        '租用可以减少购买服装的高额支出。既能满足想穿新衣服的愿望，又能减轻整理衣橱和洗衣服造成的负担。'}
                      {userLang !== 'zh-CN' &&
                        'Renting can reduce high expenses on garments. Satisfy both the want to wear something new and release the stress of closet organizing and laundry.'}
                    </Header>
                  </Grid.Column>
                  <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
              </Grid>
            </Parallax>
            <Grid>
              <Grid.Row
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  marginTop: 0,
                  backgroundImage:
                    'url(https://res.cloudinary.com/sheyou/image/upload/v1654417910/three_warehouse_workers_doing_garment_stock_take_i_2022_03_07_23_58_38_utc_a576439648.jpg)',
                  backgroundSize: 'cover'
                }}
              >
                <Segment style={{ backgroundColor: 'rgba(0,0,0,0.3)' }}>
                  <Grid
                    centered
                    style={{ paddingTop: 100, paddingBottom: 100 }}
                  >
                    <Header
                      style={{
                        color: 'white',
                        fontSize: 50,
                        paddingBottom: 100
                      }}
                    >
                      {userLang === 'zh-CN' && '商户怎样使用平台'}
                      {userLang !== 'zh-CN' && 'How it Works For Merchants'}
                    </Header>
                    <Grid.Row columns={3}>
                      <Grid.Column width={4}></Grid.Column>
                      <Grid.Column width={8}>
                        <div>
                          <Header
                            as='h3'
                            style={{
                              color: 'white'
                            }}
                          >
                            {userLang === 'zh-CN' 
                              ? '我们为个人、品牌、设计师和商家提供一个平台，用于出租、销售或交换他们的产品。'
                              : 'We provide individuals, brands, designers and merchants with a platform to rent out, sell, or trade their products.'}
                          </Header>

                          <Header
                            as='h3'
                            style={{
                              color: 'white'
                            }}
                          >
                            {userLang === 'zh-CN'
                              ? '一切都是高度可定制的。您可以选择您的设计价格以及想要出租的时长。'
                              : 'Everything is highly customizable. You can choose how much your design is and how long you want to rent it out.'}
                          </Header>
                          <Header 
                            as='h3'
                            style={{
                              color: 'white'
                            }}
                          >
                            {userLang === 'zh-CN'
                              ? '您可以对客户进行评分，并决定他们是否需要支付押金。'
                              : 'You can rate your customers and decide if they need to pay deposit.'}
                          </Header>
                        </div>
                      </Grid.Column>
                      <Grid.Column width={4}></Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
              </Grid.Row>
              <MerchantSec
                self={self}
                pic='https://res.cloudinary.com/sheyou/image/upload/v1654418469/snapshot_of_colorful_summer_stylish_outfits_and_pa_2021_09_02_23_57_46_utc_fa385d6696.jpg'
              />
            </Grid>
          </>
        )}
        <Foot />
      </div>
    )
  }
}

export default withRouter(AboutPage)
