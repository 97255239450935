import React, { useState, useEffect } from 'react';
import { Form, Dropdown } from 'semantic-ui-react';
import { Country, State, City } from 'country-state-city';

const CountryStateCity = ({ onSelect }) => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');

  useEffect(() => {
    const countryList = Country.getAllCountries().map(country => ({
      key: country.isoCode,
      value: country.isoCode,
      text: country.name
    }));
    setCountries(countryList);
  }, []);

  const handleCountryChange = (e, { value }) => {
    setSelectedCountry(value);
    setSelectedState('');
    setSelectedCity('');
    const stateList = State.getStatesOfCountry(value).map(state => ({
      key: state.isoCode,
      value: state.isoCode,
      text: state.name
    }));
    setStates(stateList);
    setCities([]);
    onSelect({ country: value, state: '', city: '' });
  };

  const handleStateChange = (e, { value }) => {
    setSelectedState(value);
    setSelectedCity('');
    const cityList = City.getCitiesOfState(selectedCountry, value).map(city => ({
      key: city.name,
      value: city.name,
      text: city.name
    }));
    setCities(cityList);
    onSelect({ country: selectedCountry, state: value, city: '' });
  };

  const handleCityChange = (e, { value }) => {
    setSelectedCity(value);
    onSelect({ country: selectedCountry, state: selectedState, city: value });
  };

  return (
    <Form>
      <Form.Field>
        <label>Country</label>
        <Dropdown
          placeholder='Select Country'
          fluid
          search
          selection
          options={countries}
          value={selectedCountry}
          onChange={handleCountryChange}
        />
      </Form.Field>
      <Form.Field>
        <label>State</label>
        <Dropdown
          placeholder='Select State'
          fluid
          search
          selection
          options={states}
          value={selectedState}
          onChange={handleStateChange}
          disabled={!selectedCountry}
        />
      </Form.Field>
      <Form.Field>
        <label>City</label>
        <Dropdown
          placeholder='Select City'
          fluid
          search
          selection
          options={cities}
          value={selectedCity}
          onChange={handleCityChange}
          disabled={!selectedState}
        />
      </Form.Field>
    </Form>
  );
};

export default CountryStateCity;
