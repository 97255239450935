import React from 'react'
import { Grid, Icon, Segment, Container, Header } from 'semantic-ui-react'
import Foot from '../foot/Foot'
import { withRouter } from 'react-router-dom'
const userLang = navigator.language || navigator.userLanguage

class LoggingPageMerchant extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  componentDidMount () {
    // window.setTimeout(() => {
    //     window.location.reload(true)

    // }, 100);
    const mobile = this.props.mobile
    window.setTimeout(() => {
      if(mobile===true){
        this.props.history.push('/merchantpage')
      }else{
        this.props.history.push('/merchantPage')
      }
      window.location.reload(true)
    }, 1000)
  }

  render () {
    const mobile = this.props.mobile

    return (
      <div>
        {/* <Nav /> */}

        {mobile && (
          <Grid>
            <Grid.Row style={{ position: 'absolute', top: '50%' }} centered>
              <Header size='huge'>
                <Icon loading name='asterisk' size='huge' color='yellow' />
                Logging You In...
              </Header>
            </Grid.Row>
          </Grid>
        )}

        {!mobile&&<div className='bg_image'>
          <br />
          <br />

          <Container>
            <Segment>
              <Grid centered colums={2}>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row>
                  {userLang === 'zh-CN' && (
                    <Header size='huge'>
                      <Icon
                        loading
                        name='asterisk'
                        size='massive'
                        color='yellow'
                      />
                      正在登录...
                    </Header>
                  )}
                  {userLang !== 'zh-CN' && (
                    <Header size='huge'>
                      <Icon
                        loading
                        name='asterisk'
                        size='massive'
                        color='yellow'
                      />
                      Logging you in...
                    </Header>
                  )}
                </Grid.Row>

                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
              </Grid>
            </Segment>
          </Container>

          <br />
          <br />
          <Foot />
        </div>}

        
      </div>
    )
  }
}

export default withRouter(LoggingPageMerchant)
