import React from 'react'
import { Grid, Menu, Header, Icon } from 'semantic-ui-react'
import NavMobilePhone from '../../nav/NavMobilePhone'
import { withRouter } from 'react-router-dom'
import LoadingPhone from '../../RedirectPages/LoadingPhone'
import axios from 'axios'

class MerchantPagePhone extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      login: false,
      userInfo: [],
      userID: '',
      merchantVerified: false,
      merchantInfo: {},

      loading: true
    }
    this.handleItemClick = this.handleItemClick.bind(this)
    this.toStore = this.toStore.bind(this)
    this.toOrders = this.toOrders.bind(this)
  }

  componentDidMount () {
    window.scrollTo(0, 0)
    const self = this
    const p = this.props
    const activeItem = p.activeItem

    self.setState({ activeItem: activeItem })

    const merchantLogin = localStorage.getItem('merchantLogin')
    const login=localStorage.getItem('login')
    if (merchantLogin !== 'true'||login !== 'true') {
      self.props.history.push('/merchantsLog')
    } else {
      this.getUserInfo()
    }

    if (activeItem === 'Membership') {
      self.setState({
        activeItem: 'Membership'
      })
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 700)
  }

  getUserInfo () {
    const self = this
    const token = localStorage.getItem('token')
    axios('https://sheyou-backend.herokuapp.com/users/me', {
      method: 'get',
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).then(function (res) {
      const userInfo = [res.data]
      if (userInfo === null) {
        self.setState({
          userInfo: [],
          userID: userInfo[0].id
        })
      } else if (userInfo.length > 0) {
        self.setState({
          merchantInfo: userInfo[0].merchantInfo,
          userInfo: userInfo,
          userID: userInfo[0].id,
          loading: false
        })
      }
    })
  }

  toStore () {
    const self = this
    self.props.history.push('/merchantpage/store')
  }

  toOrders () {
    const self = this
    self.props.history.push('/merchantOrder')
  }
  // handleItemClick = (e, { name }) => this.setState({ activeItem: name })

  handleItemClick (event, data) {
    event.preventDefault()
    var name = data.name
    const link = data.to
    this.setState({ activeItem: name })
    if (name === 'Profile Detail') {
      this.props.history.push('/profileDetail')
    } else if (name === 'Fit Preference') {
      this.props.history.push('/fitPreference')
    } else if (name === 'Address') {
      this.props.history.push('/myAddress')
    } else if (name === 'About') {
      this.props.history.push('/about')
    } else if (name === 'Contact Us') {
      this.props.history.push('/contactUs')
    } else if (name === 'Merchant') {
      this.props.history.push('/merchantsLog')
    } else if (name === 'Change Password') {
      this.props.history.push('/changePassword')
    } else if (name === 'Personal Account') {
      this.props.history.push('/user')
    } else {
      this.props.history.push(link)
    }
  }

  onHandleSignOut (event) {
    event.preventDefault()
    const self = this
    self.setState({ login: false })
    const rememberMe = localStorage.getItem('rememberMe')
    if (rememberMe === 'true') {
      localStorage.setItem('login', 'false')
    } else {
      localStorage.setItem('login', 'false')
      localStorage.setItem('identifier', '')
      localStorage.setItem('password', '')
    }

    // alert('you have been signed out')
    self.props.history.push('/loggingOut')
  }

  render () {
    const s = this.state
    const merchantInfo = s.merchantInfo
    var storeName = 'Your Store'

    const loading = s.loading
    const activeItem = this.state.activeItem
    const self = this

    if (merchantInfo !== undefined && merchantInfo.length!== 0) {
      // account = merchantInfo[0].username
      // merchantInfo = merchantInfo[0].merchantInfo
      // merchantVerified = merchantInfo.merchantVerified
      storeName = merchantInfo.storeName
    }

    return (
      <div>
        {/* <Nav /> */}

        {loading === true && <LoadingPhone />}

        {loading === false && (
          <>
            <Menu icon borderless widths={3}>
              <Menu.Item>
                <Grid centered>
                  <Grid.Row style={{ paddingBottom: 0, marginBottom: 0 }}>
                    <Header as='h1' color='teal'>
                      SHEYOU
                    </Header>
                  </Grid.Row>
                  <Grid.Row style={{ paddingTop: 0, marginTop: 0 }}>
                    <Header style={{ color: 'rgba(59,83,98)' }}>
                      {' '}
                      Merchant
                    </Header>
                  </Grid.Row>
                </Grid>
              </Menu.Item>
            </Menu>

            <div className='bg_color_image_green_orange'>
              <Grid
                columns='equal'
                divided
                style={{ paddingTop: 50, paddingBottom: 50 }}
              >
                <Grid.Column width={2}></Grid.Column>
                <Grid.Column width={7}>
                  <Grid.Row
                    style={{ paddingBottom: 0, marginBottom: 0, marginTop: 60 }}
                  >
                    <Header as='h2' style={{ color: 'rgba(59,83,98)' }}>
                      {storeName}
                    </Header>
                  </Grid.Row>

                  <Grid.Row></Grid.Row>
                </Grid.Column>

                <Grid.Column width={4}>
                  <Grid.Row centered>
                    <Icon.Group name='Store' onClick={self.toStore}>
                      <Icon size='huge' name='circle' inverted />
                      <Icon
                        style={{
                          paddingLeft: 0,
                          marginLeft: 0,
                          paddingRight: 3,
                          opacity: '0.9'
                        }}
                        color='orange'
                        size='big'
                        name='home'
                      />
                    </Icon.Group>
                    <Header
                      as='h5'
                      style={{
                        paddingTop: 0,
                        marginTop: 5,
                        marginBottom: 10,
                        paddingLeft: 10,
                        color: 'rgba(59,83,98)'
                      }}
                    >
                      Store
                    </Header>
                  </Grid.Row>

                  <Grid.Row centered>
                    <Icon.Group name='Orders' onClick={self.toOrders}>
                      <Icon size='huge' name='circle' inverted />
                      <Icon
                        style={{
                          paddingLeft: 0,
                          marginLeft: 0,
                          paddingRight: 3,
                          opacity: '0.9'
                        }}
                        color='orange'
                        size='big'
                        name='clipboard list'
                      />
                    </Icon.Group>
                    <Header
                      onClick={self.handleItemClickCart}
                      as='h5'
                      style={{
                        paddingTop: 0,
                        color: 'rgba(59,83,98)',
                        marginTop: 5,
                        paddingLeft: 15
                      }}
                    >
                      Orders
                    </Header>
                  </Grid.Row>

                  <Grid.Row></Grid.Row>
                </Grid.Column>
              </Grid>
            </div>

            <div style={{ backgroundColor: '#E6E9EA', paddingTop: 10 }}>
              <Menu
                vertical
                borderless
                style={{ width: '100%', paddingTop: 5, paddingBottom: 5 }}
              >
                <Menu.Item
                  name='Personal Account'
                  active={activeItem === 'Personal Account'}
                  onClick={this.handleItemClick}
                >
                  <Grid columns='equal '>
                    <Grid.Column width={1}></Grid.Column>
                    <Grid.Column width={2}>
                      <Icon name='user' color='teal' circular />
                    </Grid.Column>
                    <Grid.Column
                      style={{ position: 'relative', top: 6 }}
                      width={10}
                    >
                      <Header style={{ color: 'rgba(59,83,98)' }} as='h5'>
                        Personal Account
                      </Header>
                    </Grid.Column>
                    <Grid.Column style={{ position: 'relative', top: 6 }}>
                      <Icon name='angle right' />
                    </Grid.Column>
                  </Grid>
                </Menu.Item>
              </Menu>
            </div>

            <div style={{ backgroundColor: '#E6E9EA', paddingTop: 10 }}>
              <Menu
                vertical
                borderless
                style={{ width: '100%', paddingTop: 5, paddingBottom: 5 }}
              >
                <Menu.Item header style={{ color: 'rgba(59,83,98)' }}>
                  Orders
                </Menu.Item>
                <Menu.Item
                  name='Ongoing'
                  active={activeItem === 'Ongoing'}
                  to='/merchantOrder/ongoing'
                  onClick={this.handleItemClick}
                  // style={{ paddingBottom: 0 }}
                >
                  <Grid columns='equal '>
                    <Grid.Column width={1}></Grid.Column>
                    <Grid.Column width={2}>
                      <Icon color='teal' circular name='list' />
                    </Grid.Column>
                    <Grid.Column
                      style={{ position: 'relative', top: 6 }}
                      width={10}
                    >
                      <Header style={{ color: 'rgba(59,83,98)' }} as='h5'>
                        Ongoing
                      </Header>
                    </Grid.Column>
                    <Grid.Column style={{ position: 'relative', top: 6 }}>
                      <Icon name='angle right' />
                    </Grid.Column>
                  </Grid>
                </Menu.Item>

                <Menu.Item
                  name='Past'
                  active={activeItem === 'Past'}
                  to='/merchantOrder/past'
                  onClick={this.handleItemClick}
                >
                  <Grid columns='equal '>
                    <Grid.Column width={1}></Grid.Column>
                    <Grid.Column width={2}>
                      <Icon color='teal' circular name='list' />
                    </Grid.Column>
                    <Grid.Column
                      style={{ position: 'relative', top: 6 }}
                      width={10}
                    >
                      <Header style={{ color: 'rgba(59,83,98)' }} as='h5'>
                        Past
                      </Header>
                    </Grid.Column>
                    <Grid.Column style={{ position: 'relative', top: 6 }}>
                      <Icon name='angle right' />
                    </Grid.Column>
                  </Grid>
                </Menu.Item>
              </Menu>
            </div>

            <div style={{ backgroundColor: '#E6E9EA', paddingTop: 10 }}>
              <Menu
                vertical
                borderless
                style={{ width: '100%', paddingTop: 5, paddingBottom: 5 }}
              >
                <Menu.Item header style={{ color: 'rgba(59,83,98)' }}>
                  Upload Product
                </Menu.Item>
                <Menu.Item
                  name='Clothing'
                  active={activeItem === 'Clothing'}
                  onClick={this.handleItemClick}
                  to='/merchantpage/products'
                >
                  <Grid columns='equal'>
                    <Grid.Column width={1}></Grid.Column>
                    <Grid.Column width={2}>
                      <Icon name='add' color='teal' circular />
                    </Grid.Column>
                    <Grid.Column
                      style={{ position: 'relative', top: 6 }}
                      width={10}
                    >
                      <Header style={{ color: 'rgba(59,83,98)' }} as='h5'>
                        Clothing
                      </Header>
                    </Grid.Column>
                    <Grid.Column style={{ position: 'relative', top: 6 }}>
                      <Icon name='angle right' />
                    </Grid.Column>
                  </Grid>
                </Menu.Item>
                <Menu.Item
                  name='Accessory'
                  active={activeItem === 'Accessory'}
                  onClick={this.handleItemClick}
                  to='/merchantpage/accessaries'
                >
                  <Grid columns='equal'>
                    <Grid.Column width={1}></Grid.Column>
                    <Grid.Column width={2}>
                      <Icon name='add' color='teal' circular />
                    </Grid.Column>
                    <Grid.Column
                      style={{ position: 'relative', top: 6 }}
                      width={10}
                    >
                      <Header style={{ color: 'rgba(59,83,98)' }} as='h5'>
                        Accessory
                      </Header>
                    </Grid.Column>
                    <Grid.Column style={{ position: 'relative', top: 6 }}>
                      <Icon name='angle right' />
                    </Grid.Column>
                  </Grid>
                </Menu.Item>
                <Menu.Item
                  name='Sale'
                  active={activeItem === 'Sale'}
                  onClick={this.handleItemClick}
                  to='/merchantpage/sale'
                >
                  <Grid columns='equal'>
                    <Grid.Column width={1}></Grid.Column>
                    <Grid.Column width={2}>
                      <Icon name='add' color='teal' circular />
                    </Grid.Column>
                    <Grid.Column
                      style={{ position: 'relative', top: 6 }}
                      width={10}
                    >
                      <Header style={{ color: 'rgba(59,83,98)' }} as='h5'>
                        Sale
                      </Header>
                    </Grid.Column>
                    <Grid.Column style={{ position: 'relative', top: 6 }}>
                      <Icon name='angle right' />
                    </Grid.Column>
                  </Grid>
                </Menu.Item>
              </Menu>
            </div>

            <div style={{ backgroundColor: '#E6E9EA', paddingTop: 10 }}>
              <Menu
                vertical
                style={{
                  width: '100%'
                }}
              >
                <Menu.Item
                  name='Change Password'
                  active={activeItem === 'Change Password'}
                  onClick={this.handleItemClick}
                >
                  <Grid columns='equal '>
                    <Grid.Column width={1}></Grid.Column>
                    <Grid.Column width={12}>
                      <Header style={{ color: 'rgba(59,83,98)' }} as='h5'>
                        Change Password
                      </Header>
                    </Grid.Column>
                    <Grid.Column>
                      <Icon
                        name='edit'
                        // style={{ position: 'relative', top: 3 }}
                      />
                    </Grid.Column>
                  </Grid>
                </Menu.Item>

                <Menu.Item
                  name='Log Out'
                  active={activeItem === 'Log Out'}
                  onClick={this.onHandleSignOut}
                >
                  <Grid columns='equal '>
                    <Grid.Column width={1}></Grid.Column>
                    <Grid.Column width={12}>
                      <Header style={{ color: 'rgba(59,83,98)' }} as='h5'>
                        Log Out
                      </Header>
                    </Grid.Column>
                    <Grid.Column>
                      <Icon
                        name='sign-out'
                        // style={{ position: 'relative', top: 3 }}
                      />
                    </Grid.Column>
                  </Grid>
                </Menu.Item>
                <Menu.Item
                  name='Contact Us'
                  active={activeItem === 'Contact Us'}
                  onClick={this.handleItemClick}
                  to='/contactUs'
                >
                  <Grid columns='equal '>
                    <Grid.Column width={1}></Grid.Column>
                    <Grid.Column width={12}>
                      <Header style={{ color: 'rgba(59,83,98)' }} as='h5'>
                        Contact Us
                      </Header>
                    </Grid.Column>
                    <Grid.Column>
                      <Icon name='angle right' />
                    </Grid.Column>
                  </Grid>
                </Menu.Item>
              </Menu>

              <Grid style={{ paddingBottom: 55 }}>
                <Grid.Row centered>
                  <Header as='h6' style={{ color: '#384D5A' }}>
                    @Sichuan Sheyu Renyu Trading Co. LTD
                  </Header>
                </Grid.Row>
              </Grid>
            </div>
          </>
        )}

        <NavMobilePhone />
      </div>
    )
  }
}

export default withRouter(MerchantPagePhone)
