import React from 'react'
import PastOrderITem from './PastOrderItem'
import PastOrderItemRenting from './PastOrderItemRenting'
import { Grid, Header, Button, Confirm, Label } from 'semantic-ui-react'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import PastOrderItemPhone from './PastOrderItemPhone'
import PastOrderItemRentingPhone from './PastOrderItemRentingPhone'

class PastOrder extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false,
      delivered: false
    }
    this.onHandleClickDelivery = this.onHandleClickDelivery.bind(this)
    this.pay = this.pay.bind(this)
    this.onHandleDelivered = this.onHandleDelivered.bind(this)
  }

  open = () => this.setState({ open: true })
  close = () => this.setState({ open: false })

  onHandleDelivered (data, event) {
    const self = this
    const orderid = event.orderid
    const token = localStorage.getItem('token')
    this.setState({
      loading: true
    })
    console.log(data, event)
    axios('https://sheyou-backend.herokuapp.com/orders/' + orderid, {
      method: 'put',
      data: {
        delivered: true
      },
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .catch(error => console.log(error))
      .then(function (res) {
        console.log(res)
        if (res.status !== 200) {
          alert('something went wrong')
        } else {
          self.setState({
            loading: false,
            delivered: true,
            open: false
          })
        }
      })
  }
  componentDidMount () {}

  onHandleClickDelivery (event, data) {
    event.preventDefault()
    const self = this
    const orderid = data.orderid
    localStorage.setItem('orderToDeliveryId', orderid)
    self.props.history.push('/delivery')
  }

  pay (event, data) {
    const self = this
    const orderid = data.orderid
    event.preventDefault()
    localStorage.setItem('orderId', orderid)
    self.props.history.push('/confirm?orderid=' + orderid)
  }

  render () {
    const p = this.props
    const data = p.data
    const isMerchantOrder = p.isMerchantOrder
    const OrderedPickUp = data.OrderedPickUp
    const shipped = data.shipped
    const timeOfTheOrder = data.created_at
    const info = data.orders
    // const address = info.info
    // const rentOrBuy = address.rentOrBuy
    const products = info.data
    const rentOrBuy = products.rentOrBuy
    const returned = data.returned
    const orderid = p.orderid
    const delivered = p.delivered
    const paid = data.paid
    const mobile = p.mobile
    const userId = p.userId
    const returnInfo=p.returnInfo

    var clothing = products.clothing.filter(function (each) {
      return each.type !== 'sale'
    })
    var saleClothing = products.clothing.filter(function (each) {
      return each.type === 'sale'
    })
    var accessory = products.accessory

    if (isMerchantOrder) {
      clothing = clothing.filter(function (each) {
        return each.merchant === userId
      })

      saleClothing = saleClothing.filter(function (each) {
        return each.merchant === userId
      })

      accessory = accessory.filter(function (each) {
        return each.merchant === userId
      })
      console.log(clothing, saleClothing, accessory)
    }

    return (
      <>
        <Grid.Row columns={4}>
          <Grid.Column width={1}></Grid.Column>

          {paid === true && rentOrBuy === 'buy' && (
            <Grid.Column style={{ paddingTop: 12 }} width={7}>
              {timeOfTheOrder.substring(5, 7) === '01' && (
                <Header as='h5' color='grey' textAlign='center'>
                  {'Purchase Order Placed At :  January ' +
                    timeOfTheOrder.substring(8, 10) +
                    ', ' +
                    timeOfTheOrder.substring(0, 4)}
                </Header>
              )}
              {timeOfTheOrder.substring(5, 7) === '02' && (
                <Header as='h5' color='grey' textAlign='center'>
                  {'Purchase Order Placed At :  February ' +
                    timeOfTheOrder.substring(8, 10) +
                    ', ' +
                    timeOfTheOrder.substring(0, 4)}
                </Header>
              )}
              {timeOfTheOrder.substring(5, 7) === '03' && (
                <Header as='h5' color='grey' textAlign='center'>
                  {'Purchase Order Placed At :  March ' +
                    timeOfTheOrder.substring(8, 10) +
                    ', ' +
                    timeOfTheOrder.substring(0, 4)}
                </Header>
              )}
              {timeOfTheOrder.substring(5, 7) === '04' && (
                <Header as='h5' color='grey' textAlign='center'>
                  {'Purchase Order Placed At :  April ' +
                    timeOfTheOrder.substring(8, 10) +
                    ', ' +
                    timeOfTheOrder.substring(0, 4)}
                </Header>
              )}
              {timeOfTheOrder.substring(5, 7) === '05' && (
                <Header as='h5' color='grey' textAlign='center'>
                  {'Purchase Order Placed At :  May ' +
                    timeOfTheOrder.substring(8, 10) +
                    ', ' +
                    timeOfTheOrder.substring(0, 4)}
                </Header>
              )}
              {timeOfTheOrder.substring(5, 7) === '06' && (
                <Header as='h5' color='grey' textAlign='center'>
                  {'Purchase Order Placed At :  June ' +
                    timeOfTheOrder.substring(8, 10) +
                    ', ' +
                    timeOfTheOrder.substring(0, 4)}
                </Header>
              )}
              {timeOfTheOrder.substring(5, 7) === '07' && (
                <Header as='h5' color='grey' textAlign='center'>
                  {'Purchase Order Placed At :  July ' +
                    timeOfTheOrder.substring(8, 10) +
                    ', ' +
                    timeOfTheOrder.substring(0, 4)}
                </Header>
              )}
              {timeOfTheOrder.substring(5, 7) === '08' && (
                <Header as='h5' color='grey' textAlign='center'>
                  {'Purchase Order Placed At :  August ' +
                    timeOfTheOrder.substring(8, 10) +
                    ', ' +
                    timeOfTheOrder.substring(0, 4)}
                </Header>
              )}
              {timeOfTheOrder.substring(5, 7) === '09' && (
                <Header as='h5' color='grey' textAlign='center'>
                  {'Purchase Order Placed At :  September ' +
                    timeOfTheOrder.substring(8, 10) +
                    ', ' +
                    timeOfTheOrder.substring(0, 4)}
                </Header>
              )}
              {timeOfTheOrder.substring(5, 7) === '10' && (
                <Header as='h5' color='grey' textAlign='center'>
                  {'Purchase Order Placed At :  October ' +
                    timeOfTheOrder.substring(8, 10) +
                    ', ' +
                    timeOfTheOrder.substring(0, 4)}
                </Header>
              )}
              {timeOfTheOrder.substring(5, 7) === '11' && (
                <Header as='h5' color='grey' textAlign='center'>
                  {'Purchase Order Placed At :  November ' +
                    timeOfTheOrder.substring(8, 10) +
                    ', ' +
                    timeOfTheOrder.substring(0, 4)}
                </Header>
              )}
              {timeOfTheOrder.substring(5, 7) === '12' && (
                <Header as='h5' color='grey' textAlign='center'>
                  {'Purchase Order Placed At :  December ' +
                    timeOfTheOrder.substring(8, 10) +
                    ', ' +
                    timeOfTheOrder.substring(0, 4)}
                </Header>
              )}
            </Grid.Column>
          )}

          {paid !== true && (
            <Grid.Column style={{ paddingTop: 12 }} width={9}>
              <Header as='h5' color='grey' textAlign='center'>
                {this.state.days +
                  ' days ' +
                  this.state.hours +
                  ' hours ' +
                  this.state.minutes +
                  ' minutes ' +
                  this.state.seconds +
                  ' seconds,' +
                  ' before closing the order'}
              </Header>
            </Grid.Column>
          )}

          {paid === true && rentOrBuy !== 'buy' && (
            <Grid.Column style={{ paddingTop: 12 }} width={7}>
              {timeOfTheOrder.substring(5, 7) === '01' && (
                <Header as='h5' color='grey' textAlign='center'>
                  {'Renting Order Placed At  : January ' +
                    timeOfTheOrder.substring(8, 10) +
                    ', ' +
                    timeOfTheOrder.substring(0, 4)}
                </Header>
              )}
              {timeOfTheOrder.substring(5, 7) === '02' && (
                <Header as='h5' color='grey' textAlign='center'>
                  {'Renting Order Placed At  : February ' +
                    timeOfTheOrder.substring(8, 10) +
                    ', ' +
                    timeOfTheOrder.substring(0, 4)}
                </Header>
              )}
              {timeOfTheOrder.substring(5, 7) === '03' && (
                <Header as='h5' color='grey' textAlign='center'>
                  {'Renting Order Placed At  : March ' +
                    timeOfTheOrder.substring(8, 10) +
                    ', ' +
                    timeOfTheOrder.substring(0, 4)}
                </Header>
              )}
              {timeOfTheOrder.substring(5, 7) === '04' && (
                <Header as='h5' color='grey' textAlign='center'>
                  {'Renting Order Placed At  : April ' +
                    timeOfTheOrder.substring(8, 10) +
                    ', ' +
                    timeOfTheOrder.substring(0, 4)}
                </Header>
              )}
              {timeOfTheOrder.substring(5, 7) === '05' && (
                <Header as='h5' color='grey' textAlign='center'>
                  {'Renting Order Placed At  : May ' +
                    timeOfTheOrder.substring(8, 10) +
                    ', ' +
                    timeOfTheOrder.substring(0, 4)}
                </Header>
              )}
              {timeOfTheOrder.substring(5, 7) === '06' && (
                <Header as='h5' color='grey' textAlign='center'>
                  {'Renting Order Placed At  : June ' +
                    timeOfTheOrder.substring(8, 10) +
                    ', ' +
                    timeOfTheOrder.substring(0, 4)}
                </Header>
              )}
              {timeOfTheOrder.substring(5, 7) === '07' && (
                <Header as='h5' color='grey' textAlign='center'>
                  {'Renting Order Placed At  : July ' +
                    timeOfTheOrder.substring(8, 10) +
                    ', ' +
                    timeOfTheOrder.substring(0, 4)}
                </Header>
              )}
              {timeOfTheOrder.substring(5, 7) === '08' && (
                <Header as='h5' color='grey' textAlign='center'>
                  {'Renting Order Placed At  : August ' +
                    timeOfTheOrder.substring(8, 10) +
                    ', ' +
                    timeOfTheOrder.substring(0, 4)}
                </Header>
              )}
              {timeOfTheOrder.substring(5, 7) === '09' && (
                <Header as='h5' color='grey' textAlign='center'>
                  {'Renting Order Placed At  : September ' +
                    timeOfTheOrder.substring(8, 10) +
                    ', ' +
                    timeOfTheOrder.substring(0, 4)}
                </Header>
              )}
              {timeOfTheOrder.substring(5, 7) === '10' && (
                <Header as='h5' color='grey' textAlign='center'>
                  {'Renting Order Placed At  : October ' +
                    timeOfTheOrder.substring(8, 10) +
                    ', ' +
                    timeOfTheOrder.substring(0, 4)}
                </Header>
              )}
              {timeOfTheOrder.substring(5, 7) === '11' && (
                <Header as='h5' color='grey' textAlign='center'>
                  {'Renting Order Placed At  : November ' +
                    timeOfTheOrder.substring(8, 10) +
                    ', ' +
                    timeOfTheOrder.substring(0, 4)}
                </Header>
              )}
              {timeOfTheOrder.substring(5, 7) === '12' && (
                <Header as='h5' color='grey' textAlign='center'>
                  {'Renting Order Placed At  : December ' +
                    timeOfTheOrder.substring(8, 10) +
                    ', ' +
                    timeOfTheOrder.substring(0, 4)}
                </Header>
              )}
            </Grid.Column>
          )}

          {paid === true && delivered !== true && (
            <>
              {this.state.delivered !== true && (
                <Grid.Column tablet={4} computer={3}>
                  {isMerchantOrder &&
                    OrderedPickUp === true &&
                    shipped === true && (
                      <Label as='a' color='teal' tag>
                        Waiting for Receiving
                      </Label>
                    )}
                  {isMerchantOrder &&
                    OrderedPickUp === true &&
                    shipped !== true && (
                      <Label as='a' color='yellow' tag>
                        Waiting for Pick Up
                      </Label>
                    )}
                  {isMerchantOrder &&
                    OrderedPickUp !== true &&
                    shipped !== true && (
                      <Button
                        color='orange'
                        orderid={orderid}
                        onClick={this.orderPickUp}
                      >
                        Order Pick Up
                      </Button>
                    )}

                  {isMerchantOrder !== true && (
                    <Button color='orange' onClick={this.open}>
                      Received
                    </Button>
                  )}
                </Grid.Column>
              )}

              <Confirm
                orderid={orderid}
                open={this.state.open}
                onCancel={this.close}
                onConfirm={this.onHandleDelivered}
              />
            </>
          )}

          {paid !== true && (
            <>
              {this.state.delivered !== true && (
                <Grid.Column tablet={3} computer={2}>
                  {isMerchantOrder && (
                    <Label as='a' color='orange' tag>
                      Waiting to Be Paid
                    </Label>
                  )}
                  {isMerchantOrder !== true && (
                    <Button.Group>
                      <Button
                        color='yellow'
                        orderid={orderid}
                        style={{ paddingLeft: 35, paddingRight: 35 }}
                        onClick={this.pay}
                      >
                        Pay
                      </Button>
                      <Button.Or />
                      <Button onClick={this.open1}>Cancel</Button>
                    </Button.Group>
                  )}
                </Grid.Column>
              )}
              <Confirm
                orderid={orderid}
                open={this.state.open1}
                onCancel={this.close1}
                onConfirm={this.cancel}
              />
            </>
          )}

          {paid === true &&
            isMerchantOrder &&
            OrderedPickUp === true &&
            shipped === true &&
            delivered === true &&
            rentOrBuy === 'buy' && (
              <Grid.Column tablet={4} computer={3}>
                <Label as='a' color='pink' tag>
                  Order delivered
                </Label>
              </Grid.Column>
            )}
          {paid === true &&
            isMerchantOrder &&
            OrderedPickUp === true &&
            shipped === true &&
            delivered === true &&
            returned === true &&
            rentOrBuy === 'rent' && (
              <Grid.Column tablet={4} computer={3}>
                <Label as='a' color='pink' tag>
                  Order Completed
                </Label>
              </Grid.Column>
            )}

          {isMerchantOrder &&
            paid === true &&
            (returnInfo === undefined || returnInfo === null) && (
              <Grid.Column width={2}>
                <Button
                  content='Status'
                  icon='truck'
                  labelPosition='right'
                  orderid={orderid}
                  onClick={this.onHandleClickDelivery}
                />
              </Grid.Column>
            )}

          {isMerchantOrder &&
            paid === true &&
            returnInfo !== undefined &&
            returnInfo !== null && (
              <Grid.Column width={2}>
                <Button
                  content='Status'
                  icon='truck'
                  labelPosition='right'
                  orderid={orderid}
                  onClick={this.onHandleClickDeliveryReturn}
                />
              </Grid.Column>
            )}

          {!mobile && rentOrBuy === 'buy' && (
            <Grid.Column width={16}>
              {clothing.map(function (it) {
                const id = it.id
                return (
                  <PastOrderITem
                    id={id}
                    orderid={orderid}
                    delivered={delivered}
                    is={'clothing'}
                  />
                )
              })}

              {saleClothing.map(function (it) {
                const id = it.id
                return (
                  <PastOrderITem
                    isMerchantOrder={isMerchantOrder}
                    id={id}
                    orderid={orderid}
                    delivered={delivered}
                    is={'saleClothing'}
                  />
                )
              })}
              {accessory.map(function (it) {
                const id = it.id
                return (
                  <PastOrderITem
                    isMerchantOrder={isMerchantOrder}
                    id={id}
                    orderid={orderid}
                    delivered={delivered}
                    is={'accessory'}
                  />
                )
              })}
            </Grid.Column>
          )}

          {!mobile && rentOrBuy !== 'buy' && returned === true && (
            <Grid.Column width={16}>
              {clothing.map(function (it) {
                const id = it.id
                return (
                  <PastOrderItemRenting
                    isMerchantOrder={isMerchantOrder}
                    id={id}
                    orderid={orderid}
                    returned={returned}
                    delivered={delivered}
                    is={'clothing'}
                  />
                )
              })}

              {saleClothing.map(function (it) {
                const id = it.id
                return (
                  <PastOrderItemRenting
                    isMerchantOrder={isMerchantOrder}
                    id={id}
                    orderid={orderid}
                    returned={returned}
                    delivered={delivered}
                    is={'saleClothing'}
                  />
                )
              })}
              {accessory.map(function (it) {
                const id = it.id
                return (
                  <PastOrderItemRenting
                    isMerchantOrder={isMerchantOrder}
                    id={id}
                    orderid={orderid}
                    returned={returned}
                    delivered={delivered}
                    is={'accessory'}
                  />
                )
              })}
            </Grid.Column>
          )}

          {mobile && rentOrBuy === 'buy' && (
            <Grid.Column width={16}>
              {clothing.map(function (it) {
                const id = it.id
                return (
                  <PastOrderItemPhone
                  isMerchantOrder={isMerchantOrder}
                    id={id}
                    orderid={orderid}
                    delivered={delivered}
                    is={'clothing'}
                  />
                )
              })}

              {saleClothing.map(function (it) {
                const id = it.id
                return (
                  <PastOrderItemPhone
                    isMerchantOrder={isMerchantOrder}
                    id={id}
                    orderid={orderid}
                    delivered={delivered}
                    is={'saleClothing'}
                  />
                )
              })}
              {accessory.map(function (it) {
                const id = it.id
                return (
                  <PastOrderItemPhone
                    isMerchantOrder={isMerchantOrder}
                    id={id}
                    orderid={orderid}
                    delivered={delivered}
                    is={'accessory'}
                  />
                )
              })}
            </Grid.Column>
          )}

          {mobile && rentOrBuy !== 'buy' && returned === true && (
            <Grid.Column width={16}>
              {clothing.map(function (it) {
                const id = it.id
                return (
                  <PastOrderItemRentingPhone
                    isMerchantOrder={isMerchantOrder}
                    id={id}
                    orderid={orderid}
                    returned={returned}
                    delivered={delivered}
                    is={'clothing'}
                  />
                )
              })}

              {saleClothing.map(function (it) {
                const id = it.id
                return (
                  <PastOrderItemRentingPhone
                    isMerchantOrder={isMerchantOrder}
                    id={id}
                    orderid={orderid}
                    returned={returned}
                    delivered={delivered}
                    is={'saleClothing'}
                  />
                )
              })}
              {accessory.map(function (it) {
                const id = it.id
                return (
                  <PastOrderItemRentingPhone
                    isMerchantOrder={isMerchantOrder}
                    id={id}
                    orderid={orderid}
                    returned={returned}
                    delivered={delivered}
                    is={'accessory'}
                  />
                )
              })}
            </Grid.Column>
          )}
        </Grid.Row>
      </>
    )
  }
}

export default withRouter(PastOrder)
