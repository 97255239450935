import React from 'react'
import axios from 'axios'
import {
  Grid,
  Icon,
  Divider,
  Image,
  Segment,
  Container,
  Button,
  Header
} from 'semantic-ui-react'

import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'
// import Slider from "react-slick";
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

class ConfirmRent extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      cartItem: [],
      Item: '',
      products: [],
      accessoryBag: '',
      buyProducts: [],
      buyCartInfo: '',
      rentProducts: [],
      rentCartInfo: '',
      identifier: '',
      password: '',
      buyEmpty: true,
      rentEmpty: true,
      loading: true,
      userId: null,
      extraFee: 0,
      membership: '',
      payment: '',
      address: ''
    }
    this.getAccessoriesBag = this.getAccessoriesBag.bind(this)

    this.onHandleSubmit = this.onHandleSubmit.bind(this)
    this.getProductInBuyCart = this.getProductInBuyCart.bind(this)
  }

  componentDidMount () {
    const identifier = localStorage.getItem('identifier')
    const token = localStorage.getItem('token')
    const log = localStorage.getItem('login')
    const self = this
    const loading = self.state.loading
    const orderId = localStorage.getItem('orderId')

    window.setTimeout(() => {
      if (loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 1000)

    if (log === 'false') {
      self.setState({ login: false })
    } else {
      self.setState({ login: true })

      axios('https://sheyou-backend.herokuapp.com/users/me', {
        method: 'get',
        headers: {
          Authorization: 'Bearer ' + token
        }
      }).then(function (res) {
        if (res.status === 200) {
          const userInfo = [res.data]
   
          console.log(userInfo)
          if (userInfo[0].bag === null) {
            userInfo[0].bag = []
          }
          if (userInfo[0].accessoryBag === null) {
            userInfo[0].accessoryBag = []
          }
          self.setState({
            userInfo: userInfo,
            userID: userInfo[0].id,
            cart: userInfo[0].bag,
            accessoryBag: userInfo[0].accessoryBag,
            membership: userInfo[0].membership
          })
          self.getProductInBuyCart()
          self.getAccessoriesBag()
        } else {
          alert('Something went wrong, maybe log in again :(')
          localStorage.setItem('login', 'false')
        }
      })
    }

    axios('https://sheyou-backend.herokuapp.com/orders/' + orderId, {
      method: 'get',
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).then(function (res) {
      if (res.status === 200) {
        const order = res.data.orders
        console.log(order)
        self.setState({
          address: order.info,
          extraFee: order.data.extraFee,
          payment: order.payment,
          membership: order.data.membership
        })
      }
    })

    self.setState({
      identifier: identifier,
      token: token
    })
  }

  getProductInBuyCart () {
    const self = this
    // const cartIt=localStorage.getItem('buyItem')

    // const parsedCart = JSON.parse(cartIt)

    // if(cartIt===[]||cartIt===null){
    //     self.setState({buyEmpty:true})
    // }else{self.setState({buyEmpty:false})
    const userID = self.state.userID
    const token = self.state.token

    const cartItems = self.state.cart

    self.setState({ buyProducts: [] })

    if (cartItems.length === undefined) {
      alert('something went wrong :(')
      axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
        method: 'put',
        data: {
          cart: []
        },
        headers: {
          Authorization: 'Bearer ' + token
        }
      }).then(function (res) {
        console.log(res)
        if (res.status === 200) {
          alert('your cart has been cleared sorry')
        } else {
          alert('something went wrong again')
        }
        self.setState({
          loading: false
        })
      })
    } else {
      cartItems.forEach(function (cart) {
        if (cart.type !== 'sale') {
          axios
            .get('https://sheyou-backend.herokuapp.com/products?id=' + cart.id)
            .then(function (res) {
              if (res.status === 200) {
                const productSelected = res.data[0]

                if (self.state.buyProducts === '') {
                  var product = productSelected

                  self.setState({ buyCartInfo: cart })

                  product.quantity = self.state.buyCartInfo.number
                  product.colors[0] = self.state.buyCartInfo.colors
                  product.sizes[0] = self.state.buyCartInfo.sizes

                  const stringProduct = [product]
                  self.setState({ buyProducts: stringProduct })
                } else if (self.state.buyProducts !== '') {
                  product = productSelected

                  // parsedCart.filter(function(cartP){
                  // if(cartP.id===cart.id){
                  self.setState({ buyCartInfo: cart })
                  // }})
                  product.quantity = self.state.buyCartInfo.number
                  product.colors[0] = self.state.buyCartInfo.colors
                  product.sizes[0] = self.state.buyCartInfo.sizes

                  const stringProduct = product
                  product = self.state.buyProducts

                  product.push(stringProduct)
                  self.setState({ buyProducts: product })
                }
              } else {
                alert('something went wrong')
                localStorage.setItem('login', 'false')
              }

              self.setState({
                loading: false
              })
            })
        } else if (cart.type === 'sale') {
          axios
            .get(
              'https://sheyou-backend.herokuapp.com/second-hand-products?id=' +
                cart.id
            )
            .then(function (res) {
              if (res.status === 200) {
                const productSelected = res.data[0]

                if (self.state.buyProducts === '') {
                  var product = productSelected

                  self.setState({ buyCartInfo: cart })

                  product.quantity = self.state.buyCartInfo.number
                  product.colors[0] = self.state.buyCartInfo.colors
                  product.sizes[0] = self.state.buyCartInfo.sizes
                  product.type = 'sale'

                  const stringProduct = [product]
                  self.setState({ buyProducts: stringProduct })
                } else if (self.state.buyProducts !== '') {
                  product = productSelected

                  // parsedCart.filter(function(cartP){
                  // if(cartP.id===cart.id){
                  self.setState({ buyCartInfo: cart })
                  // }})
                  product.quantity = self.state.buyCartInfo.number
                  product.colors[0] = self.state.buyCartInfo.colors
                  product.sizes[0] = self.state.buyCartInfo.sizes
                  product.type = 'sale'

                  const stringProduct = product
                  product = self.state.buyProducts

                  product.push(stringProduct)
                  self.setState({ buyProducts: product })
                }
              } else {
                alert('something went wrong')
                localStorage.setItem('login', 'false')
              }
            })
        }
      })
    }
  }

  getAccessoriesBag () {
    const self = this
    const userID = self.state.userID
    const token = self.state.token

    const cartItems = self.state.accessoryBag

    self.setState({ buyProducts: [] })

    if (cartItems.length === undefined) {
      alert('something went wrong :(')
      axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
        method: 'put',
        data: {
          accessoryCart: []
        },
        headers: {
          Authorization: 'Bearer ' + token
        }
      }).then(function (res) {
        console.log(res)
        if (res.status === 200) {
          alert('your accessories has been cleared sorry')
        } else {
          alert('something went wrong again')
        }
        self.setState({
          loading: false
        })
      })
    } else {
      cartItems.forEach(function (cart) {
        if (cart.type !== 'sale') {
          axios
            .get(
              'https://sheyou-backend.herokuapp.com/accessaries?id=' + cart.id
            )
            .then(function (res) {
              if (res.status === 200) {
                const productSelected = res.data[0]

                if (self.state.buyProducts === '') {
                  var product = productSelected

                  self.setState({ buyCartInfo: cart })

                  product.quantity = self.state.buyCartInfo.number
                  product.colors[0] = self.state.buyCartInfo.colors
                  product.sizes[0] = self.state.buyCartInfo.sizes

                  const stringProduct = [product]
                  self.setState({ buyProducts: stringProduct })
                } else if (self.state.buyProducts !== '') {
                  product = productSelected

                  // parsedCart.filter(function(cartP){
                  // if(cartP.id===cart.id){
                  self.setState({ buyCartInfo: cart })
                  // }})
                  product.quantity = self.state.buyCartInfo.number
                  product.colors[0] = self.state.buyCartInfo.colors
                  product.sizes[0] = self.state.buyCartInfo.sizes

                  const stringProduct = product
                  product = self.state.buyProducts

                  product.push(stringProduct)
                  self.setState({ buyProducts: product })
                }
              } else {
                alert('something went wrong')
                localStorage.setItem('login', 'false')
              }

              self.setState({
                loading: false
              })
            })
        } else if (cart.type === 'sale') {
          axios
            .get(
              'https://sheyou-backend.herokuapp.com/second-hand-products?id=' +
                cart.id
            )
            .then(function (res) {
              if (res.status === 200) {
                const productSelected = res.data[0]

                if (self.state.buyProducts === '') {
                  var product = productSelected

                  self.setState({ buyCartInfo: cart })

                  product.quantity = self.state.buyCartInfo.number
                  product.colors[0] = self.state.buyCartInfo.colors
                  product.sizes[0] = self.state.buyCartInfo.sizes

                  const stringProduct = [product]
                  self.setState({ buyProducts: stringProduct })
                } else if (self.state.buyProducts !== '') {
                  product = productSelected

                  // parsedCart.filter(function(cartP){
                  // if(cartP.id===cart.id){
                  self.setState({ buyCartInfo: cart })
                  // }})
                  product.quantity = self.state.buyCartInfo.number
                  product.colors[0] = self.state.buyCartInfo.colors
                  product.sizes[0] = self.state.buyCartInfo.sizes

                  const stringProduct = product
                  product = self.state.buyProducts

                  product.push(stringProduct)
                  self.setState({ buyProducts: product })
                }
              } else {
                alert('something went wrong')
                localStorage.setItem('login', 'false')
              }
            })
        }
      })
    }
  }

  onHandleSubmit (event) {
    const self = this

    self.props.history.push('/mypage')
  }

  render () {
    const self = this
    var fee = 0
    const membership = this.state.membership
    const extraFee = this.state.extraFee
    if (membership.isAMember === true) {
      fee = extraFee
    } else {
      fee = extraFee + 165
    }
    var order = self.state.address
    const address = order.detailAddress
    const area = order.city + ' ,' + order.country

    const payment = self.state.payment
    const products = self.state.buyProducts
    console.log(self.state)
    const loading = self.state.loading
    var images = []

    return (
      <div>
        {/* <Nav /> */}

        {loading ? (
          <Grid>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>

            <Grid.Row colums={2}>
              <Grid.Column width={7}></Grid.Column>
              <Grid.Column width={6}>
                <Header size='huge'>
                  <Icon loading name='asterisk' size='massive' color='yellow' />
                  Loading...
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
          </Grid>
        ) : (
          <>
            <div className='bg_image'>
              <br />
              <br />
              <Container>
                <Segment>
                  <Grid container>
                    <Grid.Row columns={5} style={{ marginTop: 20 }}>
                      <Grid.Column width={1}></Grid.Column>
                      <Grid.Column width={2}>
                        <Icon size='big' name='dot circle' color='grey' />
                        <p textAlign='left'>Adress</p>
                      </Grid.Column>

                      <Grid.Column width={4}>
                        <Divider />
                      </Grid.Column>

                      <Grid.Column width={2}>
                        <Icon size='big' name='dot circle' color='grey' />
                        <p textAlign='center'>Payment</p>
                      </Grid.Column>

                      <Grid.Column width={4}>
                        <Divider />
                      </Grid.Column>

                      <Grid.Column width={2}>
                        <Icon size='big' name='dot circle' color='orange' />
                        <p textAlign='center'>Confirm</p>
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row centered>
                      <Grid.Column width={6}>
                        {products.length > 0 &&
                          products.forEach(function (product) {
                            images.push({
                              original:
                                'https://sheyou-backend.herokuapp.com/' +
                                product.picture[0].formats.small.url,
                              thumbnail:
                                'https://sheyou-backend.herokuapp.com/' +
                                product.picture[0].formats.thumbnail.url
                            })
                            console.log(images)
                          })}
                        <ImageGallery
                          items={images}
                          showFullscreenButton={false}
                          showPlayButton={false}
                          showBullets={false}
                          showIndex={true}
                          autoPlay={true}
                        />
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>

                    <Grid.Row
                      columns={3}
                      style={{
                        marginBottom: 0,
                        marginTop: 2,
                        paddingBottom: 0
                      }}
                    >
                      <Grid.Column width={2}></Grid.Column>

                      <Grid.Column width={11}>
                        <Header
                          as='h5'
                          textAlign='left'
                          style={{ marginTop: 1 }}
                        >
                          Address
                        </Header>
                      </Grid.Column>

                      <Grid.Column width={2}>
                        <Icon name='check circle' size='large' color='orange' />
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns={3}>
                      <Grid.Column width={2}></Grid.Column>

                      <Grid.Column width={10}>
                        <Header as='h5' textAlign='left' color='grey'>
                          {address}
                        </Header>
                      </Grid.Column>

                      <Grid.Column width={2}>
                        <Header as='h5' textAlign='right' color='grey'>
                          {' '}
                          {area}
                        </Header>
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Column width={2}></Grid.Column>

                      <Grid.Column width={11}>
                        <Link to='/checkout'>
                          <Header as='h5' textAlign='left' color='orange'>
                            Change
                          </Header>
                        </Link>
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row
                      columns={3}
                      style={{
                        marginBottom: 0,
                        marginTop: 2,
                        paddingBottom: 0
                      }}
                    >
                      <Grid.Column width={2}></Grid.Column>

                      <Grid.Column width={11}>
                        <Header
                          as='h5'
                          textAlign='left'
                          style={{ marginTop: 1 }}
                        >
                          Payment
                        </Header>
                      </Grid.Column>

                      <Grid.Column width={2}>
                        <Icon name='check circle' size='large' color='orange' />
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns={3}>
                      <Grid.Column width={2}></Grid.Column>

                      <Grid.Column width={10}>
                        {payment === 'Alipay' && (
                          <Image
                            size='tiny'
                            src='https://i.loli.net/2021/01/26/Pxnb4RaWhMEvBQJ.png'
                          />
                        )}
                        {payment === 'WechatPay' && (
                          <Image
                            size='tiny'
                            src='https://i.loli.net/2021/01/26/ZYmWgHk7JVc94zE.png'
                          />
                        )}
                      </Grid.Column>

                      <Grid.Column width={2}>
                        <Header as='h5' textAlign='right' color='grey'>
                          {' '}
                          {fee} RMB
                        </Header>
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Column width={2}></Grid.Column>

                      <Grid.Column width={11}>
                        <Link to='/payment'>
                          <Header as='h5' textAlign='left' color='orange'>
                            Change
                          </Header>
                        </Link>
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row centered>
                      <Button
                        color='teal'
                        style={{ paddingLeft: 40, paddingRight: 40 }}
                        onClick={this.onHandleSubmit}
                      >
                        <Icon name='check' />
                        Okay
                      </Button>
                    </Grid.Row>
                  </Grid>
                </Segment>
              </Container>
              <br />
              <br />
            </div>
          </>
        )}
      </div>
    )
  }
}

export default withRouter(ConfirmRent)
