import React from 'react'
import { Grid, Icon, Header } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
class PaypalOnboard extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  componentDidMount () {}

  render () {
    const loading = this.state.loading

    return (
      <div>
        {/* <Nav /> */}

        {loading ? (
          <Grid>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>

            <Grid.Row colums={2}>
              <Grid.Column width={7}></Grid.Column>
              <Grid.Column width={6}>
                <Header size='huge'>
                  <Icon loading name='asterisk' size='massive' color='yellow' />
                  Loading...
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
          </Grid>
        ) : (
          <>
            <div className='bg_image'>
              <Grid style={{ position: 'absolute', top: '50%', width:'100%' }}>
                <Grid.Row centered>
                  <Icon name='frown outline' size='huge' color='yellow' />
                </Grid.Row>

                <Grid.Row centered>
                  <Header textAlign='center' size='huge'>
                    Sorry
                    <Header.Subheader>
                      You have to renew the action
                    </Header.Subheader>
                  </Header>
                </Grid.Row>
              </Grid>
            </div>
          </>
        )}
      </div>
    )
  }
}

export default withRouter(PaypalOnboard)
