import React from 'react'
import { Route, Switch } from 'react-router-dom'
import HomePage from '../component/homePage/HomePage'
import ClothingPage from '../component/clothingPage/ClothingPage'
import AboutPage from '../component/aboutPage/AboutPage'
import LogInPage from '../component/RedirectPages/LogInPage'
import SignupPage from '../component/RedirectPages/SignupPage'
// import MemberBox from '../component/shoppingpages/MemberBox'
import ProductPage from '../component/clothingPage/ProductPage'
import MyPage from '../component/mypage/MyPage'
import CheckOut from '../component/shoppingpages/CheckOutPage'
import WhatIsClothesSharing from '../component/aboutPage/WhatIsClothesSharing'
// import Payment from '../component/shoppingpages/Payment'
import Confirm from '../component/shoppingpages/Confirm'
import RentingConfirm from '../component/shoppingpages/RentingConfirm'
import CheckOutRentingPage from '../component/shoppingpages/CheckOutRentingPage'
import Comment from '../component/pastOrders/Comment'
import Delivery from '../component/delivery/DeliveryPage'
import ForgetPassword from '../component/RedirectPages/ForgetPassword'
import ResetPassword from '../component/RedirectPages/ResetPassword'
import CartPage from '../component/shoppingpages/CartPage'
import Sale from '../component/SalePage/SalePage'
import SaleProduct from '../component/SalePage/SaleProduct'
import Heart from '../component/heartPage/Heart'
import SendSMSCode from '../component/utils/SendSMSCode'
import AccessoryPage from '../component/accessoryPage/AccessoryPage'
import AccessoryItem from '../component/accessoryPage/AccessoryItem'
import MerchantsLog from '../component/merchants/MerchantsLog'
import SignUpSuccess from '../component/RedirectPages/SignUpSuccess'
import ChangeEmail from '../component/changeEmail/ChangeEmail'
import VerifyEmail from '../component/changeEmail/VerifyEmail'
import ConfirmRent from '../component/shoppingpages/ConfirmRent'
import KeepPurchase from '../component/RedirectPages/KeepPurchase'
import Return from '../component/returnPage/ReturnRenting'
import Plans from '../component/plans/Plans'
import Pay from '../component/plans/Pay'
import DeliveryPageReturn from '../component/delivery/DeliveryPageReturn'
import EmailConfirmation from '../component/RedirectPages/EmailConfirmation'
import Alipay from '../component/payment/Alipay'
import WechatPay from '../component/payment/WechatPay'
import LoggingPage from '../component/nav/LoggingPage'
import Paypal from '../component/payment/Paypal'
import PhoneEmulator from '../component/PhoneEmulator'
import PaymentResult from '../component/payment/Result'
import LoggingPageMerchant from '../component/nav/LoggingPageMerchant'
import MerchantOpenShop from '../component/merchants/MerchantOpenShop'
import ContactPage from '../component/Contact/ContactPage'
import MerchantPage from '../component/merchants/merchantPage/MerchantPage'
import MerchantVerify from '../component/merchants/merchantPage/merchantVerify/MerchantVerify'
import NewClothingPreview from '../component/merchants/merchantPage/NewClothingPreview'
import NewAccessoryPreview from '../component/merchants/merchantPage/NewAccessoryPreview'
import NewSalePreview from '../component/merchants/merchantPage/NewSalePreview'
// import Stripe from '../component/payment/Stripe'
// import SendSMSCode from '../component/sendSMSCode/SendSMSCode'
import LoginFirst from '../component/RedirectPages/LoginFirst'
import Message from '../component/message/Message'
import PaypalOnboard from '../component/payment/PaypalOnboard'
import PaypalActionRenew from '../component/payment/PaypalActionRenew'
import OrderPickUp from '../component/merchants/merchantPage/merchant logistics/OrderPickUp'
import OrderPickUpConfirm from '../component/merchants/merchantPage/merchant logistics/OrderPickUpConfirm'
import OrderPickUpVerify from '../component/merchants/merchantPage/merchant logistics/OrderPickUpVerify'
import OrderPickUpSchedule from '../component/merchants/merchantPage/merchant logistics/OrderPickUpSchedule'
import OpenPersonalShop from '../component/merchants/OpenPersonalShop'
import OpenMerchantShop from '../component/merchants/OpenMerchantShop'
import OpenShop from '../component/merchants/OpenShop'
import ScrollToTop from '../ScrollToTop'
import MerchantOnboard from '../component/merchants/stripe/MerchantOnboard'
import PaymentComplete from '../component/payment/stripe/PaymentComplete'

class PcPadVersion extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true
    }
  }
  componentDidMount () {}
  render () {
    const windowWidth = this.props.windWidth

    return (
      <>
        <ScrollToTop />
        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>

          <Route exact path="/onBoardStripe">
            <MerchantOnboard />
          </Route>

          <Route exact path="/contactUs">
            <ContactPage />
          </Route>

          <Route exact path="/testSMS">
            <SendSMSCode />
          </Route>

          <Route exact path="/onBoardPaypal">
            <PaypalOnboard />
          </Route>

          <Route exact path="/onRenewActionPaypal">
            <PaypalActionRenew />
          </Route>

          {/* <Route exact path='/payNow'>
      <Stripe />
    </Route> */}

          {/* <Route exact path='/createDeliveryOrder'>
      <CreateDeliveryOrder />
    </Route> */}

          <Route exact path="/sendSMSCode">
            <SendSMSCode />
          </Route>

          <Route exact path="/openPersonalShop">
            <OpenPersonalShop />
          </Route>

          <Route exact path="/openMerchantShop">
            <OpenMerchantShop />
          </Route>

          <Route exact path="/openShop">
            <OpenShop />
          </Route>

          <Route exact path="/merchantsLog">
            <MerchantsLog />
          </Route>

          <Route exact path="/message">
            <Message />
          </Route>

          <Route exact path="/merchantOrder">
            <MerchantPage activeItem="All" />
          </Route>

          <Route exact path="/orderPickUp">
            <OrderPickUp />
          </Route>

          <Route exact path="/orderPickUp/verify">
            <OrderPickUpVerify />
          </Route>

          <Route exact path="/orderPickUp/confirm">
            <OrderPickUpConfirm />
          </Route>

          <Route exact path="/orderPickUp/schedule">
            <OrderPickUpSchedule />
          </Route>

          <Route exact path="/merchantOrder/ongoing">
            <MerchantPage activeItem="Ongoing" />
          </Route>

          <Route exact path="/merchantOrder/past">
            <MerchantPage activeItem="Past" />
          </Route>

          <Route exact path="/merchantpage">
            <MerchantPage activeItem="Home" />
          </Route>
          <Route exact path="/merchants">
            <MerchantPage activeItem="Home" />
          </Route>

          <Route exact path="/merchantpage/store">
            <MerchantPage reload={true} activeItem="Store" />
          </Route>

          <Route exact path="/merchantpage/products">
            <MerchantPage reload={true} activeItem="products" />
          </Route>

          <Route exact path="/merchantpage/accessaries">
            <MerchantPage reload={true} activeItem="accessaries" />
          </Route>

          <Route exact path="/merchantpage/sale">
            <MerchantPage reload={true} activeItem="sale" />
          </Route>

          <Route exact path="/merchantVerify/address">
            <MerchantVerify reload={true} activeItem="address" />
          </Route>

          <Route exact path="/merchantVerify/phone">
            <MerchantVerify reload={true} activeItem="phone" />
          </Route>

          <Route exact path="/merchantVerify/card">
            <MerchantVerify reload={true} activeItem="card" />
          </Route>

          <Route exact path="/merchantVerify/id">
            <MerchantVerify reload={true} activeItem="id" />
          </Route>

          <Route exact path="/merchantpage/product/newClothing">
            <MerchantPage reload={true} activeItem="newClothing" />
          </Route>

          <Route exact path="/merchantpage/product/editClothing">
            <MerchantPage reload={true} activeItem="Clothing" />
          </Route>

          <Route exact path="/merchantpage/product/editAccessory">
            <MerchantPage reload={true} activeItem="Accessory" />
          </Route>

          <Route exact path="/merchantpage/product/editSale">
            <MerchantPage reload={true} activeItem="Sale" />
          </Route>

          <Route exact path="/merchantpage/product/toSaleClothing">
            <MerchantPage reload={true} activeItem="SaleClothing" />
          </Route>

          <Route exact path="/merchantpage/product/toSaleAccessory">
            <MerchantPage reload={true} activeItem="SaleAccessory" />
          </Route>

          <Route exact path="/merchantpage/product/newAccessory">
            <MerchantPage reload={true} activeItem="newAccessory" />
          </Route>

          <Route exact path="/merchantpage/product/editClothing/preview">
            <NewClothingPreview reload={true} edit={true} />
          </Route>

          <Route exact path="/merchantpage/product/editAccessory/preview">
            <NewAccessoryPreview reload={true} edit={true} />
          </Route>

          {/* <Route exact path='/merchantpage/product/toSaleClothing/preview'>
      <NewClothingPreview reload={true} toSale={true}/>
    </Route> */}

          <Route exact path="/merchantpage/product/toSaleAccessory/preview">
            <NewAccessoryPreview reload={true} toSale={true} />
          </Route>

          <Route exact path="/merchantpage/product/newClothing/preview">
            <NewClothingPreview reload={true} />
          </Route>

          <Route exact path="/merchantpage/product/newAccessory/preview">
            <NewAccessoryPreview reload={true} />
          </Route>

          <Route exact path="/merchantpage/product/editSale/preview">
            <NewSalePreview reload={true} edit={true} />
          </Route>

          <Route exact path="/merchantpage/product/toSaleClothing/preview">
            <NewSalePreview reload={true} new={true} />
          </Route>

          <Route exact path="/merchantpage/product/toSaleAccessory/preview">
            <NewSalePreview reload={true} new={true} />
          </Route>

          <Route exact path="/merchantOpenShop">
            <MerchantOpenShop />
          </Route>

          <Route exact path="/loggingMerchant">
            <LoggingPageMerchant />
          </Route>

          <Route exact path="/result/success">
            <PaymentResult result={true} />
          </Route>

          <Route exact path="/result/fail">
            <PaymentResult result={false} />
          </Route>

          <Route exact path="/phoneemulator">
            <PhoneEmulator />
          </Route>

          <Route exact path="/logging">
            <LoggingPage />
          </Route>

          <Route exact path="/alipay">
            <Alipay />
          </Route>
          <Route exact path="/paypal">
            <Paypal />
          </Route>
          <Route exact path="/wechatpay">
            <WechatPay />
          </Route>
          <Route exact path="/loginfirst">
            <LoginFirst />
          </Route>

          <Route exact path="/pay">
            <Pay />
          </Route>
          <Route exact path="/deliveryreturn">
            <DeliveryPageReturn />
          </Route>
          <Route exact path="/mypage/membership">
            <MyPage activeItem="Membership" />
          </Route>
          <Route exact path="/return">
            <Return />
          </Route>
          <Route exact path="/plans">
            <Plans />
          </Route>
          <Route exact path="/keeppurchase">
            <KeepPurchase />
          </Route>
          <Route exact path="/confirmrent">
            <ConfirmRent />
          </Route>
          <Route exact path="/changeEmail">
            <ChangeEmail />
          </Route>
          <Route exact path="/verifyEmail">
            <VerifyEmail />
          </Route>

          <Route exact path="/accessoryItem">
            <AccessoryItem />
          </Route>

          <Route exact path="/accessory">
            <AccessoryPage />
          </Route>

          <Route exact path="/auth/email-confirmation">
            <SignUpSuccess />
          </Route>

          <Route exact path="/heart/feminine">
            <Heart reload={true} activeItem="Feminine" is="categories" />
          </Route>
          <Route exact path="/heart/masculine">
            <Heart reload={true} activeItem="Masculine" is="categories" />
          </Route>
          <Route exact path="/heart/accessaries">
            <Heart reload={true} activeItem="Accessaries" is="categories" />
          </Route>
          <Route exact path="/heart/average">
            <Heart reload={true} activeItem="Average" is="sizes" />
          </Route>
          <Route exact path="/heart/m">
            <Heart reload={true} activeItem="M" is="sizes" />
          </Route>
          <Route exact path="/heart/s">
            <Heart reload={true} activeItem="S" is="sizes" />
          </Route>
          <Route exact path="/heart/l">
            <Heart reload={true} activeItem="L" is="sizes" />
          </Route>
          <Route exact path="/heart/xxsxs">
            <Heart reload={true} activeItem="XXS/XS" is="sizes" />
          </Route>
          <Route exact path="/heart/xxlxl">
            <Heart reload={true} activeItem="XXL/XL" is="sizes" />
          </Route>

          <Route exact path="/saleproduct">
            <SaleProduct />
          </Route>

          <Route exact path="/mypage">
            <MyPage />
          </Route>

          <Route exact path="/comment">
            <Comment />
          </Route>
          <Route exact path="/delivery">
            <Delivery />
          </Route>

          <Route exact path="/cart">
            <CartPage />
          </Route>
          <Route exact path="/resetpassword">
            <ResetPassword />
          </Route>
          <Route exact path="/forgetpassword">
            <ForgetPassword />
          </Route>
          <Route exact path="/sale">
            <Sale />
          </Route>

          <Route exact path="/rentingconfirm">
            <RentingConfirm />
          </Route>
          <Route exact path="/rentingcheckout">
            <CheckOutRentingPage />
          </Route>
          <Route exact path="/checkout">
            <CheckOut />
          </Route>
          <Route exact path="/heart">
            <Heart activeItem="All" is="categories" />
          </Route>
          {/* <Route exact path='/payment'>
      <Payment />
    </Route> */}
          <Route exact path="/confirm">
            <Confirm />
          </Route>
          <Route exact path="/emailConfirmation">
            <EmailConfirmation />
          </Route>

          <Route exact path="/emailConfirmationMerchant">
            <EmailConfirmation isMerchant={true} />
          </Route>

          <Route exact path="/product">
            <ProductPage />
          </Route>
          <Route exact path="/signin">
            <LogInPage windWidth={windowWidth} />
          </Route>
          <Route exact path="/signin/wrong">
            <LogInPage
              windWidth={windowWidth}
              loginFailure="Wrong username & password combination"
            />
          </Route>

          <Route exact path="/signin/failure">
            <LogInPage windWidth={windowWidth} loginFailure="An error occured" />
          </Route>

          <Route exact path="/signup">
            <SignupPage />
          </Route>

          <Route exact path="/clothing">
            <ClothingPage />
          </Route>
          <Route exact path="/about">
            <AboutPage />
          </Route>
          <Route exact path="/whatisclothessharing">
            <WhatIsClothesSharing />
          </Route>

          <Route exact path="/payment-complete">
            <PaymentComplete />
          </Route>
        </Switch>
      </>
    )
  }
}

export default PcPadVersion
