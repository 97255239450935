import React from 'react'
import { Icon, Header } from 'semantic-ui-react'
import PropTypes from 'prop-types'

const LoadingPage = ({ 
  backgroundImg = "https://res.cloudinary.com/sheyou/image/upload/v1641196507/IMG_0879_072c82fdcf.png ", // Replace with your default background
  minHeight = '100vh'
}) => {
  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: minHeight,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'
      }}
    >
      <Header size='huge' style={{ textAlign: 'center' }}>
        <Icon loading name='asterisk' size='massive' color='yellow' />
        <Header.Content>Loading...</Header.Content>
      </Header>
    </div>
  )
}

LoadingPage.propTypes = {
  backgroundImg: PropTypes.string,
  minHeight: PropTypes.string
}

export default LoadingPage 