import React from 'react'
import axios from 'axios'
import {
    Grid, Image,  Button,Popup,
    Segment, Icon, Placeholder
} from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'



class PastOrderItem extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            productInfo: {},
            loading: true,
            token: '',
            isCommented: false,
            open: false
        }
        this.onHandleClick = this.onHandleClick.bind(this)
        // this.onHandleClickDelivery = this.onHandleClickDelivery.bind(this)
        // this.onHandleDelivered = this.onHandleDelivered.bind(this)
        this.getSaleProductData = this.getSaleProductData.bind(this)
        this.getAccessoryData = this.getAccessoryData.bind(this)


    }
    componentDidMount() {

        const self = this
        const token = localStorage.getItem('token')
        const p = this.props
        const delivered = p.delivered
        const is = p.is

        if (is === 'clothing') {
            this.getProductData()
        } else if (is === 'saleClothing') {
            this.getSaleProductData()
        }
        else {
            this.getAccessoryData()
        }
        self.setState({
            token: token,
            delivered: delivered
        })
        window.setTimeout(() => {
            if (self.state.loading === true) {
                self.setState({
                    loading: false
                })
            }

        }, 500);
    }

    getProductData() {
        const self = this
        const p = this.props
        const productId = p.id
        const orderid = p.orderid

        axios('https://sheyou-backend.herokuapp.com/products?id=' + productId, {
            method: 'get'
        })
            .then(function (res) {
                const theData = res.data[0]
                self.setState({ productInfo: theData })
            })
            .catch(function (err) {
                console.log(err)
            })
        axios('https://sheyou-backend.herokuapp.com/orders/' + orderid, {
            method: 'get'
        })
            .then(function (res) {
                res.data.orders.data.clothing.forEach(function (each) {
                  
                    if (each.id === productId && each.commented === true) {
                        self.setState({
                            isCommented: true,
                            loading: false
                        })
                    }
                })

            })
            .catch(function (err) {
                console.log(err)
            })

    }
    getSaleProductData() {
        const self = this
        const p = this.props
        const productId = p.id
        const orderid = p.orderid

        axios('https://sheyou-backend.herokuapp.com/second-hand-products?id=' + productId, {
            method: 'get'
        })
            .then(function (res) {
                const theData = res.data[0]
                self.setState({ productInfo: theData })
            })
            .catch(function (err) {
                console.log(err)
            })
        axios('https://sheyou-backend.herokuapp.com/orders/' + orderid, {
            method: 'get'
        })
            .then(function (res) {
                res.data.orders.data.clothing.forEach(function (each) {
                    if (each.type === 'sale' && each.id === productId && each.commented === true) {
                        self.setState({
                            isCommented: true
                        })
                    }
                })

            })
            .catch(function (err) {
                console.log(err)
            })

    }
    getAccessoryData() {
        const self = this
        const p = this.props
        const productId = p.id
        const orderid = p.orderid

        axios('https://sheyou-backend.herokuapp.com/accessaries?id=' + productId, {
            method: 'get'
        })
            .then(function (res) {
                const theData = res.data[0]
                self.setState({ productInfo: theData })
            })
            .catch(function (err) {
                console.log(err)
            })
        axios('https://sheyou-backend.herokuapp.com/orders/' + orderid, {
            method: 'get'
        })
            .then(function (res) {
                res.data.orders.data.accessory.forEach(function (each) {
                    if (each.id === productId && each.commented === true) {
                        self.setState({
                            isCommented: true
                        })
                    }
                })

            })
            .catch(function (err) {
                console.log(err)
            })

    }


    // open = () => this.setState({ open: true })
    // close = () => this.setState({ open: false })


    onHandleClick(event, data) {
        event.preventDefault()
        const self = this
        const productId = data.id
        const orderid = data.orderid
        const is = this.props.is
        localStorage.setItem('productToCommentId', productId)
        localStorage.setItem('orderToCommentId', orderid)
        localStorage.setItem('is', is)
        self.props.history.push('/comment')
    }



    render() {
        const self = this
        const productInfo = this.state.productInfo
        const pics = productInfo.picture
        const loading = self.state.loading
        const p = this.props
        const isMerchantOrder=p.isMerchantOrder
        const orderid = p.orderid


        return <>
        {loading ? (
             <Segment>
             <Grid>
                 <Grid.Row columns={3}>
                     <Grid.Column width={1}>

                     </Grid.Column >
                     <Grid.Column width={8}>
                         <Placeholder style={{ height: 100, width: 100 }}>
                             <Placeholder.Image square />
                         </Placeholder>
                     </Grid.Column >
                     <Grid.Column width={6}>
                         <Grid>
                             <Grid.Row >
                                 <Grid.Column >
                                     <Placeholder>
                                         <Placeholder.Header>
                                             <Placeholder.Line length='medium' />
                                         </Placeholder.Header>
                                     </Placeholder>
                                 </Grid.Column>

                                
                             </Grid.Row>
                             <Grid.Row >
                                 <Grid.Column width={8}>
                                     <Placeholder>
                                         <Placeholder.Paragraph>
                                             <Placeholder.Line length='short' />
                                         </Placeholder.Paragraph>
                                     </Placeholder>
                                 </Grid.Column>
                             </Grid.Row>

                             
                             <Grid.Column width={8}>
                                 <Button disabled={loading}>Button</Button>
                             </Grid.Column>
                             <Grid.Row>

                             </Grid.Row>
                         </Grid>
                     </Grid.Column>
                 </Grid.Row>

             </Grid>

         </Segment>
        ) : (<>
            
            <Segment>
                <Grid>
                    <Grid.Row columns={3}>
                        
                        <Grid.Column width={6}>
                            {pics !== undefined && loading === false && <Image size="large"
                                src={productInfo.picture[0].formats.small.url} />}

                        </Grid.Column >
                        <Grid.Column width={9}>
                            <Grid>
                                <Grid.Row style={{paddingBottom:0, marginBottom:0}}>
                                        <p> {productInfo.name}</p>
                                   
                                </Grid.Row>
                                {!isMerchantOrder&&<>
                                    <Grid.Row >
                                   
                                   Price:
                                   {' ' + productInfo.price + ' RMB'}
                              
                           </Grid.Row>

                           <Grid.Row>


                               {self.state.delivered === true && self.state.isCommented !== true &&
                                   <Button color='teal' id={productInfo.id}
                                       orderid={orderid}
                                       onClick={self.onHandleClick}>Comment</Button>
                               }

                               {self.state.delivered === true && self.state.isCommented === true &&
                                   <Popup pinned on='click' content='Thanks for the feedback' trigger={
                                   <Button icon labelPosition='left' >
                                       <Icon name='check' />
                                       Commented
                                   </Button>}/>
                                   
                               }

                           </Grid.Row>
                                </>}
                                
                             
                               
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>

                </Grid>

            </Segment>
        </>)}
       
        
        </>
    }


}

export default withRouter(PastOrderItem)