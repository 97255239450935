import React from 'react'
import axios from 'axios'
import {
  Grid,
  Image,
  Icon,
  Container,
  Button,
  Header,
  Segment,
  Popup,
  Dimmer,
  Menu,
  Label,
  Message
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { Parallax } from 'react-parallax'
import { withRouter } from 'react-router-dom'
// import HandleBuyCartDelete from '../cartFunction/HandleBuyCartDelete'
import CenteredBackground from '../utils/CenteredBackground'
import LoadingPage from '../utils/LoadingPage'

class CartPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      products: '',
      productsRental: '',
      buyProducts: [],
      rentProducts: [],
      buyEmpty: true,
      login: false,
      quantity: 0,
      addCartSuccess: true,
      colors: '',
      buyCartInfo: '',
      rentCartInfo: '',
      productPrice: 0,
      accessoryPrice: 0,
      productRentPrice: 0,
      accessoryRentPrice: 0,
      activeItem: 'Buy',
      accessoryCartInfo: '',

      userInfo: [],
      userID: '',
      cart: [],
      membership: [],
      identifier: '',
      token: '',
      accessory: [],

      open: false,
      open1: false,

      bag: [],
      accessoryBag: [],

      loading: true,
      active: false
    }

    this.onHandleDelete = this.onHandleDelete.bind(this)
    this.onHandleRentDelete = this.onHandleRentDelete.bind(this)
    this.handleItemClick = this.handleItemClick.bind(this)
    this.toClothingPage = this.toClothingPage.bind(this)
    this.handleSelectRentLess = this.handleSelectRentLess.bind(this)
    this.handleSelectLess = this.handleSelectLess.bind(this)
    this.handleSelectRentMore = this.handleSelectRentMore.bind(this)
    this.handleSelectMore = this.handleSelectMore.bind(this)
    this.getAccessories = this.getAccessories.bind(this)
    this.close = this.close.bind(this)
    this.close1 = this.close1.bind(this)
    this.onHandleCheckOut = this.onHandleCheckOut.bind(this)
    this.getProductInAccessoryRentCart = this.getProductInAccessoryRentCart.bind(this)
    this.getProductInBuyCart = this.getProductInBuyCart.bind(this)
    this.getProductInRentCart = this.getProductInRentCart.bind(this)
  }

  componentDidMount () {
    const identifier = localStorage.getItem('identifier')
    const token = localStorage.getItem('token')
    const log = localStorage.getItem('login')
    const self = this

    const loading = self.state.loading

    const ifRent = this.props.location.search.substring(6)

    if (ifRent === 'true') {
      self.setState({
        activeItem: 'Rent'
      })
    }

    window.addEventListener('load', () => {
      if (loading) {
        this.setState({
          loading: false
        })
      }
    })

    if (log === 'false') {
      self.setState({ login: false })
    } else {
      self.setState({ login: true })

      axios('https://sheyou-backend.herokuapp.com/users/me', {
        method: 'get',
        headers: {
          Authorization: 'Bearer ' + token
        }
      }).then(function (res) {
        console.log(res)
        if (res.status === 200) {
          var userInfo = res.data
          if (userInfo.cart === null) {
            userInfo.cart = []
          }
          if (userInfo.accessoryCart === null) {
            userInfo.accessoryCart = []
          }
          if (userInfo.bag === null) {
            userInfo.bag = []
          }
          if (userInfo.accessoryBag === null) {
            userInfo.accessoryBag = []
          }

          self.setState({
            userInfo: userInfo,
            userID: userInfo.id,
            cart: userInfo.cart,
            accessory: userInfo.accessoryCart,
            bag: userInfo.bag,
            accessoryBag: userInfo.accessoryBag,
            membership: userInfo.membership
          }, () => {
            self.getProductInBuyCart();
            self.getAccessories();
            self.getProductInRentCart();
            self.getProductInAccessoryRentCart();
          })
        } else {
          self.setState({
            loading: false,
            addCartSuccess: false
          })
        }
      })
    }

    self.setState({
      identifier: identifier,
      token: token
    })
  }

  componentWillUnmount() {
    window.removeEventListener('load', () => {
      if (this.state.loading) {
        this.setState({
          loading: false
        })
      }
    })
  }

  getProductInBuyCart () {
    const self = this
    var price = 0
    const userID = self.state.userID
    const token = self.state.token
    const cartItems = self.state.cart

    // Handle empty or invalid cart
    if (!cartItems || cartItems.length === 0 || cartItems.length === undefined) {
      // Update cart to empty array in backend
      return axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
        method: 'put',
        data: { cart: [] },
        headers: { Authorization: 'Bearer ' + token }
      }).then(() => {
        self.setState({
          loading: false,
          addCartSuccess: cartItems.length === undefined ? false : true
        })
      })
    }

    // Process valid cart items
    const promises = cartItems.map(cart => {
      const url = cart.type === 'sale' 
        ? 'https://sheyou-backend.herokuapp.com/second-hand-products?id=' + cart.id
        : 'https://sheyou-backend.herokuapp.com/products?id=' + cart.id

      return axios.get(url).then(res => {
        if (res.status === 200) {
          const product = res.data[0]
          product.quantity = cart.number
          product.colors[0] = cart.colors 
          product.sizes[0] = cart.sizes
          if (cart.type === 'sale') {
            product.type = 'sale'
          }
          const itemPrice = product.price * product.quantity
          price += itemPrice
          return product
        }
        throw new Error('Failed to fetch product')
      })
    })

    return Promise.all(promises)
      .then(products => {
        self.setState({
          buyProducts: products,
          productPrice: price,
          loading: false
        })
      })
      .catch(() => {
        self.setState({
          loading: false,
          addCartSuccess: false
        })
      })
  }

  getAccessories () {
    const self = this
    var price = 0
    const userID = self.state.userID 
    const token = self.state.token
    const cartItems = self.state.accessory

    if (!cartItems || cartItems.length === 0 || cartItems.length === undefined) {
      return axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
        method: 'put', 
        data: { accessoryCart: [] },
        headers: { Authorization: 'Bearer ' + token }
      }).then(() => {
        self.setState({
          loading: false,
          addCartSuccess: cartItems.length === undefined ? false : true
        })
      })
    }

    const promises = cartItems.map(cart => {
      const url = cart.type === 'sale'
        ? 'https://sheyou-backend.herokuapp.com/second-hand-products?id=' + cart.id 
        : 'https://sheyou-backend.herokuapp.com/accessaries?id=' + cart.id

      return axios.get(url).then(res => {
        if (res.status === 200) {
          const product = res.data[0]
          product.quantity = cart.number
          product.colors[0] = cart.colors
          product.sizes[0] = cart.sizes
          product.is = 'Accessaries'
          if (cart.type === 'sale') {
            product.type = 'sale'
          }
          const itemPrice = product.price * product.quantity
          price += itemPrice
          return product
        }
        throw new Error('Failed to fetch accessory')
      })
    })

    return Promise.all(promises)
      .then(products => {
        self.setState({
          buyProducts: products,
          accessoryPrice: price,
          loading: false
        }) 
      })
      .catch(() => {
        self.setState({
          loading: false,
          addCartSuccess: false
        })
      })
  }

  getProductInRentCart () {
    const self = this
    var price = 0
    const userID = self.state.userID
    const token = self.state.token
    const cartItems = self.state.bag

    // Handle empty or invalid cart
    if (!cartItems || cartItems.length === 0 || cartItems.length === undefined) {
      return axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
        method: 'put',
        data: { bag: [] },
        headers: { Authorization: 'Bearer ' + token }
      }).then(() => {
        self.setState({
          loading: false,
          addCartSuccess: cartItems.length === undefined ? false : true
        })
      })
    }

    // Process valid cart items
    const promises = cartItems.map(cart => {
      return axios.get('https://sheyou-backend.herokuapp.com/products?id=' + cart.id)
        .then(res => {
          if (res.status === 200) {
            const product = res.data[0]
            product.quantity = cart.number
            product.colors[0] = cart.colors
            product.sizes[0] = cart.sizes
            product.endDate = cart.endDate
            product.startDate = cart.startDate
            product.rentTimeSelect = cart.rentTimeSelect
            const itemPrice = cart.rentTimeSelect ? cart.rentTimeSelect.price * product.quantity : 0
            price += itemPrice
            return product
          }
          throw new Error('Failed to fetch product')
        })
    })

    return Promise.all(promises)
      .then(products => {
        self.setState({
          rentProducts: products,
          productRentPrice: price,
          loading: false
        })
      })
      .catch(() => {
        self.setState({
          loading: false,
          addCartSuccess: false
        })
      })
  }

  getProductInAccessoryRentCart () {
    const self = this
    var price = 0
    const userID = self.state.userID
    const token = self.state.token
    const accessoryItems = self.state.accessoryBag

    // Handle empty or invalid cart
    if (!accessoryItems || accessoryItems.length === 0 || accessoryItems.length === undefined) {
      return axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
        method: 'put',
        data: { accessoryBag: [] },
        headers: { Authorization: 'Bearer ' + token }
      }).then(() => {
        self.setState({
          loading: false,
          addCartSuccess: accessoryItems.length === undefined ? false : true
        })
      })
    }

    // Process valid accessory items
    const promises = accessoryItems.map(accessory => {
      return axios.get('https://sheyou-backend.herokuapp.com/accessaries?id=' + accessory.id)
        .then(res => {
          if (res.status === 200) {
            const product = res.data[0]
            product.quantity = accessory.number
            product.colors[0] = accessory.colors
            product.sizes[0] = accessory.sizes
            product.endDate = accessory.endDate
            product.startDate = accessory.startDate
            product.rentTimeSelect = accessory.rentTimeSelect
            product.is = 'Accessaries'
            const itemPrice = accessory.rentTimeSelect ? accessory.rentTimeSelect.price * product.quantity : 0
            price += itemPrice
            return product
          }
          throw new Error('Failed to fetch accessory')
        })
    })

    return Promise.all(promises)
      .then(products => {
        self.setState({
          rentProducts: [...self.state.rentProducts, ...products],
          productRentPrice: self.state.productRentPrice + price,
          loading: false
        })
      })
      .catch(() => {
        self.setState({
          loading: false,
          addCartSuccess: false
        })
      })
  }

  handleSelectLess (event, data) {
    const self = this
    const userID = self.state.userID
    const token = self.state.token
    const thisId = data.id
    const type = data.type
    const category = data.category

    event.preventDefault()

    // Set loading state first
    self.setState({ loading: true }, () => {
      const cartItems = self.state.cart.filter(item => item.type !== 'sale')
      const saleCartItem = self.state.cart.filter(item => item.type === 'sale')
      const accessoryCartItem = self.state.accessory
      
      // Handle sale items
      if (type === 'sale') {
        const targetItem = saleCartItem.find(item => item.id === thisId)
        if (!targetItem || targetItem.number <= 1) {
          return self.setState({
            open: [data.id, type, category],
            loading: false
          })
        }

        const newCart = [
          ...saleCartItem.map(item => {
            if (item.id === thisId) {
              return {...item, number: item.number - 1}
            }
            return item
          }),
          ...cartItems
        ]
        const newAccessoryCart = [...accessoryCartItem]

        // Update backend
        axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
          method: 'put',
          data: {
            cart: newCart,
            accessoryCart: newAccessoryCart
          },
          headers: { Authorization: 'Bearer ' + token }
        }).then(res => {
          if (res.status === 200) {
            self.setState({
              accessory: newAccessoryCart,
              cart: newCart,
              open: false,
              open1: false
            }, () => {
              // Fetch updated products after state is set
              self.getProductInBuyCart()
              self.getAccessories()
            })
          } else {
            self.setState({
              loading: false,
              addCartSuccess: false
            })
          }
        })
      }

      // Handle regular products
      if (type !== 'sale' && category !== 'Accessaries') {
        const targetItem = cartItems.find(item => item.id === thisId)
        if (!targetItem || targetItem.number <= 1) {
          return self.setState({
            open: [data.id, type, category],
            loading: false
          })
        }

        const newCart = [
          ...cartItems.map(item => {
            if (item.id === thisId) {
              return {...item, number: item.number - 1}
            }
            return item
          }),
          ...saleCartItem
        ]
        const newAccessoryCart = [...accessoryCartItem]

        axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
          method: 'put',
          data: {
            cart: newCart,
            accessoryCart: newAccessoryCart
          },
          headers: { Authorization: 'Bearer ' + token }
        }).then(res => {
          if (res.status === 200) {
            self.setState({
              accessory: newAccessoryCart,
              cart: newCart,
              open: false,
              open1: false
            }, () => {
              self.getProductInBuyCart()
              self.getAccessories()
            })
          } else {
            self.setState({
              loading: false,
              addCartSuccess: false
            })
          }
        })
      }

      // Handle accessories
      if (category === 'Accessaries') {
        const targetItem = accessoryCartItem.find(item => item.id === thisId)
        if (!targetItem || targetItem.number <= 1) {
          return self.setState({
            open: [data.id, type, category],
            loading: false
          })
        }

        const newAccessoryCart = accessoryCartItem.map(item => {
          if (item.id === thisId) {
            return {...item, number: item.number - 1}
          }
          return item
        })
        const newCart = [...cartItems, ...saleCartItem]

        axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
          method: 'put',
          data: {
            cart: newCart,
            accessoryCart: newAccessoryCart
          },
          headers: { Authorization: 'Bearer ' + token }
        }).then(res => {
          if (res.status === 200) {
            self.setState({
              accessory: newAccessoryCart,
              cart: newCart,
              open: false,
              open1: false
            }, () => {
              self.getAccessories()
              self.getProductInBuyCart()
            })
          } else {
            self.setState({
              loading: false,
              addCartSuccess: false
            })
          }
        })
      }
    })
  }

  handleSelectRentLess (event, data) {
    const self = this
    const userID = self.state.userID
    const token = self.state.token
    const thisId = data.id
    const type = data.type
    const category = data.category

    event.preventDefault()

    // Set loading state first
    self.setState({ loading: true }, () => {
      const cartItems = self.state.bag.filter(item => item.type !== 'sale')
      const saleCartItem = self.state.bag.filter(item => item.type === 'sale')
      const accessoryCartItem = self.state.accessoryBag

      // Handle sale items
      if (type === 'sale') {
        const targetItem = saleCartItem.find(item => item.id === thisId)
        if (!targetItem || targetItem.number <= 1) {
          return self.setState({
            open: [data.id, type, category],
            loading: false
          })
        }

        const newCart = [
          ...saleCartItem.map(item => {
            if (item.id === thisId) {
              return {...item, number: item.number - 1}
            }
            return item
          }),
          ...cartItems
        ]
        const newAccessoryCart = [...accessoryCartItem]

        axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
          method: 'put',
          data: {
            bag: newCart,
            accessoryBag: newAccessoryCart
          },
          headers: { Authorization: 'Bearer ' + token }
        }).then(res => {
          if (res.status === 200) {
            self.setState({
              addBagSuccess: true,
              accessoryBag: newAccessoryCart,
              bag: newCart,
              open: false,
              open1: false
            }, () => {
              self.getProductInRentCart()
              self.getProductInAccessoryRentCart()
            })
          } else {
            self.setState({
              loading: false,
              addCartSuccess: false
            })
          }
        })
      }

      // Handle regular products
      if (type !== 'sale' && category !== 'Accessaries') {
        const targetItem = cartItems.find(item => item.id === thisId)
        if (!targetItem || targetItem.number <= 1) {
          return self.setState({
            open: [data.id, type, category],
            loading: false
          })
        }

        const newCart = [
          ...cartItems.map(item => {
            if (item.id === thisId) {
              return {...item, number: item.number - 1}
            }
            return item
          }),
          ...saleCartItem
        ]
        const newAccessoryCart = [...accessoryCartItem]

        axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
          method: 'put',
          data: {
            bag: newCart,
            accessoryBag: newAccessoryCart
          },
          headers: { Authorization: 'Bearer ' + token }
        }).then(res => {
          if (res.status === 200) {
            self.setState({
              addBagSuccess: true,
              accessoryBag: newAccessoryCart,
              bag: newCart,
              open: false,
              open1: false
            }, () => {
              self.getProductInRentCart()
              self.getProductInAccessoryRentCart()
            })
          } else {
            self.setState({
              loading: false,
              addCartSuccess: false
            })
          }
        })
      }

      // Handle accessories
      if (category === 'Accessaries') {
        const targetItem = accessoryCartItem.find(item => item.id === thisId)
        if (!targetItem || targetItem.number <= 1) {
          return self.setState({
            open: [data.id, type, category],
            loading: false
          })
        }

        const newAccessoryCart = accessoryCartItem.map(item => {
          if (item.id === thisId) {
            return {...item, number: item.number - 1}
          }
          return item
        })
        const newCart = [...cartItems, ...saleCartItem]

        axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
          method: 'put',
          data: {
            bag: newCart,
            accessoryBag: newAccessoryCart
          },
          headers: { Authorization: 'Bearer ' + token }
        }).then(res => {
          if (res.status === 200) {
            self.setState({
              addBagSuccess: true,
              accessoryBag: newAccessoryCart,
              bag: newCart,
              open: false,
              open1: false
            }, () => {
              self.getProductInRentCart()
              self.getProductInAccessoryRentCart()
            })
          } else {
            self.setState({
              loading: false,
              addCartSuccess: false
            })
          }
        })
      }
    })
  }

  handleSelectMore(event, data) {
    const self = this
    const userID = self.state.userID
    const token = self.state.token
    const thisId = data.id
    const type = data.type
    const category = data.category

    // Check stock before allowing increase
    const url = type === 'sale' 
      ? 'https://sheyou-backend.herokuapp.com/second-hand-products?id=' + thisId
      : category === 'Accessaries'
        ? 'https://sheyou-backend.herokuapp.com/accessaries?id=' + thisId
        : 'https://sheyou-backend.herokuapp.com/products?id=' + thisId

    axios.get(url)
      .then(res => {
        if (res.status === 200) {
          const stock = res.data[0].stock
          const currentQty = self.getCurrentQuantity(thisId, type, category)

          if (stock === 0) {
            // Item out of stock - should be grayed out
            return
          }

          if (currentQty >= stock) {
            // Show popup for max stock reached
            self.setState({
              open1: [thisId, type, category]
            })
            return
          }

          // If stock available, proceed with existing increase logic
          self.setState({ loading: true }, () => {
            const cartItems = self.state.cart.filter(item => item.type !== 'sale')
            const saleCartItem = self.state.cart.filter(item => item.type === 'sale')
            const accessoryCartItem = self.state.accessory

            // Handle sale items
            if (type === 'sale') {
              const newCart = [
                ...saleCartItem.map(item => {
                  if (item.id === thisId) {
                    return {...item, number: item.number + 1}
                  }
                  return item
                }),
                ...cartItems
              ]
              const newAccessoryCart = [...accessoryCartItem]

              axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
                method: 'put',
                data: {
                  cart: newCart,
                  accessoryCart: newAccessoryCart
                },
                headers: { Authorization: 'Bearer ' + token }
              }).then(res => {
                if (res.status === 200) {
                  self.setState({
                    accessory: newAccessoryCart,
                    cart: newCart,
                    open: false,
                    open1: false
                  }, () => {
                    self.getProductInBuyCart()
                    self.getAccessories()
                  })
                } else {
                  self.setState({
                    loading: false,
                    addCartSuccess: false
                  })
                }
              })
            }

            // Handle regular products
            if (type !== 'sale' && category !== 'Accessaries') {
              const newCart = [
                ...cartItems.map(item => {
                  if (item.id === thisId) {
                    return {...item, number: item.number + 1}
                  }
                  return item
                }),
                ...saleCartItem
              ]
              const newAccessoryCart = [...accessoryCartItem]

              axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
                method: 'put',
                data: {
                  cart: newCart,
                  accessoryCart: newAccessoryCart
                },
                headers: { Authorization: 'Bearer ' + token }
              }).then(res => {
                if (res.status === 200) {
                  self.setState({
                    accessory: newAccessoryCart,
                    cart: newCart,
                    open: false,
                    open1: false
                  }, () => {
                    self.getProductInBuyCart()
                    self.getAccessories()
                  })
                } else {
                  self.setState({
                    loading: false,
                    addCartSuccess: false
                  })
                }
              })
            }

            // Handle accessories
            if (category === 'Accessaries') {
              const newAccessoryCart = accessoryCartItem.map(item => {
                if (item.id === thisId) {
                  return {...item, number: item.number + 1}
                }
                return item
              })
              const newCart = [...cartItems, ...saleCartItem]

              axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
                method: 'put',
                data: {
                  cart: newCart,
                  accessoryCart: newAccessoryCart
                },
                headers: { Authorization: 'Bearer ' + token }
              }).then(res => {
                if (res.status === 200) {
                  self.setState({
                    accessory: newAccessoryCart,
                    cart: newCart,
                    open: false,
                    open1: false
                  }, () => {
                    self.getAccessories()
                    self.getProductInBuyCart()
                  })
                } else {
                  self.setState({
                    loading: false,
                    addCartSuccess: false
                  })
                }
              })
            }
          })
        }
      })
      .catch(() => {
        self.setState({
          loading: false,
          addCartSuccess: false
        })
      })
  }

  handleSelectRentMore(event, data) {
    const self = this
    const userID = self.state.userID
    const token = self.state.token
    const thisId = data.id
    const type = data.type
    const category = data.category

    // Check stock before allowing increase
    const url = type === 'sale' 
      ? 'https://sheyou-backend.herokuapp.com/second-hand-products?id=' + thisId
      : category === 'Accessaries'
        ? 'https://sheyou-backend.herokuapp.com/accessaries?id=' + thisId
        : 'https://sheyou-backend.herokuapp.com/products?id=' + thisId

    axios.get(url)
      .then(res => {
        if (res.status === 200) {
          const stock = res.data[0].stock
          const currentQty = self.getCurrentQuantity(thisId, type, category)

          if (stock === 0) {
            // Item out of stock - should be grayed out
            return
          }

          if (currentQty >= stock) {
            // Show popup for max stock reached
            self.setState({
              open1: [thisId, type, category]
            })
            return
          }

          // If stock available, proceed with existing increase logic
          self.setState({ loading: true }, () => {
            const cartItems = self.state.bag.filter(item => item.type !== 'sale')
            const saleCartItem = self.state.bag.filter(item => item.type === 'sale')
            const accessoryCartItem = self.state.accessoryBag

            // Handle sale items
            if (type === 'sale') {
              const newCart = [
                ...saleCartItem.map(item => {
                  if (item.id === thisId) {
                    return {...item, number: item.number + 1}
                  }
                  return item
                }),
                ...cartItems
              ]
              const newAccessoryCart = [...accessoryCartItem]

              axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
                method: 'put',
                data: {
                  bag: newCart,
                  accessoryBag: newAccessoryCart
                },
                headers: { Authorization: 'Bearer ' + token }
              }).then(res => {
                if (res.status === 200) {
                  self.setState({
                    addBagSuccess: true,
                    accessoryBag: newAccessoryCart,
                    bag: newCart,
                    open: false,
                    open1: false
                  }, () => {
                    self.getProductInRentCart()
                    self.getProductInAccessoryRentCart()
                  })
                } else {
                  self.setState({
                    loading: false,
                    addCartSuccess: false
                  })
                }
              })
            }

            // Handle regular products
            if (type !== 'sale' && category !== 'Accessaries') {
              const newCart = [
                ...cartItems.map(item => {
                  if (item.id === thisId) {
                    return {...item, number: item.number + 1}
                  }
                  return item
                }),
                ...saleCartItem
              ]
              const newAccessoryCart = [...accessoryCartItem]

              axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
                method: 'put',
                data: {
                  bag: newCart,
                  accessoryBag: newAccessoryCart
                },
                headers: { Authorization: 'Bearer ' + token }
              }).then(res => {
                if (res.status === 200) {
                  self.setState({
                    addBagSuccess: true,
                    accessoryBag: newAccessoryCart,
                    bag: newCart,
                    open: false,
                    open1: false
                  }, () => {
                    self.getProductInRentCart()
                    self.getProductInAccessoryRentCart()
                  })
                } else {
                  self.setState({
                    loading: false,
                    addCartSuccess: false
                  })
                }
              })
            }

            // Handle accessories
            if (category === 'Accessaries') {
              const newAccessoryCart = accessoryCartItem.map(item => {
                if (item.id === thisId) {
                  return {...item, number: item.number + 1}
                }
                return item
              })
              const newCart = [...cartItems, ...saleCartItem]

              axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
                method: 'put',
                data: {
                  bag: newCart,
                  accessoryBag: newAccessoryCart
                },
                headers: { Authorization: 'Bearer ' + token }
              }).then(res => {
                if (res.status === 200) {
                  self.setState({
                    addBagSuccess: true,
                    accessoryBag: newAccessoryCart,
                    bag: newCart,
                    open: false,
                    open1: false
                  }, () => {
                    self.getProductInRentCart()
                    self.getProductInAccessoryRentCart()
                  })
                } else {
                  self.setState({
                    loading: false,
                    addCartSuccess: false
                  })
                }
              })
            }
          })
        }
      })
      .catch(() => {
        self.setState({
          loading: false,
          addCartSuccess: false
        })
      })
  }

  // Helper to get current quantity
  getCurrentQuantity(id, type, category) {
    const self = this
      if (category === 'Accessaries') {
      const item = self.state.accessory.find(i => i.id === id)
      return item ? item.number : 0
    }
    
    const items = type === 'sale' 
      ? self.state.cart.filter(i => i.type === 'sale')
      : self.state.cart.filter(i => i.type !== 'sale')
    
    const item = items.find(i => i.id === id)
    return item ? item.number : 0
  }

  onHandleDelete (event, data) {
    const self = this
    const thisId = data.id
    const type = data.type
    const category = data.category

    event.preventDefault()
    
    // Show confirmation popup first
    self.setState({ 
      open: [thisId, type, category]  // This will trigger the confirmation popup
    })
  }

  // Add a new method to handle actual deletion after confirmation
  handleConfirmDelete(data) {
    const self = this
    const thisId = data.id
    const type = data.type 
    const category = data.category

    // Set loading state first  
    self.setState({ loading: true }, () => {
      const cartItems = self.state.cart.filter(item => item.type !== 'sale')
      const saleCartItem = self.state.cart.filter(item => item.type === 'sale')
    const accessoryCartItem = self.state.accessory

      let newCart = []
      let newAccessoryCart = []

      // Build new cart arrays based on type
    if (type === 'sale') {
        newCart = [
          ...saleCartItem.filter(item => item.id !== thisId),
          ...cartItems
        ]
        newAccessoryCart = [...accessoryCartItem]
      } else if (type !== 'sale' && category !== 'Accessaries') {
        newCart = [
          ...cartItems.filter(item => item.id !== thisId),
          ...saleCartItem
        ]
        newAccessoryCart = [...accessoryCartItem]
      } else if (category === 'Accessaries') {
        newAccessoryCart = accessoryCartItem.filter(item => item.id !== thisId)
        newCart = [...cartItems, ...saleCartItem]
    }

    const userID = self.state.userID
    const token = self.state.token

      // Update backend
    axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
      method: 'put',
      data: {
        cart: newCart,
        accessoryCart: newAccessoryCart
      },
      headers: {
        Authorization: 'Bearer ' + token
      }
      })
        .then(res => {
      if (res.status === 200) {
        // Check if this was the last item
        const isLastItem = newCart.length === 0 && newAccessoryCart.length === 0

        self.setState({
          cart: newCart,
          accessory: newAccessoryCart,
          open: false,
          open1: false,
          buyProducts: [], // Clear products when empty
          loading: isLastItem ? false : true // Keep loading if not last item
        }, () => {
          if (isLastItem) {
            // If last item, force a re-render with empty state
            self.setState({
              productsLength: 0
            })
          } else {
            // If not last item, fetch updated products
            Promise.all([
              self.getProductInBuyCart(),
              self.getAccessories()
            ]).catch(() => {
              self.setState({
                loading: false,
                addCartSuccess: false
              })
            })
          }
        })
      } else {
            throw new Error('Failed to update cart')
          }
        })
        .catch(() => {
        self.setState({
          loading: false,
          addCartSuccess: false
        })
        })
    })
  }

  onHandleRentDelete (event, data) {
    const self = this
    const thisId = data.id
    const type = data.type
    const category = data.category

    event.preventDefault()
    
    // Set loading state first  
    self.setState({ loading: true }, () => {
      const cartItems = self.state.bag.filter(item => item.type !== 'sale')
      const saleCartItem = self.state.bag.filter(item => item.type === 'sale')
    const accessoryCartItem = self.state.accessoryBag

      let newCart = []
      let newAccessoryCart = []

      // Build new cart arrays based on type
    if (type === 'sale') {
        newCart = [
          ...saleCartItem.filter(item => item.id !== thisId),
          ...cartItems
        ]
        newAccessoryCart = [...accessoryCartItem]
      } else if (type !== 'sale' && category !== 'Accessaries') {
        newCart = [
          ...cartItems.filter(item => item.id !== thisId),
          ...saleCartItem
        ]
        newAccessoryCart = [...accessoryCartItem]
      } else if (category === 'Accessaries') {
        newAccessoryCart = accessoryCartItem.filter(item => item.id !== thisId)
        newCart = [...cartItems, ...saleCartItem]
    }

    const userID = self.state.userID
    const token = self.state.token

      // Update backend
    axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
      method: 'put',
      data: {
        bag: newCart,
        accessoryBag: newAccessoryCart
      },
      headers: {
        Authorization: 'Bearer ' + token
      }
      })
        .then(res => {
      if (res.status === 200) {
        self.setState({
          addBagSuccess: true,
          bag: newCart,
          accessoryBag: newAccessoryCart,
          open: false,
          open1: false
            }, () => {
              // Check if cart is empty
              if (newCart.length === 0 && newAccessoryCart.length === 0) {
                self.setState({ loading: false })
              } else {
                // Fetch updated products
                Promise.all([
                  self.getProductInRentCart(),
                  self.getProductInAccessoryRentCart()
                ]).catch(() => {
          self.setState({
                    loading: false,
                    addCartSuccess: false
                  })
          })
        }
            })
      } else {
            throw new Error('Failed to update cart')
          }
        })
        .catch(() => {
        self.setState({
          loading: false,
          addCartSuccess: false
        })
        })
    })
  }

  toClothingPage () {
    const self = this
    self.props.history.push('/clothing')
  }

  close () {
    this.setState({
      open: false,
      loading: false
    })
  }

  close1 () {
    this.setState({
      open1: false,
      loading: false
    })
  }

  onHandleCheckOut () {
    const self = this
    const cart = this.state.cart
    const accessories = this.state.accessory
    const price = this.state.accessoryPrice + this.state.productPrice
    const identifier = localStorage.getItem('identifier')
    const token = localStorage.getItem('token')
    const userID = this.state.userID

    // Set loading state
    self.setState({ loading: true }, () => {
      // Prepare order data
      const order = {
        data: {
          clothing: cart,
          accessory: accessories,
          rentOrBuy: 'buy',
          price: price,
          identifier: identifier
        }
      }

      // Get unique merchants
      const merchant = [...new Set([
        ...cart.filter(i => i.merchant !== null).map(i => i.merchant),
        ...accessories.filter(i => i.merchant !== null).map(i => i.merchant)
      ])]

      // First clear the cart
      axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
        method: 'put',
        data: {
          cart: [],
          accessoryCart: []
        },
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
        .then(res => {
          if (res.status !== 200) {
            throw new Error('Failed to clear cart')
          }

          // Then create the order
          return axios('https://sheyou-backend.herokuapp.com/orders', {
            method: 'post',
            data: {
              orders: order,
              users_permissions_user: userID,
              products: cart.filter(each => each.type !== 'sale').map(each => each.id),
              accessories: accessories.map(each => each.id),
              second_hand_products: cart.filter(each => each.type === 'sale').map(each => each.id),
              merchants: merchant
            },
            headers: {
              Authorization: 'Bearer ' + token
            }
          })
        })
        .then(res => {
          if (res.status === 200) {
            localStorage.setItem('orderId', res.data.id)
            self.setState({ loading: false }, () => {
              self.props.history.push('/checkout?orderid=' + res.data.id)
            })
          } else {
            throw new Error('Failed to create order')
          }
        })
        .catch(error => {
          self.setState({
            loading: false,
            addCartSuccess: false
          })
          console.error('Error during checkout:', error)
        })
    })
  }

  handleItemClick (event, data) {
    const self = this
    const name = data.name

    self.setState({ loading: true }, () => {
      if (name === 'Buy') {
        self.props.history.push('/cart')
        self.setState({ 
          activeItem: name,
          loading: false 
        })
      } else {
        self.props.history.push('/cart?rent=true')
        self.setState({ 
          activeItem: name,
          loading: false 
        })
      }
    })
  }

  handleConfirmRentDelete(data) {
    const self = this
    const thisId = data.id
    const type = data.type 
    const category = data.category

    // Set loading state first  
    self.setState({ loading: true }, () => {
      const cartItems = self.state.bag.filter(item => item.type !== 'sale')
      const saleCartItem = self.state.bag.filter(item => item.type === 'sale')
      const accessoryCartItem = self.state.accessoryBag

      let newCart = []
      let newAccessoryCart = []

      // Build new cart arrays based on type
      if (type === 'sale') {
        newCart = [
          ...saleCartItem.filter(item => item.id !== thisId),
          ...cartItems
        ]
        newAccessoryCart = [...accessoryCartItem]
      } else if (type !== 'sale' && category !== 'Accessaries') {
        newCart = [
          ...cartItems.filter(item => item.id !== thisId),
          ...saleCartItem
        ]
        newAccessoryCart = [...accessoryCartItem]
      } else if (category === 'Accessaries') {
        newAccessoryCart = accessoryCartItem.filter(item => item.id !== thisId)
        newCart = [...cartItems, ...saleCartItem]
      }

      const userID = self.state.userID
      const token = self.state.token

      // Update backend
      axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
        method: 'put',
        data: {
          bag: newCart,
          accessoryBag: newAccessoryCart
        },
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
        .then(res => {
          if (res.status === 200) {
            // Check if this was the last item
            const isLastItem = newCart.length === 0 && newAccessoryCart.length === 0

            self.setState({
              bag: newCart,
              accessoryBag: newAccessoryCart,
              open: false,
              open1: false,
              rentProducts: [], // Clear products when empty
              loading: isLastItem ? false : true // Keep loading if not last item
            }, () => {
              if (isLastItem) {
                // If last item, force a re-render with empty state
                self.setState({
                  productsLength: 0
                })
              } else {
                // If not last item, fetch updated products
                Promise.all([
                  self.getProductInRentCart(),
                  self.getProductInAccessoryRentCart()
                ]).catch(() => {
                  self.setState({
                    loading: false,
                    addCartSuccess: false
                  })
                })
              }
            })
          } else {
            throw new Error('Failed to update cart')
          }
        })
        .catch(() => {
          self.setState({
            loading: false,
            addCartSuccess: false
          })
        })
    })
  }

  render () {
    const s = this.state
    console.log(s)
    const self = this
    const login = s.login
    var fee = 0
    var products = s.buyProducts
    var rentProducts = s.rentProducts

    const style = {
      borderRadius: 12,
      opacity: 0.9,
      padding: '1.5em',
      color: 'orange'
    }
    console.log(products)
    var productsLength = products.length + rentProducts.length

    const open = s.open
    const open1 = s.open1
    var loading = s.loading
    // const price = s.productPrice + s.accessoryPrice
    // console.log(price)

    return (
      <div>
        {/* <Nav /> */}

        {loading === true && (
          <LoadingPage 
            backgroundImg="https://res.cloudinary.com/sheyou/image/upload/v1641196507/IMG_0879_072c82fdcf.png "
            minHeight="50vh"
          />
        )}

        {loading === false && login === true && productsLength > 0 && (
          <Grid stackable style={{ paddingTop: 10 }}>
            <Grid.Row>
              <Grid.Column>
                <Container>
                  <br />

                  <Grid
                    divided='vertically'
                    style={{ paddingBottom: 0, marginBottom: 0 }}
                  >
                    <Grid.Row
                      columns={2}
                      style={{ paddingTop: 0, marginTop: 0 }}
                    >
                      <Grid.Column width={16}>
                        <Header as='h2'>
                          <Icon name='shopping cart' />

                          <Header.Content>Shopping Cart</Header.Content>
                        </Header>
                      </Grid.Column>
                      <Grid.Column width={16}>
                        <Menu attached='top' tabular>
                          <Menu.Item
                            name='Buy'
                            active={s.activeItem === 'Buy'}
                            onClick={this.handleItemClick}
                          >
                            Buy
                            <Label color='teal' basic>
                              {products.length}
                            </Label>
                          </Menu.Item>

                          <Menu.Item
                            name='Rent'
                            active={s.activeItem === 'Rent'}
                            onClick={this.handleItemClick}
                          >
                            Rent
                            <Label color='teal' basic>
                              {rentProducts.length}
                            </Label>
                          </Menu.Item>
                        </Menu>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  {!s.addCartSuccess && (
                    <Message negative>
                      <Message.Header>Failure</Message.Header>
                      <p>Something Went Wrong</p>
                    </Message>
                  )}

                  <Segment attached='bottom'>
                    <Grid
                      divided='vertically'
                      style={{ paddingTop: 0, marginTop: 0 }}
                    >
                      {s.activeItem === 'Buy' && products.length === 0 ? (
                        <CenteredBackground
                          backgroundImg="https://i.loli.net/2020/11/25/ZHjYm1wL9runqOz.png"
                          headerText="You Don't Have Anything to Buy"
                          buttonText="Start Shopping"
                          buttonLink="/clothing"
                          minHeight="50vh"  // Take half the page
                        />
                      ) : s.activeItem === 'Rent' && rentProducts.length === 0 ? (
                        <CenteredBackground
                          backgroundImg="https://i.loli.net/2020/11/25/ZHjYm1wL9runqOz.png"
                          headerText="You Don't Have Anything to Rent"
                          buttonText="Start Shopping"
                          buttonLink="/clothing"
                          minHeight="50vh"  // Take half the page
                        />
                      ) : (
                        <>
                          <Grid.Row
                            columns={4}
                            divided
                            style={{ paddingTop: 0, marginTop: 0 }}
                          >
                            <Grid.Column width={8}>PRODUCT</Grid.Column>
                            <Grid.Column width={4}>Quantity</Grid.Column>
                            {s.activeItem === 'Buy' && (
                              <Grid.Column>PRICE</Grid.Column>
                            )}
                            {s.activeItem === 'Rent' && (
                              <Grid.Column>Info</Grid.Column>
                            )}
                          </Grid.Row>

                          {s.activeItem === 'Buy' &&
                            products.length > 0 &&
                            products.map(function (product) {
                              const isOutOfStock = product.stock === '0'
                              return (
                                <Grid.Row columns={9} style={isOutOfStock ? {opacity: 0.5} : {}}>
                                  <Dimmer active={isOutOfStock} inverted>
                                    <Grid centered>
                                      <Grid.Row>
                                        <Header as='h3' color='grey'>
                                          Sorry, this item is out of stock
                                        </Header>
                                      </Grid.Row>
                                      <Grid.Row>
                                        <Icon
                                          id={product.id}
                                          size='large'
                                          type={product.type}
                                          category={product.is}
                                          onClick={self.onHandleDelete}
                                          circular
                                          color='grey'
                                          name='close'
                                        />
                                      </Grid.Row>
                                    </Grid>
                                  </Dimmer>

                                  <Grid.Column width={1}>
                                    {product.stock !== '0' && (
                                      <Icon
                                        id={product.id}
                                        type={product.type}
                                        category={product.is}
                                        onClick={self.onHandleDelete}
                                        circular
                                        color='orange'
                                        name='close'
                                      />
                                    )}
                                  </Grid.Column>
                                  <Grid.Column width={3}>
                                    <Image
                                      size='medium'
                                      src={product.picture[0].url}
                                    />
                                  </Grid.Column>

                                  <Grid.Column width={4}>
                                    <Grid>
                                      <Grid.Row>{product.name}</Grid.Row>
                                      <Grid.Row>
                                        Color: {product.colors[0]}
                                      </Grid.Row>
                                      <Grid.Row>Size: {product.sizes[0]}</Grid.Row>
                                    </Grid>
                                  </Grid.Column>

                                  <Grid.Column width={1}>
                                    <Popup
                                      trigger={
                                        <Icon
                                          id={product.id}
                                          type={product.type}
                                          category={product.is}
                                          onClick={self.handleSelectLess}
                                          name='minus'
                                          color='orange'
                                        />
                                      }
                                      content={
                                        <Grid centered divided>
                                          <Grid.Column textAlign='center'>
                                            <p>Do you want to delete it?</p>
                                            <Button
                                              id={product.id}
                                              color='teal'
                                              type={product.type}
                                              category={product.is}
                                              onClick={() => self.handleConfirmDelete({
                                                id: product.id,
                                                type: product.type,
                                                category: product.is
                                              })}
                                            >
                                              Yes
                                            </Button>
                                            <Button
                                              id={product.id}
                                              color='teal'
                                              basic
                                              onClick={self.close}
                                            >
                                              No
                                            </Button>
                                          </Grid.Column>
                                        </Grid>
                                      }
                                      style={style}
                                      position='top center'
                                      open={
                                        product.id === open[0] &&
                                        product.type === open[1] &&
                                        product.is === open[2]
                                      }
                                    />
                                  </Grid.Column>

                                  {product.stock === '0' && (
                                    <Grid.Column width={1}>0</Grid.Column>
                                  )}
                                  {product.stock !== '0' && (
                                    <Grid.Column width={1}>
                                      {product.quantity}
                                    </Grid.Column>
                                  )}
                                  <Grid.Column width={2}>
                                    <Popup
                                      trigger={
                                        <Icon
                                          id={product.id}
                                          type={product.type}
                                          category={product.is}
                                          onClick={self.handleSelectMore}
                                          name='plus'
                                          color='orange'
                                          disabled={product.quantity >= product.stock}
                                        />
                                      }
                                      content={
                                        <Grid centered divided>
                                          <Grid.Column textAlign='center'>
                                            <p>Not enough stock available</p>
                                            <Button color='teal' onClick={self.close1}>OK</Button>
                                          </Grid.Column>
                                        </Grid>
                                      }
                                      style={style}
                                      position='top center'
                                      open={product.id === open1[0] && product.type === open1[1] && product.is === open1[2]}
                                    />
                                  </Grid.Column>

                                  <Grid.Column>{'$ ' + product.price}</Grid.Column>
                                </Grid.Row>
                              )
                            })}

                          {s.activeItem === 'Buy' &&
                            products.length > 0 &&
                            products.forEach(function (product) {
                              // Only add price if product is in stock
                              if (product.stock !== '0') {
                                fee = fee + product.price * product.quantity
                                localStorage.setItem('fee', fee)
                              }
                            })}

                          {s.activeItem === 'Rent' &&
                            rentProducts.length > 0 &&
                            rentProducts.map(function (product) {
                              const isOutOfStock = product.stock === '0'
                              return (
                                <Grid.Row columns={8} style={isOutOfStock ? {opacity: 0.5} : {}}>
                                  <Dimmer active={isOutOfStock} inverted>
                                    <Grid centered>
                                      <Grid.Row>
                                        <Header as='h3' color='grey'>
                                          Sorry, this item is out of stock
                                        </Header>
                                      </Grid.Row>
                                      <Grid.Row>
                                        <Icon
                                          id={product.id}
                                          size='large'
                                          type={product.type}
                                          category={product.is}
                                          onClick={self.onHandleRentDelete}
                                          circular
                                          color='grey'
                                          name='close'
                                        />
                                      </Grid.Row>
                                    </Grid>
                                  </Dimmer>

                                  <Grid.Column width={1}>
                                    {product.stock !== '0' && (
                                      <Icon
                                        id={product.id}
                                        type={product.type}
                                        category={product.is}
                                        onClick={self.onHandleRentDelete}
                                        circular
                                        color='orange'
                                        name='close'
                                      />
                                    )}
                                  </Grid.Column>
                                  <Grid.Column width={3}>
                                    <Image
                                      size='medium'
                                      src={product.picture[0].url}
                                    />
                                  </Grid.Column>

                                  <Grid.Column width={3}>
                                    <Grid>
                                      <Grid.Row>{product.name}</Grid.Row>
                                      <Grid.Row>Color: {product.colors}</Grid.Row>
                                      <Grid.Row>Size: {product.sizes}</Grid.Row>
                                    </Grid>
                                  </Grid.Column>

                                  <Grid.Column width={1}></Grid.Column>

                                  <Grid.Column width={1}>
                                    <Popup
                                      trigger={
                                        <Icon
                                          id={product.id}
                                          type={product.type}
                                          category={product.is}
                                          onClick={self.handleSelectRentLess}
                                          name='minus'
                                          color='orange'
                                        />
                                      }
                                      content={
                                        <Grid centered divided>
                                          <Grid.Column textAlign='center'>
                                            <p>Do you want to delete it?</p>
                                            <Button
                                              id={product.id}
                                              color='teal'
                                              type={product.type}
                                              category={product.is}
                                              onClick={self.onHandleRentDelete}
                                            >
                                              Yes
                                            </Button>
                                            <Button
                                              id={product.id}
                                              color='teal'
                                              basic
                                              onClick={self.close}
                                            >
                                              No
                                            </Button>
                                          </Grid.Column>
                                        </Grid>
                                      }
                                      style={style}
                                      position='top center'
                                      open={
                                        product.id === open[0] &&
                                        product.type === open[1] &&
                                        product.is === open[2]
                                      }
                                    />
                                  </Grid.Column>

                                  {product.stock === '0' && (
                                    <Grid.Column width={1}>0</Grid.Column>
                                  )}
                                  {product.stock !== '0' && (
                                    <Grid.Column width={1}>
                                      {product.quantity}
                                    </Grid.Column>
                                  )}
                                  <Grid.Column width={2}>
                                    <Popup
                                      trigger={
                                        <Icon
                                          id={product.id}
                                          type={product.type}
                                          category={product.is}
                                          onClick={self.handleSelectRentMore}
                                          name='plus'
                                          color='orange'
                                        />
                                      }
                                      content={
                                        <Grid centered divided>
                                          <Grid.Column textAlign='center'>
                                            <p>
                                              Sorry, not enough in stock, you can't
                                              keep adding
                                            </p>
                                            <Button
                                              id={product.id}
                                              color='teal'
                                              onClick={self.close1}
                                            >
                                              OK
                                            </Button>
                                          </Grid.Column>
                                        </Grid>
                                      }
                                      style={style}
                                      position='top center'
                                      open={
                                        product.id === open1[0] &&
                                        product.type === open1[1] &&
                                        product.is === open1[2]
                                      }
                                    />
                                  </Grid.Column>

                                  <Grid.Column width={4}>
                                  <Grid>
                                    <Grid.Row>
                                      
                                        {product.rentTimeSelect!==undefined&&'Rent Price: $ ' + product.rentTimeSelect.price}
                                        {product.rentTimeSelect===undefined&&'Error! Contact Seller'}

                                    </Grid.Row>
                                    <Grid.Row>
                                      {product.startDate.substring(5, 7) ===
                                        '01' && (
                                        <p textAlign='center'>
                                          {'Start Date  : January ' +
                                            product.startDate.substring(8, 10) +
                                            ', ' +
                                            product.startDate.substring(0, 4)}
                                        </p>
                                      )}
                                      {product.startDate.substring(5, 7) ===
                                        '02' && (
                                        <p textAlign='center'>
                                          {'Start Date  : February ' +
                                            product.startDate.substring(8, 10) +
                                            ', ' +
                                            product.startDate.substring(0, 4)}
                                        </p>
                                      )}
                                      {product.startDate.substring(5, 7) ===
                                        '03' && (
                                        <p textAlign='center'>
                                          {'Start Date  : March ' +
                                            product.startDate.substring(8, 10) +
                                            ', ' +
                                            product.startDate.substring(0, 4)}
                                        </p>
                                      )}
                                      {product.startDate.substring(5, 7) ===
                                        '04' && (
                                        <p textAlign='center'>
                                          {'Start Date  : April ' +
                                            product.startDate.substring(8, 10) +
                                            ', ' +
                                            product.startDate.substring(0, 4)}
                                        </p>
                                      )}
                                      {product.startDate.substring(5, 7) ===
                                        '05' && (
                                        <p textAlign='center'>
                                          {'Start Date  : May ' +
                                            product.startDate.substring(8, 10) +
                                            ', ' +
                                            product.startDate.substring(0, 4)}
                                        </p>
                                      )}
                                      {product.startDate.substring(5, 7) ===
                                        '06' && (
                                        <p textAlign='center'>
                                          {'Start Date  : June ' +
                                            product.startDate.substring(8, 10) +
                                            ', ' +
                                            product.startDate.substring(0, 4)}
                                        </p>
                                      )}
                                      {product.startDate.substring(5, 7) ===
                                        '07' && (
                                        <p textAlign='center'>
                                          {'Start Date  : July ' +
                                            product.startDate.substring(8, 10) +
                                            ', ' +
                                            product.startDate.substring(0, 4)}
                                        </p>
                                      )}
                                      {product.startDate.substring(5, 7) ===
                                        '08' && (
                                        <p textAlign='center'>
                                          {'Start Date  : August ' +
                                            product.startDate.substring(8, 10) +
                                            ', ' +
                                            product.startDate.substring(0, 4)}
                                        </p>
                                      )}
                                      {product.startDate.substring(5, 7) ===
                                        '09' && (
                                        <p
                                          color='orange'
                                          as='h6'
                                          textAlign='center'
                                        >
                                          {'Start Date  : September ' +
                                            product.startDate.substring(8, 10) +
                                            ', ' +
                                            product.startDate.substring(0, 4)}
                                        </p>
                                      )}
                                      {product.startDate.substring(5, 7) ===
                                        '10' && (
                                        <p textAlign='center'>
                                          {'Start Date  : October ' +
                                            product.startDate.substring(8, 10) +
                                            ', ' +
                                            product.startDate.substring(0, 4)}
                                        </p>
                                      )}
                                      {product.startDate.substring(5, 7) ===
                                        '11' && (
                                        <p textAlign='center'>
                                          {'Start Date  : November ' +
                                            product.startDate.substring(8, 10) +
                                            ', ' +
                                            product.startDate.substring(0, 4)}
                                        </p>
                                      )}
                                      {product.startDate.substring(5, 7) ===
                                        '12' && (
                                        <p textAlign='center'>
                                          {'Start Date  : December ' +
                                            product.startDate.substring(8, 10) +
                                            ', ' +
                                            product.startDate.substring(0, 4)}
                                        </p>
                                      )}

                                      {/* Start Date: {product.startDate} */}
                                    </Grid.Row>
                                    <Grid.Row>
                                      {product.endDate.substring(5, 7) === '01' && (
                                        <p textAlign='center'>
                                          {'End Date  : January ' +
                                            product.endDate.substring(8, 10) +
                                            ', ' +
                                            product.endDate.substring(0, 4)}
                                        </p>
                                      )}
                                      {product.endDate.substring(5, 7) === '02' && (
                                        <p textAlign='center'>
                                          {'End Date  : February ' +
                                            product.endDate.substring(8, 10) +
                                            ', ' +
                                            product.endDate.substring(0, 4)}
                                        </p>
                                      )}
                                      {product.endDate.substring(5, 7) === '03' && (
                                        <p textAlign='center'>
                                          {'End Date  : March ' +
                                            product.endDate.substring(8, 10) +
                                            ', ' +
                                            product.endDate.substring(0, 4)}
                                        </p>
                                      )}
                                      {product.endDate.substring(5, 7) === '04' && (
                                        <p textAlign='center'>
                                          {'End Date  : April ' +
                                            product.endDate.substring(8, 10) +
                                            ', ' +
                                            product.endDate.substring(0, 4)}
                                        </p>
                                      )}
                                      {product.endDate.substring(5, 7) === '05' && (
                                        <p textAlign='center'>
                                          {'End Date  : May ' +
                                            product.endDate.substring(8, 10) +
                                            ', ' +
                                            product.endDate.substring(0, 4)}
                                        </p>
                                      )}
                                      {product.endDate.substring(5, 7) === '06' && (
                                        <p textAlign='center'>
                                          {'End Date  : June ' +
                                            product.endDate.substring(8, 10) +
                                            ', ' +
                                            product.endDate.substring(0, 4)}
                                        </p>
                                      )}
                                      {product.endDate.substring(5, 7) === '07' && (
                                        <p textAlign='center'>
                                          {'End Date  : July ' +
                                            product.endDate.substring(8, 10) +
                                            ', ' +
                                            product.endDate.substring(0, 4)}
                                        </p>
                                      )}
                                      {product.endDate.substring(5, 7) === '08' && (
                                        <p textAlign='center'>
                                          {'End Date  : August ' +
                                            product.endDate.substring(8, 10) +
                                            ', ' +
                                            product.endDate.substring(0, 4)}
                                        </p>
                                      )}
                                      {product.endDate.substring(5, 7) === '09' && (
                                        <p
                                          color='orange'
                                          as='h6'
                                          textAlign='center'
                                        >
                                          {'End Date  : September ' +
                                            product.endDate.substring(8, 10) +
                                            ', ' +
                                            product.endDate.substring(0, 4)}
                                        </p>
                                      )}
                                      {product.endDate.substring(5, 7) === '10' && (
                                        <p textAlign='center'>
                                          {'End Date  : October ' +
                                            product.endDate.substring(8, 10) +
                                            ', ' +
                                            product.endDate.substring(0, 4)}
                                        </p>
                                      )}
                                      {product.endDate.substring(5, 7) === '11' && (
                                        <p textAlign='center'>
                                          {'End Date  : November ' +
                                            product.endDate.substring(8, 10) +
                                            ', ' +
                                            product.endDate.substring(0, 4)}
                                        </p>
                                      )}
                                      {product.endDate.substring(5, 7) === '12' && (
                                        <p textAlign='center'>
                                          {'End Date  : December ' +
                                            product.endDate.substring(8, 10) +
                                            ', ' +
                                            product.endDate.substring(0, 4)}
                                        </p>
                                      )}
                                    </Grid.Row>
                                  </Grid>
                                  </Grid.Column>

                                  
                                </Grid.Row>
                              )
                            })}

                          {s.activeItem === 'Rent' &&
                            rentProducts.length > 0 &&
                            rentProducts.forEach(function (product) {
                              // Only add price if product is in stock
                              if (product.stock !== '0') {
                                fee = fee + product.rentTimeSelect.price * product.quantity
                                localStorage.setItem('rentFee', fee)
                              }
                            })}

                          <Grid.Row>
                            <Grid.Column floated='right' width={11}>
                              Subtotal
                            </Grid.Column>

                            <Grid.Column floated='left' width={2}>
                              ${fee}
                            </Grid.Column>

                            <Grid.Column floated='right' width={11}>
                              Shipping Fee
                            </Grid.Column>

                            <Grid.Column floated='left' width={2}>
                              $ 0
                            </Grid.Column>
                          </Grid.Row>

                          <Grid.Row>
                            <Grid.Column floated='right' width={11}>
                              <Header>TOTAL</Header>
                            </Grid.Column>

                            <Grid.Column floated='left' width={2}>
                              <Header>${fee}</Header>
                            </Grid.Column>
                          </Grid.Row>
                        </>
                      )}
                    </Grid>

                    {((s.activeItem === 'Buy' && products.length > 0) || 
                      (s.activeItem === 'Rent' && rentProducts.length > 0)) && (
                      <>
                        <br />
                        <br />
                        <Grid>
                          <Grid.Row columns={3}>
                            <Grid.Column width={5}></Grid.Column>
                            <Grid.Column width={5}>
                              <Button
                                fluid
                                color='teal'
                                content='Check Out'
                                onClick={this.onHandleCheckOut}
                              />
                            </Grid.Column>
                            <Grid.Column width={4}></Grid.Column>
                          </Grid.Row>
                        </Grid>
                        <br />
                        <br />
                      </>
                    )}
                  </Segment>
                </Container>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}

        {loading === false && login === false && (
          <CenteredBackground
            backgroundImg="https://i.loli.net/2020/11/25/ZHjYm1wL9runqOz.png"
            headerText="You Should login first"
            buttonText="Login"
            buttonLink="/signin"
          />
        )}

        {loading === false && login === true && productsLength === 0 && (
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={16}>
                <CenteredBackground
                  backgroundImg="https://i.loli.net/2020/11/25/ZHjYm1wL9runqOz.png"
                  headerText="You Don't Have Anything in Your Cart"
                  buttonText="Start Shopping"
                  buttonLink="/clothing"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </div>
    )
  }
}

export default withRouter(CartPage)
