import React from 'react'
import {
  Form,
  Grid,
  Header,
  Segment,
  Icon,
  Container,
  Message
} from 'semantic-ui-react'
import emailjs from 'emailjs-com'
import Foot from '../foot/Foot'
// import '../../App.css'
import { withRouter } from 'react-router-dom'

class ContactPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      firstname: '',
      phonenumber: '',
      lastname: '',
      handleEmail: '',
      email: '',
      message: '',
      somethingWrong: false,
      sentEmail: false,
      loadingSent: false,
    }
    this.handleFirstName = this.handleFirstName.bind(this)
    this.handleLastName = this.handleLastName.bind(this)
    this.handlePhoneNumber = this.handlePhoneNumber.bind(this)
    this.handleEmail = this.handleEmail.bind(this)
    this.handleMessage = this.handleMessage.bind(this)
    this.sendMessage = this.sendMessage.bind(this)
  }

  componentDidMount () {
    window.scrollTo(0, 0)

    const self = this

    window.onload = event => {
      self.setState({
        loading: false
      })
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 500)
  }

  handleFirstName (event) {
    this.setState({
      firstname: event.target.value
    })
  }

  handleLastName (event) {
    this.setState({
      lastname: event.target.value
    })
  }

  handlePhoneNumber (event) {
    this.setState({
      phonenumber: event.target.value
    })
  }

  handleEmail (event) {
    this.setState({
      email: event.target.value
    })
  }

  handleMessage (event) {
    this.setState({
      message: event.target.value
    })
  }

  sendMessage () {
    const self = this
    const firstName = this.state.firstname
    const email = this.state.email
    const message = this.state.message
    const lastName = this.state.lastname
    const phonenumber = this.state.phonenumber
    const verificationCode =
      message + ', with phone number: ' + phonenumber + ', and email: ' + email
    this.setState({
      loadingSent: true,
    }) // Set loading state
    const name = firstName + ' ' + lastName

    emailjs
      .send(
        'service_orld5qb',
        'template_l5xa2ig',
        {
          email: 'susanna.xinshu@gmail.com',
          name: name,
          verificationCode: verificationCode,
          reply_to: 'susanna.xinshu@gmail.com'
        },
        'user_4rMSnDYBigUutDlR5RSib'
      )
      .then(function (response) {
        self.setState({
          loadingSent: false,
          sentEmail: true,
          somethingWrong: false,
        })
        console.log('SUCCESS!', response.status, response.text)
        console.log(self.state)
      })
      .catch(function (error) {
        self.setState({
          somethingWrong: 'FAILED...' + error.text,
          loadingSent: false,
          sentEmail: false,
        })
      })
  }

  render () {
    const { loading, loadingSent, sentEmail, somethingWrong } =
      this.state
    console.log('Current state in render:', this.state)

    return (
      <div>
        {/* <Nav /> */}

        {loading ? (
          <Grid>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>

            <Grid.Row colums={2}>
              <Grid.Column width={7}></Grid.Column>
              <Grid.Column width={6}>
                <Header size='huge'>
                  <Icon loading name='asterisk' size='massive' color='yellow' />
                  Loading...
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
          </Grid>
        ) : (
          <>
            <div className='bg_image'>
              <br />
              <br />
              <br />
              <br />
              <Segment raised>
                <Grid>
                  <Grid.Row></Grid.Row>
                  <Grid.Row centered style={{ fontSize: 30 }}>
                    <Header>Contact Us </Header>
                  </Grid.Row>
                </Grid>
                <Grid columns={4} divided>
                  <Grid.Column width={3}></Grid.Column>

                  <Grid.Column width={6}>
                    <Icon
                      name='phone'
                      color='teal'
                      size='big'
                      style={{ position: 'relative', top: 20 }}
                    >
                      <Header
                        as='h4'
                        style={{ position: 'relative', left: -6, top: 5 }}
                        color='teal'
                      >
                        PHONE
                      </Header>
                    </Icon>
                    <Grid style={{ position: 'relative', left: 100, top: -10 }}>
                      <Grid.Row>
                        <Header as='h5' color='grey'>
                          {' (360)202-2983'}
                        </Header>
                      </Grid.Row>
                      <Grid.Row>
                        <Header as='h5' color='grey'>
                          {' +86 17308079166'}
                        </Header>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>

                  <Grid.Column width={6}>
                    <Icon
                      name='mail'
                      color='teal'
                      size='big'
                      style={{ position: 'relative', top: 20 }}
                    >
                      <Header
                        as='h4'
                        style={{ position: 'relative', left: -3, top: 5 }}
                        color='teal'
                      >
                        EMAIL
                      </Header>
                    </Icon>
                    <Grid style={{ position: 'relative', left: 100, top: -10 }}>
                      <Grid.Row>
                        <Header as='h5' color='grey'>
                          {' susanna.xinshu@gmail'}
                        </Header>
                      </Grid.Row>
                      <Grid.Row>
                        <Header as='h5' color='grey'>
                          {' 1181456762@qq.com'}
                        </Header>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                </Grid>

                <br />
                <br />
                <br />

                <Container>
                  <Form>
                    <Form.Group widths='equal'>
                      <Form.Input
                        fluid
                        label='First name'
                        placeholder='First name'
                        onChange={this.handleFirstName}
                      />
                      <Form.Input
                        fluid
                        label='Last name'
                        placeholder='Last name'
                        onChange={this.handleLastName}
                      />
                    </Form.Group>
                    <Form.Group widths='equal'>
                      <Form.Input
                        fluid
                        label='Phone Number'
                        placeholder='Phone Number'
                        onChange={this.handlePhoneNumber}
                      />
                      <Form.Input
                        fluid
                        label='Email'
                        placeholder='Email'
                        onChange={this.handleEmail}
                      />
                    </Form.Group>
                    <Form.TextArea
                      label='Write Your Message'
                      placeholder='Write Your Message'
                      onChange={this.handleMessage}
                    />

                    {somethingWrong && (
                      <Message negative>
                        <Message.Header>Error</Message.Header>
                        <p>{`Something Went Wrong ${somethingWrong}`}</p>
                      </Message>
                    )}

                    {sentEmail && (
                      <Message positive>
                        <Message.Header>Message Sent</Message.Header>
                        <p>
                          Message Received. We Will Reply As Soon As Possible
                        </p>
                      </Message>
                    )}
                    <br />
                    <Grid>
                      <Grid.Row centered>
                        <Form.Button
                          color='orange'
                          centered
                          style={{ paddingLeft: 30, paddingRight: 30 }}
                          onClick={this.sendMessage}
                        >
                          {loadingSent ? 'Sending...' : 'Send Your Message'}
                        </Form.Button>
                      </Grid.Row>
                    </Grid>
                  </Form>
                </Container>

                <br />
                <br />
                <br />
                <br />
              </Segment>

              <br />
              <br />
              <br />
              <br />
            </div>
          </>
        )}
        <Foot />
      </div>
    )
  }
}

export default withRouter(ContactPage)
