import React from 'react'
import {
  Grid,
  Header,
  Button,
  Form,
  Icon,
  Divider,
  Message,
  // StatisticLabel
} from 'semantic-ui-react'

import axios from 'axios'

import { withRouter } from 'react-router-dom'

import { countrySelect } from './CountrySelect'

class ContactAddress extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            log:'',
            token:'',
            identifier:'',
            userInfo: [],
            userID:'',
            merchantInfo: {},

            address1: '',
            address2: '',
            state: '',
            city: '',
            country: '',
            zipCode: '',
            checkbox: false,
            confirm: false,

            saved: false,
            next: false,
            loading: true,
            somethingWrong: false,
            saveLoading:false
        }
        this.getUserInfo = this.getUserInfo.bind(this)
        this.onAddress1 = this.onAddress1.bind(this)
        this.onAddress2 = this.onAddress2.bind(this)
        this.onCity = this.onCity.bind(this)
        this.onCountry = this.onCountry.bind(this)
        this.onstate = this.onstate.bind(this)
        this.onZipCode = this.onZipCode.bind(this)
        this.onConfirmClick = this.onConfirmClick.bind(this)
        this.onCheckBox = this.onCheckBox.bind(this)

        this.handleSaveClick = this.handleSaveClick.bind(this)
        this.handleNextClick = this.handleNextClick.bind(this)


    }

componentDidMount()
{    
    const self = this
    const log = localStorage.getItem('login')
    self.setState({log:log})
    if(log==='true'){
      self.getUserInfo()
    }
    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 700)
  }

  getUserInfo(){
    const self = this
    const identifier = localStorage.getItem('identifier')
    const token = localStorage.getItem('token')

    self.setState({
      identifier: identifier,
      token: token,
      loading: true
    })
    axios('https://sheyou-backend.herokuapp.com/users/me',{
      method: 'get',
      headers: {
        'Authorization': 'Bearer '+token
      }
    })
      .then(function(res){
        console.log(res)
        const userInfo = [res.data]
        if(userInfo === null){
          self.setState({
            userInfo: [],
            userID: userInfo[0].id,
            loading: false
          })
        }else{
          self.setState({
            userInfo: userInfo,
            userID: userInfo[0].id,
            loading: false
          })
        }

        const merchantInfo = userInfo[0].merchantInfo
        console.log(merchantInfo)
        if(merchantInfo===null){
          self.setState({merchantInfo: {}})
        }else{
          if(merchantInfo.address !== null && merchantInfo.address !== undefined){
            self.setState({
              address1: merchantInfo.address.address1,
              address2: merchantInfo.address.address2,
              state: merchantInfo.address.state,
              city: merchantInfo.address.city,
              country: merchantInfo.address.country,
              zipCode: merchantInfo.address.zipcode,
              saved: true
            })
            
          }
          self.setState({merchantInfo: merchantInfo})
        }
        self.onCheckBox()
        
        console.log(userInfo)        

      })

      .catch(function(err){
        self.props.history.push('/loginfirst')
        // console.log(err)
      })

  }

    onAddress1(event){
    this.setState({
        address1: event.target.value,
        saved: false
        })
    this.onCheckBox()
    }

    onAddress2(event){
    this.setState({
        address2: event.target.value,
        saved: false
        })
    }

    onstate(event){
    this.setState({
        state: event.target.value,
        saved: false
        })
    this.onCheckBox()
    }

    onCity(event){
    this.setState({
        city: event.target.value,
        saved: false
        })
    this.onCheckBox()
    }

    onCountry(event, data){
    this.setState({
        country: data.value,
        saved: false
        })
        this.onCheckBox()
    }

    onZipCode(event){
        console.log(event.target)
        this.setState({
            zipCode: event.target.value,
            saved: false
        })
        this.onCheckBox()
    }

    onCheckBox(){
      console.log(this.state)
      const address1Length = this.state.address1.length
      const stateLength = this.state.state.length
      const zipCodeLength = this.state.zipCode.length
      const cityLength = this.state.city.length
      const countryLength = this.state.country.length
      console.log(address1Length, stateLength, zipCodeLength, cityLength, countryLength)
      if(address1Length>=5 && stateLength>=2 && zipCodeLength>=5 && cityLength>=2 && countryLength>=2){
          this.setState({
              checkbox: true
          })
          console.log(this.state.checkbox)
      }else{
          this.setState({
              checkbox: false
          })
          console.log(this.state.checkbox)
      }
    }

    onConfirmClick(event){
        const confirmState = this.state.confirm
        this.setState({
            confirm: !confirmState,
            next: !confirmState
        })
        console.log(this.state.confirm)
        console.log(this.state.next)
    }

    handleSaveClick(){
        const self = this
        const s = self.state
        const userID = this.state.userID
        const token=localStorage.getItem('token')
        console.log(userID, token)
        self.setState({
          saveLoading: true
        })
        const address = {
          address:{
            country: s.country,
            zipcode: s.zipCode,
            state: s.state,
            city: s.city,
            address1: s.address1,
            address2: s.address2
          }
        }

        const merchantInfo = Object.assign(s.merchantInfo, address)
        console.log(merchantInfo)
        axios('https://sheyou-backend.herokuapp.com/users/'+userID,
        {
          method: 'put',
          data: {
            merchantInfo: merchantInfo
          },
          headers: {
            'Authorization': 'Bearer ' + token,
          }

        })
        .then(function(response){
            console.log(response)
            self.setState({
                saved: true,
                saveLoading:false
            })
        })

        .catch(function(err){
            self.setState({
                saved: false,
                somethingWrong: 'something is wrong '+err,
                saveLoading:false
            })
          })
        console.log(s.saved)
    }

    handleNextClick () {
      const self = this
      if(self.state.saved === true){
        // window.location.assign('https://sheyoufashion.com/#' + link)
        self.props.history.push('/merchantVerify/card')
        // window.location.reload(true)
      }else{
        self.setState({
            somethingWrong: 'You have not saved your information, please click the save button before you proceed.'
        })
      }
    }

  render () {
    
    const countries = countrySelect()

    return (
      <>
        {this.state.loading ? (
          <Grid>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>

            <Grid.Row colums={2}>
              <Grid.Column width={7}></Grid.Column>
              <Grid.Column width={6}>
                <Header size='huge'>
                  <Icon loading name='asterisk' size='massive' color='yellow' />
                  Loading...
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
          </Grid>
        ) : (
          <>
            <Grid columns={1}>
              <Grid.Row>
                <Grid.Column width={16}>
                <Header>Provide Your Contact Address</Header>
                    <Divider />
                  <Form style={{position:'relative',left:-70}}>
                  

                    <Form.Group>
                      <Form.Select
                        label='Country'
                        required
                        options={countries}
                        placeholder='Select Country'
                        width={5}
                        value={this.state.country}
                        onChange={this.onCountry}
                        search
                        searchInput={{}}
                      />
                      <Form.Input
                        label='Zip Code'
                        required
                        placeholder='Zip Code'
                        width={5}
                        value={this.state.zipCode}
                        onChange={this.onZipCode}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Input
                        label='State / state'
                        required
                        placeholder='State / state'
                        width={5}
                        value={this.state.state}
                        onChange={this.onstate}
                      />
                      <Form.Input
                        label='City'
                        required
                        placeholder='City'
                        width={5}
                        value={this.state.city}
                        onChange={this.onCity}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Input
                        required
                        label='Address Line 1'
                        placeholder='Address Line 1'
                        style={{ width: 400 }}
                        value={this.state.address1}
                        onChange={this.onAddress1}
                      />

                      <Form.Input
                        label='Address Line 2'
                        placeholder='Address Line 2'
                        style={{ width: 400 }}
                        value={this.state.address2}
                        onChange={this.onAddress2}
                      />

                      
                    </Form.Group>
                    <Form.Checkbox style={{width:300}} disabled={!this.state.checkbox} label='I confirm that my address is correct.' checked={this.state.confirm} onClick={this.onConfirmClick}/>
                    <br />
                  </Form>

                  <Grid.Row centered>
                  {this.state.somethingWrong !== false && (
                      <Message warning>
                        <Message.Header>Somthing Went Wrong</Message.Header>
                        <p>{this.state.somethingWrong}</p>
                      </Message>
                  )}
                  {((this.state.saved === true) && (this.state.saveLoading === false)) && (
                      <Message positive>
                        <Message.Header>Saved</Message.Header>
                        <p>You have saved your contact address, please confirm and then click NEXT</p>
                      </Message>
                  )}
                  </Grid.Row>

                  
                </Grid.Column>
              </Grid.Row>

              <Grid.Row columns={2}>
                <Grid.Column>
                  <Button loading={this.state.saveLoading} fluid onClick={this.handleSaveClick}>SAVE</Button>
                </Grid.Column>

                <Grid.Column>
                  <Button fluid color='orange' disabled={!this.state.next} onClick={this.handleNextClick}>
                      NEXT
                  </Button>
                </Grid.Column>
              
              </Grid.Row>

              
            </Grid>
          </>
        )}
      </>
    )
  }
}

export default withRouter(ContactAddress)
