import React from 'react'
import {
  Grid,
  Button,
  Segment,
  Header,
  Icon
} from 'semantic-ui-react'

import { withRouter } from 'react-router-dom'
// import Nav from '../nav/Nav'

class SignUpSuccessPhone extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
    this.onHandleToSign = this.onHandleToSign.bind(this)
  }

  onHandleToSign (event) {
    const self = this
    self.props.history.push('/signin')
  }

  render () {
    const self = this
    return (
      <div
        className='bg_color_image'
        style={{ height: '100vh', width: '100%' }}
      >
        <Segment
          style={{
            position: 'absolute',
            top: '25%',
            height: '45%',
            width: '100%',
            paddingTop: 20,
            paddingBottom: 20
          }}
        >
          <Grid>
            <Grid.Row></Grid.Row>
            <Grid.Row centered>
              <Icon name='smile outline' color='yellow' size='huge' />
            </Grid.Row>
            <Grid.Row centered columns={3}>
              <Grid.Column width={14}>
              <Header textAlign='center'>
                You have Suceessfully Registered Now You Can Sign In
              </Header>
              </Grid.Column>
              
            </Grid.Row>
            <Grid.Row centered>
              <Button
                style={{ paddingLeft: 20, paddingRight: 20 }}
                color='orange'
                basic
                onClick={self.onHandleToSign}
              >
                Log In
              </Button>
            </Grid.Row>
          </Grid>
        </Segment>
      </div>
    )
  }
}

export default withRouter(SignUpSuccessPhone)
