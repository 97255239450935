import React from 'react'
import {
  Card,
  Image,
  Placeholder,
  Modal,
  Button,
  Header
} from 'semantic-ui-react'
import axios from 'axios'
import { withRouter } from 'react-router-dom'

class MerchantCardTemplate extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      open: false,
      secondOpen: false,
      thirdOpen: false,

      itemId: '',
      itemImg: '',
      loadingDelete: false
    }
    this.onHandleClick = this.onHandleClick.bind(this)
    this.onHandleDelete = this.onHandleDelete.bind(this)
    this.setOpen = this.setOpen.bind(this)
    this.setOpen1 = this.setOpen1.bind(this)
    this.setSecondOpen = this.setSecondOpen.bind(this)
    this.toSale = this.toSale.bind(this)
    this.toEdit = this.toEdit.bind(this)

    this.setThirdOpen = this.setThirdOpen.bind(this)
  }

  toEdit (id) {
    const self = this
    const activeItem = self.state.activeItem
    if (activeItem === 'products') {
      this.props.history.push('/merchantpage/product/editClothing?id=' + id)
    } else if (activeItem === 'accessaries') {
      this.props.history.push('/merchantpage/product/editAccessory?id=' + id)
    } else if (activeItem === 'sale') {
      this.props.history.push('/merchantpage/product/editSale?id=' + id)
    }
  }

  toSale (id) {
    const self = this
    const activeItem = self.state.activeItem
    if (activeItem === 'products') {
      this.props.history.push('/merchantpage/product/toSaleClothing?id=' + id)
    } else if (activeItem === 'accessaries') {
      this.props.history.push('/merchantpage/product/toSaleAccessory?id=' + id)
    }
  }

  setOpen (status) {
    this.setState({
      open: status
    })
  }

  setOpen1 (status, id, img) {
    this.setState({
      open: status
    })

    this.setState({
      itemId: id,
      itemImg: img
    })
  }

  setThirdOpen (status) {
    this.setState({
      open: status,
      thirdOpen: status
    })
    window.location.reload(true)
  }
  setSecondOpen (status) {
    this.setState({
      open: status,
      secondOpen: status
    })
    window.location.reload(true)
  }

  componentDidMount () {
    const self = this
    const p = this.props
    const activeItem = p.activeItem

    self.setState({ activeItem: activeItem })

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 5000)
  }

  onHandleClick (event, data) {
    const self = this
    const activeItem = self.state.activeItem
    // const productId = data.id
    const productId = event.target.id
    event.preventDefault()
    localStorage.setItem('productId', productId)
    if (activeItem === 'products') {
      self.props.history.push('/product?id=' + productId)
    } else if (activeItem === 'accessaries') {
      self.props.history.push('/accessoryItem?id=' + productId)
    } else if (activeItem === 'sale') {
      self.props.history.push('/saleproduct?id=' + productId)
    }
  }

  onHandleDelete () {
    const self = this
    const activeItem = self.state.activeItem
    const productId = self.state.itemId
    // event.preventDefault()
    const token = localStorage.getItem('token')
    self.setState({
      loadingDelete: true
    })

    axios(
      'https://sheyou-backend.herokuapp.com/' + activeItem + '/' + productId,
      {
        method: 'delete',

        headers: {
          Authorization: 'Bearer ' + token
        }
      }
    )
      .then(function (res) {
        console.log(res)

        self.setState({
          secondOpen: true,
          // open:false,
          loadingDelete: false
        })
      })
      .catch(function (err) {
        self.setState({
          thirdOpen: true,
          // open:false,
          loadingDelete: false
        })
      })
  }

  render () {
    const p = this.props
    const self = this
    const products = p.products

    const type = p.location.pathname.substring(14)
    console.log(type)

    const activeItem = self.state.activeItem
    const loading = self.state.loading
    // const log = self.state.log
    // const color=self.state.color
    // const heartList = self.state.heartList

    return (
      <Card.Group centered>
        {products.map(function (product) {
          const keys = product.id.toString()
          return (
            <>
              {product.stock !== '0' && (
                <Card id={product.id} key={keys}>
                  {loading ? (
                    <>
                      <Placeholder key={keys + '_01'}>
                        <Placeholder.Image square key={keys + '_02'} />
                      </Placeholder>

                      <Card.Content key={keys + '_03'}>
                        <Placeholder key={keys + '_04'}>
                          <Placeholder.Paragraph>
                            <Placeholder.Line length='very short' />
                            <Placeholder.Line length='medium' />
                            <Placeholder.Line length='short' />
                          </Placeholder.Paragraph>
                        </Placeholder>
                      </Card.Content>
                    </>
                  ) : (
                    <>
                      <Image
                        id={product.id}
                        key={keys + '_1'}
                        onClick={self.onHandleClick}
                        style={{ height: 300 }}
                        src={product.picture[0].url}
                      />

                      <Card.Content
                        id={product.id}
                        key={keys + '_2'}
                        onClick={self.onHandleClick}
                      >
                        <Card.Header id={product.id} key={keys + '_3'}>
                          {product.name}
                        </Card.Header>

                        {product.description !== null && (
                          <Card.Meta id={product.id} key={keys + '_4'}>
                            {product.description.substring(0, 20) + '...'}
                          </Card.Meta>
                        )}

                        <Card.Description id={product.id} key={keys + '_5'}>
                          {type === 'products' && 'Buy: $' + product.price}
                          {type === 'accessaries' && 'Buy: $' + product.price}
                          {type === 'sale' &&
                          <h5>
                            Buy: $
                            <span style={{textDecoration: 'line-through'}} >{' ' + product.price +' '}</span>
                            {product.newPrice}
                          </h5>
                          }
                        </Card.Description>
                        <Card.Description id={product.id}>
                          {type === 'products'&&product.rentPriceAndTime!==null && 'Rent: '}
                          {type === 'products' &&product.rentPriceAndTime!==null&&product.rentPriceAndTime.length>0&&
                            product.rentPriceAndTime.map(
                              p => '$' + p.price + '/ ' + p.number+p.option + ' | '
                            )}
                          {type === 'accessaries'&&product.rentPriceAndTime!==null && 'Rent: '}
                          {type === 'accessaries' &&product.rentPriceAndTime!==null&&product.rentPriceAndTime.length>0&&
                            product.rentPriceAndTime.map(
                              p => '$' + p.price + '/ ' + p.number+p.option + ' | '
                            )}
                        </Card.Description>
                      </Card.Content>
                      <Card.Content>
                        <div className='ui three buttons'>
                          <Button
                            basic
                            color='green'
                            id={product.id}
                            key={keys + '_7'}
                            onClick={() => self.toEdit(product.id)}
                          >
                            Edit
                          </Button>

                          {activeItem !== 'sale' && (
                            <Button
                              basic
                              color='orange'
                              id={product.id}
                              onClick={() => self.toSale(product.id)}
                            >
                              Sale
                            </Button>
                          )}

                          <Button
                            basic
                            color='red'
                            id={product.id}
                            key={keys + '_8'}
                            img={product.picture[0].url}
                            onClick={() =>
                              self.setOpen1(
                                true,
                                product.id,
                                product.picture[0].url
                              )
                            }
                          >
                            Delete
                          </Button>
                        </div>
                      </Card.Content>
                    </>
                  )}
                </Card>
              )}
            </>
          )
        })}
        <Modal
          onClose={() => self.setOpen(false)}
          onOpen={() => self.setOpen(true)}
          open={self.state.open}
        >
          <Modal.Header>Delete</Modal.Header>
          <Modal.Content image>
            <Image size='medium' src={self.state.itemImg} />
            <Modal.Description>
              <Header>Are you sure you want to delete this product?</Header>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button color='black' onClick={() => self.setOpen(false)}>
              Nope
            </Button>
            <Button
              id={self.state.itemId}
              onClick={self.onHandleDelete}
              loading={self.state.loadingDelete}
              positive
            >
              Yes, I want to delete this product
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal
          onClose={() => self.setSecondOpen(false)}
          onOpen={() => self.setSecondOpen(true)}
          open={self.state.secondOpen}
          size='small'
        >
          <Modal.Header>Done!</Modal.Header>
          <Modal.Content>
            <p>Successfully deleted</p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              icon='check'
              content='All Done'
              onClick={() => self.setSecondOpen(false)}
            />
          </Modal.Actions>
        </Modal>

        <Modal
          onClose={() => self.setThirdOpen(false)}
          onOpen={() => self.setSecondOpen(true)}
          open={self.state.thirdOpen}
          size='small'
        >
          <Modal.Header>Sorry</Modal.Header>
          <Modal.Content>
            <p>Something Went Wrong</p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              icon='delete'
              content='All Done'
              onClick={() => self.setThirdOpen(false)}
            />
          </Modal.Actions>
        </Modal>
      </Card.Group>
    )
  }
}

export default withRouter(MerchantCardTemplate)
