import React from 'react'
import {
  Grid,
  Header,
  Container,
  Placeholder,
  Divider
} from 'semantic-ui-react'
import 'react-chat-elements/dist/main.css'
import {ChatItem } from 'react-chat-elements'
import { orderBy } from 'natural-orderby'
import '../../App.css'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import NavMobilePhone from '../nav/NavMobilePhone'
import LoadingPhone from '../RedirectPages/LoadingPhone'
import LogInFirstPhone from '../RedirectPages/LogInFirstPhone'


class MessageListPhone extends React.Component {
  constructor (props) {
    super(props)
    this.inputText = React.createRef()
    this.state = {
      loading: true,
      sender: '',
      senderName: '',
      receiver: '',
      messages: false,
      receiverInfo: '',
      chatList: [],
      receiverName: '',
      chatUsers: [],
      lastMessages: false,
      messageLoaded: false,
      buttonLoading: false,
      scrollTo: 'bottom',
      scrollLoading: false,
      noMoreChat: false,
      messageLength: 0,
      chatNum: 5
    }
    this.getMessage = this.getMessage.bind(this)
    this.getChatList = this.getChatList.bind(this)
    this.getNewInfo = this.getNewInfo.bind(this)
    this.toMessage = this.toMessage.bind(this)
  }

  getNewInfo (receiver, receiverName, receiverInfo) {
    const self = this

    receiverInfo.updated_at = new Date()
    self.setState({
      receiver: receiver,
      receiverName: receiverName,
      receiverInfo: receiverInfo
    })
    receiver = JSON.stringify(receiver)
    receiverName = JSON.stringify(receiverName)
    receiverInfo.updated_at = new Date()
    receiverInfo = JSON.stringify(receiverInfo)
    localStorage.setItem('receiver', receiver)
    localStorage.setItem('receiverName', receiverName)
    localStorage.setItem('receiverInfo', receiverInfo)

    self.getChatList()
    self.getMessage()
  }

  componentDidMount () {
    window.scrollTo(0, 0)

    const self = this
    var receiver = localStorage.getItem('receiver')
    const p = this.props
    var list = p.list
    self.setState({
      list: list
    })

    window.onload = event => {
      self.setState({
        loading: false
      })
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 500)

    const login = localStorage.getItem('login')
    if (login === 'true') {
      self.getChatList()

      if (
        receiver !== null &&
        receiver !== undefined &&
        receiver !== '' &&
        receiver.length!== 0
      ) {
        self.getMessage()
      }
    }
  }

  getChatList () {
    const self = this
    const token = localStorage.getItem('token')
    var sender = localStorage.getItem('sender')
    console.log(sender)

    if (sender === undefined || sender === null || sender === '') {
      sender = localStorage.getItem('userInfo')
      sender = JSON.parse(sender)
      console.log(sender)
      sender = sender[0].id
      console.log(sender)

    }
    var senderName = localStorage.getItem('senderName')
    if (senderName === undefined || senderName === null || senderName === '') {
      senderName = localStorage.getItem('userInfo')
      senderName = JSON.parse(senderName)
      senderName = senderName[0].username

    }
    var receiver = localStorage.getItem('receiver')
    var receiverInfo = localStorage.getItem('receiverInfo')

    if (
      receiverInfo !== '' &&
      receiverInfo !== undefined &&
      receiverInfo !== null
    ) {
      receiverInfo = JSON.parse(receiverInfo)
      self.setState({
        receiver: receiverInfo.id,
        receiverInfo: receiverInfo,
        receiverName: receiverInfo.username
      })
      var receiverName = receiverInfo.username
      localStorage.setItem('receiver',receiverInfo.id)
      localStorage.setItem('receiverInfo',JSON.stringify(receiverInfo))
      localStorage.setItem('receiverName',receiverName)
    }

    self.setState({
      sender: sender,
      senderName: senderName
    })
    localStorage.setItem('sender',sender)
    localStorage.setItem('senderName',senderName)
    var chatUsers = []

    axios('https://sheyou-backend.herokuapp.com/users/' + sender, {
      method: 'get',
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(function (res) {
        if (res.status === 200) {
          if (res.date.length!== 0) {
            chatUsers = res.data.chatUsers
            console.log(chatUsers)
            chatUsers.forEach(function (e) {
              console.log(e, receiver)
              const id = JSON.stringify(e.id)
              if (id === receiver) {
                e.updated_at = new Date()
                self.setState({
                  receiverName: e.username,
                  receiver: e.id,
                  receiverInfo: e
                })
                localStorage.setItem('receiverName', e.username)
              }
            })

            chatUsers = orderBy(chatUsers, [v => v.updated_at], ['desc'])

            const chatListLength = chatUsers.length
            var lastMessages = []
            var i = 0

            chatUsers.forEach(function (e) {
              axios(
                'https://sheyou-backend.herokuapp.com/messages?messageSender=' +
                  sender +
                  '&messageSender=' +
                  e.id +
                  '&messageReceiver=' +
                  sender +
                  '&messageReceiver=' +
                  e.id +
                  '&_sort=created_at:DESC',
                {
                  method: 'get',
                  headers: {
                    Authorization: 'Bearer ' + token
                  }
                }
              )
                .then(function (res) {
                  if (res.status === 200) {
                    console.log(res)
                    if (e.id === receiver) {
                      self.setState({ messageLength: res.data.length })
                    }
                    lastMessages.push(res.data[0])
                    self.setState({ lastMessages: lastMessages })
                  }
                  i++
                  if (i === chatListLength) {
                    self.setState({
                      messageLoaded: true
                    })
                  }
                })
                .catch(function (err) {
                  i++
                  self.props.history.push('/loginfirst')
                })
            })
            self.setState({
              chatUsers: chatUsers
            })
          } else {
            if (
              receiver !== null &&
              receiver !== undefined &&
              receiver !== '' &&
              receiver.length!== 0
            ) {
              axios('https://sheyou-backend.herokuapp.com/users/' + sender, {
                method: 'put',
                headers: {
                  Authorization: 'Bearer ' + token
                }
              })
                .then(function (e) {
                  self.setState({
                    receiverName: e.data.username,
                    receiver: e.data.id,
                    receiverInfo: e.data
                  })
                  axios(
                    'https://sheyou-backend.herokuapp.com/messages?messageSender=' +
                      sender +
                      '&messageSender=' +
                      e.data.id +
                      '&messageReceiver=' +
                      sender +
                      '&messageReceiver=' +
                      e.data.id,
                    {
                      method: 'get',
                      headers: {
                        Authorization: 'Bearer ' + token
                      }
                    }
                  )
                    .then(function (res) {
                      var lastMessages = []
                      lastMessages.push(res.data[0])
                      if (e.id === receiver) {
                        self.setState({ messageLength: res.data.length })
                      }
                      self.setState({
                        lastMessages: lastMessages,
                        messageLoaded: true
                      })
                    })
                    .catch(function (err) {
                      self.props.history.push('/loginfirst')
                    })
                })
                .catch(function (err) {
                  self.props.history.push('/loginfirst')
                })
            }
          }
        }
      })
      .catch(function (err) {
        self.props.history.push('/loginfirst')
      })
  }

  getMessage (add, newId) {
    const self = this
    const s = this.state
    var sender = localStorage.getItem('sender')
    var receiver = localStorage.getItem('receiver')
    const token = localStorage.getItem('token')
    self.setState({
      noMoreChat: false
    })

    var num = s.chatNum
    if (add !== undefined && newId !== undefined) {
      const id = newId
      num = 5
      var chatUsers = s.chatUsers
      chatUsers.forEach(function (e) {
        if (e.id === id) {
          e.updated_at = new Date()
        }
      })

      chatUsers = orderBy(chatUsers, [v => v.updated_at], ['desc'])
      self.setState({
        chatUsers: chatUsers,
        scrollTo: 'bottom'
      })
    }
    if (add === 10) {
      num = num + add
      self.setState({
        scrollLoading: true
      })
    } else if (add === 'new message') {
      num = num + 1
      self.setState({
        scrollTo: 'bottom',
        buttonLoading: true
      })
    }
    console.log(num)

    axios(
      'https://sheyou-backend.herokuapp.com/messages?_sort=created_at:DESC' +
        '&messageSender=' +
        sender +
        '&messageReceiver=' +
        sender +
        '&messageSender=' +
        receiver +
        '&messageReceiver=' +
        receiver +
        '&_limit=' +
        num,
      {
        method: 'get',
        headers: {
          Authorization: 'Bearer ' + token
        }
      }
    )
      .then(function (res) {
        if (res.status === 200) {
          if (res.data.length < s.chatNum) {
            self.setState({
              noMoreChat: true
            })
          }
          self.setState({
            scrollLoading: false,
            chatNum: num
          })
          var messages = res.data
          messages = orderBy(messages, [v => v.created_at], ['asc'])

          console.log(res.data)
          if (res.date.length!== 0) {
            self.setState({
              messages: messages,
              buttonLoading: false
            })
            if (add === 'new message') {
              self.setState({
                scrollTo: 'bottom'
              })
            } else if (add === 10) {
              self.setState({
                scrollTo: 'original'
              })
            } else if (newId !== undefined) {
              self.setState({
                scrollTo: 'bottom'
              })
            }
          }
        }
      })
      .catch(function (err) {
        self.props.history.push('/loginfirst')
      })
  }

  toMessage (id, name, info) {
    const self = this
    const messageLength = self.state.messageLength

    var messageInfo = {
      add: 'newMessage',
      id: id,
      name: name,
      info: info,
      messageLength: messageLength
    }
    messageInfo = JSON.stringify(messageInfo)
    localStorage.setItem('messageInfo', messageInfo)
    localStorage.setItem('receiver', id)
    localStorage.setItem('receiverName', name)
    self.props.history.push('/message')
  }

  render () {
    const loading = this.state.loading

    const s = this.state
    const self = this
    const windowHeight = window.innerHeight
    const windowWidth = window.windowWidth
    console.log(windowHeight)

    const login = localStorage.getItem('login')

    return (
      <div>
        {/* <Nav /> */}

        {loading ? (
          <LoadingPhone />
        ) : (
          <>
            {login !== 'true' && <LogInFirstPhone />}

            {login === 'true' && (
              <>
                {s.chatUsers.length === 0 && (
                  <Grid>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>

                    <Grid.Row colums={2}>
                      <Grid.Column width={7}></Grid.Column>
                      <Grid.Column width={6}>
                        <Header size='huge'>No Chats Available</Header>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                  </Grid>
                )}

                {s.chatUsers.length!== 0 && (
                  <Container
                    style={{ width: windowWidth, height: windowHeight }}
                  >
                    <Grid>
                      <Grid.Column width={16}>
                        <br />

                        <Grid>
                          <Grid.Row centered style={{ fontSize: 20 }}>
                            <Header color='teal'>Chat </Header>
                          </Grid.Row>
                        </Grid>
                        <Divider />

                        {s.messageLoaded ? (
                          <>
                            {s.chatUsers.map(function (each) {
                              var lastMessage = false
                              var lastTime = false

                              s.lastMessages.forEach(function (e) {
                                if (
                                  e !== undefined &&
                                  (e.messageSender[0].username ===
                                    each.username ||
                                    e.messageReceiver[0].username ===
                                      each.username)
                                ) {
                                  lastMessage = e.messageText

                                  lastTime = new Date(e.created_at)
                                }
                              })
                              return (
                                <ChatItem
                                  alt=''
                                  avatar={
                                    'https://ui-avatars.com/api/?name=' +
                                    each.username +
                                    '&background=random'
                                  }
                                  title={each.username}
                                  subtitle={lastMessage}
                                  date={lastTime}
                                  unread={0}
                                  id={each.id}
                                  onClick={() =>
                                    self.toMessage(each.id, each.name, each)
                                  }
                                />
                              )
                            })}
                          </>
                        ) : (
                          <>
                            {s.chatUsers.map(function (each) {
                              return (
                                <Placeholder>
                                  <Placeholder.Header image>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                  </Placeholder.Header>
                                </Placeholder>
                              )
                            })}
                          </>
                        )}
                      </Grid.Column>
                    </Grid>
                  </Container>
                )}
              </>
            )}
          </>
        )}
        <NavMobilePhone />
      </div>
    )
  }
}

export default withRouter(MessageListPhone)
