import React from 'react'
import PastOrder from './PastOrderPhone'

class PastOrdersPhone extends React.Component {



    render() {
        const p = this.props
        const orders = p.orders
        const activeItem = p.activeItem


        return (orders.map(function (it) {
            console.log(it)
            if (it.canceled !== true) {
                return <PastOrder data={it} orderid={it.id} activeItem={activeItem} 
                delivered={it.delivered} returnInfo={it.orders.returnInfo}/>

            }
        }))



    }
}


PastOrdersPhone.defaultProps = {
    orders: []
}


export default PastOrdersPhone