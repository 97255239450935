import React from 'react'
import IntroSecAnimated from './introSec/occasionAnimated'
import SharingExchange from './introSec/sharingExchange'
import SharingMap from './introSec/sharingMap'
import IntroSec from './introSec/IntroSecVertical'
import SustainSec from './sustainSec/SustainSec'
import { Parallax } from 'react-parallax'
import { Grid, Header, Button, Icon, Segment } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import Foot from '../foot/Foot'
const userLang = navigator.language || navigator.userLanguage

class HomePage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true
    }
  }

  componentDidMount () {
    window.scrollTo(0, 0)
    const self = this

    console.log(window.location)

    window.onload = event => {
      self.setState({
        loading: false
      })
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 700)

    // console.log(window)
  }
  render () {
    const loading = this.state.loading
    // const self = this
    console.log(userLang)
    return (
      <div>
        {/* <Nav /> */}

        {loading ? (
          <Grid>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>

            <Grid.Row colums={2}>
              <Grid.Column width={7}></Grid.Column>
              <Grid.Column width={6}>
                <Header size='huge'>
                  <Icon loading name='asterisk' size='massive' color='yellow' />
                  Loading...
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
          </Grid>
        ) : (
          <Grid style={{ marginBottom: 0, paddingBottom: 0 }}>
            <Grid.Row columns={3} style={{ marginBottom: 0, paddingBottom: 0 }}>
              <Grid.Column width={16}>
                <SharingExchange />
              </Grid.Column>

              <Grid.Column width={16}>
                <IntroSec />
              </Grid.Column>

              <Grid.Column width={16}>
                <IntroSecAnimated />
              </Grid.Column>
              
              <Grid.Column width={16}>
                <SharingMap />
              </Grid.Column>
              <Grid.Column width={16}>
                <SustainSec />
              </Grid.Column>
              <Grid.Column width={16}>
                <Parallax
                  bgImage={
                    'https://res.cloudinary.com/sheyou/image/upload/v1654418878/smiling_asian_girl_with_tablet_looking_at_camera_2022_01_19_00_09_04_utc_23ba1dbfe9.jpg'
                  }
                  bgImageAlt='merchant page image'
                  strength={-200}
                >
                  <Segment
                    style={{
                      backgroundColor: 'rgba(0,0,0,0.2)',
                      paddingLeft: 50,
                      paddingRight: 50
                    }}
                  >
                    <Grid
                      stackable
                      divided
                      style={{
                        paddingTop: 150,
                        paddingBottom: 70
                      }}
                    >
                      <Header
                        style={{
                          color: 'white',
                          fontSize: 60
                        }}
                      >
                        {userLang === 'zh-CN' && '成为舍予商户'}
                        {userLang !== 'zh-CN' && 'Become Merchant in SHEYOU'}
                      </Header>
                      <Grid.Row columns={6}>
                        <Grid.Column width={4} textAlign='center'>
                          <div>
                            <>
                              <Header
                                style={{
                                  color: 'white'
                                }}
                                size='large'
                              >
                                {userLang === 'zh-CN' && '长期利润'}

                                {userLang !== 'zh-CN' &&
                                  'Higher Long Term Profit'}
                              </Header>
                              <Header
                                size='small'
                                style={{
                                  color: 'white'
                                }}
                              >
                                {userLang === 'zh-CN' &&
                                  '租借不会产生退货，租金加卖出的钱意味着更多的利润'}
                                {userLang !== 'zh-CN' &&
                                  'Renting means no sales return, and rent plus purchase produce more profit in the long term.'}
                              </Header>
                            </>
                          </div>
                        </Grid.Column>
                        <Grid.Column width={4} textAlign='center'>
                          <div>
                            <>
                              <Header
                                style={{
                                  color: 'white'
                                }}
                                size='large'
                              >
                                {userLang === 'zh-CN' && '租借自由'}
                                {userLang !== 'zh-CN' && 'Freedom To Rent Out'}
                              </Header>
                              <Header
                                size='small'
                                style={{
                                  color: 'white'
                                }}
                              >
                                {userLang === 'zh-CN' &&
                                  '商户自定出租时间与租金'}
                                {userLang !== 'zh-CN' &&
                                  'Merchants customzie their renting time and money charge'}
                              </Header>
                            </>
                          </div>
                        </Grid.Column>
                        <Grid.Column width={4} textAlign='center'>
                          <div>
                            <>
                              <Header
                                style={{
                                  color: 'white'
                                }}
                                size='large'
                              >
                                {userLang === 'zh-CN' && '吸引用户'}
                                {userLang !== 'zh-CN' && 'Attract Customers'}
                              </Header>
                              <Header
                                style={{
                                  color: 'white'
                                }}
                                size='small'
                              >
                                {userLang === 'zh-CN' &&
                                  '租借对于用户更划算，也意味着更多笔交易'}
                                {userLang !== 'zh-CN' &&
                                  'Renting is more cost effective for customers, which means more orders'}
                              </Header>
                            </>
                          </div>
                        </Grid.Column>
                        <Grid.Column width={4} textAlign='center'>
                          <div>
                            <>
                              <Header
                                style={{
                                  color: 'white'
                                }}
                                size='large'
                              >
                                {userLang === 'zh-CN' && '更少成本'}
                                {userLang !== 'zh-CN' &&
                                  'Lower Production Costs'}
                              </Header>
                              <Header
                                size='small'
                                style={{
                                  color: 'white'
                                }}
                              >
                                {userLang === 'zh-CN' &&
                                  '租借需要生产更少的商品，每个季节也有更少的剩余'}
                                {userLang !== 'zh-CN' &&
                                  'Renting requires less production, which reduces out-of-season leftovers'}
                              </Header>
                            </>
                          </div>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    <Grid style={{ paddingBottom: 150 }}>
                      <Grid.Row textAlign='center'>
                        <Grid.Column>
                          {/* <Link to='/individualMerchant'> */}
                          <Link to='/merchantOpenShop'>
                            <Button
                              size='huge'
                              circular
                              inverted
                              style={{
                                paddingLeft: 50,
                                paddingRight: 50,
                                width: 300
                              }}
                            >
                              {userLang === 'zh-CN' && '开店'}
                              {userLang !== 'zh-CN' && 'Open Shop'}

                              <Icon
                                name='chevron right'
                                inverted
                                style={{ position: 'relative', top: 2 }}
                              />
                            </Button>
                          </Link>

                          {/* </Link> */}
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Segment>
                </Parallax>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}

        <Foot />
      </div>
    )
  }
}

export default HomePage
