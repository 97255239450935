import React from 'react'
import axios from 'axios'
import { Grid, Button, Header, Segment, Icon, Label, Pagination } from 'semantic-ui-react'
import {
    Link
} from "react-router-dom";
import { Parallax } from 'react-parallax';
import { withRouter } from 'react-router-dom'
import MainFilter from './HeartMainFilter'
import SizeFilter from './HeartSizeFilter'
import CardTemplete from '../heartPage/HeartTemplete'
// import HandleBuyCartDelete from '../cartFunction/HandleBuyCartDelete'
import Foot from '../foot/Foot';
import round from '@stdlib/math-base-special-round'
const userLang = navigator.language || navigator.userLanguage




class Heart extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            userInfo: [],
            userID: '',
            heartList: [],
            heartProducts: [],
            membership: [],
            loading: true,
            dataloading: true,
            accessoryHeartList: [],
            accessoryHeartProducts: [],
            activePage: 1,
            pageNum: 0,
            dataShow: []
        }

        this.getProductInHeartList = this.getProductInHeartList.bind(this)
        this.getAccessoryInHeartList = this.getAccessoryInHeartList.bind(this)
        this.getPageNum = this.getPageNum.bind(this)
        this.handlePaginationChange = this.handlePaginationChange.bind(this)
    }



    componentDidMount() {

        window.scrollTo(0, 0)
        const self = this
        const p = this.props
        
        const is = p.is
        const activeItem = p.activeItem

        self.setState({
            activeItem: activeItem,
            is: is //这个is是为了区分category和sizes
        })
        
        var page = p.location.search.substring(3)
        console.log(page==='') 
        if(page===null||page===undefined||page===''){
            page = 1
        }
        console.log(page)
        if(page!==undefined&&page!==null){
            self.setState({
                activePage: page
            })
            // this.getProductInHeartList()
        }

        window.onload = (event) => {
            self.setState({
                loading: false
            })
        };

        window.setTimeout(() => {
            if (self.state.loading === true) {
                this.setState({
                    loading: false
                })
            }

        }, 2000);
        const identifier = localStorage.getItem('identifier')
        const token = localStorage.getItem('token')

        self.setState({
            identifier: identifier, 
            token: token
        })


        axios('https://sheyou-backend.herokuapp.com/users/me', {
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then(function (res) {
                if (res.status === 200) {
                    const userInfo = [res.data]
                    if (userInfo[0].heart === null) {
                        userInfo[0].heart = []
                    }
                    if (userInfo[0].accessoryHeart === null) {
                        userInfo[0].accessoryHeart = []
                    }
                    self.setState({
                        userInfo: userInfo,
                        userID: userInfo[0].id,
                        heartList: userInfo[0].heart,
                        accessoryHeartList: userInfo[0].accessoryHeart,
                        membership: userInfo[0].membership
                    })
                    self.getProductInHeartList()
                    self.getAccessoryInHeartList()
                    // self.getPageNum()
                    self.ifLog()
                }
                else {
                    alert('Something went wrong, maybe log in again :(')
                    localStorage.setItem('login', 'false')
                }


            })


    }

    handlePaginationChange (e, { activePage }) {
        this.setState({ activePage })
        const thisPath = this.props.history.location.pathname
        this.props.history.push(thisPath + '?p=' + activePage)
        window.location.reload(true)
      }

    getProductInHeartList() {
        const self = this
        const p = this.props
        const is = p.is
        const activeItem = p.activeItem
        console.log(activeItem)

        const cartIt = self.state.heartList
        const heartProducts = []

        if (cartIt.length===0 || cartIt === null) {
            self.setState({ heartEmpty: true })
        } else {
            // console.log(cartIt)
            self.setState({ heartEmpty: false }
                )


            // self.setState({rentProducts:''})

            cartIt.forEach(function (cart, cartIdx, array) {
                activeItem.toLowerCase()
                // console.log(cart.type)
                if (cart.type === 'sale') {
                    axios.get('https://sheyou-backend.herokuapp.com/second-hand-products?id=' + cart.id)
                        .then(function (res) {
                            // console.log(res)
                            if (res.status === 200) {
                                res.data[0].type = 'sale'
                                // console.log(is)
                                if(is === 'categories'){
                                    res.data[0].categories.forEach(function (c) {
                                        if (c.name === activeItem) {
                                            heartProducts.push(res.data[0])
                                        }
                                    })
                                }
                                else{
                                    res.data[0].sizes.forEach(function (c) {
                                        if (c.name === activeItem) {
                                            heartProducts.push(res.data[0])
                                        }
                                    })
                                }
                                
                                self.setState({
                                    heartProducts: heartProducts,
                                    // loading: false,
                                })
                                if(cartIdx === array.length -1){
                                    self.setState({
                                        dataloading: false
                                    })
                                }

                                self.getPageNum()
                            }
                            else {
                                alert('something went wrong :(')
                                localStorage.setItem('login', false)
                            }
                        })
                }
                else {
                    axios.get('https://sheyou-backend.herokuapp.com/products?id=' + cart.id)
                        .then(function (res) {
                            if (res.status === 200) {
                                if(is==='categories'){
                                    res.data[0].categories.forEach(function (c) {
                                        if (c.name === activeItem) {
                                            heartProducts.push(res.data[0])
                                        }
                                    })
                                }
                                else{
                                    res.data[0].sizes.forEach(function (c) {
                                        if (c.name === activeItem) {
                                            heartProducts.push(res.data[0])
                                        }
                                    })
                                }

                                
                                self.setState({
                                    heartProducts: heartProducts,
                                    // loading: false,
                                })
                                if(cartIdx === array.length -1){
                                    self.setState({
                                        dataloading: false
                                    })
                                }

                                self.getPageNum()
                            }
                            else {
                                alert('something went wrong :(')
                                localStorage.setItem('login', "false")
                            }
                        })
                }
            })
        }


    }

    getAccessoryInHeartList() {

        const self = this

        const cartIt = self.state.accessoryHeartList
        const heartProducts = []
        const accessoryHeartProducts = []

        if (cartIt.length===0 || cartIt === null) {
            self.setState({ heartEmpty: true })
        } else {
            self.setState({ heartEmpty: false })


            // self.setState({rentProducts:''})

            cartIt.forEach(function (cart, cartIdx, array) {

                axios.get('https://sheyou-backend.herokuapp.com/accessaries?id=' + cart.id)
                    .then(function (res) {
                        if (res.status === 200) {
                            heartProducts.push(res.data[0])

                            self.setState({
                                accessoryHeartProducts: heartProducts,
                                // loading: false
                            })
                            if(cartIdx === array.length -1){
                                self.setState({
                                    dataloading: false
                                })
                            }

                            self.getPageNum()
                            
                        }
                        else {
                            alert('something went wrong :(')
                            localStorage.setItem('login', "false")
                        }
                    })

            })
            console.log(accessoryHeartProducts)
        }



    }

    getPageNum(){
        const s = this.state
        const self = this
        const activeItem = s.activeItem
        const activePage = s.activePage
        var products = s.heartProducts.sort(function(a, b){return(a.id - b.id)})
        var accessories = s.accessoryHeartProducts.sort(function(a, b){return(a.id - b.id)})
        var dataShow = []
        var allProducts = ''

        if(activeItem === 'All'){
             allProducts = products.concat(accessories)
        }else if(activeItem === 'Accessaries'){
             allProducts = accessories
        }else{
             allProducts = products
        }

        const itemNum = allProducts.length


        for (var i = activePage * 9 - 9; i < activePage * 9; i++) {
        if (i < itemNum) {
            dataShow.push(allProducts[i])
        }
        }

        var roundPageNum = 0
        var pageNum = 0
        roundPageNum = round(itemNum / 9)
        pageNum = itemNum / 9.0

        if(itemNum === 0){
            pageNum = 1
        }
        else if(itemNum > 0 && pageNum - roundPageNum > 0) {
            pageNum = roundPageNum + 1
            
        }else{
            pageNum = roundPageNum
        }
        self.setState({
            heartProducts: products,
            accessoryHeartProducts: accessories,
            dataShow: dataShow,
            pageNum: pageNum
        })
    }

    ifLog() {
        const log = localStorage.getItem('login')

        const self = this
        if (log === 'false') { self.setState({ login: false }) }
        else { self.setState({ login: true }) }
    }


    render() {
        const s = this.state
        const self = this
        const login = s.login
        const len = self.state.heartList.length
        const accessoryLen = self.state.accessoryHeartList.length
        var products = s.heartProducts
        var accessories = s.accessoryHeartProducts
        var dataShow = s.dataShow

        // if(activeItem === 'All'){
        //     var allProducts = products.concat(accessories)
        // }else if(activeItem === 'Accessaries'){
        //     var allProducts = accessories
        // }else{
        //     var allProducts = products
        // }



        console.log(s.loading)
        console.log(s.pageNum)
        console.log(accessories)
        console.log(products)
        console.log(dataShow)
        console.log(userLang)


        // var products1=localStorage.getItem('rentProducts')
        // products1=JSON.parse('['+products1+']')


        return <div>
            {/* <Nav /> */}


            {s.loading ? (
                <Grid >
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>


                    <Grid.Row colums={2}>
                        <Grid.Column width={7}></Grid.Column>
                        <Grid.Column width={6}>
                            <Header size='huge'>
                                <Icon loading name='asterisk' size='massive' color='yellow' />
                                Loading...
                            </Header>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                </Grid>
            ) : (

                <>
                <Grid columns = {2}>
                    <Grid.Column floated='left' width={4} style={{ paddingLeft: 80, paddingTop: 20 }}>
                        {userLang === 'zh-CN' && (
                             <Label tag size='big' color='orange'>
                                收藏夹
                            </Label>
                        )}

                        {userLang !=='zh-CN' && (
                            <Label tag size='big' color='orange'>
                                Heart
                            </Label>
                        )}
                        <MainFilter activeItem={this.state.activeItem}/>
                        <SizeFilter activeItem={this.state.activeItem}/>
                    </Grid.Column>

                    {s.dataloading === true &&
                    <Grid.Column width={12}>
                        <Parallax
                            bgImage='https://i.loli.net/2020/11/25/ZHjYm1wL9runqOz.png'
                            bgImageAlt=''
                            strength={200}>

                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />

                            <div>
                                <Segment>
                                    <Grid>
                                        <Grid.Row centered>
                                            <Header>Loading...</Header>
                                        </Grid.Row>
                                    </Grid>
                                </Segment>
                            </div>

                            <div style={{ height: "270px" }} />
                        </Parallax>
                    </Grid.Column>
                    }

                    {s.dataloading !== true && login !== true &&
                    <Grid.Column width={12}>
                        <Parallax
                            bgImage='https://i.loli.net/2020/11/25/ZHjYm1wL9runqOz.png'
                            bgImageAlt=''
                            strength={200}>

                            <div>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <Segment>
                                    <Grid>
                                        {userLang === 'zh-CN' && (
                                            <>
                                                <Grid.Row centered>
                                                    <Header> 您应该先登录</Header>
                                                </Grid.Row>
                                                <Grid.Row centered>
                                                    <Link to='/signin'>
                                                        <Button size='huge' color='orange'> 登录</Button>
                                                    </Link>
                                                </Grid.Row>
                                            </>
                                        )}
                                        {userLang !== 'zh-CN' && (
                                            <>
                                                <Grid.Row centered>
                                                    <Header> You Should login first</Header>
                                                </Grid.Row>
                                                <Grid.Row centered>
                                                    <Link to='/signin'>
                                                        <Button size='huge' color='orange'> Login</Button>
                                                    </Link>
                                                </Grid.Row>
                                            </>
                                        )}
                                        </Grid>
                                </Segment>
                            </div>

                            <div style={{ height: "200px" }} />
                        </Parallax>
                    </Grid.Column>
                    }

                    {s.dataloading !== true && login === true &&
                    <Grid.Column width={12}>
                        {len + accessoryLen === 0 &&
                            <Parallax
                                bgImage='https://i.loli.net/2020/11/25/ZHjYm1wL9runqOz.png'
                                bgImageAlt=''
                                strength={200}>

                                <div>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <Segment>
                                        {userLang === 'zh-CN' && (
                                            <Grid>
                                                <Grid.Row centered>
                                                    <Header>您的收藏夹是空的</Header>
                                                </Grid.Row>

                                                <Grid.Row centered>
                                                    <Link to='/clothing'>
                                                        <Button size='huge' color='orange'> 看看我们的衣橱！</Button>
                                                    </Link>
                                                </Grid.Row>
                                            </Grid>
                                        )}
                                        {userLang !== 'zh-CN' && (
                                            <Grid>
                                                <Grid.Row centered>
                                                    <Header>You Don't Have Anything In Your Heart List</Header>
                                                </Grid.Row>

                                                <Grid.Row centered>
                                                    <Link to='/clothing'>
                                                        <Button size='huge' color='orange'> Go Check Out Our Closet</Button>
                                                    </Link>
                                                </Grid.Row>
                                            </Grid>
                                        )}
                                    </Segment>
                                </div>

                                <div style={{ height: "200px" }} />
                            </Parallax>
                        }
                        {dataShow.length > 0 &&
                            <div>
                                <CardTemplete products= {dataShow} />
                                <Grid>
                                    <Grid.Row centered>
                                    <Pagination
                                        onPageChange={this.handlePaginationChange}
                                        activePage={self.state.activePage}
                                        pointing
                                        secondary
                                        ellipsisItem={false}
                                        totalPages={s.pageNum}
                                    />
                                    </Grid.Row>
                                </Grid>
                            </div>
                        }
                        {dataShow.length === 0 &&
                            <Parallax
                                bgImage='https://i.loli.net/2020/11/25/ZHjYm1wL9runqOz.png'
                                bgImageAlt=''
                                strength={200}>

                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />

                                <div>
                                    <Segment>
                                        {userLang === 'zh-CN' && (
                                            <Grid>
                                                <Grid.Row centered>
                                                    <Header>Sorry, we couldn’t find any matches for your search criteria</Header>
                                                </Grid.Row>

                                                <Grid.Row centered>
                                                    <Button size='huge' color='orange' onClick={this.handleItemClick}> Browse All</Button>

                                                </Grid.Row>
                                            </Grid>
                                        )}
                                        {userLang !== 'zh-CN' && (
                                            <Grid>
                                                <Grid.Row centered>
                                                    <Header>Sorry, we couldn’t find any matches for your search criteria</Header>
                                                </Grid.Row>

                                                <Grid.Row centered>
                                                    <Button size='huge' color='orange' onClick={this.handleItemClick}> Browse All</Button>

                                                </Grid.Row>
                                            </Grid>
                                        )}
                                    </Segment>
                                </div>

                                <div style={{ height: "270px" }} />
                            </Parallax>
                        }
                    </Grid.Column>
                    }
      
                </Grid>

                </>)
            }
            <Foot />


        </div >
    }

}

export default withRouter(Heart)


