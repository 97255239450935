import React from 'react'
import axios from 'axios'
import {
  Grid,
  Header,
  Icon,
  Pagination,
  Segment,
  Menu,
  Sidebar,
  Image,
  Label
} from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
import CardTempletePhone from './CardTempletePhone'
import round from '@stdlib/math-base-special-round'
import { Link } from 'react-router-dom'
import LoadingPhone from '../RedirectPages/LoadingPhone'

import NavMobilePhone from '../nav/NavMobilePhone'
import SigninPopPhone from '../RedirectPages/SigninPopPhone'
import LogInPagePhonePop from '../mypage/LogInPagePhonePop'

class ClothingPagePhone extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      products: [],
      loading: true,
      activePage: 1,
      pageNum: 0,
      categoriesSelected: 'Select',
      categoriesVisible: '',
      sizesVisible: '',
      sizesSelected: 'Select',
      activeItemPath: 'Clothing',
      activeItem: 'All',
      cart: [],
      bag: [],
      accessoryCart: [],
      accessoryBag: [],
      signinVisible: ''

      // productId
    }
    this.handlePaginationChange = this.handlePaginationChange.bind(this)
    this.categoriesVisible = this.categoriesVisible.bind(this)
    this.sizesVisible = this.sizesVisible.bind(this)
    this.handleItemClick = this.handleItemClick.bind(this)
    this.handleItemClickCart = this.handleItemClickCart.bind(this)
    this.signinVisible = this.signinVisible.bind(this)
  }
  componentDidMount () {
    const self = this
    const p = this.props
    console.log(p)

    var page = p.location.search.substring(3)
    console.log(page === '')
    if (page === null || page === undefined || page === '') {
      page = 1
    }
    const is = p.is
    const activeItem = p.activeItem

    self.setState({
      activeItem: activeItem,
      is: is
    })
    if (page !== undefined && page !== null) {
      self.setState({
        activePage: page
      })
      this.getProducts()
    }

    window.scrollTo(0, 0)

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 5000)
  }
  handlePaginationChange (e, { activePage }) {
    this.setState({ activePage })
    const thisPath = this.props.history.location.pathname
    console.log(this)
    this.props.history.push(thisPath + '?p=' + activePage)
    window.location.reload(true)
  }

  getProducts () {
    const self = this

    const p = this.props
    const is = p.is
    var activeItem = p.activeItem
    self.setState({
      activeItem: activeItem
    })

    if (activeItem !== 'All') {
      activeItem.toLowerCase()
      axios
        .get(
          'https://sheyou-backend.herokuapp.com/' + is + '?name=' + activeItem
        )
        .then(function (res) {
          console.log(res)
          if (res.status === 200) {
            const data = res.data[0].products
            console.log(data)
            const itemNum = data.length
            const activePage = self.state.activePage

            var dataShow = []
            for (var i = activePage * 8 - 8; i < activePage * 8; i++) {
              if (i < itemNum) {
                dataShow.push(data[i])
              }
            }

            var roundPageNum = 0
            var pageNum = 0
            roundPageNum = round(itemNum / 8)
            pageNum = itemNum / 8.0

            if (pageNum - roundPageNum > 0) {
              pageNum = roundPageNum + 1
            }

            self.setState({
              products: dataShow,
              pageNum: pageNum,
              loading: false,
              itemNum: itemNum
            })
          } else {
            alert('something went wrong :(')
            localStorage.setItem('login', false)
          }
        })
    } else {
      axios
        .get('https://sheyou-backend.herokuapp.com/products')
        .then(function (res) {
          console.log(res)
          if (res.status === 200) {
            const data = res.data
            const itemNum = data.length
            const activePage = self.state.activePage
            console.log(activePage)

            var dataShow = []
            for (var i = activePage * 8 - 8; i < activePage * 8; i++) {
              if (i < itemNum) {
                dataShow.push(data[i])
              }
            }

            var roundPageNum = 0
            var pageNum = 0
            roundPageNum = round(itemNum / 8)
            pageNum = itemNum / 8.0

            if (pageNum - roundPageNum > 0) {
              pageNum = roundPageNum + 1
            }

            console.log(data)
            self.setState({
              products: dataShow,
              loading: false,
              pageNum: pageNum,
              itemNum: itemNum
            })
          } else {
            alert('something went wrong :(')
            localStorage.setItem('login', false)
          }
        })
      // .catch(function(e){
      //   console.log(e)
      // })
    }
  }

  categoriesVisible () {
    const categoriesVisible = this.state.categoriesVisible
    const self = this
    console.log(categoriesVisible)
    if (categoriesVisible !== true) {
      self.setState({
        categoriesVisible: true
      })
    } else if (categoriesVisible === true) {
      self.setState({
        categoriesVisible: false
      })
    }
  }

  sizesVisible () {
    const sizesVisible = this.state.sizesVisible
    if (sizesVisible !== true) {
      this.setState({
        sizesVisible: true
      })
    } else if (sizesVisible === true) {
      this.setState({
        sizesVisible: false
      })
    }
  }

  signinVisible () {
    const signinVisible = this.state.signinVisible
    console.log(signinVisible)

    if (signinVisible !== true) {
      this.setState({
        signinVisible: true
      })
    } else if (signinVisible === true) {
      this.setState({
        signinVisible: false
      })
    }
  }


  handleItemClickCart (event, data) {
    const self = this
    const log = localStorage.getItem('login')
    if (log !== 'true') {
      self.signinVisible()
    } else {
      self.props.history.push('/cart')
    }
    
  }

  handleItemClick (event, data) {
    // var name = data.name
    const link = data.link
    const self = this
    window.location.assign('https://www.sheyoufashion.com/#' + link)
    if (link === undefined || link === null) {
      self.props.history.push('/clothing')
      window.location.reload(true)
    } else {
      self.props.history.push(link)
      window.location.reload(true)
    }
  }

  render () {
    const s = this.state
    const self = this
    const loading = s.loading
    // const n = { position: 'absolute', top: 1000 }
    console.log(s.activeItem)

    const cartNum = s.cart.length + s.accessoryCart.length

    return (
      <div>
        {loading ? (
          <LoadingPhone />
        ) : (
          <Grid>
            <Grid.Row
              centered
              style={{ paddingBottom: 0, marginBottom: 0, marginTop: 10 }}
            >
              <Menu borderless secondary>
                <Menu.Item
                  as={Link}
                  link='/clothing'
                  name='Clothing'
                  active={s.activeItemPath === 'Clothing'}
                  onClick={this.handleItemClick}
                />

                <Menu.Item
                  as={Link}
                  link='/accessory'
                  name='Accessory'
                  active={s.activeItemPath === 'Accessory'}
                  onClick={this.handleItemClick}
                />

                <Menu.Item
                  as={Link}
                  link='/sale'
                  name='Sale'
                  active={s.activeItemPath === 'Sale'}
                  onClick={this.handleItemClick}
                />
                <Menu.Item
                  name='Cart'
                  linkto='/cart'
                  active={s.activeItemPath === 'Cart'}
                  onClick={this.handleItemClickCart}
                >
                  <Icon name='shopping cart' color='teal'>
                    <Label floating circular>
                      {cartNum}
                    </Label>
                  </Icon>

                  {/* heart */}
                </Menu.Item>
              </Menu>
            </Grid.Row>
            <Grid.Row
              columns={3}
              style={{
                paddingBottom: 0,
                marginBottom: 0,
                paddingTop: 0,
                marginTop: 5
              }}
            >
              <Grid.Column width={1}></Grid.Column>
              <Grid.Column width={14}>
                <Segment style={{ height: 70 }}>
                  <Grid divided style={{ height: 70 }}>
                    <Grid.Row columns={2}>
                      <Grid.Column width={2}></Grid.Column>
                      <Grid.Column width={6}>
                        <Grid style={{ height: 70 }}>
                          <Grid.Row
                            style={{ paddingBottom: 0, marginBottom: 0 }}
                          >
                            <Header as='h6' style={{ paddingLeft: 10 }}>
                              Categories
                            </Header>
                          </Grid.Row>
                          <Grid.Row>
                            {/* <Select placeholder='Select your country' options={countryOptions} /> */}
                            {this.state.activeItem === 'All' && (
                              <Header
                                as='h6'
                                color='orange'
                                style={{ paddingLeft: 10 }}
                                onClick={self.categoriesVisible}
                              >
                                <Icon corner name='list' />
                                Select
                              </Header>
                            )}
                            {this.state.activeItem !== 'All' && (
                              <Header
                                as='h6'
                                color='orange'
                                style={{ paddingLeft: 10 }}
                                onClick={self.categoriesVisible}
                              >
                                <Icon corner name='list' />

                                {this.state.activeItem}
                              </Header>
                            )}

                            <Sidebar
                              as={Menu}
                              animation='overlay'
                              // onHide={this.categoriesVisible}
                              vertical
                              visible={this.state.categoriesVisible === true}
                              width='thin'
                              direction='bottom'
                            >
                              <Menu.Item
                                onClick={this.handleItemClick}
                                link='/clothing'
                              >
                                All
                              </Menu.Item>
                              <Menu.Item
                                onClick={this.handleItemClick}
                                link='/clothing/feminine'
                              >
                                Feminine
                              </Menu.Item>
                              <Menu.Item
                                onClick={this.handleItemClick}
                                link='/clothing/masculine'
                              >
                                Masculine
                              </Menu.Item>
                            </Sidebar>

                            {/* <Button.Group style={{marginLeft:10}} basic size='mini' color='orange'>
                            <Button >
                              <Icon name='list' />
                              Select
                            </Button>
                            <Dropdown
                              className='button icon'
                              floating
                              options={optionsCategories}
                              trigger={<></>}
                            />
                          </Button.Group> */}
                          </Grid.Row>
                        </Grid>
                      </Grid.Column>

                      <Grid.Column width={6}>
                        <Grid style={{ height: 70 }}>
                          <Grid.Row
                            style={{ paddingBottom: 0, marginBottom: 0 }}
                          >
                            <Header as='h6' style={{ paddingLeft: 10 }}>
                              Sizes
                            </Header>
                          </Grid.Row>
                          <Grid.Row>
                            <Header
                              style={{ paddingLeft: 10 }}
                              as='h6'
                              color='orange'
                              onClick={self.sizesVisible}
                            >
                              <Image
                                style={{
                                  height: 18,
                                  width: 18,
                                  position: 'relative',
                                  top: -5
                                }}
                                src='https://res.cloudinary.com/sheyou/image/upload/v1645878606/4_4417bd5612.png?441339.6999999881'
                                size='mini'
                              />
                              <p
                                style={{
                                  position: 'relative',
                                  top: -20,
                                  left: 27
                                }}
                              >
                                Select
                              </p>
                            </Header>

                            <Sidebar
                              as={Menu}
                              animation='overlay'
                              // onHide={this.sizesVisible}
                              vertical
                              visible={this.state.sizesVisible === true}
                              width='thin'
                              direction='bottom'
                            >
                              <Menu.Item
                                onClick={this.handleItemClick}
                                link='/clothing/average'
                              >
                                Average
                              </Menu.Item>
                              <Menu.Item
                                onClick={this.handleItemClick}
                                link='/clothing/m'
                              >
                                M
                              </Menu.Item>
                              <Menu.Item
                                onClick={this.handleItemClick}
                                link='/clothing/s'
                              >
                                S
                              </Menu.Item>
                              <Menu.Item
                                onClick={this.handleItemClick}
                                link='/clothing/l'
                              >
                                L
                              </Menu.Item>
                              <Menu.Item
                                onClick={this.handleItemClick}
                                link='/clothing/xxsxs'
                              >
                                XXS XS
                              </Menu.Item>
                              <Menu.Item
                                onClick={this.handleItemClick}
                                link='/clothing/xxlxl'
                              >
                                XXL XL
                              </Menu.Item>
                            </Sidebar>

                            {/* <Button.Group  basic size='mini' color='orange' style={{marginLeft:10}}>
                            <Button style={{height:30, paddingRight:35}}>
                              <Image
                                style={{
                                  height: 15,
                                  width: 15,
                                  position:'relative',
                                  top:-1,
                                  left:-3
                                }}
                                src='https://res.cloudinary.com/sheyou/image/upload/v1645878606/4_4417bd5612.png?441339.6999999881'
                               
                              />
                              <p style={{position:'relative',top:-16,left:17}}>
                              Select
                              </p>
                            </Button>
                            <Dropdown
                              className='button icon'
                              floating
                              options={optionsSizes}
                              trigger={<></>}
                            />
                          </Button.Group> */}
                          </Grid.Row>
                        </Grid>
                      </Grid.Column>

                      <Grid.Column width={1}></Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
              </Grid.Column>
              <Grid.Column width={1}></Grid.Column>
            </Grid.Row>

            <Grid.Row>
              {s.products.length === 0 && (
                <LoadingPhone />
              )}
              <Grid.Column width={16}>
                <CardTempletePhone products={s.products} showSignin={self.signinVisible}/>
                <Grid>
                  <Grid.Row centered>
                    <Pagination
                      onPageChange={this.handlePaginationChange}
                      activePage={self.state.activePage}
                      pointing
                      secondary
                      ellipsisItem={false}
                      totalPages={this.state.pageNum}
                    />
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>

            <Sidebar
              as={Menu}
              animation='overlay'
              // onHide={this.signinVisible}
              vertical
              visible={this.state.signinVisible === true}
              direction='bottom'
            >
              <Segment
                style={{ width: '100%', paddingBottom: 7, paddingTop: 7 }}
              >
                <Grid>
                  <Grid.Row columns={4}>
                    <Grid.Column width={1}>
                      <Icon
                        name='close'
                        size='small'
                        onClick={self.signinVisible}
                      />
                    </Grid.Column>
                    <Grid.Column width={5}></Grid.Column>
                    <Grid.Column width={4}>
                      <Header
                        as='h4'
                        color='teal'
                        textAlign='centered'
                        style={{
                          paddingBottom: 0,
                          marginBottom: 0,
                          fontWeight: 500
                        }}
                      >
                        Sign In
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>

                {/* <Header as='h6'  textAlign='centered' style={{paddingTop:0,marginTop:0,color:'#384D5A'}}>
                             Next Generation Fashion Platform 
                         </Header> */}
              </Segment>
              <LogInPagePhonePop />
            </Sidebar>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
          </Grid>
        )}
        <SigninPopPhone signinVisible={self.state.signinVisible} />
        <NavMobilePhone />
      </div>
    )
  }
}

export default withRouter(ClothingPagePhone)
