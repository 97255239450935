import React from 'react'
import axios from 'axios'
import {
  Grid, 
  Image, 
  Icon, 
  Container, 
  Button,
  Header, 
  Radio, 
  Message, 
  Sticky,
  Segment, 
  Menu} from 'semantic-ui-react'

import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import { withRouter } from 'react-router-dom'
// import Nav from '../nav/Nav'
// import { useContext } from 'react';
// import { forEach } from 'json-server-auth';
const userLang = navigator.language || navigator.userLanguage


class AccessoryItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stock: 0,
      images: [],
      details: [],
      activeItem: 'Product Information',
      colors: [],
      selectNum: 1,

      userInfo: [],
      value: '',
      userID: '',
      token: '',

      log: '',
      loading: true,
      uploadP: false,
      loadingSubmit: false
    }


    this.uploadProduct = this.uploadProduct.bind(this)
  }

  componentDidMount() {
    console.log(this)
    
    window.scrollTo(0, 0)

    const token = localStorage.getItem('token')

    const self = this
    window.onload = (event) => {
      self.setState({
        loading: false
      })
    };

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }

    }, 700);

    self.setState({
      token: token
    })

    const log = localStorage.getItem('login')
    self.setState({ log: log })

  }

  toPrevios () {
    window.history.go(-1)
  }

  uploadProduct () {
    const self = this
    self.setState({
      loadingSubmit: true
    })
    var product = localStorage.getItem('newProduct')
    product = JSON.parse(product)
    console.log(product)

    if (self.props.edit === true) {
      product = localStorage.getItem('newEditProduct')
      product = JSON.parse(product)
    }

    // if (self.props.toSale === true) {
    //   product = localStorage.getItem('newSaleProduct')
    //   product = JSON.parse(product)
    // }


    var picture = product.newProductPhoto.map(function (each) {
      return each.id
    })
    var detail = product.newDescriptPhoto.map(function (each) {
      return each.id
    })
    const name = product.name
    const description = product.description
    const price = product.price
    // if(self.props.toSale === true){
    //   price=product.price
    // }    
    const stock = product.stock
    const rentPrice = product.rentPrice
    const categories = product.accessoryCat.id
    const colors = product.colors.map(function (each) {
      return each.id
    })
    const occasions = product.occasions.map(function (each) {
      return each.id
    })
    const styles = product.styles.map(function (each) {
      return each.id
    })
    const genders = product.gender.id

    var user = localStorage.getItem('userInfo')

    if (user === null || user === undefined) {
      self.setState({
        uploadP: 'Wrong',
        loadingSubmit: false
      })
    } else {
      user = JSON.parse(user)

      user = user[0].id

      const token = localStorage.getItem('token')
      const productId = this.props.history.location.search.substring(4)

      if (self.props.edit === true) {
        axios('https://sheyou-backend.herokuapp.com/accessaries/' + productId, {
          method: 'put',
          data: {
            picture: picture,
            detail: detail,
            name: name,
            description: description,
            price: price,
            stock: stock,
            forSell: product.forSell,
            forRent: product.forRent,
            rentPriceAndTime: rentPrice,
            categories: categories,
            colors: colors,
            ocassions: occasions,
            styles: styles,
            genders: genders,
            users_permissions_user: user
          },
          headers: {
            Authorization: 'Bearer ' + token
          }
        })
          .then(function (res) {
            console.log(res)
            if (res.status === 200) {
              self.setState({
                uploadP: true,
                loadingSubmit: false
              })

              window.setTimeout(() => {
                localStorage.removeItem('newProduct')
                localStorage.removeItem('newEditProduct')
                self.props.history.push('/merchantpage/accessaries')
              }, 1000)
            } else {
              self.setState({
                uploadP: 'Wrong',
                loadingSubmit: false
              })
            }
          })
          .catch(function (err) {
            self.setState({
              uploadP: 'Wrong',
              loadingSubmit: false
            })
          })
      }
      // else if (self.props.toSale === true) {
      //   axios('https://sheyou-backend.herokuapp.com/second-hand-products', {
      //     method: 'post',
      //     data: {
      //       picture: picture,
      //       detail: detail,
      //       name: name,
      //       description: description,
      //       price: price,
      //       stock: stock,
      //       newPrice:newPrice,
      //       categories: categories,
      //       colors: colors,
      //       ocassions: occasions,
      //       styles: styles,
      //       genders: genders,
      //       users_permissions_user: user
      //     },
      //     headers: {
      //       Authorization: 'Bearer ' + token
      //     }
      //   })
      //     .then(function (res) {
      //       console.log(res)
      //       if (res.status === 200) {
      //         self.setState({
      //           uploadP: true,
      //           loadingSubmit: false
      //         })

      //         window.setTimeout(() => {
      //           localStorage.removeItem('newSaleProduct')
      //           self.props.history.push('/merchantpage/sale')
      //         }, 1000)
      //       } else {
      //         self.setState({
      //           uploadP: 'Wrong',
      //           loadingSubmit: false
      //         })
      //       }
      //     })
      //     .catch(function (err) {
      //       self.setState({
      //         uploadP: 'Wrong',
      //         loadingSubmit: false
      //       })
      //     })
      // } 
      else {
        axios('https://sheyou-backend.herokuapp.com/accessaries', {
          method: 'post',
          data: {
            picture: picture,
            detail: detail,
            name: name,
            description: description,
            price: price,
            stock: stock,
            forSell: product.forSell,
            forRent: product.forRent,
            rentPriceAndTime: rentPrice,
            categories: categories,
            colors: colors,
            ocassions: occasions,
            styles: styles,
            genders: genders,
            users_permissions_user: user
          },
          headers: {
            Authorization: 'Bearer ' + token
          }
        })
          .then(function (res) {
            console.log(res)
            if (res.status === 200) {
              self.setState({
                uploadP: true,
                loadingSubmit: false
              })

              window.setTimeout(() => {
                localStorage.removeItem('newProduct')
                self.props.history.push('/merchantpage/accessaries')
              }, 1000)
            } else {
              self.setState({
                uploadP: 'Wrong',
                loadingSubmit: false
              })
            }
          })
          .catch(function (err) {
            self.setState({
              uploadP: 'Wrong',
              loadingSubmit: false
            })
          })
      }

    }
  }


  render() {
    const self = this
    const s = this.state
    var product = localStorage.getItem('newProduct')
    product = JSON.parse(product)

    if (self.props.edit === true) {
      product = localStorage.getItem('newEditProduct')
      product = JSON.parse(product)
    }

    // if (self.props.toSale === true) {
    //   product = localStorage.getItem('newSaleProduct')
    //   product = JSON.parse(product)
    // }

    console.log(product)


    var images = product.newProductPhoto.map(function (each) {
        return { original: each.url, thumbnail: each.thumbnail }
      })
  
    var colors = product.colors.map(function (each) {
    return each.text
    })
    var details = product.newDescriptPhoto.map(function (each) {
    return each.url
    })

    const activeItem = this.state.activeItem


    return <div>
      {/* <Nav /> */}

      {this.state.loading ? (
        <Grid >
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>


          <Grid.Row colums={2}>
            <Grid.Column width={7}></Grid.Column>
            <Grid.Column width={6}>
              <Header size='huge'>
                <Icon loading name='asterisk' size='massive' color='yellow' />
                Loading...
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
        </Grid>
      ) : (
      <>
        <Sticky>
            <Grid centered>
            <Grid.Row style={{ paddingTop: 20 }}>
                <Grid.Column width={12} textAlign='center'>
                <Message info compact>
                    <Message.Header>
                      {self.props.edit === true && 
                      <>
                      {userLang !== 'zh-CN' && 'Edit: '}
                      {userLang === 'zh-CN' && '编辑：'}
                      </>
                      }
                      {userLang !== 'zh-CN' && 'Accessory Demo Preview'}
                      {userLang === 'zh-CN' && '配饰预览页'}
                    </Message.Header>
                    <p>
                    {userLang !== 'zh-CN' && 'You are now at the accessory product preview demo' +
                    'page, some of the functions of the product page are not' +
                    'available here.'}
                    {userLang === 'zh-CN' && '您现在正在配饰预览页面，某些页面功能暂不可用。'}
                    </p>
                </Message>
                </Grid.Column>
                <Grid.Column width={3} style={{ paddingTop: 15 }}>
                <Button
                    size='big'
                    color='teal'
                    icon
                    loading={this.state.loadingSubmit}
                    labelPosition='left'
                    style={{ marginLeft: -150 }}
                    onClick={this.uploadProduct}
                >
                    <Icon name='upload' />
                    {userLang !== 'zh-CN' && 'Submit'}
                    {userLang === 'zh-CN' && '提交'}
                </Button>
                <Button onClick={this.toPrevios} size='big'>
                  {userLang !== 'zh-CN' && 'BACK'}
                  {userLang === 'zh-CN' && '返回'}
                </Button>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                {this.state.uploadP === true && (
                <Message success>
                    <Message.Header>
                      {userLang !== 'zh-CN' && 'Success'}
                      {userLang === 'zh-CN' && '成功'}
                    </Message.Header>
                    {userLang !== 'zh-CN' && 'Product Uploaded'}
                    {userLang === 'zh-CN' && '商品上传成功'}
                </Message>
                )}

                {this.state.uploadP === 'Wrong' && (
                <Message negative>
                    <Message.Header>
                      {userLang !== 'zh-CN' && 'Wrong'}
                      {userLang === 'zh-CN' && '错误'}
                    </Message.Header>
                    {userLang !== 'zh-CN' && 'Something Went Wrong'}
                    {userLang === 'zh-CN' && '出现未知错误'}
                </Message>
                )}
            </Grid.Row>
            </Grid>
        </Sticky>
        <div>
            <Container fluid>
                <Grid stackable>

                <Grid.Row columns={4} >
                    <Grid.Column width={1}></Grid.Column>
                    <Grid.Column width={6}>
                    <Grid >

                        <Grid.Row>
                        <ImageGallery items={images} />
                        </Grid.Row>

                    </Grid>


                    </Grid.Column>

                    <Grid.Column width={1}></Grid.Column>


                    <Grid.Column width={8}>


                        <Grid divided='vertically' >
                        <Grid.Column width={16}></Grid.Column>


                        <Grid.Row centered>
                            <Grid.Column width={9}>
                            <Header as='h3' textAlign="center"> {product.name}</Header>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <>
                            {product.forSell && (
                            <>
                              <Grid.Column width={3}>
                                <Header as='h5'>
                                  {userLang !== 'zh-CN' && 'Sell Price: '}
                                  {userLang === 'zh-CN' && '售价：'}
                                </Header>
                              </Grid.Column>
                              <Grid.Column width={13}>
                                <Header color='grey'>
                                  RMB {product.price}
                                </Header>
                              </Grid.Column>
                            </>
                          )}

                          {product.forSell !== true && (
                            <Grid.Column width={16}>
                              <Header color='grey' as='h5'>
                                {userLang !== 'zh-CN' && 'Not For Sell '}
                                {userLang === 'zh-CN' && '暂不出售 '}
                              </Header>
                            </Grid.Column>
                          )}

                          {product.forRent !== true && (
                            <Grid.Column width={16}>
                              <Header color='grey' as='h5'>
                                {userLang !== 'zh-CN' && 'Not For Rent '}
                                {userLang === 'zh-CN' && '暂不提供租借 '}
                              </Header>
                            </Grid.Column>
                          )}

                          {product.forRent && (
                            <>
                              <Grid.Column width={3}>
                                <Header as='h5'>
                                  {userLang !== 'zh-CN' && 'Rental Price: '}
                                  {userLang === 'zh-CN' && '租借价格：'}
                                </Header>
                              </Grid.Column>
                              <Grid.Column width={13}>
                                <Header color='grey'>
                                  {userLang !== 'zh-CN' && product.rentPrice.map(
                                    p =>
                                      ' | RMB' + p.price + ' per ' + p.number+' '+p.option
                                  )}
                                  {userLang === 'zh-CN' && product.rentPrice.map(
                                    p =>
                                      ' | RMB' + p.price + ' 每 ' + p.number+' '+p.option
                                  )}
                                </Header>
                              </Grid.Column>{' '}
                            </>
                          )}
                          
                          </>


                          <Grid.Column width={3}>
                            <Header as='h5'>
                              {userLang !== 'zh-CN' && 'Description: '}
                              {userLang === 'zh-CN' && '商品描述：'}
                            </Header>
                          </Grid.Column>
                          <Grid.Column width={13}>
                            <Header as='h5'>{product.description}</Header>
                          </Grid.Column>
                        </Grid.Row>


                        <Grid.Row>
                            <Grid.Column width={5}>
                            <Header as='h5'>
                              {userLang !== 'zh-CN' && 'Select Color: '}
                              {userLang === 'zh-CN' && '选择颜色：'}
                            </Header>
                            </Grid.Column>
                            <Grid.Column width={11} >
                            {
                                colors.map(function (color) {
                                return <Radio label={color}
                                    value={color}
                                    />
                                })}
                            {/* <Radio label='pink' defaultChecked /> */}
                            
                            </Grid.Column>
                            

                        </Grid.Row>
                        

                        <Grid.Row>

                            <Grid.Column width={2}>
                            <Icon name='minus' onClick={this.handleSelectLess} />
                            </Grid.Column>
                            <Grid.Column width={2}>

                            {s.selectNum}
                            </Grid.Column>
                            <Grid.Column width={2}>
                            <Icon name='plus' onClick={this.handleSelectMore} />

                            </Grid.Column>


                        </Grid.Row>


                        <Grid.Row>

                            <Grid.Column width={16}>
                            <Button style={{ paddingLeft: 50, paddingRight: 50 }} icon color='orange' size='big' basic>

                                <Icon name='shopping bag' />
                                {userLang !== 'zh-CN' && 'Wear As Member'}
                                {userLang === 'zh-CN' && '加入租借单'}

                            </Button>
                            </Grid.Column>




                            <Grid.Column width={16}>
                            <Button style={{ paddingLeft: 50, paddingRight: 50 }} icon color='orange' size='big'>
                                <Icon name='cart' /> 
                                {userLang !== 'zh-CN' && 'Add To Cart'}
                                {userLang === 'zh-CN' && '加入购物车'}
                            </Button>
                            </Grid.Column>


                        </Grid.Row>
                        </Grid>

                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>


                </Grid.Row>
                </Grid>


            </Container>
            <br />
            <Container>
                {userLang === 'zh-CN' && (
                <Menu attached='top' tabular color='teal'>
                    <Menu.Item
                    name='商品详情'
                    active={activeItem === 'Product Information'}
                    />
                    <Menu.Item
                    name='评价'
                    />

                </Menu>
                )}

                {userLang !== 'zh-CN' && (
                <Menu attached='top' tabular color='teal'>
                    <Menu.Item
                    name='Product Information'
                    active={activeItem === 'Product Information'}
                    onClick={this.handleItemClick}
                    />
                    <Menu.Item
                    name='Reviews'
                    active={activeItem === 'Reviews'}
                    onClick={this.handleItemClick}
                    />

                </Menu>
                )}

                {s.activeItem === "Product Information" &&

                <Segment attached='bottom'>

                    {details.map(function (detail) {
                    return <Image fluid src={detail} />
                    }
                    )}
                </Segment>
                }


            </Container>
        </div>
      </>
      )}



    </div>
  }

}

export default withRouter(AccessoryItem)

