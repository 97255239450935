import React, { useState } from 'react'
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'

import { Button } from 'semantic-ui-react'

const CheckoutForm = ({ onLoadingChange ,orderId}) => {
  const stripe = useStripe()
  const elements = useElements()
  const [errorMessage, setErrorMessage] = useState(null)
  const [isElementsLoaded, setIsElementsLoaded] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)

  console.log(isProcessing,555)
  const handleSubmit = async event => {
    event.preventDefault()

    if (!stripe || !elements) {
      return
    }

    setIsProcessing(true)
    setErrorMessage(null)

    try {
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/payment-complete?order_id=${orderId}`
        }
      })

      if (error) {
        setErrorMessage(error.message)
        setIsProcessing(false)
      }
    } catch (err) {
      console.error('Payment error:', err)
      setErrorMessage('An unexpected error occurred.')
      setIsProcessing(false)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement 
        onReady={() => {
          setIsElementsLoaded(true);
          setIsProcessing(false);
          onLoadingChange(false);
        }}
      />

      <Button
        style={{
          marginTop: '30px',
          marginBottom: '30px',
          paddingLeft: '40px',
          paddingRight: '40px'
        }}
        color='teal'
        disabled={!isElementsLoaded || !stripe}
        loading={isProcessing}
      >
        {isProcessing ? 'Processing...' : 'Pay Now'}
      </Button>
      {errorMessage && <div style={{ color: 'red', marginTop: '10px' }}>{errorMessage}</div>}
    </form>
  )
}

export default CheckoutForm
