import React from 'react'
import {
  Segment,
  Button,
  Container,
  Form,
  Grid,
  Header,
  Message
} from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import { passwordStrength } from 'check-password-strength'
import newOTP from 'otp-generators'
import emailjs from 'emailjs-com'
var jwt = require('jsonwebtoken')

class ShopPop extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      shopName: '',
      email: '',
      password: '',
      shopDescription: '',
      passStrength: '',
      passWeak: false,
      loading: false,
      sentEmail: '',
      somethingWrong: false
    }
    this.onHandleShopName = this.onHandleShopName.bind(this)
    this.onHandleShopDescription = this.onHandleShopDescription.bind(this)
    // this.handlePhoneChange = this.handlePhoneChange.bind(this)
    // this.handlePhoneVerification = this.handlePhoneVerification.bind(this)
    this.onHandleEmail = this.onHandleEmail.bind(this)
    this.onHandlePassword = this.onHandlePassword.bind(this)
    this.onOpenShop = this.onOpenShop.bind(this)
  }

//   handlePhoneChange (phoneNumber) {
//     this.setState({ phoneNumber })
//   }

//   handlePhoneVerification (event) {
//     this.setState({
//       phoneVerification: event.target.value
//     })
//   }

  componentDidMount () {
    window.scrollTo(0, 0)
    const self = this
    window.onload = event => {
      self.setState({
        loading: false
      })
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 700)
  }

  onHandleShopName (event) {
    this.setState({
      shopName: event.target.value
    })
  }

  onHandleShopDescription (event) {
    this.setState({
      shopDescription: event.target.value
    })
  }

  onHandleEmail (event) {
    this.setState({
      email: event.target.value
    })
  }

  onHandlePassword = (event) => {
    const password = event.target.value
    this.setState({
      password: password,
      passStrength: passwordStrength(password).value
    })
  }

  onOpenShop = (event) => {
    const self = this
    event.preventDefault()
    const { shopName, email, password, passStrength, shopDescription } = this.state

    if (passStrength === 'Weak' || passStrength === 'Too weak') {
      this.setState({ passWeak: true })
      return
    }

    this.setState({ passWeak: false, loading: true })

    const storeName=shopName
    const storeDetail={
        description: shopDescription,
    }

    const merchantInfo={
        storeName: storeName,
        storeDetail: storeDetail,
        isMerchant: true
    }

    axios.post('https://sheyou-backend.herokuapp.com/auth/local/register', {
      username: shopName,
      email: email,
      password: password,
      merchantInfo: merchantInfo,
      confirmed: false
    })
    .then(response => {
      const id = response.data.user.id
      localStorage.setItem('id', id)

      const verificationCode = newOTP.generate(6, {
        alphabets: false,
        upperCase: false,
        specialChar: false
      })

      const token = jwt.sign(
        { data: verificationCode },
        'sheyoufashion',
        { expiresIn: '5min' }
      )

       emailjs.send(
        'service_orld5qb',
        'template_c1h9qsr',
        {
          email: email,
          reply_to: 'susanna.xinshu@gmail.com',
          name: shopName,
          verificationCode: verificationCode
        },
        'user_4rMSnDYBigUutDlR5RSib'
      )
      .then(
        function (response) {
          console.log('SUCCESS!', response.status, response.text)
          self.setState({
            loading: false,
            sentEmail: true
          })

          localStorage.setItem('identifier', shopName)
          localStorage.setItem('verificationCode', token)
          self.props.history.push('/emailConfirmationMerchant')
        },
        function (error) {
          self.setState({
            loading: false,
            sentEmail: false,
            somethingWrong: 'FAILED...' + error.text
          })
        }
      )
    })
    .catch(error => {
      this.setState({
        loading: false,
        sentEmail: false,
        somethingWrong: error.response?.data?.message[0]?.messages[0]?.message || 'An error occurred'
      })
      localStorage.setItem('email', email)
    })
  }

  render () {
    const { shopName, email, password, shopDescription, passWeak, loading, sentEmail, somethingWrong } = this.state
    
    return (
      <div
        className='bg_color_image_green_orange'
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh'
        }}
      >
        <Segment placeholder style={{ width: '80%', maxWidth: '600px' }}>
          <Container>
            <Grid>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Header as='h2' color='teal' textAlign='center'>
                    Open Shop Now
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Form>
                    <Form.Input
                      onChange={this.onHandleShopName}
                      label='Shop Name'
                      placeholder='Shop Name'
                      error={!shopName && sentEmail!=='' ? { content: 'Please enter a shop name', pointing: 'below' } : null}
                    />

                    <Form.Input
                      onChange={this.onHandleEmail}
                      label='Email'
                      placeholder='Email'
                      error={!email && sentEmail!=='' ? { content: 'Please enter an email address', pointing: 'below' } : null}
                    />

                    <Form.Input
                      onChange={this.onHandlePassword}
                      label='Password'
                      placeholder='Password'
                      type='password'
                    />

                    <Form.Input
                      onChange={this.onHandleShopDescription}
                      label='Shop description'
                      placeholder="What's the product about"
                    />
                  </Form>
                </Grid.Column>
              </Grid.Row>

              {passWeak && (
                <Grid.Row>
                  <Grid.Column>
                    <Message
                      warning
                      header='Weak Password'
                      content='Please create a stronger password combination. Your password must be longer than 8 digits, including upper lowercase letters, numbers, and special characters.'
                    />
                  </Grid.Column>
                </Grid.Row>
              )}

              {sentEmail && (
                <Grid.Row>
                  <Grid.Column>
                    <Message
                      success
                      header='Email Sent'
                      content='We Have Successfully Sent Confirmation, Please Check In Your Email, Expire in 5 Minutes'
                    />
                  </Grid.Column>
                </Grid.Row>
              )}

              {somethingWrong && (
                <Grid.Row>
                  <Grid.Column>
                    <Message
                      error
                      header='Register Failure'
                      content={`Something Wrong: ${somethingWrong === 'Email is already taken.' ? 'Email is already taken. Please go to log in' : somethingWrong}`}
                    />
                  </Grid.Column>
                </Grid.Row>
              )}

              <Grid.Row>
                <Grid.Column textAlign='center'>
                  <Button
                    onClick={this.onOpenShop}
                    content='Open Shop'
                    color='orange'
                    size='large'
                    loading={loading}
                    disabled={!shopName || !email || !password}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Segment>
      </div>
    )
  }
}

export default withRouter(ShopPop)
