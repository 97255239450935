import React from 'react'
import { Grid, Button } from 'semantic-ui-react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
// import Slider from 'react-slick'
// import IntroPageButton from './HomeIntroButtonPhone'
import IntroPageText from './IntroTextPhone'
import HomePageText from './HomeTextPhone'
import SusPageText from './SusTextPhone'
import { Parallax } from 'react-parallax'
import { Link } from 'react-router-dom'
import LoadingPhone from '../../RedirectPages/LoadingPhone'
import axios from 'axios'
import NavMobilePhone from '../../nav/NavMobilePhone'
import MerchantTextPhone from './MerchantTextPhone'
// import LogInPagePhonePop from '../../mypage/LogInPagePhonePop'

const userLang = navigator.language || navigator.userLanguage

class HomePagePhone extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      products: [],
      signinVisible: false
    }
    this.signinVisible = this.signinVisible.bind(this)
    this.setLink = this.setLink.bind(this)
  }
  componentDidMount () {
    window.scrollTo(0, 0)
    const self = this

    window.onload = event => {
      self.setState({
        loading: false
      })
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 700)

    axios
      .get('https://sheyou-backend.herokuapp.com/show-at-homes?ShowOrNot=Show')
      .then(function (res) {
        console.log(res.data[0].products)
        const products = res.data[0].products
        if (res.status === 200) {
          self.setState({
            products: products
          })
        } else {
          alert('something went wrong :(')
          localStorage.setItem('login', false)
        }
      })

    // console.log(window)
  }

  signinVisible () {
    const signinVisible = this.state.signinVisible

    if (signinVisible === false) {
      this.setState({
        signinVisible: true
      })
    }
    if (signinVisible === true) {
      this.setState({
        signinVisible: false
      })
    }
  }

  setLink (event, data) {
    const self = this
    // var name = data.name
    // const link = data.link
    // window.location.assign('https://sheyoufashion.com/#' + link)
    self.props.history.push('/clothing')
  }

  render () {
    const loading = this.state.loading

    return (
      <div>
        {loading ? (
          <LoadingPhone />
        ) : (
          <>
            <Grid centered>
              {/* ////////////// */}
              {/* //////////////// */}

              <Parallax
                bgImage={
                  'https://res.cloudinary.com/sheyou/image/upload/v1653724876/glamorous_african_woman_in_bright_clothes_posing_o_2022_02_03_10_40_25_utc_54cada45a8.jpg'
                }
                bgImageAlt='home page image'
                strength={100}
              >
                <Grid centered style={{ paddingTop: 200 }}>
                  <Grid.Row>
                    <IntroPageText />
                  </Grid.Row>
                  <Grid.Row>
                    <Link to='/clothing'>
                      <Button
                        // onClick={this.setLink}
                        link={'/clothing'}
                        color='orange'
                        circular
                        size='medium'
                        style={{ paddingLeft: 50, paddingRight: 50, color: '#493d2b' }}
                        //  style={{paddingTop:2,paddingBottom:2, paddingLeft:3,paddingRight:3}}
                      >
                        {userLang === 'zh-CN' && '查看衣橱'}
                        {userLang !== 'zh-CN' && 'View Our Closet'}
                      </Button>
                    </Link>
                  </Grid.Row>
                </Grid>

                <div style={{ height: '250px' }} />
              </Parallax>

              {/* ////////////// */}
              {/* //////////////// */}

              <Parallax
                bgImage={
                  'https://res.cloudinary.com/sheyou/image/upload/v1654168716/positive_diverse_women_enjoying_time_on_sandy_coas_2022_01_17_19_08_29_utc_0ba4f60e0c.jpg'
                }
                bgImageAlt='home page image'
                strength={150}
              >
                <Grid centered style={{ paddingTop: 200 }}>
                  <Grid.Row>
                    <HomePageText />
                  </Grid.Row>
                  <Grid.Row>
                    <Link to='/about'>
                      <Button
                        // onClick={this.setLink}
                        link={'/about'}
                        color='teal'
                        circular
                        size='medium'
                        style={{ paddingLeft: 65, paddingRight: 65 }}

                        //  style={{paddingTop:2,paddingBottom:2, paddingLeft:3,paddingRight:3}}
                      >
                        {userLang === 'zh-CN' && '立刻加入'}
                        {userLang !== 'zh-CN' && 'Join Now'}
                      </Button>
                    </Link>
                  </Grid.Row>
                </Grid>

                <div style={{ height: '250px' }} />
              </Parallax>

              {/* ////////////// */}
              {/* //////////////// */}

              <Parallax
                bgImage={
                  'https://res.cloudinary.com/sheyou/image/upload/v1655460327/women_2022_02_04_23_48_21_utc_1bc9f7e384.jpg?485782.1000000015'
                }
                bgImageAlt='home page image'
                strength={200}
              >
                <Grid centered style={{ paddingTop: 200 }}>
                  <Grid.Row>
                    <SusPageText />
                  </Grid.Row>
                  <Grid.Row>
                    <Link to='/about'>
                      <Button
                        // onClick={this.setLink}
                        link={'/about'}
                        color='yellow'
                        circular
                        size='medium'
                        style={{ paddingLeft: 40, paddingRight: 40 }}
                        //  style={{paddingTop:2,paddingBottom:2, paddingLeft:3,paddingRight:3}}
                      >
                        {userLang === 'zh-CN' && '了解更多'}
                        {userLang !== 'zh-CN' && 'Learn More'}
                      </Button>
                    </Link>
                  </Grid.Row>
                </Grid>

                <div style={{ height: '150px' }} />
              </Parallax>
              {/* ////////////// */}
              {/* //////////////// */}

              <Parallax
                bgImage={
                  'https://res.cloudinary.com/sheyou/image/upload/v1654418878/smiling_asian_girl_with_tablet_looking_at_camera_2022_01_19_00_09_04_utc_23ba1dbfe9.jpg'
                }
                bgImageAlt='home page image'
                strength={100}
              >
                <Grid
                  centered
                  style={{ paddingTop: 200 }}
                >
                  <Grid.Row>
                    <MerchantTextPhone />
                  </Grid.Row>
                  
                  <Grid.Row>
                    <Link to='/about'>
                      <Button
                        // onClick={this.setLink}
                        link={'/about'}
                        color='orage'
                        circular
                        size='medium'
                        style={{ paddingLeft: 40, paddingRight: 40 }}
                        //  style={{paddingTop:2,paddingBottom:2, paddingLeft:3,paddingRight:3}}
                      >
                        {userLang === 'zh-CN' && '立即开店'}
                        {userLang !== 'zh-CN' && 'Open Store'}
                      </Button>
                    </Link>
                  </Grid.Row>
                </Grid>

                <div style={{ height: '250px' }} />
              </Parallax>

              {/* ////////////// */}
              {/* //////////////// */}

              {/* ////////////// */}
              {/* //////////////// */}
            </Grid>

            <Grid style={{ backgroundColor: '#F0F3F4' }}></Grid>

            <Grid
              style={{
                backgroundColor: '#F0F3F4',
                paddingTop: 0,
                marginTop: 0
              }}
            ></Grid>
          </>
        )}

        <NavMobilePhone />
      </div>
    )
  }
}

export default HomePagePhone

// {s.products .length!==0 && (
//   <Grid.Row style={{ marginBottom: 20 }}>
//     <div style={{ width: '100%' }}>
//       <Header as='h4' style={{ color: '#384D5A' }}>

//         Clothes Sharing Closet
//       </Header>

//       <HomeCarosel products={s.products} />
//     </div>
//   </Grid.Row>
// )}
