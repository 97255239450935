import React from 'react'
import axios from 'axios'
import {
  Grid,
  Image,
  Header,
  Button,
  Popup,
  Segment,
  Icon,
  Placeholder
} from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'

class PastOrderItem extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      productInfo: {},
      loading: true,
      token: '',
      isCommented: false,
      open: false
    }
    this.onHandleClick = this.onHandleClick.bind(this)
    this.getSaleProductData = this.getSaleProductData.bind(this)
    this.getAccessoryData = this.getAccessoryData.bind(this)
  }
  componentDidMount () {
    const self = this
    const token = localStorage.getItem('token')
    const p = this.props
    const delivered = p.delivered
    const is = p.is

    if (is === 'clothing') {
      this.getProductData()
    } else if (is === 'saleClothing') {
      this.getSaleProductData()
    } else {
      this.getAccessoryData()
    }
    self.setState({
      token: token,
      delivered: delivered
    })
    window.setTimeout(() => {
      if (self.state.loading === true) {
        self.setState({
          loading: false
        })
      }
    }, 500)
  }

  getProductData () {
    const self = this
    const p = this.props
    const productId = p.id
    const orderid = p.orderid

    axios('https://sheyou-backend.herokuapp.com/products?id=' + productId, {
      method: 'get'
    })
      .then(function (res) {
        console.log(res)
        const theData = res.data[0]
        self.setState({ productInfo: theData })
      })
      .catch(function (err) {
        console.log(err)
      })
    axios('https://sheyou-backend.herokuapp.com/orders/' + orderid, {
      method: 'get'
    })
      .then(function (res) {
        res.data.orders.data.clothing.forEach(function (each) {
          if (each.id === productId && each.commented === true) {
            self.setState({
              isCommented: true,
              loading: false
            })
          }
        })
      })
      .catch(function (err) {
        console.log(err)
      })
  }
  getSaleProductData () {
    const self = this
    const p = this.props
    const productId = p.id
    const orderid = p.orderid

    axios(
      'https://sheyou-backend.herokuapp.com/second-hand-products?id=' +
        productId,
      {
        method: 'get'
      }
    )
      .then(function (res) {
        const theData = res.data[0]
        self.setState({ productInfo: theData })
      })
      .catch(function (err) {
        console.log(err)
      })
    axios('https://sheyou-backend.herokuapp.com/orders/' + orderid, {
      method: 'get'
    })
      .then(function (res) {
        res.data.orders.data.clothing.forEach(function (each) {
          if (
            each.type === 'sale' &&
            each.id === productId &&
            each.commented === true
          ) {
            self.setState({
              isCommented: true
            })
          }
        })
      })
      .catch(function (err) {
        console.log(err)
      })
  }
  getAccessoryData () {
    const self = this
    const p = this.props
    const productId = p.id
    const orderid = p.orderid

    axios('https://sheyou-backend.herokuapp.com/accessaries?id=' + productId, {
      method: 'get'
    })
      .then(function (res) {
        const theData = res.data[0]
        self.setState({ productInfo: theData })
      })
      .catch(function (err) {
        console.log(err)
      })
    axios('https://sheyou-backend.herokuapp.com/orders/' + orderid, {
      method: 'get'
    })
      .then(function (res) {
        res.data.orders.data.accessory.forEach(function (each) {
          if (each.id === productId && each.commented === true) {
            self.setState({
              isCommented: true
            })
          }
        })
      })
      .catch(function (err) {
        console.log(err)
      })
  }

  // open = () => this.setState({ open: true })
  // close = () => this.setState({ open: false })

  onHandleClick (event, data) {
    event.preventDefault()
    const self = this
    const productId = data.id
    const orderid = data.orderid
    const is = this.props.is
    localStorage.setItem('productToCommentId', productId)
    localStorage.setItem('orderToCommentId', orderid)
    localStorage.setItem('is', is)
    self.props.history.push('/comment')
  }

  // onHandleClickDelivery(event, data) {
  //     event.preventDefault()
  //     const self = this
  //     const productId = data.id
  //     const orderid = data.orderid
  //     localStorage.setItem('productToDeloveryId', productId)
  //     localStorage.setItem('orderToDeliveryId', orderid)
  //     self.props.history.push('/delivery')
  // }

  // onHandleDelivered(data, event) {
  //     const self = this
  //     const orderid = event.orderid
  //     const token = this.state.token
  //     this.setState({
  //         loading: true
  //     })
  //     // console.log(event.orderid,token)
  //     axios('https://sheyou-backend.herokuapp.com/orders/' + orderid, {
  //         method: 'put',
  //         data: {
  //             delivered: true
  //         },
  //         headers: {
  //             'Authorization': 'Bearer ' + token,
  //         }
  //     }).catch(error => console.log(error))
  //         .then(function (res) {
  //             console.log(res)
  //             if (res.status !== 200) {
  //                 alert('something went wrong')
  //             } else {
  //                 self.setState({
  //                     loading: false,
  //                     delivered: true,
  //                     open: false
  //                 })
  //             }
  //         })
  // }

  render () {
    const self = this
    const productInfo = this.state.productInfo
    const loading = self.state.loading
    const p = this.props
    const orderid = p.orderid
    const isMerchantOrder=p.isMerchantOrder

    return (
      <>
        {loading ? (
          <Segment>
            <Grid>
              <Grid.Row columns={3}>
                <Grid.Column width={1}></Grid.Column>
                <Grid.Column width={4}>
                  <Placeholder style={{ height: 150, width: 150 }}>
                    <Placeholder.Image square />
                  </Placeholder>
                </Grid.Column>
                <Grid.Column width={10}>
                  <Grid>
                    <Grid.Row Column={2}>
                      <Grid.Column width={8}>
                        <Placeholder>
                          <Placeholder.Header>
                            <Placeholder.Line length='medium' />
                          </Placeholder.Header>
                        </Placeholder>
                      </Grid.Column>

                      <Grid.Column>
                        <Button disabled={loading}>Button</Button>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={8}>
                        <Placeholder>
                          <Placeholder.Paragraph>
                            <Placeholder.Line length='short' />
                          </Placeholder.Paragraph>
                        </Placeholder>
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Column width={8}>
                      <Button disabled={loading}>Button</Button>
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <Button disabled={loading}>Button</Button>
                    </Grid.Column>
                    <Grid.Row></Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        ) : (
          <>
          {productInfo!==undefined&&productInfo.length!==0&&
           <Segment>
           <Grid>
             <Grid.Row columns={3}>
               <Grid.Column width={1}></Grid.Column>
               <Grid.Column width={4}>
                 {productInfo !== undefined&&productInfo.length!==0&&productInfo.picture!==undefined && (
                   <Image
                     size='small'
                     src={productInfo.picture[0].formats.small.url}
                   />
                 )}
               </Grid.Column>
               <Grid.Column width={10}>
                 <Grid>
                   <Grid.Row Column={2}>
                     <Grid.Column style={{ paddingTop: 12 }} width={8}>
                     {productInfo!==undefined&&productInfo.length!==0&&productInfo.name !== undefined &&  <Header> {productInfo.name}</Header>}
                     </Grid.Column>

                     {/* <Grid.Column>
                                     <Button content='Status' icon='truck'
                                         labelPosition='right' id={productInfo.id}
                                         orderid={orderid} onClick={self.onHandleClickDelivery} />
                                 </Grid.Column> */}
                   </Grid.Row>
                   <Grid.Row>
                     {/* Date: */}
                     <Grid.Column width={8}>
                       Price:
                       {productInfo!==undefined&&productInfo.length!==0&&productInfo.price!==undefined&&' ' + productInfo.price + ' RMB'}
                     </Grid.Column>
                   </Grid.Row>

                   <Grid.Column width={8}>
                     {isMerchantOrder!==true&&self.state.delivered === true &&
                       self.state.isCommented !== true && (
                         <Button
                           color='teal'
                           id={productInfo.id}
                           orderid={orderid}
                           onClick={self.onHandleClick}
                         >
                           Comment
                         </Button>
                       )}

                     {self.state.delivered === true &&
                       self.state.isCommented === true && (
                         <Popup
                           pinned
                           on='click'
                           content='Thanks for the feedback'
                           trigger={
                             <Button icon labelPosition='left'>
                               <Icon name='check' />
                               Commented
                             </Button>
                           }
                         />
                       )}
                   </Grid.Column>
                   {/* {self.state.delivered === true &&
                                 <Grid.Column width={8}>
                                     <Button basic color='teal' >Try Again</Button>
                                 </Grid.Column>} */}
                 </Grid>
               </Grid.Column>
             </Grid.Row>
           </Grid>
         </Segment>}
           
          </>
        )}
      </>
    )
  }
}

export default withRouter(PastOrderItem)
