import React from 'react'
import { Grid, Header, Icon, Segment, Button,Sidebar,Menu } from 'semantic-ui-react'

import { withRouter } from 'react-router-dom'
import LogInPagePhonePop from '../mypage/LogInPagePhonePop'


// const userLang = navigator.language || navigator.userLanguage

class LoginFirstPhone extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      signinVisible: false
    }
    this.signinVisible = this.signinVisible.bind(this)
  }
  componentDidMount () {
    const p=this.props
    const signinVisible=p.signinVisible
    this.setState({
        signinVisible:signinVisible
    })
  }

  signinVisible () {
    const signinVisible = this.state.signinVisible
    console.log(signinVisible)

    if (signinVisible !== true) {
      this.setState({
        signinVisible: true
      })
    } else if (signinVisible === true) {
      this.setState({
        signinVisible: false
      })
    }
  }

  render () {
    const self = this
    return (
      <>
        <div
          className='bg_color_image'
          style={{ height: '100vh', width: '100%' }}
        >
          <Segment
            style={{
              position: 'absolute',
              top: '25%',
              height: '45%',
              width: '100%',
              paddingTop: 20,
              paddingBottom: 20
            }}
          >
            <Grid>
              <Grid.Row></Grid.Row>
              <Grid.Row centered>
                <Icon name='smile outline' color='yellow' size='huge' />
              </Grid.Row>
              <Grid.Row centered>
                <Header>Please Log In First</Header>
              </Grid.Row>
              <Grid.Row centered>
                <Button
                  style={{ paddingLeft: 20, paddingRight: 20 }}
                  color='orange'
                  basic
                  onClick={self.signinVisible}
                >
                  Log In
                </Button>
              </Grid.Row>
            </Grid>
          </Segment>
        </div>
        <Sidebar
          as={Menu}
          animation='overlay'
          // onHide={this.signinVisible}
          vertical
          visible={this.state.signinVisible === true}
          direction='bottom'
        >
          <Segment style={{ width: '100%', paddingBottom: 7, paddingTop: 7 }}>
            <Grid>
              <Grid.Row columns={4}>
                <Grid.Column width={1}>
                  <Icon
                    name='close'
                    size='small'
                    onClick={self.signinVisible}
                  />
                </Grid.Column>
                <Grid.Column width={5}></Grid.Column>
                <Grid.Column width={4}>
                  <Header
                    as='h4'
                    color='teal'
                    textAlign='centered'
                    style={{
                      paddingBottom: 0,
                      marginBottom: 0,
                      fontWeight: 500
                    }}
                  >
                    Sign In
                  </Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>

            {/* <Header as='h6'  textAlign='centered' style={{paddingTop:0,marginTop:0,color:'#384D5A'}}>
                                   Next Generation Fashion Platform 
                               </Header> */}
          </Segment>
          <LogInPagePhonePop />
        </Sidebar>
      </>
    )
  }
}

export default withRouter(LoginFirstPhone)
