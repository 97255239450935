import React from 'react'
import axios from 'axios'

import {
  Grid,
  Header,
  Icon,
  Segment,
  Button,
  Message,
  Dropdown,
  Divider
} from 'semantic-ui-react'

import { withRouter } from 'react-router-dom'
import CardTemplate from './MerchantCardTemplate'
import LoadingPhone from '../../RedirectPages/LoadingPhone'

class MerchantProduct extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      token: '',
      userID: '',
      userInfo: [],
      userProducts: [],
      catOpt: [],
      sizeOpt: [],
      catLoading: false,
      sizeLoading: false,

      productLoading: true,
      filterCat: 2,
      filterSize: 'All Size',
      filterProducts: [],
      productShow: [],
      loading: true,
      dropitdown: false,
      merchantVerified: ''
    }
    this.handleAddProduct = this.handleAddProduct.bind(this)
    this.handleGoVerify = this.handleGoVerify.bind(this)
    this.getUserInfo = this.getUserInfo.bind(this)
    this.dropitdown = this.dropitdown.bind(this)
    this.getFilterProduct = this.getFilterProduct.bind(this)
    this.toPrevios = this.toPrevios.bind(this)
  }

  componentDidMount () {
    const self = this
    // const verified = p.verify
    const log = localStorage.getItem('login')
    const p = this.props
    const activeItem = p.activeItem
    console.log(activeItem)
    if (activeItem === 'accessaries') {
      self.setState({ caturl: 'accessory-categories' })
    } else {
      self.setState({ caturl: 'categories' })
    }

    self.setState(
      {
        activeItem: activeItem,
        // verified: verified,
        log: log
      },
      () => {
        if (log === 'true') {
          self.getUserInfo()
          self.getFilterInfo()
        }
      }
    )

    window.onload = event => {
      self.setState({
        loading: false
      })
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 700)
  }

  getUserInfo () {
    const self = this
    const token = localStorage.getItem('token')
    var activeItem = self.state.activeItem
    if (activeItem === 'sale') {
      activeItem = 'second-hand-products'
    }

    self.setState({
      token: token,
      loading: true,
      productLoading: true
    })
    console.log(1234)

    axios('https://sheyou-backend.herokuapp.com/users/me', {
      method: 'get',
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(function (res) {
        if (res.status === 200) {
          const userInfo = [res.data]
          console.log(userInfo)
          self.setState({
            userInfo: userInfo,
            userID: userInfo[0].id
          })

          const merchantInfo = userInfo[0].merchantInfo
          if (merchantInfo !== null && merchantInfo.merchantVerified === true) {
            self.setState({
              merchantVerified: true,
              loading: false
            })
          } else {
            self.setState({
              merchantVerified: false,
              loading: false
            })
          }
        }
      })
      .catch(function (err) {
          console.log(err)
        // self.props.history.push('/loginfirst')
      })

    axios({
      url: 'https://sheyou-backend.herokuapp.com/' + activeItem,
      method: 'get',
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(function (res) {
        console.log(res.data)
        const userID = self.state.userID
        // console.log(userID)
        const data = res.data
        const userProducts = data.filter(
          product =>
            product.users_permissions_user !== null &&
            product.users_permissions_user.id === userID
          // function(product){
          // if(product.users_permissions_user !== null){
          //     return(product.users_permissions_user.id === self.state.userID)
          // }
          // else{
          //     return false
          // }}
        )

        console.log(userProducts)

        self.setState({
          userProducts: userProducts,
          productShow: userProducts,
          productLoading: false
        })
      })
      .catch(function (err) {
        console.log(err)
        // self.props.history.push('/loginfirst')
      })
  }

  handleAddProduct (event, data) {
    var value = data.value
    const self = this
    const link = '/merchantpage/product/' + value
    window.location.assign('https://www.sheyoufashion.com/#' + link)
    self.props.history.push(link)
    window.location.reload(true)
  }

  dropitdown () {
    this.setState({
      dropitdown: true
    })
  }

  handleGoVerify () {
    const self = this
    self.props.history.push('/merchantVerify/address')
  }

  getFilterProduct (event, data) {
    const self = this
    self.setState({ productLoading: true })
    const value = data.value
    const token = self.state.token
    var activeItem = self.state.activeItem
    if (activeItem === 'sale') {
      activeItem = 'second-hand-products'
    }

    const caturl = self.state.caturl
    if (data.defaultValue === 'All Size') {
      this.setState(
        {
          filterSize: value
        },
        () => {
          const filterCat = this.state.filterCat
          const filterSize = this.state.filterSize
          // console.log(filterCat, filterSize)
          if (filterSize === 'All Size') {
            axios({
              url:
                'https://sheyou-backend.herokuapp.com/' +
                caturl +
                '?id=' +
                filterCat,
              methodL: 'get',
              headers: {
                Authorization: 'Bearer ' + token
              }
            })
              .then(function (res) {
                var data = res.data[0]
                if (activeItem === 'accessaries') {
                  data = data.accessories
                } else if (activeItem === 'products') {
                  data = data.products
                } else {
                  data = data.second_hand_products
                }
                console.log(data)
                const filterProducts = data.products
                const userProducts = self.state.userProducts
                // console.log(userProducts)
                // console.log(filterProducts)
                const products = []
                for (let i1 = 0; i1 < filterProducts.length; i1++) {
                  const product = userProducts.filter(
                    product => product.id === filterProducts[i1].id
                  )
                  if (product.length > 0) {
                    products.push(product[0])
                  }
                }
                // console.log(products)
                self.setState({
                  productShow: products,
                  productLoading: false
                })
              })
              .catch(function (err) {
                console.log(err)
              })
          } else {
            axios({
              url:
                'https://sheyou-backend.herokuapp.com/' +
                activeItem +
                '?sizes.id=' +
                filterSize +
                '&categories.id=' +
                filterCat,
              method: 'get',
              headers: {
                Authorization: 'Bearer ' + token
              }
            })
              .then(function (res) {
                const data = res.data
                console.log(data)
                const filterProducts = data
                const userProducts = self.state.userProducts
                // console.log(userProducts)
                // console.log(filterProducts)
                const products = []
                for (let i2 = 0; i2 < filterProducts.length; i2++) {
                  const product = userProducts.filter(
                    product => product.id === filterProducts[i2].id
                  )
                  if (product.length > 0) {
                    products.push(product[0])
                  }
                }
                // console.log(products)
                self.setState({
                  productShow: products,
                  productLoading: false
                })
              })
              .catch(function (err) {
                console.log(err)
              })
          }
        }
      )
    } else if (data.defaultValue === 2) {
      this.setState(
        {
          filterCat: value
        },
        () => {
          const filterCat = this.state.filterCat
          const filterSize = this.state.filterSize
          console.log(filterCat, filterSize)
          if (filterSize === 'All Size') {
            axios({
              url:
                'https://sheyou-backend.herokuapp.com/' +
                caturl +
                '?id=' +
                filterCat,
              method: 'get',
              headers: {
                Authorization: 'Bearer ' + token
              }
            })
              .then(function (res) {
                var data = res.data[0]
                if (activeItem === 'accessaries') {
                  data = data.accessories
                } else if (activeItem === 'products') {
                  data = data.products
                } else {
                  data = data.second_hand_products
                }
                console.log(data)
                const filterProducts = data
                const userProducts = self.state.userProducts
                // console.log(userProducts)
                // console.log(filterProducts)
                const products = []
                for (let i3 = 0; i3 < filterProducts.length; i3++) {
                  const product = userProducts.filter(
                    product => product.id === filterProducts[i3].id
                  )
                  if (product.length > 0) {
                    products.push(product[0])
                  }
                }
                // console.log(products)
                self.setState({
                  productShow: products,
                  productLoading: false
                })
              })
              .catch(function (err) {
                console.log(err)
              })
          } else {
            axios({
              url:
                'https://sheyou-backend.herokuapp.com/' +
                activeItem +
                '?sizes.id=' +
                filterSize +
                '&categories.id=' +
                filterCat,
              method: 'get',
              headers: {
                Authorization: 'Bearer ' + token
              }
            })
              .then(function (res) {
                // console.log(res)
                const data = res.data
                console.log(data)
                const filterProducts = data
                const userProducts = self.state.userProducts
                // console.log(userProducts)
                // console.log(filterProducts)
                const products = []
                for (let i4 = 0; i4 < filterProducts.length; i4++) {
                  const product = userProducts.filter(
                    product => product.id === filterProducts[i4].id
                  )
                  if (product.length > 0) {
                    products.push(product[0])
                  }
                }
                // console.log(products)
                self.setState({
                  productShow: products,
                  productLoading: false
                })
              })
              .catch(function (err) {
                console.log(err)
              })
          }
        }
      )
    }
  }

  getFilterInfo () {
    const self = this
    const activeItem = self.state.activeItem
    const caturl = self.state.caturl
    var categories = []
    var sizes = [
      {
        text: 'All Size',
        value: 'All Size',
        key: 'AS'
      }
    ]

    self.setState({
      catLoading: true,
      sizeLoading: true
    })

    axios({
      url: 'https://sheyou-backend.herokuapp.com/' + caturl,
      method: 'get'
    })
      .then(function (res) {
        const data = res.data
        data.forEach(function (option) {
          const category = {
            text: option.name,
            value: option.id,
            key: option.id
          }
          categories.push(category)
        })

        self.setState({
          catOpt: categories,
          catLoading: false
        })
      })
      .catch(function (err) {
        console.log(err)
      })

    if (activeItem !== 'accessaries') {
      axios('https://sheyou-backend.herokuapp.com/sizes', {
        method: 'get'
      })
        .then(function (res) {
          const data = res.data
          data.forEach(function (option) {
            const size = {
              text: option.name,
              value: option.id,
              key: option.id
            }
            sizes.push(size)
          })

          self.setState({
            sizeOpt: sizes,
            sizeLoading: false
          })
        })
        .catch(function (err) {
          console.log(err)
        })
    } else {
      self.setState({
        sizeOpt: sizes,
        sizeLoading: false
      })
    }
  }
  toPrevios () {
    window.history.go(-1)
  }
  render () {
    const s = this.state
    // console.log(s)
    // const loading = s.loading
    const self = this
    const verified = this.state.merchantVerified
    const activeItem = this.state.activeItem
    console.log(s.productShow)
    var defaultCat = null
    if (activeItem === 'accessaries') {
      defaultCat = 1
    } else {
      defaultCat = 2
    }
    console.log(verified)
    const p = this.props
    const isPhone = p.isPhone

    return (
      <>
        {this.state.loading === true && isPhone !== true && (
          <Grid>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>

            <Grid.Row colums={2}>
              <Grid.Column width={7}></Grid.Column>
              <Grid.Column width={6}>
                <Header size='huge'>
                  <Icon loading name='asterisk' size='massive' color='yellow' />
                  Loading...
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
          </Grid>
        )}
        {this.state.loading === true && isPhone === true && <LoadingPhone />}
        {this.state.loading !== true && (
          <div>
            {isPhone && (
              <Grid>
                <Grid.Row>
                  <Grid.Column width={1}>
                    <Icon
                      name='chevron left'
                      style={{ paddingTop: 20, paddingLeft: 20 }}
                      size='large'
                      onClick={self.toPrevios}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )}

            {verified === true && (
              <Grid centered>
                <Grid.Row>
                  <Grid.Column width='12'>
                    <Segment>
                      <Header textAlign='left'>
                        {}
                        My {activeItem}
                        {s.productLoading !== true && (
                          <Header.Subheader>
                            {s.productShow.length} entries found
                          </Header.Subheader>
                        )}
                      </Header>
                      <Divider />
                      <Grid.Column floated='right' width={6}>
                        <Button.Group color='teal' floated='right'>
                          <Button size='large'>Upload New</Button>
                          <Dropdown
                            floating
                            className='button icon'
                            trigger={s.dropitdown}
                          >
                            <Dropdown.Menu>
                              <Dropdown.Header content='Main Categories' />
                              <Dropdown.Item
                                icon='add'
                                value='newClothing'
                                text='Clothing'
                                onClick={this.handleAddProduct}
                              />
                              <Dropdown.Item
                                icon='add'
                                value='newAccessory'
                                text='Accessory'
                                onClick={this.handleAddProduct}
                              />
                            </Dropdown.Menu>
                          </Dropdown>
                        </Button.Group>
                      </Grid.Column>
                      <Grid.Column floated='left' width={10}>
                        {/* <Button content='filter' icon='filter'/> */}
                        <Grid.Row>
                          <Header as='h5'>
                            <Icon name='filter' />
                            <Header.Content>
                              Filter by category:{' '}
                              <Dropdown
                                inline
                                options={s.catOpt}
                                loading={s.catLoading}
                                defaultValue={defaultCat}
                                onChange={this.getFilterProduct}
                              />
                            </Header.Content>
                          </Header>
                        </Grid.Row>
                        <br />
                        <Grid.Row>
                          <Header as='h5'>
                            <Icon name='filter' />
                            <Header.Content>
                              Filter by size:{' '}
                              <Dropdown
                                inline
                                options={s.sizeOpt}
                                loading={s.sizeLoading}
                                defaultValue='All Size'
                                onChange={this.getFilterProduct}
                              />
                            </Header.Content>
                          </Header>
                        </Grid.Row>
                      </Grid.Column>
                    </Segment>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  {s.productLoading === true && (
                    <Header size='huge'>
                      <Icon
                        loading
                        name='asterisk'
                        size='massive'
                        color='yellow'
                      />
                      Loading...
                    </Header>
                  )}
                  {s.productLoading !== true && (
                    <>
                      {s.productShow.length > 0 && (
                        <Grid.Column>
                          <CardTemplate
                            products={s.productShow}
                            activeItem={activeItem}
                          />
                        </Grid.Column>
                      )}
                      {s.userProducts.length === 0 && (
                        <Grid.Column>
                          <br />
                          <Header textAlign='center'>
                            You have not uploaded any {activeItem}.
                          </Header>
                          <br />
                        </Grid.Column>
                      )}
                    </>
                  )}
                </Grid.Row>
                {s.productLoading !== true && s.productShow.length === 0 && (
                  <Grid.Row>
                    <Grid.Column>
                      <br />
                      <Header textAlign='center'>No {activeItem} Found.</Header>
                      <br />
                      <br />
                      <br />
                    </Grid.Column>
                  </Grid.Row>
                )}
              </Grid>
            )}

            {verified === false && (
              <Segment>
                <br />
                <Grid
                  centered
                  style={{ paddingLeft: 10, paddingRight: 10, marginTop: 7 }}
                >
                  <Grid.Row>
                    <Message
                      size='large'
                      warning
                      header='You have not verified your merchant account.'
                      content='Please go verify your merchant account before you can add or view any products.'
                    />
                  </Grid.Row>
                  <Grid.Row>
                    <Button
                      size='large'
                      color='orange'
                      onClick={this.handleGoVerify}
                    >
                      Go Verify
                    </Button>
                  </Grid.Row>
                </Grid>
                <br />
                <br />
              </Segment>
            )}

            {verified === '' && (
              <Grid>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row colums={2}>
                  <Grid.Column width={7}></Grid.Column>
                  <Grid.Column width={6}>
                    <Header size='huge'>
                      <Icon
                        loading
                        name='asterisk'
                        size='massive'
                        color='yellow'
                      />
                      Loading...
                    </Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
              </Grid>
            )}
          </div>
        )}
      </>
    )
  }
}

export default withRouter(MerchantProduct)
