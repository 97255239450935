import React from 'react'
// import { Parallax, } from 'react-parallax';
// import {
//   Segment,
//   Button,
//   Container,
//   Form,
//   Grid
// } from 'semantic-ui-react'
import {withRouter} from 'react-router-dom'
import PersonalShopPop from './PersonalShopPop'
// import IntlTelInput from 'react-intl-tel-input-ueat'
import 'react-intl-tel-input-ueat/dist/main.css'

class OpenPersonalShop extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      shopName: '',
      email: '',
      phoneNumber: '',
      phoneVerification: ''
    }
  }

  componentDidMount () {
    window.scrollTo(0, 0)
    const self = this
    window.onload = event => {
      self.setState({
        loading: false
      })
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 700)
  }

 

  render () {
    console.log(this)
    return (
      <PersonalShopPop/>
    )
  }
}

export default withRouter(OpenPersonalShop)
