import React from 'react'
import axios from 'axios'
import { Segment, Rating, Grid, Header, Image } from 'semantic-ui-react'
import "react-image-gallery/styles/css/image-gallery.css";
import { withRouter } from 'react-router-dom'


class ProductReview extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            comment: ''

        }

        this.getReviews = this.getReviews.bind(this)
    }

    componentDidMount() {
        this.getReviews();
    }

    getReviews() {
        const p = this.props
        const self = this
        const is=p.is
        const productId = p.productId
        axios('https://sheyou-backend.herokuapp.com/comments', {
            method: 'get'
        })
            .then(function (res) {
                
                const theData = res.data
                const data = theData.forEach(function (it) {
                    console.log(it.comment.comment.is===is)
                    console.log(it.comment.comment.productId,productId)
                    if (it.comment.comment.is === is&&
                        it.comment.comment.productId === productId) {
                            return it
                        }
                    
                })
                self.setState({ comment: data })
            })
    }

    render() {
        const comment = this.state.comment
        const len = comment.length

        var totalRating = 0
        var averageRating = 0



        if (len > 0) {
            comment.forEach(function (it) {
                totalRating += it.comment.comment.rating
            })
        }
        averageRating = parseInt(totalRating / len)

        return <Segment attached='bottom'>
            <Segment inverted color='teal'>
                <Header as='h1'> {len} Reviews </Header>
                {len > 0 &&
                    <Rating
                        maxRating={5}
                        defaultRating={averageRating}
                        icon='star'
                        size='massive'
                        disabled
                    />}
            </Segment>


            {len > 0 && comment.map(function (each) {
                return <Segment>
                    <Grid divided>
                        <Grid.Row >
                            <Grid.Column width={5}>
                                <Grid divided='vertically' centered columns={2}>
                                    <Grid.Row >
                                        <Header>
                                            {each.comment.comment.commentInformation.identifier}
                                        </Header>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid>
                                            <Grid.Column width={4}></Grid.Column>
                                            <Grid.Column width={6}>
                                                <Grid>
                                                    {each.comment.comment.userInfo.clothingsize !== null &&
                                                        <Grid.Row >
                                                            <Header as='h6'>Size Worn:</Header>
                                                        </Grid.Row>
                                                    }
                                                    {each.comment.comment.userInfo.height !== null &&
                                                        <Grid.Row style={{ paddingBottom: 0, marginBottom: 0 }}>
                                                            <Header as='h6'>Height:</Header>
                                                        </Grid.Row>
                                                    }
                                                    {each.comment.comment.userInfo.weight !== null &&
                                                        <Grid.Row style={{ paddingBottom: 0, marginBottom: 0 }}>
                                                            <Header as='h6'>Weight:</Header>
                                                        </Grid.Row>
                                                    }
                                                    {each.comment.comment.userInfo.bodytype !== null &&
                                                        <Grid.Row style={{ paddingBottom: 0, marginBottom: 0 }}>
                                                            <Header as='h6'>Bodytype:</Header>
                                                        </Grid.Row>
                                                    }

                                                    {each.comment.comment.userInfo.bustsize !== null &&
                                                        <Grid.Row>
                                                            <Header as='h6'>Bustsize:</Header>
                                                        </Grid.Row>
                                                    }
                                                    <Grid.Row></Grid.Row>


                                                </Grid>

                                            </Grid.Column>
                                            <Grid.Column width={5}>
                                                <Grid>
                                                    {each.comment.comment.userInfo.clothingsize !== null &&
                                                        <Grid.Row >
                                                            <Header color='orange' as='h6'>
                                                                {each.comment.comment.userInfo.clothingsize}
                                                            </Header>
                                                        </Grid.Row>
                                                    }
                                                    {each.comment.comment.userInfo.height !== null &&
                                                        <Grid.Row style={{ paddingBottom: 0, marginBottom: 0 }}>
                                                            <Header color='orange' as='h6'>
                                                                {each.comment.comment.userInfo.height}
                                                            </Header>
                                                        </Grid.Row>
                                                    }
                                                    {each.comment.comment.userInfo.weight !== null &&
                                                        <Grid.Row style={{ paddingBottom: 0, marginBottom: 0 }}>
                                                            <Header color='orange' as='h6'>
                                                                {each.comment.comment.userInfo.weight}
                                                            </Header>
                                                        </Grid.Row>
                                                    }
                                                    {each.comment.comment.userInfo.bodytype !== null &&
                                                        <Grid.Row style={{ paddingBottom: 0, marginBottom: 0 }}>
                                                            <Header color='orange' as='h6'>
                                                                {each.comment.comment.userInfo.bodytype}
                                                            </Header>
                                                        </Grid.Row>
                                                    }
                                                    {each.comment.comment.userInfo.bustsize !== null &&
                                                        <Grid.Row >
                                                            <Header color='orange' as='h6'>
                                                                {each.comment.comment.userInfo.bustsize}
                                                            </Header>
                                                        </Grid.Row>
                                                    }
                                                    <Grid.Row></Grid.Row>

                                                </Grid>

                                            </Grid.Column>

                                        </Grid>

                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                            <Grid.Column width={10}>
                                <Grid centered>
                                    <Grid.Row columns={3}>
                                        <Grid.Column>
                                            <Rating icon='star' defaultRating={each.comment.comment.rating} maxRating={5} />
                                        </Grid.Column>
                                        <Grid.Column>

                                            {each.created_at.substring(5, 7) === '01' &&
                                                <Header color='orange' as='h6'>
                                                    {'January ' + each.created_at.substring(8, 10) + ", " + each.created_at.substring(0, 4)}
                                                </Header>
                                            }
                                            {each.created_at.substring(5, 7) === '02' &&
                                                <Header color='orange' as='h6'>
                                                    {'February ' + each.created_at.substring(8, 10) + ", " + each.created_at.substring(0, 4)}
                                                </Header>
                                            }
                                            {each.created_at.substring(5, 7) === '03' &&
                                                <Header color='orange' as='h6'>
                                                    {'March ' + each.created_at.substring(8, 10) + ", " + each.created_at.substring(0, 4)}
                                                </Header>
                                            }
                                            {each.created_at.substring(5, 7) === '04' &&
                                                <Header color='orange' as='h6'>
                                                    {'April ' + each.created_at.substring(8, 10) + ", " + each.created_at.substring(0, 4)}
                                                </Header>
                                            }
                                            {each.created_at.substring(5, 7) === '05' &&
                                                <Header color='orange' as='h6'>
                                                    {'May ' + each.created_at.substring(8, 10) + ", " + each.created_at.substring(0,4)}
                                                </Header>
                                            }
                                            {each.created_at.substring(5, 7) === '06' &&
                                                <Header color='orange' as='h6'>
                                                    {'June ' + each.created_at.substring(8, 10) + ", " + each.created_at.substring(0,4)}
                                                </Header>
                                            }
                                            {each.created_at.substring(5, 7) === '07' &&
                                                <Header color='orange' as='h6'>
                                                    {'July ' + each.created_at.substring(8, 10) + ", " + each.created_at.substring(0,4)}
                                                </Header>
                                            }
                                            {each.created_at.substring(5, 7) === '08' &&
                                                <Header color='orange' as='h6'>
                                                    {'August ' + each.created_at.substring(8, 10) + ", " + each.created_at.substring(0, 4)}
                                                </Header>
                                            }
                                            {each.created_at.substring(5, 7) === '09' &&
                                                <Header color='orange' as='h6'>
                                                    {'September ' + each.created_at.substring(8, 10) + ", " + each.created_at.substring(0, 4)}
                                                </Header>
                                            }
                                            {each.created_at.substring(5, 7) === '10' &&
                                                <Header color='orange' as='h6'>
                                                    {'October ' + each.created_at.substring(8, 10) + ", " + each.created_at.substring(0, 4)}
                                                </Header>
                                            }
                                            {each.created_at.substring(5, 7) === '11' &&
                                                <Header color='orange' as='h6'>
                                                    {'November ' + each.created_at.substring(8, 10) + ", " + each.created_at.substring(0, 4)}
                                                </Header>
                                            }
                                            {each.created_at.substring(5, 7) === '12' &&
                                                <Header color='orange' as='h6'>
                                                    {'December ' + each.created_at.substring(8, 10) + ", " + each.created_at.substring(0, 4)}
                                                </Header>
                                            }


                                        </Grid.Column>
                                    </Grid.Row>
                                    {/* <Grid.Row floated='left' width={5} style={{paddingBottom:0, marginBottom:0}}>
                                                        <Header as='h3'>Header</Header>
                                                    </Grid.Row> */}
                                    <Grid.Row width={5} columns={2}>
                                        <Grid.Column width={1}>
                                        </Grid.Column>
                                        <Grid.Column width={7}>
                                            <Header>{each.comment.comment.comment}</Header>
                                        </Grid.Column>
                                        <Grid.Column width={1}></Grid.Column>

                                        {each.pictures.length!==0&&
                                        <Grid.Column width={6}>
                                            <Image src={'https://sheyou-backend.herokuapp.com/' + each.pictures[0].url} size='medium' />
                                        </Grid.Column>
                                        }
                                        

                                    </Grid.Row>
                                    <Grid.Row></Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>


                    </Grid>
                </Segment>

            })}


            {/* {
                    comment.map(function(it){
                    return <Segment>

                          </Segment>
                    })
                } */}
                <br/>
                <br/>
                <br/>
                
        </Segment>
    }

}

export default withRouter(ProductReview)

