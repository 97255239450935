import React from 'react'
import SustainPageText from './SustainSecText'
import SusParallax from './SustainSecParallax'




class SustainSec extends React.Component{
    render(){
      return<div> 
 
          <SusParallax strength={150} image="https://res.cloudinary.com/sheyou/image/upload/v1727700908/environment_befe0dab6d.svg" content1={<SustainPageText/>}/>

          {/* <SusParallax Parall strength={100} image="https://i.loli.net/2021/04/15/4SF1JxHKnoqpbie.jpg"/> */}
         
          



      </div>
    }
  }
  
  export default SustainSec;
