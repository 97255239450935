import React from 'react'
import { Header, Grid, Button, Segment, Icon } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
import Foot from '../foot/Foot'
import MerchantSec from '../homePage/merchantSec/MerchantSec'
const userLang = navigator.language || navigator.userLanguage

class MerchantOpenShop extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      personalVisible: false,
      companyVisible: false
    }
    // this.toPersonalShop = this.toPersonalShop.bind(this)
    // this.toMerchantShop = this.toMerchantShop.bind(this)
    this.toOpenShop = this.toOpenShop.bind(this)
  }

  componentDidMount () {
    window.scrollTo(0, 0)
    const self = this
    window.onload = event => {
      self.setState({
        loading: false
      })
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 700)
  }

  // toPersonalShop () {
  //   this.props.history.push('/openPersonalShop')
  // }

  // toMerchantShop () {
  //   this.props.history.push('/openMerchantShop')
  // }

  toOpenShop () {
    this.props.history.push('/openShop')
  }

  render () {
    const self = this
    return (
      <div>
        {/* <Nav /> */}
        <Grid stackable>
          <Grid.Row
            style={{
              paddingTop: 200,
              paddingBottom: 200,
              marginBottom: 0,
              marginTop: 14,
              width: '100%',
              backgroundImage:
                'url(https://res.cloudinary.com/sheyou/image/upload/v1653724863/woman_in_red_hat_sunglasses_and_suit_of_90s_with_2022_01_12_03_20_40_utc_7cb0c2bedc.jpg?291171.1000000015)',
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}
          >
            <Segment
              basic
              inverted
              textAlign='center'
              style={{ backgroundColor: 'transparent' }}
            >
              <Grid stackable centered columns={1} style={{ paddingTop: 80 }}>
                <Grid.Column
                // style={{ backgroundColor: 'rgba(255,255,255,.8)' }}
                >
                  <div>
                    {userLang === 'zh-CN' && (
                      <Header
                        textAlign='center'
                        style={{
                          fontSize: '60px',
                          fontWeight: '1000',
                          marginBottom: 0,
                          color: 'white'
                        }}
                      >
                        在舍予开店
                      </Header>
                    )}

                    {userLang !== 'zh-CN' && (
                      <Header
                        textAlign='center'
                        style={{
                          fontSize: '60px',
                          fontWeight: '1000',
                          marginBottom: 0,
                          color: 'white'
                        }}
                      >
                        Open Your Store In SHEYOU
                      </Header>
                    )}
                  </div>
                </Grid.Column>

                <Grid.Row centered columns={4}>
                  <Grid.Column width={8} textAlign='center'>
                    <Button
                      onClick={self.toOpenShop}
                      size='huge'
                      circular
                      color='yellow'
                      style={{
                        paddingLeft: 50,
                        paddingRight: 50,
                        width: 300,
                        margin: '0 auto'
                      }}
                    >
                      {userLang === 'zh-CN' && '现在开店'}
                      {userLang !== 'zh-CN' && 'Open Shop Now'}

                      <Icon
                        name='chevron right'
                        inverted
                        style={{ position: 'relative', top: 2 }}
                      />
                    </Button>
                  </Grid.Column>
                  {/* <Grid.Column width={8} textAlign='center'>
                    <Button
                      onClick={self.toPersonalShop}
                      size='huge'
                      circular
                      color='yellow'
                      style={{
                        paddingLeft: 50,
                        paddingRight: 50,
                        width: 300,
                        margin: '0 auto'
                      }}
                    >
                      {userLang === 'zh-CN' && '个人开店'}
                      {userLang !== 'zh-CN' && 'Personal Shop'}

                      <Icon
                        name='chevron right'
                        inverted
                        style={{ position: 'relative', top: 2 }}
                      />
                    </Button>
                  </Grid.Column>

                  <Grid.Column width={8} textAlign='center'>
                    <Button
                      onClick={self.toMerchantShop}
                      size='huge'
                      circular
                      color='yellow'
                      style={{
                        paddingLeft: 60,
                        paddingRight: 60,
                        width: 300,
                        margin: '0 auto'
                      }}
                    >
                      {userLang === 'zh-CN' && '企业开店'}
                      {userLang !== 'zh-CN' && 'Entreprise Shop'}

                      <Icon
                        name='chevron right'
                        inverted
                        style={{ position: 'relative', top: 2 }}
                      />
                    </Button>
                  </Grid.Column> */}
                </Grid.Row>
              </Grid>
            </Segment>
          </Grid.Row>
          <Grid.Row
            style={{
              // paddingTop: 100,
              marginTop: 0,
              marginBottom: 0,
              paddingBottom: 0,
              backgroundColor: 'rgba(241,241,241)'
            }}
          >
            <Grid.Column
              width={9}
              style={{
                marginBottom: 0,
                backgroundImage:
                  'url(https://res.cloudinary.com/sheyou/image/upload/v1653730579/Screen_Shot_2022_05_28_at_5_35_56_PM_a977be6fbc.png?1774877.1000000015)',
                backgroundSize: 'cover'
              }}
            ></Grid.Column>
            <Grid.Column
              width={7}
              centered
              style={{
                backgroundColor: 'rgba(241,241,241)',
                marginBottom: 0,
                paddingBottom: 50,
                paddingTop: 50
              }}
            >
              <Header
                style={{
                  color: 'rgba(37,37,37)',
                  paddingTop: 50,
                  fontSize: 100
                }}
              >
                "
              </Header>
              <Header
                style={{
                  position: 'relative',
                  top: -30,
                  color: 'rgba(37,37,37)',
                  fontSize: 50,
                  textDecoration: 'underline'
                }}
              >
                {userLang === 'zh-CN' && '没有衣服穿'}
                {userLang !== 'zh-CN' && 'I Have Nothing To Wear'}
              </Header>
              <Header
                as='h3'
                style={{ color: 'rgba(37,37,37)', paddingRight: 100 }}
              >
                {userLang === 'zh-CN' &&
                  '研究表明，80%的女性平均仅将衣柜里的衣服穿五次'}
                {userLang !== 'zh-CN' &&
                  'Research shows 80% of women wear the clothing in their closets an average of 5 times.'}
              </Header>
              <Header
                as='h3'
                style={{
                  color: 'rgba(37,37,37)',
                  paddingRight: 100,
                  paddingBottom: 100
                }}
              >
                {userLang === 'zh-CN' &&
                  '租用可以减少购买服装的高额支出。既能满足想穿新衣服的愿望，又能减轻整理衣橱和洗衣服造成的负担。'}
                {userLang !== 'zh-CN' &&
                  'Renting can reduce high expenses on garments. Satisfy both the want to wear something new and release the stress of closet organizing and laundry.'}
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            style={{
              paddingTop: 0,
              marginTop: 0,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundImage:
                'url(https://res.cloudinary.com/sheyou/image/upload/v1654416265/warehouse_workers_wrapping_garment_stock_in_plasti_2022_03_07_23_58_56_utc_acd787db77.jpg)'
            }}
          >
            <Segment style={{ backgroundColor: 'rgba(0,0,0,0.3)' }}>
              <Grid centered style={{ paddingTop: 100, paddingBottom: 300 }}>
                <Header
                  style={{
                    color: 'white',
                    fontSize: 50,
                    paddingBottom: 100
                  }}
                >
                  {userLang === 'zh-CN' && '商户怎样使用平台'}
                  {userLang !== 'zh-CN' && 'How it Works For Merchants'}
                </Header>
                <Grid.Row columns={3}>
                  <Grid.Column width={4}></Grid.Column>
                  <Grid.Column width={8}>
                    <div>
                      <Header
                        as='h3'
                        style={{
                          color: 'white'
                        }}
                      >
                        {userLang === 'zh-CN'
                          ? '我们为个人、品牌、设计师和商家提供一个平台，用于出租、销售或交换他们的产品。'
                          : 'We provide individuals, brands, designers and merchants with a platform to rent out, sell, or trade their products.'}
                      </Header>

                      <Header
                        as='h3'
                        style={{
                          color: 'white'
                        }}
                      >
                        {userLang === 'zh-CN'
                          ? '一切都是高度可定制的。您可以选择您的设计价格以及想要出租的时长。'
                          : 'Everything is highly customizable. You can choose how much your design is and how long you want to rent it out.'}
                      </Header>
                      <Header
                        as='h3'
                        style={{
                          color: 'white'
                        }}
                      >
                        {userLang === 'zh-CN'
                          ? '您可以对客户进行评分，并决定他们是否需要支付押金。'
                          : 'You can rate your customers and decide if they need to pay deposit.'}
                      </Header>
                    </div>
                  </Grid.Column>
                  <Grid.Column width={4}></Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Grid.Row>
          <MerchantSec
            self={self}
            pic='https://res.cloudinary.com/sheyou/image/upload/v1654419339/smiling_asian_businesswoman_working_at_a_table_in_2022_02_02_03_47_55_utc_b10a057b58.jpg'
          />
        </Grid>

        {/* <Sidebar
          as={Menu}
          animation='overlay'
          // onHide={this.personalVisible}
          vertical
          visible={this.state.personalVisible === true}
          style={{ width: '40%' }}
          direction='right'
        >
          <Segment style={{ width: '100%', paddingBottom: 7, paddingTop: 7 }}>
            <Grid>
              <Grid.Row inline>
                <Grid.Column width={1}>
                  <Icon
                    name='close'
                    size='small'
                    onClick={self.personalVisible}
                    color='grey'
                    style={{
                      fontSize: 20,
                      paddingTop: 7
                    }}
                  />
                </Grid.Column>
                <Grid.Column width={15}>
                  <Grid>
                    <Grid.Row centered>
                      <Grid.Column>
                        <Header
                          as='h4'
                          color='teal'
                          textAlign='centered'
                          style={{
                            paddingBottom: 0,
                            marginBottom: 0,
                            fontSize: 30
                          }}
                        >
                          Open A Personal Shop
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <PersonalShopPop />

           
          </Segment>

        </Sidebar>

        <Sidebar
          as={Menu}
          animation='overlay'
          // onHide={this.personalVisible}
          vertical
          style={{ width: '40%' }}
          visible={this.state.companyVisible === true}
          direction='right'
        >
          <Segment style={{ width: '100%', paddingBottom: 7, paddingTop: 7 }}>
            <Grid>
              <Grid.Row columns={4}>
                <Grid.Column width={1}>
                  <Icon
                    name='close'
                    size='small'
                    onClick={self.companyVisible}
                    color='grey'
                    style={{
                      fontSize: 20,
                      paddingTop: 7
                    }}
                  />
                </Grid.Column>
                <Grid.Column width={15}>
                  <Grid>
                    <Grid.Row centered>
                      <Grid.Column>
                        <Header
                          as='h4'
                          color='teal'
                          textAlign='centered'
                          style={{
                            paddingBottom: 0,
                            marginBottom: 0,
                            fontSize: 30
                          }}
                        >
                          Open An Merchant Shop
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
            </Grid>

          </Segment>
          <EnterpriseShopPop />
        </Sidebar> */}

        <Foot />
      </div>
    )
  }
}

export default withRouter(MerchantOpenShop)
