import React from 'react'
import axios from 'axios'
import { Grid, Header, Icon, Segment, Button, Menu } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { Parallax } from 'react-parallax'
import { withRouter } from 'react-router-dom'

import PastOrders from '../../pastOrders/PastOrdersList'
import PastPastOrders from '../../pastOrders/PastPastOrderList'
import OngoingPastOrders from '../../pastOrders/OngoingtPastOrderList'

import LoadingPhone from '../../RedirectPages/LoadingPhone'

class MerchantOrderPhone extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      orders: [],
      loading: '',
      login: false,
      userInfo: '',
      userId: '',
      ongoingOrder: [],
      pastOrder: []
    }
    this.getMerchantInfo = this.getMerchantInfo.bind(this)
    this.sortOrders = this.sortOrders.bind(this)
    this.handleItemClick = this.handleItemClick.bind(this)
    this.toPrevios = this.toPrevios.bind(this)
  }

  componentDidMount () {
    const self = this

    var userInfo = localStorage.getItem('userInfo')
    userInfo = JSON.parse(userInfo)
    var userId = userInfo[0].id
    // console.log(userId, userInfo)
    self.setState({
      userInfo: userInfo,
      userId: userId
    })

    const merchantLogin = localStorage.getItem('merchantLogin')
    const login=localStorage.getItem('login')
    if (merchantLogin === 'true'&&login === 'true') {
      self.setState({
        login: true
      })
      self.getMerchantInfo(userId)
    }else{
      self.props.history.push('/merchantsLog')
    }
    
    window.onload = event => {
      self.setState({
        loading: false
      })
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 700)
  }

  toPrevios () {
    window.history.go(-1)
  }

  getMerchantInfo (userId) {
    const self = this
    // const identifier = localStorage.getItem('identifier')
    const token = localStorage.getItem('token')
    console.log(userId)

    axios('https://sheyou-backend.herokuapp.com/orders?merchants='+userId, {
      method: 'get',
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).then(function (res) {
      if (res.status === 200) {
        var d = res.data
        self.setState({orders:d,userId:userId})
       
      } else {
        alert('something went wrong :(')
        // localStorage.setItem('login', 'false')
      }
    })
  }

  sortOrders () {
    const self = this
    const s = self.state
    const userId = self.state.userId
    const d = s.orders
    // console.log('originl date', d)
    d.forEach(function (i) {
      const orderDataA = []
      const orderDataC = []
      i.orders.data.accessory.forEach(function (a) {
        // console.log(a)
        if (a.merchant === userId) {
          orderDataA.push(a)
        }
      })
      i.orders.data.accessory = orderDataA
      i.orders.data.clothing.forEach(function (c) {
        // console.log(c)

        if (c.merchant === userId) {
          orderDataC.push(c)
        }
      })
      i.orders.data.clothing = orderDataC
    })
    // console.log('new data', d)
  }

  handleItemClick (event, data) {
    console.log(event, data)
    const self = this
    const name = data.name
    if (name === 'Ongoing') {
      self.props.history.push('/merchantOrder/ongoing')
    } else if (name === 'Past') {
      self.props.history.push('/merchantOrder/past')
    } else if (name === 'All') {
      self.props.history.push('/merchantOrder')
    }
  }

  render () {
    const self = this
    const s = self.state
    const p = this.props
    var activeItem = p.activeItem
    console.log(this.state)

    const orders = s.orders
    console.log(orders)
    
    return (
      <>
        {this.state.loading ? (
          <LoadingPhone />
        ) : (
          <>
            {this.state.login === 'true' ? (
              <Parallax
                bgImage='https://i.loli.net/2020/11/25/ZHjYm1wL9runqOz.png'
                bgImageAlt=''
                strength={200}
              >
                <div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <Segment>
                    <Grid>
                      <Grid.Row centered>
                        <Header> You Should login first</Header>
                      </Grid.Row>

                      <Grid.Row centered>
                        <Link to='/signin'>
                          <Button size='huge' color='orange'>
                            Login
                          </Button>
                        </Link>
                      </Grid.Row>
                    </Grid>
                  </Segment>
                </div>

                <div style={{ height: '200px' }} />
              </Parallax>
            ) : (
              <>
                <Grid>
                  <Grid.Row
                    centered
                    style={{
                      borderBottom: '1.5px solid rgba(0,0,0,0.1)',
                      paddingTop: '30px'
                    }}
                  >
                    <Grid.Column width={1}>
                      <Icon
                        name='chevron left'
                        onClick={self.toPrevios}
                        style={{ paddingTop: 11 }}
                      />
                    </Grid.Column>
                    <Grid.Column width={14}>
                      <Menu secondary>
                        <Menu.Item header color='teal'>
                          My orders
                        </Menu.Item>
                        <Menu.Item
                          name='All'
                          active={activeItem === 'All'}
                          onClick={this.handleItemClick}
                        />
                        <Menu.Item
                          name='Ongoing'
                          active={activeItem === 'Ongoing'}
                          onClick={this.handleItemClick}
                        />
                        <Menu.Item
                          name='Past'
                          active={activeItem === 'Past'}
                          onClick={this.handleItemClick}
                        />
                      </Menu>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>

                {activeItem === 'All' && (
                  <>
                    {orders.length === 0 && (
                      <Grid>
                        <Grid.Row centered>
                          <Header centered color='orange' as='h1'>
                            {' '}
                            You haven no order yet
                          </Header>
                        </Grid.Row>
                      </Grid>
                    )}
                    {orders.length !== 0 && (
                      <>
                         
                        <Grid divided='vertically'>
                          <PastOrders
                            orders={orders}
                            userId={s.userId}
                            activeItem={activeItem}
                            isMerchantOrder={true}
                            mobile={true}
                          />
                        </Grid>
                      </>
                    )}
                  </>
                )}
                {activeItem === 'Ongoing' && (
                  <>
                    {orders.length === 0 && (
                      <Grid>
                        <Grid.Row centered>
                          <Header centered color='orange' as='h1'>
                            {' '}
                            You haven no order yet
                          </Header>
                         
                        </Grid.Row>
                      </Grid>
                    )}
                    {orders.length !== 0 && (
                      <>
                        <Grid divided='vertically'>
                          <OngoingPastOrders
                            orders={s.orders}
                            userId={s.userId}
                            activeItem={activeItem}
                            isMerchantOrder={true}
                            mobile={true}

                          />
                           
                        </Grid>
                      </>
                    )}
                  </>
                )}
                {activeItem === 'Past' && (
                  <>
                    {orders.length === 0 && (
                      <Grid>
                        <Grid.Row centered>
                          <Header centered color='orange' as='h1'>
                            {' '}
                            You haven no order yet
                          </Header>
                     
                        </Grid.Row>
                      </Grid>
                    )}
                    {orders.length !== 0 && (
                      <>
                        <Grid divided='vertically'>
                          <PastPastOrders
                            orders={s.orders}
                            userId={s.userId}
                            activeItem={activeItem}
                            isMerchantOrder={true}
                            mobile={true}
                          />
                        </Grid>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </>
    )
  }
}
export default withRouter(MerchantOrderPhone)
