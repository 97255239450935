import React from 'react'
import axios from 'axios'
import { Grid, Header, Icon, Segment, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

// import { Parallax,} from 'react-parallax';
// import Nav from '../nav/Nav'
import { withRouter } from 'react-router-dom'

class MerchantHome extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      totalIncome: 0,
      ordersNum: 0,
      incomePerOrder: 0,
      clothingNum: 0,
      accessoryNum: 0,
      productsNum: 0,
      descriptionRate: 0,
      serviceRate: 0,
      logisticsRate: 0
    }
    this.getMerchantInfo = this.getMerchantInfo.bind(this)
    this.getOrderInfo = this.getOrderInfo.bind(this)
    this.handleGoVerify = this.handleGoVerify.bind(this)
  }
  getOrderInfo () {
    const self = this
    var userInfo = localStorage.getItem('userInfo')
    userInfo = JSON.parse(userInfo)
    const id = userInfo[0].id
    axios('https://sheyou-backend.herokuapp.com/orders?merchants=' + id, {
      method: 'get'
    })
      .then(function (res) {
        console.log(res)
        const data = res.data
        const ordersNum = data.length

        var totalIncome = 0
        var incomePerOrder = 0
        for (var i = 0; i < ordersNum; i++) {
          totalIncome = totalIncome + data[i].orders.data.price
        }
        incomePerOrder = totalIncome / ordersNum

        self.setState({
          orders: data,
          ordersNum: ordersNum,
          totalIncome: totalIncome,
          incomePerOrder: incomePerOrder
        })
      })
      .catch(function (err) {
        console.log(err)
      })
  }

  getMerchantInfo () {
    const self = this
    var userInfo = localStorage.getItem('userInfo')
    userInfo = JSON.parse(userInfo)
    const id = userInfo[0].id
    axios(
      'https://sheyou-backend.herokuapp.com/products?users_permissions_user=' +
        id,
      {
        method: 'get'
      }
    )
      .then(function (res) {
        console.log(res)
        const data = res.data
        self.setState({
          clothingNum: data.length
        })
        axios(
          'https://sheyou-backend.herokuapp.com/accessaries?users_permissions_user=' +
            id,
          {
            method: 'get'
          }
        )
          .then(function (res) {
            console.log(res)
            const data = res.data
            const accessoryNum = data.length
            const productsNum = accessoryNum + self.state.clothingNum
            self.setState({
              accessoryNum: accessoryNum,
              productsNum: productsNum
            })
          })
          .catch(function (err) {
            console.log(err)
          })
      })
      .catch(function (err) {
        console.log(err)
      })
  }

  componentDidMount () {
    const self = this
    this.getMerchantInfo()
    this.getOrderInfo()
    window.onload = event => {
      self.setState({
        loading: false
      })
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 700)
  }

  handleGoVerify() {
    const self = this
    self.props.history.push('/merchantVerify/address')
  }

  render () {
    const p = this.props
    var merchantInfo = p.merchantInfo
    console.log(merchantInfo)
    var merchantVerified = []
    var storeName = 'Your Store'
    var account = 'Your Account'
    const isMerchantOnboard = p.isMerchantOnboard
    if (
      merchantInfo !== undefined &&
      merchantInfo.length !== 0 &&
      merchantInfo.length > 0
    ) {
      account = merchantInfo[0].username
      merchantInfo = merchantInfo[0].merchantInfo
      merchantVerified = merchantInfo.merchantVerified
      storeName = merchantInfo.storeName
      
    }

    return (
      <>
        {this.state.loading ? (
          <Grid>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>

            <Grid.Row colums={2}>
              <Grid.Column width={7}></Grid.Column>
              <Grid.Column width={6}>
                <Header size='huge'>
                  <Icon loading name='asterisk' size='massive' color='yellow' />
                  Loading...
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
          </Grid>
        ) : (
          <>
            <Grid stackable>
              <Grid.Row columns={2}>
                <Grid.Column width={7}>
                  <Segment>
                    <br />

                    <Grid>
                      <Grid.Row>
                        <Grid.Column
                          width={14}
                          style={{ paddingLeft: 50, paddingRight: 50 }}
                        >
                          <Grid stackable>
                            <Grid.Row
                              style={{ marginBottom: 15, marginTop: 12 }}
                              columns={2}
                            >
                              <Grid.Column width={14}>
                                <Header as='h2' style={{ color: '#767676' }}>
                                  {storeName}
                                </Header>
                              </Grid.Column>

                              <Grid.Column width={2}>
                                <Link to='/merchantpage/store'>
                                  <Button basic color='teal' size='small'>
                                    Edit
                                  </Button>
                                </Link>
                              </Grid.Column>
                            </Grid.Row>
                            {/* <Grid.Row style={{ paddingTop: 10, marginTop: 5 }}>
                              <Link to='/merchantpage/store'>
                                <Button
                                  basic
                                  color='teal'
                                  size='mini'
                                  style={{ paddingLeft: 20, paddingRight: 20 }}
                                >
                                  Edit Your Shop
                                </Button>
                              </Link>
                            </Grid.Row> */}
                          </Grid>
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row style={{ paddingLeft: 10, paddingRight: 10 }}>
                        <Grid.Column width={5}>
                          <Header as='h4'>Account</Header>
                        </Grid.Column>
                        <Grid.Column width={3}>
                          <p
                            style={{
                              color: '#767676',
                              position: 'relative',
                              top: -3
                            }}
                          >
                            {account}
                          </p>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row style={{ paddingLeft: 10, paddingRight: 10 }}>
                        <Grid.Column width={5}>
                          <Header as='h4'>Detail Info</Header>
                        </Grid.Column>
                        <Grid.Column width={9}>
                          <Link
                            to='/merchantpage/store'
                            style={{
                              color: '#767676',
                              position: 'relative',
                              top: -3
                            }}
                          >
                            {'Fill Out  '}
                            <Icon
                              name='chevron right'
                              style={{
                                color: '#767676',
                                position: 'relative',
                                top: -1
                              }}
                              color='grey'
                              size='small'
                            />
                          </Link>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row
                        columms={2}
                        style={{ paddingLeft: 10, paddingRight: 10 }}
                      >
                        <Grid.Column width={5}>
                          <Header as='h4'>Rating</Header>
                        </Grid.Column>
                        <Grid.Column width={11}>
                          <Grid >
                            <Grid.Row columns={6}>
                              <Grid.Column width={8}>
                                <p
                                  style={{
                                    color: '#767676',
                                    position: 'relative',
                                    top: -3
                                  }}
                                >
                                  Description
                                </p>
                              </Grid.Column>
                              <Grid.Column width={5}>
                                <Header as='h4'>
                                  {this.state.descriptionRate}
                                </Header>
                              </Grid.Column>

                              <Grid.Column width={8}>
                                <p
                                  style={{
                                    color: '#767676',
                                    position: 'relative',
                                    top: -3
                                  }}
                                >
                                  Service
                                </p>
                              </Grid.Column>
                              <Grid.Column width={5}>
                                <Header as='h4'>
                                  {this.state.serviceRate}
                                </Header>
                              </Grid.Column>

                              <Grid.Column width={8}>
                                <p
                                  style={{
                                    color: '#767676',
                                    position: 'relative',
                                    top: -3
                                  }}
                                >
                                  Logistics
                                </p>
                              </Grid.Column>
                              <Grid.Column width={5}>
                                <Header as='h4'>
                                  {this.state.logisticsRate}
                                </Header>
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    <br />
                  </Segment>
                </Grid.Column>
                <Grid.Column width={9}>
                  <Segment>
                    <br />
                    <Grid
                      style={{ paddingLeft: 20, paddingRight: 10 }}
                      stackable
                    >
                      <Grid.Row>
                        <Header>Store Data</Header>
                      </Grid.Row>

                      <Grid.Row
                        columns='equal'
                        style={{
                          height: window.innerWidth < 766 ? 200 : 92,
                          // paddingBottom: 15,
                          paddingTop: 20
                        }}
                      >
                        <Grid.Column>
                          <Grid
                            style={{
                              height: 80,
                              backgroundColor: 'rgb(249,250,251)',
                              marginRight: 5,
                              paddingLeft: 5
                            }}
                          >
                            <Grid.Row
                              style={{
                                paddingTop: 20,
                                paddingBottom: 0,
                                marginBottom: 0,
                                marginTop: 0
                              }}
                              centered
                            >
                              <p
                                style={{
                                  color: '#767676'
                                }}
                              >
                                Total Income
                              </p>
                            </Grid.Row>
                            <Grid.Row
                              style={{
                                paddingTop: 0,
                                paddingBottom: 0,
                                marginBottom: 0,
                                marginTop: 0
                              }}
                              centered
                            >
                              <Header as='h4'>{this.state.totalIncome}</Header>
                            </Grid.Row>
                            <Grid.Row
                              style={{
                                paddingTop: 0,
                                paddingBottom: 0,
                                marginBottom: 0,
                                marginTop: 0
                              }}
                              centered
                            >
                              <p
                                style={{
                                  color: '#767676',
                                  position: 'relative',
                                  top: -3
                                }}
                              >
                                {/* Yesterday 0 */}
                              </p>
                            </Grid.Row>
                          </Grid>
                        </Grid.Column>

                        <Grid.Column>
                          <Grid
                            style={{
                              height: 80,
                              backgroundColor: 'rgb(249,250,251)',
                              marginRight: 5
                            }}
                          >
                            <Grid.Row
                              style={{
                                paddingTop: 20,
                                paddingBottom: 0,
                                marginBottom: 0,
                                marginTop: 0
                              }}
                              centered
                            >
                              <p
                                style={{
                                  color: '#767676'
                                }}
                              >
                                Orders
                              </p>
                            </Grid.Row>
                            <Grid.Row
                              style={{
                                paddingTop: 0,
                                paddingBottom: 0,
                                marginBottom: 0,
                                marginTop: 0
                              }}
                              centered
                            >
                              <Header as='h4'>{this.state.ordersNum}</Header>
                            </Grid.Row>
                            <Grid.Row
                              style={{
                                paddingTop: 0,
                                paddingBottom: 0,
                                marginBottom: 0,
                                marginTop: 0
                              }}
                              centered
                            >
                              <p
                                style={{
                                  color: '#767676',
                                  position: 'relative',
                                  top: -3
                                }}
                              >
                                {/* Yesterday 0 */}
                              </p>
                            </Grid.Row>
                          </Grid>
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row
                        columns='equal'
                        style={{
                          height: window.innerWidth < 766 ? 200 : 92,
                          paddingBottom: 20
                        }}
                      >
                        <Grid.Column>
                          <Grid
                            style={{
                              backgroundColor: 'rgb(249,250,251)',
                              height: 80,
                              marginRight: 5,
                              paddingLeft: 5
                            }}
                          >
                            <Grid.Row
                              style={{
                                paddingTop: 20,
                                paddingBottom: 0,
                                marginBottom: 0,
                                marginTop: 0
                              }}
                              centered
                            >
                              <p
                                style={{
                                  color: '#767676'
                                }}
                              >
                                Income Per Order
                              </p>
                            </Grid.Row>
                            <Grid.Row
                              style={{
                                paddingTop: 0,
                                paddingBottom: 0,
                                marginBottom: 0,
                                marginTop: 0
                              }}
                              centered
                            >
                              <Header as='h4'>
                                {this.state.incomePerOrder}
                              </Header>
                            </Grid.Row>
                          </Grid>
                        </Grid.Column>

                        <Grid.Column>
                          <Grid
                            style={{
                              backgroundColor: 'rgb(249,250,251)',
                              height: 80,
                              marginRight: 5,
                              paddingLeft: 5
                            }}
                          >
                            <Grid.Row
                              style={{
                                paddingTop: 20,
                                paddingBottom: 0,
                                marginBottom: 0,
                                marginTop: 0
                              }}
                              centered
                            >
                              <p
                                style={{
                                  color: '#767676'
                                }}
                              >
                                Products
                              </p>
                            </Grid.Row>
                            <Grid.Row
                              style={{
                                paddingTop: 0,
                                paddingBottom: 0,
                                marginBottom: 0,
                                marginTop: 0
                              }}
                              centered
                            >
                              <Header as='h4'>{this.state.productsNum}</Header>
                            </Grid.Row>
                          </Grid>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    <br />
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>

            {(merchantInfo === undefined ||
              merchantInfo.length === 0 ||
              merchantVerified !== true) && (
              <Segment style={{marginTop: '20px', marginBottom:'20px'}}>
                <Grid centered style={{ paddingLeft: '20px', paddingRight: '10px', paddingBottom:'20px' }}>
                  <Grid.Row>
                    <Header>Get verified to upload products and start earning</Header>
                  </Grid.Row>

                  <Grid.Row>
                    <Button 
                      size='large' 
                      color='orange' 
                      onClick={() => this.props.history.push('/onBoardStripe')}
                    >
                      Start Onboarding
                    </Button>
                  </Grid.Row>
                </Grid>
              </Segment>
            )}

            {merchantInfo !== undefined &&
              merchantInfo.length !== 0 &&
              merchantVerified === true && (
                <Segment style={{marginTop: '20px', marginBottom:'20px'}}>
                  <br />
                  <Grid>
                    <Grid.Row>
                      <Grid.Column>
                        <Header size='big' textAlign='centered'>
                          <Icon name='smile outline' size='massive' />
                          You Are Verified
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row centered>
                      <Link to='/merchantpage/products'>
                        <Button color='yellow' style={{marginRight: '10px'}}>Upload Product</Button>
                      </Link>
                      <a href='https://dashboard.stripe.com/' target='_blank' rel='noopener noreferrer'>
                        <Button color='teal'>
                          <Icon name='stripe' />
                          Manage Stripe Account
                        </Button>
                      </a>
                    </Grid.Row>
                  </Grid>
                  <br />
                </Segment>
              )}
          </>
        )}
      </>
    )
  }
}

export default withRouter(MerchantHome)
