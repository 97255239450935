import { useState, useEffect } from 'react'
import { loadConnectAndInitialize } from '@stripe/connect-js'
import axios from 'axios'
//live: 
const BACKEND_URL ='https://sheyou-backend.herokuapp.com'
//test
// const BACKEND_URL = 'http://localhost:1337';

export const useStripeConnect = connectedAccountId => {
  const [stripeConnectInstance, setStripeConnectInstance] = useState()
  const token = localStorage.getItem('token')

  useEffect(() => {
    if (connectedAccountId) {
      const fetchClientSecret = async () => {
        try {
          const response = await axios.post(`${BACKEND_URL}/pay/stripeAccountSession`, {
            account: connectedAccountId
          }, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token
            }
          });

          if (response.status !== 200) {
            const { error } = response.data;
            throw new Error("An error occurred: " + error);
          } else {
            const { client_secret: clientSecret } = response.data;
            return clientSecret;
          }
        } catch (error) {
          console.error("Error fetching client secret:", error);
          throw error;
        }
      }

      setStripeConnectInstance(
        loadConnectAndInitialize({
          publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
          fetchClientSecret,
          appearance: {
            overlays: 'dialog',
            variables: {
              colorPrimary: '#f2711c', // Semantic UI's orange
              colorBackground: '#ffffff',
              colorText: 'rgba(0, 0, 0, 0.87)', // Semantic UI's default text color
              fontFamily: 'Lato, "Helvetica Neue", Arial, Helvetica, sans-serif', // Semantic UI's font stack
              fontSizeBase: '14px',
              borderRadius: '4px',
              spacingUnit: '4px'
            },
            elements: {
              button: {
                backgroundColor: '#f2711c',
                color: '#ffffff',
                '&:hover': {
                  backgroundColor: '#f26202' // Darker orange on hover
                }
              },
              input: {
                borderColor: 'rgba(34, 36, 38, 0.15)', // Semantic UI's input border color
                '&:focus': {
                  borderColor: '#85b7d9' // Semantic UI's focus border color
                }
              }
            }
          }
        })
      )
    }
  }, [connectedAccountId])

  return stripeConnectInstance
}

export default useStripeConnect
